import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import DesignFamiliesTable from 'business/resources/design/components/designFamiliesTable';
import { useGetDesignFamilies } from 'business/resources/design/services/useGetDesignFamilies';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const DesignFamiliesPage: React.FC = () => {
  const { data, tableProps } = useGetDesignFamilies();
  const { onExport, loading } = useDownloadTableToXlsx();

  return (
    <ConnectedPage translationKey="designs">
      <DesignFamiliesTable
        data-test-id="designFamiliesTable"
        dataSource={data}
        loadingExport={loading}
        onExport={(columns) => {
          onExport(columns, 'designFamily');
        }}
        {...tableProps}
      />
    </ConnectedPage>
  );
};

export default DesignFamiliesPage;

import React from 'react';
import Page from 'ui/layout/page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

const DisconnectedPage = (props: Props) => {
  return <Page {...props} isConnected={false} />;
};

export default DisconnectedPage;

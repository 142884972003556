import { useLoggedInAppContext } from 'business/AppBootstrapper';
import useUpdateUserForm from 'business/configuration/accessManagement/components/forms/hooks/useUpdateUserForm';
import { UserModal } from 'business/configuration/accessManagement/components/modals/user.modal';
import { UserWithGroups } from 'business/groups/services/types';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Table from 'ui/table';
import { TableProps } from 'ui/table/types';
import TagResults from 'ui/tag/tagResults';
import defaultColumns from './columns';
import { DeleteOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import useTableActions from 'business/configuration/accessManagement/components/tables/services/useGetUserTableActions';

interface UserGroupsTableProps
  extends Omit<TableProps<UserWithGroups>, 'defaultColumns'> {
  groups: { label: string; value: string }[];
  refetch?: () => void;
  companyId: number;
}

const UserGroupsTable: React.FC<UserGroupsTableProps> = ({
  groups,
  refetch,
  companyId,
  ...props
}) => {
  const { t } = useTranslation();
  const { user: currentUser } = useLoggedInAppContext();
  const { setUser, user, form, onEdit } = useUpdateUserForm({ refetch });
  const { sendBackRegistration, isUserActivePending, confirmDeleteUserGroup } =
    useTableActions({ refetch, currentUser, user: user ? user : {} });

  const actions = [
    {
      tooltipTitle: t('common.edit'),
      icon: <EditOutlined />,
      dataTestId: 'editUserButton',
      onClick: (userData: UserWithGroups) => {
        setUser(userData);
      },
    },

    {
      tooltipTitle: t('common.delete'),
      icon: <DeleteOutlined />,
      dataTestId: 'deleteUserButton',
      onClick: (userData: UserWithGroups) => {
        confirmDeleteUserGroup(userData.companyGroup[0].id);
      },
    },
    {
      tooltipTitle: t('pages.admin.bdlUsers.table.actions.tooltips.sendEmail'),
      icon: <MailOutlined />,
      dataTestId: 'sendBackEmailButton',
      hide: isUserActivePending,
      onClick: (userData: UserWithGroups) => {
        sendBackRegistration(userData.id, userData.email);
      },
    },
  ];

  return (
    <>
      <Table<UserWithGroups>
        id="userGroupsTable"
        {...props}
        defaultColumns={defaultColumns(
          t,
          {
            currentUser,
            editUser: setUser,
            refetch,
          },
          actions,
        )}
        header={
          <Flex alignItems="center">
            <h1 className="headerTitle" style={{ marginRight: 10 }}>
              {t('pages.companyDetails.users')}
            </h1>
            <TagResults
              total={props.pagination?.total ?? 0}
              dataTestId="nbUsers"
            />
          </Flex>
        }
      />
      {!!user && (
        <UserModal
          form={form}
          visible={!!user.id}
          onSubmit={onEdit}
          handleClose={() => setUser(undefined)}
          user={user}
          groupOptions={groups}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default UserGroupsTable;

import { Checkbox, DatePicker, Form } from 'antd';
import { useState } from 'react';
import Spacer from 'ui/spacer';

const RequestOptionFormItem = ({
  request,
}: {
  request: {
    code: string;
    label: string;
    datePicker?: boolean;
  };
}) => {
  const [displayYearCalendar, setDisplayYearCalendar] = useState(false);

  return (
    <Form.Item key={request.code} name={request.code} valuePropName="checked">
      <Spacer size="small" direction="vertical">
        <Checkbox
          onClick={() =>
            request.datePicker
              ? setDisplayYearCalendar(!displayYearCalendar)
              : undefined
          }
          style={{ marginLeft: 0 }}
        >
          {request.label}
        </Checkbox>
        {displayYearCalendar && request.datePicker ? (
          <Form.Item name="yearReportDate">
            <DatePicker picker="year" />
          </Form.Item>
        ) : null}
      </Spacer>
    </Form.Item>
  );
};

export default RequestOptionFormItem;

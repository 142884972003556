import { Input } from 'antd';
import AdvancedFiltersDrawer from 'business/advancedSearch/components/advancedFiltersDrawer';
import AdvancedSearchTabs from 'business/advancedSearch/components/advancedSearchTabs';
import { useFetchAllResources } from 'business/advancedSearch/hooks/useFetchAllResources';
import useResourceAdvancedFilters from 'business/advancedSearch/hooks/useResourceAdvancedFilters';
import { TabsFamily } from 'business/common/services/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { removeUndefinedField } from 'technical/validation/index';
import Spacer from 'ui/spacer';
import styles from './index.module.scss';

interface AdvancedSearchPageProps {
  family: TabsFamily;
  setFamily: (family: TabsFamily) => void;

  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;

  search?: string;
  setSearch: (search: string) => void;
}

const AdvancedSearch = ({
  family,
  setFamily,
  search,
  setSearch,
  filtersOpen,
  setFiltersOpen,
}: AdvancedSearchPageProps) => {
  const { t } = useTranslation();

  const {
    components,
    advancedFilters,
    setAdvancedFilters,
    resetToPreviousValue,
  } = useResourceAdvancedFilters(family);

  const { resourcesData, firstNonEmptyFamily } = useFetchAllResources(
    advancedFilters,
    setAdvancedFilters,
    resetToPreviousValue,
    search,
  );

  const openFilters = () => setFiltersOpen(true);
  const closeFilters = () => setFiltersOpen(false);

  useEffect(() => {
    if (search && !advancedFilters[family]?.filters) {
      setFamily(firstNonEmptyFamily);
    }
  }, [search, firstNonEmptyFamily]);

  return (
    <div>
      <Spacer align="center" direction="vertical" size="small">
        <h1 className={styles.titleAdvancedSearch}>
          {t('table.common.advancedSearch')}
        </h1>
        <Input.Search
          defaultValue={search}
          onSearch={setSearch}
          className={styles.searchBar}
        />
      </Spacer>
      <AdvancedSearchTabs
        family={family}
        filters={advancedFilters}
        data={resourcesData}
        onClickTabFamily={setFamily}
        onClickAdvancedFilter={openFilters}
      />
      <AdvancedFiltersDrawer
        family={family}
        open={filtersOpen}
        onClose={closeFilters}
        filtersValues={advancedFilters[family]?.filters}
        onApplyFilters={(newFilters, closeDrawer = true) => {
          setAdvancedFilters({
            ...advancedFilters,
            [family]: {
              ...advancedFilters[family],
              filters: removeUndefinedField(newFilters),
            },
          });
          if (closeDrawer) {
            closeFilters();
          }
        }}
        filterComponents={components}
      />
    </div>
  );
};

export default AdvancedSearch;

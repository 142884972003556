import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

interface CompanyDescriptionProps {
  companyRef: string;
  name: string;
  address: string;
}

const CompanyDescription = ({
  companyRef,
  name,
  address,
}: CompanyDescriptionProps) => {
  const { t } = useTranslation();
  return (
    <Descriptions layout="horizontal" size="small" className="flex1" column={1}>
      <Descriptions.Item label={t('table.common.column.socialReason')}>
        {companyRef}
      </Descriptions.Item>
      <Descriptions.Item label={t('table.common.column.name')}>
        {name}
      </Descriptions.Item>
      <Descriptions.Item label={t('metadata.address')}>
        {address}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CompanyDescription;

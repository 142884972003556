import { Form, Input } from 'antd';
import CompanyKeywordsSelect from 'business/configuration/keywords/components/companyKeywordsSelect';
import { useTranslation } from 'react-i18next';

interface ArchiveInfoFormProps {
  companyId?: number | null;
}

const ArchiveInfoForm = ({ companyId }: ArchiveInfoFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="clientReference1"
        label={t('pages.archive.form.clientRef1Label')}
        rules={[{ required: true, message: t('errors.required') }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        name="clientReference2"
        label={t('pages.archive.form.clientRef2Label')}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        name="description"
        label={t('pages.archive.form.descriptionLabel')}
      >
        <Input.TextArea maxLength={140} rows={4} />
      </Form.Item>

      {companyId ? (
        <CompanyKeywordsSelect companyId={companyId} />
      ) : (
        <Form.Item label={t('pages.archive.form.keywordLabel')}>
          <Input
            disabled
            placeholder={t('pages.archive.form.keywordTooltip')}
          />
        </Form.Item>
      )}
    </>
  );
};

export default ArchiveInfoForm;

import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import Button from '.';

const RedirectionButton = ({
  to,
  text,
}: {
  to: LocationDescriptor;
  text: string;
}) => {
  return (
    <Link to={to}>
      <Button className="searchButton" type="primary">
        {text}
      </Button>
    </Link>
  );
};

export default RedirectionButton;

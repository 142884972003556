import {
  useGetArchiveInvoiceDownloadUrlLazyQuery,
  useGetDomainInvoiceDownloadUrlLazyQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { assertIsDefined } from 'technical/assert';
import { openInNewTab } from 'technical/router/helpers';
import { InvoiceType } from 'business/invoice/services/types';

type DownloadUrlHook = () => readonly [
  (param: { variables: { id: number } }) => Promise<{
    data?: { getInvoicePdf?: { downloadUrl?: string | null } | null };
    error?: any;
  }>,
  any,
];

const useOpenInvoiceInNewTabHooks: Record<InvoiceType, DownloadUrlHook> = {
  domain: useGetDomainInvoiceDownloadUrlLazyQuery,
  archive: useGetArchiveInvoiceDownloadUrlLazyQuery,
};

const useOpenInvoiceInNewTab = (type: InvoiceType) => {
  const { t } = useTranslation();

  const [getInvoice] = useOpenInvoiceInNewTabHooks[type]();

  const openArchiveInvoiceInNewTab = async (id: number) => {
    const { data: invoiceFileData, error: invoiceFileError } = await getInvoice(
      {
        variables: { id },
      },
    );
    const downloadUrl = invoiceFileData?.getInvoicePdf?.downloadUrl;

    assertIsDefined(downloadUrl, {
      error: invoiceFileError,
      message: t('errors.error', { context: 'downloadArchiveInvoice' }),
    });
    openInNewTab(downloadUrl);
  };

  return [openArchiveInvoiceInNewTab] as const;
};

export default useOpenInvoiceInNewTab;

import { useUpdateDefaultKeywordsMutation } from 'generated/graphql';

const useUpdateDefaultKeywords = () => {
  const [updateDefaultKeywordsMutation] = useUpdateDefaultKeywordsMutation();

  const updateDefaultKeywords = (keywords: string[]) => {
    return updateDefaultKeywordsMutation({
      variables: {
        keywords,
      },
    });
  };

  return {
    updateDefaultKeywords,
  };
};

export default useUpdateDefaultKeywords;

import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import { TFunction } from 'i18next';
import { Log } from 'business/admin/services/types';
import { RangePicker } from 'ui/date/datePicker';

type LogsTableProps = Omit<TableProps<Log>, 'defaultColumns'>;

export const defaultColumns = (t?: TFunction): ColumnGenerationData<Log> => {
  return [
    {
      key: 'date',
    },
    {
      key: 'firstName',
    },
    {
      key: 'email',
      render: (_: any, record: Log) => {
        return (
          <p className="noStyle" data-test-id="log-email">
            {record.email}
          </p>
        );
      },
    },
    {
      key: 'action',
      render: (_: any, record: Log) => {
        return (
          <p className="noStyle" data-test-id="log-action">
            {t && t(`log.action.type.${record.action}`)}
          </p>
        );
      },
    },
    { key: 'details' },
  ];
};

const LogsTable = ({
  filtersValues,
  setFilterValue,
  ...props
}: LogsTableProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Table
        id="logsTable"
        {...props}
        customFilters={
          <RangePicker
            size="large"
            disabledDate={
              (current) => !!(new Date(current.toString()) > new Date()) // cannot select date after today
            }
            value={
              filtersValues.dateRange
                ? [filtersValues.dateRange.from, filtersValues.dateRange.to]
                : null
            }
            onChange={(dates) => {
              return dates && dates[0] && dates[1]
                ? setFilterValue('dateRange', {
                    from: dates[0],
                    to: dates[1],
                  })
                : setFilterValue('dateRange', null);
            }}
          />
        }
        filtersValues={filtersValues}
        setFilterValue={setFilterValue}
        defaultColumns={defaultColumns(t)}
        header={<h1 className="headerTitle">{t('pages.logs.title')}</h1>}
      />
    </div>
  );
};

export default LogsTable;

import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import LinkSettings from 'ui/linkSettings';
import Spacer from 'ui/spacer';

interface ConfigurationLinksProps {
  onClick: () => void;
  onClickButtonText: string;
  links: {
    path: string;
    hidden?: boolean;
    text: string;
  }[];
}

const ConfigurationLinks = ({
  onClick,
  links,
  onClickButtonText,
}: ConfigurationLinksProps) => {
  return (
    <Spacer wrap align="center" direction="horizontal" size="small">
      <Button type="link" onClick={onClick} data-test-id="addUserButton">
        <PlusCircleOutlined />
        {onClickButtonText}
      </Button>
      {links.map(
        ({ path, text, hidden }) =>
          !hidden && (
            <LinkSettings
              key={path}
              icon={<SettingOutlined />}
              pathname={path}
              text={text}
            />
          ),
      )}
    </Spacer>
  );
};

export default ConfigurationLinks;

import {
  GetDomainFamilySearchQuery,
  GetDomainFamilySearchQueryVariables,
  useGetDomainFamilySearchQuery,
} from 'generated/graphql';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { StorageKey, storageKeys } from 'technical/storage/constants';
import { DomainFamily, DomainOfFamily } from 'business/resources/domain/types';

export const useGetDomainFamily = ({
  filterValues,
  overrideOrderBy,
  storageKey,
  search,
}: {
  filterValues: GetDomainFamilySearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<DomainFamily>;
  storageKey?: StorageKey;
  search?: string;
}) =>
  useQueryForTable<
    DomainOfFamily,
    GetDomainFamilySearchQuery,
    GetDomainFamilySearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKey ?? storageKeys.domainFamilies,
    commonFiltersKeys: ['search'],
    useQuery: (filters) =>
      useGetDomainFamilySearchQuery({
        variables: {
          where: {
            ...filterValues,
            search: search ?? filters.searchText,
            isOwn: filters.isOwn,
          },
          ...filters,
        },
      }),
    getTotal: (res) => res?.domainSearch?.count ?? 0,
    map: (res) => res?.domainSearch?.resources ?? [],
  });

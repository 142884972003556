import { TFunction } from 'i18next';

export const userGroupsFormListName = 'userGroups';

export const USER_GROUPS_MODAL_RULES = (t: TFunction) => ({
  [userGroupsFormListName]: {
    groupId: [
      {
        required: true,
        message: t('errors.required'),
      },
    ],
  },
});

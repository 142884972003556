import { isBdlAdmin } from 'business/admin/services/permission';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { TFunction } from 'i18next';
import Spacer from 'ui/spacer';
import ActionButton from 'ui/table/tableActions/actionButton';
import { ITableAction } from 'business/configuration/accessManagement/components/tables/services/types';
import { ColumnGenerationData } from 'ui/table/types';
import { BdlUser } from 'business/admin/services/types';

const defaultColumns = (
  t: TFunction,
  currentUser: UserWithPrivateInfos,
  actions: ITableAction<BdlUser>[],
): ColumnGenerationData<BdlUser> => [
  { key: 'firstName' },
  { key: 'lastName' },
  { key: 'email', alwaysVisible: true },
  {
    key: 'role',
    render: (role: string) => t(`pages.admin.bdlUsers.roles.${role}`),
  },
  { key: 'lastConnection' },
  {
    key: 'isActive',
    alwaysVisible: true,
    render: (_val: string, { status }: BdlUser) =>
      t('user.status', {
        context: status,
      }),
  },
  {
    key: 'enabledMFA',
    checked: false,
  },
  {
    key: 'isActive',
    translationKey: 'actions',
    fixed: 'right',
    sorter: false,
    alwaysVisible: true,
    disabled: !isBdlAdmin(currentUser),
    render: (_val: any, data: BdlUser) => {
      return (
        <Spacer size="xxsmall">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.dataTestId}
                hide={action.hide}
                tooltipTitle={action.getTooltipTitle(data)}
                dataTestId={action.dataTestId}
                icon={action.getIcon(data)}
                disabled={action.isDisabled?.(data)}
                onClick={() => action.onClick(data)}
              />
            );
          })}
        </Spacer>
      );
    },
  },
];

export default defaultColumns;

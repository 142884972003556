export function isDefined<T>(data: T | null | undefined): data is T {
  return data !== null && data !== undefined;
}

type EnsureDefined<T, R extends keyof T> = {
  [K in keyof T]: K extends R ? NonNullable<T[K]> : T[K];
};

export function ensureFieldsAreDefined<
  T extends { [keys: string]: any },
  Keys extends keyof T,
>(object: T, keys: Keys[]): EnsureDefined<T, Keys> {
  keys.forEach((key) => {
    if (!isDefined(object[key])) {
      throw new Error(
        `[ENSURE DEFINED] Object has key '${String(key)}' that is not defined`,
      );
    }
  });
  return object;
}

export function removeUndefinedField<T extends { [keys: string]: any }>(
  obj: T,
) {
  return Object.keys(obj)
    .filter((k) =>
      Array.isArray(obj[k]) ? obj[k].length > 0 : isDefined(obj[k]),
    )
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
}

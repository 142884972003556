import { useTranslation } from 'react-i18next';
import DnsServerTable from 'business/resources/domain/components/dnsServerTable';
import { Records } from 'ui/simpleTable';

interface PublicDnsServerTableProps<T> {
  servers: T[];
}

const PublicDnsServerTable = <T extends Records>({
  servers,
}: PublicDnsServerTableProps<T>) => {
  const { t } = useTranslation();

  return (
    <DnsServerTable
      dataSource={servers}
      subTitle={t('pages.domain.dnsPublicData.servers.title')}
    />
  );
};

export default PublicDnsServerTable;

import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Table from 'ui/table';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';
import { TRADEMARK_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';

interface ITrademarkAcumass {
  filters: OsrFilterState;
}

const TrademarkAcumass = (props: ITrademarkAcumass) => {
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);

  const { data: newEntriesData, tableProps: newEntriesTableProps } =
    useGetTrademarkOsrData('NEW_ENTRIES', {
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    });
  const { data: newRegistrationsData, tableProps: newRegistrationsTableProps } =
    useGetTrademarkOsrData('NEW_REGISTRATIONS', {
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    });

  return (
    <OsrTabs
      actions={
        <Button type="primary" onClick={() => generateExcel('ACUMASS')}>
          Exporter les tableaux
        </Button>
      }
      tabsItems={[
        {
          key: 'newEntries',
          label: `Nouvelles entrées (${newEntriesTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_RENEW_COLUMNS}
              {...newEntriesTableProps}
              dataSource={newEntriesData}
            />
          ),
        },
        {
          key: 'newRegistration',
          label: `Nouveaux enregistrements (${newRegistrationsTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_RENEW_COLUMNS}
              {...newRegistrationsTableProps}
              dataSource={newRegistrationsData}
            />
          ),
        },
      ]}
    />
  );
};

export default TrademarkAcumass;

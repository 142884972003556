const ALL_DIGITS_NUMBER = 9876543210;
const RANDOM_NUMBER = 12345.6;

/**
 * Localized number parsing from Intl formatting
 * https://observablehq.com/@mbostock/localized-number-parsing
 *
 * Basically reverse engineered the parser from the formatter by replacing each special char in
 * the parsed string by the one you get by formatting the RANDOM_NUMBER number, which
 * contains all possible number parts (digits, group separator and decimal separator)
 */
class NumberParser {
  private group;

  private decimal;

  private numeral;

  private index;

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(RANDOM_NUMBER);
    const numerals = new Intl.NumberFormat(locale, { useGrouping: false })
      .format(ALL_DIGITS_NUMBER)
      .split('')
      .reverse();
    const indexMap = new Map(numerals.map((d, i) => [d, i]));
    this.group = new RegExp(
      `[${parts.find((d) => d.type === 'group')?.value}]`,
      'g',
    );
    this.decimal = new RegExp(
      `[${parts.find((d) => d.type === 'decimal')?.value}]`,
    );
    this.numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this.index = (d: string) => `${indexMap.get(d)}`;
  }

  /**
   * Parse a number from a localized string
   * @param string The string to parse the number
   * @returns the parsed number or NaN if a number couldn't be parsed
   */
  parse(string: string): number {
    const res = string
      .trim()
      .replace(this.group, '')
      .replace(this.decimal, '.')
      .replace(this.numeral, this.index);
    return res ? +res : NaN;
  }
}

export default NumberParser;

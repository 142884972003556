import { ReloadOutlined } from '@ant-design/icons';
import { Button, Result, ResultProps } from 'antd';
import { useTranslation } from 'react-i18next';

export type ErrorResultProps = Omit<ResultProps, 'status'> & {
  refetch?: () => void;
  error?: {
    name: string;
    code?: string;
    message?: string;
  };
};

const ErrorResult = ({ refetch, error, ...props }: ErrorResultProps) => {
  const { t } = useTranslation();

  return (
    <Result
      status="error"
      title={t('errors.error')}
      subTitle={t('errors.generic')}
      extra={
        refetch ? (
          <Button
            size="small"
            icon={<ReloadOutlined />}
            type="primary"
            onClick={() => refetch()}
          >
            {t('common.reload')} {error?.code}
          </Button>
        ) : null
      }
      {...props}
    />
  );
};

export default ErrorResult;

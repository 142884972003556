import {
  useGetDesignFamiliesImagesQuery,
  useGetDesignFamilyImageQuery,
  useGetDesignFamilyImagesQuery,
  useGetDesignImagesListQuery,
  useGetDesignImagesQuery,
} from 'generated/graphql';
import { ImageQueryHook } from 'ui/imageDisplay';

export const useGetDesignFamilyImages = (familyId: string) => {
  const { data, loading, error } = useGetDesignFamilyImagesQuery({
    variables: { id: familyId },
  });
  return {
    images: data?.design.flatMap(({ images }) => images) ?? [],
    loading,
    error,
  };
};

export const getDesignImageQuery =
  (designId: number): ImageQueryHook =>
  ({ skip }) => {
    const { data, ...rest } = useGetDesignImagesQuery({
      variables: { designId },
      skip,
    });
    return { data: { image: data?.image?.[0] }, ...rest };
  };

export const getDesignFamilyImageQuery =
  (designFamilyId: string): ImageQueryHook =>
  ({ skip }) =>
    useGetDesignFamilyImageQuery({
      variables: { designFamilyId },
      skip,
    });

export const useGetDesignFamiliesImages = (ids: string[]) => {
  const { data, loading } = useGetDesignFamiliesImagesQuery({
    variables: { ids },
  });

  return {
    imageList: data?.getDesignImagesByFamilyListIds ?? [],
    loading,
  };
};

export const useGetDesignImages = (ids: string[]) => {
  const { data, loading } = useGetDesignImagesListQuery({
    variables: { ids },
  });

  return {
    imageList: data?.getDesignImagesByListIds ?? [],
    loading,
  };
};

import { isBdlAdmin } from 'business/admin/services/permission';
import Routes from 'business/router/routes';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { TFunction } from 'react-i18next';
import { generatePath } from 'react-router';

const getConfigurationLinks = (
  t: TFunction,
  user: UserWithPrivateInfos,
  companyId: number,
) => [
  {
    path: generatePath(Routes.CompanyDomainInvoicingConfiguration, {
      id: companyId,
    }),
    hidden: !isBdlAdmin(user),
    text: t('configLink.domainSetting'),
  },
  {
    path: generatePath(Routes.CompanyGroups, { id: companyId }),
    text: t('configLink.groupsSetting'),
  },
  {
    path: generatePath(Routes.CompanyArchiveConfiguration, {
      id: companyId,
    }),
    text: t('configLink.storageSetting'),
  },
];

export default getConfigurationLinks;

import { PatentFamilies } from 'business/resources/patent/types';
import {
  GetPatentFamiliesSearchQuery,
  GetPatentFamiliesSearchQueryVariables,
  useGetPatentFamiliesSearchQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetPatentFamilies = () =>
  useQueryForTable<
    PatentFamilies,
    GetPatentFamiliesSearchQuery,
    GetPatentFamiliesSearchQueryVariables
  >({
    subStorageKey: storageKeys.patentFamilies,
    commonFiltersKeys: ['search', 'isEffective'],
    useQuery: ({ searchText, isEffective, ...filters }) =>
      useGetPatentFamiliesSearchQuery({
        variables: {
          where: {
            isEffective,
            search: searchText,
          },
          ...filters,
        },
      }),
    getTotal: (res) => res?.patentFamilySearch?.count ?? 0,
    map: (res) => res?.patentFamilySearch?.resources ?? [],
  });

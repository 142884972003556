import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProperty } from 'technical/types';
import { UserGroupsFormValues } from 'business/configuration/accessManagement/components/modals/types';
import { useUpdateUserGroups } from 'business/configuration/accessManagement/services/useUpdateUserGroups';
import { User } from 'business/configuration/accessManagement/services/types';

export const useUpdateUserGroupsForm = ({
  user,
  handleClose,
}: {
  user: User;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<UserGroupsFormValues>();
  const { updateUserGroups } = useUpdateUserGroups();

  const onSubmitForm = async (values: UserGroupsFormValues) => {
    const userGroupToDelete = user.groups.map(getProperty('id'));
    const userGroupToInsert = values.userGroups.map(({ groupId }) => ({
      userId: user.id,
      groupId,
    }));

    try {
      await updateUserGroups({
        userId: user.id,
        userGroupToDelete,
        userGroupToInsert,
      });
      notification.success({
        message: t('user.groupModal.notifications.success'),
      });
      form.resetFields();
      handleClose();
    } catch (err) {
      notification.error({
        message: t('user.groupModal.notifications.error'),
      });
    }
  };

  return {
    onSubmitForm,
    form,
  };
};

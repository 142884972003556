import {
  createPassword,
  forgotPassword,
  getAccessToken,
  getAuthResult,
  goToLogin,
  initAuthentication,
  isAuthenticated,
  logout,
  renewToken,
  requestLoginCallback,
  resetPassword,
  signIn,
} from 'technical/auth/providers/custom';

const authService = {
  goToLogin,
  isAuthenticated,
  signIn,
  requestLoginCallback,
  getAuthResult,
  getAccessToken,
  initAuthentication,
  logout,
  requestPasswordReset: forgotPassword,
  resetPassword,
  renewToken,
  createPassword,
};

export default authService;

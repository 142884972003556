import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import SubmitButtonForm from 'ui/submitButtonForm';
import { useUpdatePasswordForm } from 'business/configuration/accessManagement/components/forms/hooks/useUpdatePasswordForm';
import { ApolloError } from '@apollo/client';
import PasswordConfirmationForm from 'ui/form/passwordConfirm';
import { UpdatePasswordFormValues } from './types';

const UserProfilePasswordForm = () => {
  const { updatePassword } = useUpdatePasswordForm();
  const { t } = useTranslation();
  const [form] = Form.useForm<UpdatePasswordFormValues>();

  const onError = (err: unknown) => {
    form.setFields([
      {
        name: 'currentPassword',
        errors: [
          t('pages.profile.form.passwordUpdate.notifications.error', {
            context: err instanceof ApolloError ? err.message : null,
          }),
        ],
      },
    ]);
  };

  const onFinish = async (values: UpdatePasswordFormValues) => {
    await updatePassword(values, form.resetFields, onError);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Spacer direction="vertical" size="xsmall">
        <Form.Item
          name="currentPassword"
          label={t('pages.profile.form.passwordUpdate.actualPassword')}
          hasFeedback
          style={{ marginBottom: 20 }}
          rules={[
            {
              required: true,
              message: t('errors.required'),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <PasswordConfirmationForm />
        <SubmitButtonForm
          dataTestId="submitButton"
          style={{ marginTop: 30 }}
          label={t('pages.profile.form.passwordUpdate.submit')}
        />
      </Spacer>
    </Form>
  );
};

export default UserProfilePasswordForm;

import { message } from 'antd';
import Routes from 'business/router/routes';
import { GetCompanyWithGroupsByPkQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Redirect, generatePath } from 'react-router';

interface CompanyWithNoGroupsRedirectionProps {
  company: GetCompanyWithGroupsByPkQuery['company_by_pk'];
  children: React.ReactNode;
}

const CompanyWithNoGroupsRedirection = ({
  company,
  children,
}: CompanyWithNoGroupsRedirectionProps) => {
  const { t } = useTranslation();
  if (company && company.groups.length === 0) {
    message.warning(t('pages.companyDetails.notifications.noGroup'));
    return (
      <Redirect
        to={{
          state: { cancelLink: Routes.Companies },
          pathname: generatePath(Routes.GroupCreate, { id: company.id }),
        }}
      />
    );
  }
  return <>{children}</>;
};

export default CompanyWithNoGroupsRedirection;

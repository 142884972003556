import { Tooltip } from 'antd';
import Button from 'ui/button';

interface IActionTableButton {
  tooltipTitle: string;
  icon: React.ReactNode;
  onClick: () => void;
  dataTestId: string;
  disabled?: boolean;
  hide?: boolean;
}

const ActionButton = ({
  tooltipTitle,
  icon,
  onClick,
  dataTestId,
  disabled,
  hide,
}: IActionTableButton) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        data-test-id={dataTestId}
        type="text"
        disabled={disabled}
        hidden={hide}
        size="middle"
        icon={icon}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default ActionButton;

import { ApolloError } from '@apollo/client';
import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreateUser } from 'business/configuration/accessManagement/services/useCreateUser';
import { UserFormValues } from 'business/configuration/accessManagement/services/types';

const { useForm } = Form;

interface CreateUserFormHookParam {
  refetch: () => void;
  closeModal: () => void;
}

const useCreateUserForm = ({
  refetch,
  closeModal,
}: CreateUserFormHookParam) => {
  const { t } = useTranslation();
  const [form] = useForm<UserFormValues>();
  const { createUser } = useCreateUser();

  const handleClose = () => {
    form.resetFields();
    refetch();
    closeModal();
  };

  const onSubmit = async (values: UserFormValues) => {
    try {
      await createUser(values);
      form.resetFields();
      notification.success({
        message: t('user.infoModal.notifications.success'),
      });
      handleClose();
    } catch (err) {
      notification.error({
        message: t('user.infoModal.notifications.error', {
          context: err instanceof ApolloError ? err.message : null,
        }),
      });
    }
  };

  return {
    onSubmit,
    form,
  };
};

export default useCreateUserForm;

import { Spin } from 'antd';
import React, { createContext, useContext, useMemo } from 'react';
import logger from 'technical/logger';
import { ensureFieldsAreDefined } from 'technical/validation';
import Flex from 'ui/flex';
import { isBdlAdmin } from './admin/services/permission';
import useUserData from './user/provider/useUserData';
import { UserWithConsent } from './user/types/user';

export interface AppContextAttributes {
  user: UserWithConsent | undefined;
  isConnected: boolean;
  appBootstraped: boolean;
  requestRebootstrap: () => Promise<void>;
}

interface LoggedInAppContextAttributes extends AppContextAttributes {
  user: UserWithConsent;
  canSeeCompany: (companyId: number) => boolean;
}

const AppContext = createContext<AppContextAttributes>({
  user: undefined,
  isConnected: false,
  appBootstraped: false,
  requestRebootstrap: () => Promise.resolve(),
});

export const useAppContext = () => useContext(AppContext);

/**
 * To use only in components where the user is logged in, or
 * it will throw an error
 */
export const useLoggedInAppContext = (): LoggedInAppContextAttributes => {
  const userContextValue = useAppContext();
  const { user, ...loggedInAppContext } = ensureFieldsAreDefined(
    userContextValue,
    ['user'],
  );

  return {
    user,
    canSeeCompany: (companyId: number) =>
      isBdlAdmin(user) || user.companyIds.includes(companyId),
    ...loggedInAppContext,
  };
};

interface Props {
  children: React.ReactElement;
}

const AppBootstrapper = ({ children }: Props) => {
  const { appBootstraped } = useAppContext();
  return appBootstraped ? (
    children
  ) : (
    <Flex
      alignItems="center"
      justify="center"
      style={{ flex: 1, height: '100vh' }}
    >
      <Spin size="large" />
    </Flex>
  );
};

export const AppProvider = ({ children }: Props) => {
  const { isBootstraped, ...userData } = useUserData();
  logger.info('AppProvider', userData);

  const providerValue = useMemo(() => {
    return {
      ...userData,
      appBootstraped: isBootstraped,
    };
  }, [userData, isBootstraped]);

  return (
    <AppContext.Provider value={providerValue}>
      <AppBootstrapper>{children}</AppBootstrapper>
    </AppContext.Provider>
  );
};

import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Redirect } from 'react-router';
import { canUserAccessSafranRequestPage } from 'business/user/services/';
import { DEFAULT_CONNECTED_ROUTE } from './constant';

const CanAccessSafranRequestPage = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { user } = useLoggedInAppContext();

  return canUserAccessSafranRequestPage(user) ? (
    children
  ) : (
    <Redirect to={DEFAULT_CONNECTED_ROUTE} />
  );
};

export default CanAccessSafranRequestPage;

import PageAuth from 'business/user/pages/pageAuth';
import LoginCard from 'business/user/components/loginCard';

const SignInPage = () => {
  return (
    <PageAuth>
      <LoginCard />
    </PageAuth>
  );
};

export default SignInPage;

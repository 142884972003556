import { PatentDocument } from 'business/resources/patent/types';
import { getProperty } from 'technical/types';
import { ALLOWED_DOCUMENT_TYPES_TO_DISPLAY_DRAWINGS } from 'business/resources/patent/services/constant';

const getDocumentIdsOnlyForAllowedTypes = (documents: PatentDocument[]) =>
  documents.filter(({ type }) =>
    ALLOWED_DOCUMENT_TYPES_TO_DISPLAY_DRAWINGS.includes(type),
  );

export const getDrawingIdsFromDocuments = (documents?: PatentDocument[]) => {
  if (!documents) {
    return [];
  }
  return getDocumentIdsOnlyForAllowedTypes(documents).reduce<string[]>(
    (acc, document) => {
      const drawingIds = document.patentDrawings.map(getProperty('id'));
      return [...acc, ...drawingIds];
    },
    [],
  );
};

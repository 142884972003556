import { Button, Layout } from 'antd';
import { useAppContext } from 'business/AppBootstrapper';
import authService from 'business/user/services/authService';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import MenuDropdown from './components/menuDropdown';
import MenuSections from './components/menuSections';
import styles from './index.module.scss';

const { Header } = Layout;

export interface NavSection {
  label: string;
  path: string;
}

interface Props {
  isConnected?: boolean;
}

const NavBar = ({ isConnected }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppContext();

  function handleLoginOnClick() {
    authService.goToLogin();
  }

  return (
    <Header className={classnames(styles.header)}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <AppLogo clickable height="56px" />
        </div>
        {isConnected && user ? (
          <>
            <MenuSections user={user} />
            <div className={styles.filler} />
            <MenuDropdown user={user} />
          </>
        ) : (
          <div className={styles.headerRight}>
            <Button onClick={() => handleLoginOnClick}>
              {t('pages.signIn.submit')}
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
};

export default NavBar;

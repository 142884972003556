import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Statistic from 'ui/statistic';
import styles from './index.module.scss';

interface ResourceStatistic {
  nbTotal: number;
  nbEffective: number;
  title: string;
}
interface CompanyResourceStatisticProps {
  resources: ResourceStatistic[];
}

const CompanyResourceStatistic = ({
  resources,
}: CompanyResourceStatisticProps) => {
  const { t } = useTranslation();
  return (
    <Row gutter={16} justify="center" className="flex1">
      {resources.map((resource) => {
        return (
          <Col
            key={resource.title}
            span={5}
            offset={1}
            className="textAlignCenter"
          >
            <Flex column>
              <span className={styles.title}>{resource.title}</span>
              <Divider className="overrideMargin0" />
              <div className="padding5">
                <Statistic
                  title={t('metadata.effective')}
                  count={resource.nbEffective}
                />
                <Statistic
                  title={t('metadata.total')}
                  count={resource.nbTotal}
                />
              </div>
            </Flex>
          </Col>
        );
      })}
    </Row>
  );
};

export default CompanyResourceStatistic;

import { Checkbox, Form, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

const { Link } = Typography;

interface TermCheckBoxProps {
  termPath: string;
  type: 'tos' | 'tou';
}

const TermCheckbox = ({ termPath, type }: TermCheckBoxProps) => {
  const { t } = useTranslation();

  const checkBoxValidator: Rule[] = [
    {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error(t('errors.acceptTerms', { context: type })),
            ),
    },
  ];
  return (
    <Form.Item name={type} valuePropName="checked" rules={checkBoxValidator}>
      <Checkbox>
        <span>{`${t('user.infoModal.acceptTerms', { context: type })} `}</span>
        <Link href={termPath} target="_blank">
          {t('user.infoModal.link', { context: type })}
        </Link>
      </Checkbox>
    </Form.Item>
  );
};

export default TermCheckbox;

import { TFunction } from 'react-i18next';
import { getCustomRendererForField } from 'ui/table/columns';
import { DisplayItemParam } from './types';

interface CreateLabelValueParam {
  key: string | number;
  label?: string;
  translationKey?: string;
  context?: string;
  suffix?: string;
  t: TFunction;
}

export const createLabelValue = ({
  key,
  label,
  translationKey,
  context,
  suffix,
  t,
}: CreateLabelValueParam) => {
  return (
    label ??
    t(`table.common.column.${translationKey ?? String(key)}`, {
      context,
    }) + (suffix ?? '')
  );
};

export const displayItemValue = <T>({
  dataRendered,
  key,
  value,
  t,
}: DisplayItemParam<T>): string | React.ReactNode => {
  return (
    dataRendered ?? getCustomRendererForField(String(key), t)?.(value) ?? value
  );
};

export const shouldDisplayRow = <T>(
  data: T,
  hideRow?: (data: T | null) => boolean,
  value?: NonNullable<T>[keyof T],
) => !(!value || (hideRow && hideRow(data)));

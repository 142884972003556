import { getDrawingIdsFromDocuments } from 'business/resources/patent/services/getDrawingIdsFromDocuments';
import { PatentDocument } from 'business/resources/patent/types';
import { useGetDrawingsSignedUrlQuery } from 'generated/graphql';
import { isDefined } from 'technical/validation';

export const useGetPatentDrawingsFromDocuments = (
  documents?: PatentDocument[],
  documentLoading?: boolean,
) => {
  const validDrawingIds = getDrawingIdsFromDocuments(documents);

  const { data, error, loading } = useGetDrawingsSignedUrlQuery({
    variables: {
      drawingIds: validDrawingIds,
    },
    skip: !validDrawingIds.length,
  });
  return {
    images: data?.getSignedDownloadUrlForPatentDrawings.filter(isDefined) ?? [],
    error,
    loading: documentLoading || loading,
  };
};

const sortByKey = <T>(
  items: T[],
  key: keyof T,
  compareFn: (a: T[keyof T], b: T[keyof T]) => number,
) => items.sort((a, b) => compareFn(a[key], b[key]));

export const alphabeticalSortByKey = <
  T extends { [key: string]: string | number },
>(
  items: T[],
  key: keyof T,
) => sortByKey(items, key, (a, b) => a.toString().localeCompare(b.toString()));

import AntdParagraph, { ParagraphProps } from 'antd/es/typography/Paragraph';
import AntdText, { TextProps } from 'antd/es/typography/Text';
import AntdTitle, { TitleProps } from 'antd/es/typography/Title';
import AntdTypography, { TypographyProps } from 'antd/es/typography/Typography';
import classnames from 'classnames';
import styles from './index.module.scss';

const Typography = (props: TypographyProps<'p'>) => {
  return <AntdTypography {...props} />;
};

const Title = ({ center, ...rest }: TitleProps & { center?: boolean }) => {
  return (
    <AntdTitle
      {...rest}
      className={classnames(
        { [styles.center]: center },
        styles.title,
        rest.className,
      )}
    />
  );
};

const Paragraph = ({
  center,
  emphase,
  soften,
  children,
  ...rest
}: ParagraphProps & {
  center?: boolean;
  emphase?: boolean;
  soften?: boolean;
}) => {
  return (
    <AntdParagraph
      {...rest}
      className={classnames(
        {
          [styles.center]: center,
          [styles.emphase]: emphase,
          [styles.soften]: soften,
        },
        rest.className,
      )}
    >
      <p className={styles.p}>{children}</p>
    </AntdParagraph>
  );
};

const Text = ({
  center,
  emphase,
  soften,
  noWrap = true,
  ...rest
}: TextProps & {
  center?: boolean;
  emphase?: boolean;
  soften?: boolean;
  noWrap?: boolean;
}) => {
  return (
    <AntdText
      {...rest}
      className={classnames(
        {
          [styles.preLine]: true,
          [styles.center]: center,
          [styles.emphase]: emphase,
          [styles.soften]: soften,
          [styles.noWrap]: noWrap,
        },
        rest.className,
      )}
    />
  );
};

Typography.Text = Text;
Typography.Paragraph = Paragraph;
Typography.Title = Title;

export default Typography;

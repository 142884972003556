import { PlusCircleOutlined } from '@ant-design/icons';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import GroupListTable from 'business/groups/components/groupListTable';
import { useGetCompanyGroups } from 'business/groups/hooks/useGetCompanyGroups';
import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useGetCompanyByPkQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import {
  ExtractRouteParams,
  generatePath,
  useHistory,
  useParams,
} from 'react-router';
import Button from 'ui/button';
import ErrorOverlay from 'ui/errorOverlay';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { resourceExists } from 'business/common/services';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';

const GroupList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { canSeeCompany } = useLoggedInAppContext();
  const { id } = useParams<ExtractRouteParams<Routes.GroupCreate, string>>();

  const companyId = Number(id);
  const { data: companyData, ...companyDataState } = useGetCompanyByPkQuery({
    variables: { id: companyId },
  });

  const { data, tableProps } = useGetCompanyGroups(companyId);

  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();

  const company = companyData?.company_by_pk;

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(company, tableProps)}
    >
      <ConnectedPage
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(company),
          { label: t('pages.groupList.breadcrumb') },
        ]}
        translationKey="companyGroups"
      >
        <ErrorOverlay {...companyDataState}>
          <Flex style={{ marginBottom: '20px' }} justify="space-between">
            <h1 className="headerTitle">
              {t('pages.groupList.title', {
                companyName: companyData?.company_by_pk?.name,
              })}
            </h1>
          </Flex>

          <Spacer size="middle" direction="vertical">
            <div>
              <Button
                className="configLink"
                type="link"
                onClick={() => {
                  history.push({
                    pathname: generatePath(Routes.GroupCreate, {
                      id: companyId,
                    }),
                  });
                }}
              >
                <PlusCircleOutlined />
                {t('configLink.addGroup')}
              </Button>
            </div>
            <GroupListTable
              dataSource={data}
              data-test-id="groupListTable"
              {...tableProps}
            />
          </Spacer>
        </ErrorOverlay>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default GroupList;

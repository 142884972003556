import { DomainFamily } from 'business/resources/domain/types';
import {
  GetDomainFamiliesSearchQuery,
  GetDomainFamiliesSearchQueryVariables,
  useGetDomainFamiliesSearchQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetDomainFamilies = () =>
  useQueryForTable<
    DomainFamily,
    GetDomainFamiliesSearchQuery,
    GetDomainFamiliesSearchQueryVariables
  >({
    subStorageKey: storageKeys.domainFamilies,
    commonFiltersKeys: ['search', 'isEffective'],
    useQuery: ({ searchText, isEffective, ...filters }) =>
      useGetDomainFamiliesSearchQuery({
        variables: {
          where: {
            isEffective: !!isEffective,
            search: searchText,
          },
          ...filters,
        },
        fetchPolicy: 'network-only',
      }),
    getTotal: (res) => res?.domainFamilySearch?.count ?? 0,
    map: (res) => res?.domainFamilySearch?.resources ?? [],
  });

import { TableOutlined } from '@ant-design/icons';
import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import {
  getCountryColumn,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { TrademarkFamily } from 'business/resources/trademark/services/types';
import { Link } from 'react-router-dom';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import { ColumnGenerationData } from 'ui/table/types';
import { formatDate } from 'technical/date';
import { Image } from 'antd';
import Loader from 'ui/loader';

export const trademarkFamilyColumns: ColumnGenerationData<TrademarkFamily> = [
  {
    ...getCountryColumn(),
    alwaysVisible: true,
    ellipsis: true,
    render: (_, trademark: TrademarkFamily, index) => (
      <Link
        className="titleRedirect"
        data-test-id={`titleRedirect-${index}`}
        to={generatePathByAssetType('trademark', trademark.id)}
      >
        {trademark[getCountryKey()]}
      </Link>
    ),
  },
  {
    key: 'classes',
    render: (classes: { classCode: string; classDetail: string }[]) => {
      return classes.map((c) => Number(c.classCode)).join('-');
    },
  },
  { key: 'depositNumber', ellipsis: true },
  { key: 'depositDate', ellipsis: true },
  { key: 'registrationNumber', ellipsis: true },
  {
    key: 'lastRegistrationDate',
    ellipsis: true,
    render: (_, data: TrademarkFamily) => {
      if (data.firstRegistrationDate) {
        return formatDate(new Date(data.firstRegistrationDate));
      }
      if (data.lastRegistrationDate) {
        return formatDate(new Date(data.lastRegistrationDate));
      }
      return null;
    },
  },
  {
    key: 'nextRegistrationDate',
    ellipsis: true,
    render: (_, data: TrademarkFamily) => {
      if (data.nextRegistrationDate) {
        return formatDate(new Date(data.nextRegistrationDate));
      }
    },
  },
  {
    key: 'nextAction',
  },

  { key: 'bdlRef', ellipsis: true },
  { key: 'misRef', ellipsis: true },
  { key: 'clientRef', ellipsis: true },
  { key: 'clientRef2', ellipsis: true },
  { key: 'status' },
];

export const advancedSearchTrademarkColumns: ColumnGenerationData<TrademarkFamily> =
  [
    {
      key: 'holder',
    },
    {
      key: 'effectiveHolder',
      ellipsis: true,
    },
    {
      key: 'title',
      context: 'trademark',
      render: (_, { id, title }: TrademarkFamily, index) => (
        <Link
          className="titleRedirect"
          data-test-id={`titleRedirect-${index}`}
          to={generatePathByAssetType('trademark', id)}
        >
          {title}
        </Link>
      ),
    },
    {
      key: 'image',
      translationKey: 'image',
      sorter: false,
      width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
      render: (_: any, { image }) => {
        if (image === 'loading') {
          return <Loader />;
        }
        if (image) {
          return (
            <Image src={image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />
          );
        }
      },
    },
    {
      key: 'classes',
      render: (classes: { classCode: string; classDetail: string }[]) => {
        return classes.map((c) => Number(c.classCode)).join('-');
      },
    },
    {
      ...getCountryColumn(),
      alwaysVisible: true,
    },
    { key: 'depositNumber', ellipsis: true },
    { key: 'depositDate', ellipsis: true },
    { key: 'registrationNumber', checked: false, ellipsis: true },
    { key: 'firstRegistrationDate', checked: false, ellipsis: true },
    { key: 'lastRegistrationDate', checked: false, ellipsis: true },
    {
      key: 'nextRegistrationDate',
      ellipsis: true,
      render: (_, data: TrademarkFamily) => {
        if (data.nextRegistrationDate) {
          return formatDate(new Date(data.nextRegistrationDate));
        }
      },
    },
    { key: 'nextAction' },
    { key: 'bdlRef' },
    { key: 'misRef', checked: false },
    { key: 'clientRef' },
    { key: 'clientRef2' },
    {
      key: 'bdlOfficer',
    },
    {
      key: 'clientOfficer',
    },
    { key: 'status', ellipsis: true },

    {
      key: 'familyId',
      translationKey: 'family',
      render: (_, { familyId }: TrademarkFamily) => (
        <Link to={generateFamilyPathByAssetType('trademark', familyId)}>
          <TableOutlined />
        </Link>
      ),
    },
  ];

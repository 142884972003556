import { DocumentRecord } from 'business/resources/shared/services/documents/types';
import classNames from 'classnames';
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import styles from './index.module.scss';

const extensionToIcon: Record<string, AntdIconProps> = {
  pdf: <FilePdfOutlined className={styles.icon} />,
  doc: <FileWordOutlined className={styles.icon} />,
  msg: <MailOutlined className={styles.icon} />,
  jpg: <FileImageOutlined className={styles.icon} />,
};

const DocumentLabel: React.VFC<DocumentRecord> = ({
  label,
  extension,
  children,
}) => {
  const isFolder = !!children?.length;

  const icon = !isFolder
    ? extensionToIcon[extension as keyof typeof extensionToIcon]
    : null;

  const name = isFolder ? label : label + '.' + extension;

  return (
    <span className={classNames({ pointerCursor: isFolder })}>
      {icon}
      <span>{name}</span>
    </span>
  );
};

export default DocumentLabel;

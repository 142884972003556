import { QueryResult } from '@apollo/client';
import { Image, ImageProps, Spin } from 'antd';

export interface BasicImageQueryResult {
  image?: string | null;
}

type ResourceImageProps<ImageQueryResult extends BasicImageQueryResult> = Pick<
  QueryResult<ImageQueryResult>,
  'data' | 'loading'
> &
  Pick<ImageProps, 'height' | 'width'> & {
    overrideNoResult?: JSX.Element;
  } & { style?: React.CSSProperties };

const ResourceImage = <ImageQueryResult extends BasicImageQueryResult>({
  data,
  loading,
  height,
  width,
  overrideNoResult,
  style,
}: ResourceImageProps<ImageQueryResult>) => {
  if (loading) {
    return <Spin />;
  }

  return data?.image ? (
    <Image style={style} src={data.image} height={height} width={width} />
  ) : (
    overrideNoResult ?? null
  );
};

export default ResourceImage;

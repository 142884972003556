import { isDefined } from 'technical/validation';
import { EXPANDED_COUNTRIES } from './constants';
import { mapStyles } from './styles';
import { MapData, MapStatus } from './types';

export const handleMapStyle = (geo: any, data: MapData) => {
  const countryCode: string = geo.properties['Alpha-2'];
  const status = data[countryCode];
  return status ? mapStyles[status] : mapStyles.unprotected;
};

// MapStatus sorted from most important to least important
const prioritizedStatuses = [
  MapStatus.Valid,
  MapStatus.Published,
  MapStatus.Deposited,
  MapStatus.PlannedDeposit,
  MapStatus.NotEffective,
  MapStatus.MandatedOut,
  MapStatus.Unprotected,
];

/**
 * Compares two statuses and returns a number indicating which one is more important
 * @returns negative integer if a is more important, positive integer if b is more important, 0 if they are equal
 */
export const compareStatuses = (a: MapStatus, b: MapStatus) =>
  prioritizedStatuses.indexOf(a) - prioritizedStatuses.indexOf(b);

/**
 * Merges map resources with the same country code and returns the most important one
 * @returns list of map resources with unique country codes
 */
const mergeMapResources = (
  mapResources: { status: MapStatus; countryCode: string }[],
) =>
  mapResources.reduce<MapData>((acc, { countryCode, status }) => {
    const currentStatus = acc[countryCode];
    if (
      !isDefined(currentStatus) ||
      compareStatuses(status, currentStatus) < 0
    ) {
      acc[countryCode] = status;
    }
    return acc;
  }, {});

const specialCountryCodes = ['EP', 'QT'];

const statusToNotExpand = [
  MapStatus.Unprotected,
  MapStatus.NotEffective,
  MapStatus.MandatedOut,
];

/**
 * Expands country codes to include all countries that are part of the same region
 */
const expandCountries = (mapData: MapData) => {
  const keys = Object.keys(mapData);

  return keys.flatMap((countryCode) => {
    const status = mapData[countryCode];

    // Do not color PCT patents if they are effective or not effective
    if (specialCountryCodes.includes(countryCode)) {
      // If the status is effective, color all countries that are part of the same region
      if ([MapStatus.Valid].includes(status)) {
        return keys
          .filter((code) => EXPANDED_COUNTRIES[countryCode].includes(code))
          .map((code) => ({
            countryCode: code,
            status,
          }));
      }
      // If the status is not effective or unprotected, do not color any countries
      if (statusToNotExpand.includes(status)) {
        return [];
      }
    }

    const countryCodes = EXPANDED_COUNTRIES[countryCode] ?? [countryCode];
    return countryCodes.map((code) => ({ countryCode: code, status }));
  }, []);
};

/**
 * Processes map data and returns an object containing all map resources with unique country codes
 */
export const processMapData = (mapData: MapData) =>
  mergeMapResources(expandCountries(mapData));

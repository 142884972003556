import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import {
  limitedInputLength,
  requiredRule,
} from 'technical/validation/antFormRules';

const { useForm } = Form;

interface CustomFilterCreationModalForm {
  customFilterName: string;
}

interface CustomFilterCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (filterName: string) => Promise<void>;
}

const CustomFilterCreationModal: React.FC<CustomFilterCreationModalProps> = ({
  isOpen,
  onClose,
  onApply,
}) => {
  const { t } = useTranslation();
  const [form] = useForm<CustomFilterCreationModalForm>();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (value: CustomFilterCreationModalForm) => {
    setLoading(true);
    onApply(value.customFilterName).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  useEffect(form.resetFields, [isOpen]);

  return (
    <Modal
      title={t('pages.advancedSearch.customFilters.modal.title')}
      open={isOpen}
      onCancel={onClose}
      confirmLoading={loading}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          data-test-id="customFilterCreationModalOkButton"
          onClick={() =>
            form
              .validateFields()
              .then(onFinish)
              .catch((err) => logger.error(err))
          }
        >
          {t('common.confirm')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          customFilterName: '',
        }}
      >
        <Form.Item
          name="customFilterName"
          rules={[requiredRule, limitedInputLength]}
        >
          <Input
            data-test-id="customFilterNameInput"
            placeholder={t(
              'pages.advancedSearch.customFilters.modal.inputPlaceholder',
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomFilterCreationModal;

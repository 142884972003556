import Spacer from 'ui/spacer';
import { Resource } from 'business/common/services/types';
import classNames from 'classnames';
import ExternalLinks from 'business/common/components/externalLinks';

interface ResourceLinksProps {
  resource?: Resource | null;
}

const ResourceLinks: React.VFC<ResourceLinksProps> = ({ resource }) => {
  if (!resource) {
    return null;
  }

  return (
    <Spacer
      className={classNames('flex-end', 'padding10')}
      fullWidth={false}
      size="xsmall"
    >
      <ExternalLinks resource={resource} />
    </Spacer>
  );
};

export default ResourceLinks;

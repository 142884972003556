import { notification } from 'antd';
import { TFunction } from 'i18next';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

interface NotifyFuncArgs {
  successNotification?: string | null;
  errorNotification?: string;
  reportError?: 'error' | 'warning' | 'info';
  finally?: () => void;
  refetch?: () => void;
  onError?: (err: unknown) => void;
  notificationType?: 'success' | 'info';
  successDescription?: string;
}

async function notifyPromise(
  t: TFunction,
  func: () => Promise<any>,
  args?: NotifyFuncArgs,
) {
  try {
    await func();
    if (args?.successNotification !== null) {
      notification[args?.notificationType ?? 'success']({
        message: args?.successNotification ?? t('common.successNotification'),
        description: args?.successDescription,
        duration: 5,
      });
    }
    args?.refetch?.();
  } catch (err) {
    if (args?.onError) {
      args.onError(err);
    } else {
      if (args?.reportError) {
        errorReporting[args.reportError](err);
      }
      logger.error(err);
      notification.error({
        message: args?.errorNotification ?? t('errors.generic'),
      });
    }
  }
  args?.finally?.();
}

export default notifyPromise;

import { Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCompanyClientRef } from 'business/configuration/archive/services/useCompanyClientRef';
import Spacer from 'ui/spacer';
import SubmitButtonForm from 'ui/submitButtonForm';
import { useForm } from 'antd/es/form/Form';
import ErrorOverlay from 'ui/errorOverlay';
import Card from 'ui/card';
import CompanyClientRefList from 'business/configuration/archive/components/companyClientRefList';
import { removeDuplicates } from 'technical/array';

interface CompanyClientRefConfigurationProps {
  companyId: number;
}

interface CompanyClientRefSubmitValues {
  clientRefs: {
    clientRef: string;
  }[];
  isTextVersion: boolean;
}
const CompanyClientRefConfiguration = ({
  companyId,
}: CompanyClientRefConfigurationProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { initialValues, updateClientRefs, loading, error } =
    useCompanyClientRef(companyId);

  const onSubmit = ({
    clientRefs,
    isTextVersion,
  }: CompanyClientRefSubmitValues) => {
    const formattedClientRefs = clientRefs
      .filter((item) => item && item.clientRef)
      .map((item) => item.clientRef);

    updateClientRefs({
      clientRefs: removeDuplicates(formattedClientRefs),
      isTextVersion,
    });
  };

  const { useWatch } = Form;
  const isTextVersion = useWatch<boolean>('isTextVersion', form);

  return (
    <ErrorOverlay loading={loading} error={error}>
      <Card>
        {!loading && !error && (
          <Form<CompanyClientRefSubmitValues>
            onFinish={onSubmit}
            initialValues={initialValues}
            form={form}
          >
            <Spacer direction="vertical" size="small">
              <Form.Item
                name="isTextVersion"
                valuePropName="checked"
                label={t('archive.clientRefs.chooseByInput')}
              >
                <Switch />
              </Form.Item>
              <CompanyClientRefList disabled={isTextVersion} />
              <SubmitButtonForm label={t('common.save')} />
            </Spacer>
          </Form>
        )}
      </Card>
    </ErrorOverlay>
  );
};

export default CompanyClientRefConfiguration;

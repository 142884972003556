import { GetDomainQuery } from 'generated/graphql';
import { TFunction } from 'react-i18next';
import { createTimelineEvents } from 'technical/timeline';
import { isDefined } from 'technical/validation';

export const getDomainTimelineEvents = (
  t: TFunction,
  domain: GetDomainQuery['domain_by_pk'],
) => {
  if (!domain) {
    return [];
  }
  const operations =
    domain.timelineEvents
      .map(({ gandiOperation }) => {
        if (!gandiOperation) {
          return null;
        }
        const { type, operationDate } = gandiOperation;
        return {
          title: t('table.common.column.domainType', {
            context: type,
          }),
          date: operationDate,
        };
      })
      .filter(isDefined) ?? [];

  if (domain.expirationDate) {
    operations.push({
      title: 'expiration',
      date: domain.expirationDate,
    });
  }

  return createTimelineEvents(operations);
};

import {
  GetPatentEventsQuery,
  GetPatentEventsQueryVariables,
  Order_By,
  useGetPatentEventsQuery,
} from 'generated/graphql';
import { Event } from 'business/resources/events/services/types';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetPatentEvents = (patentId: number) =>
  useQueryForTable<Event, GetPatentEventsQuery, GetPatentEventsQueryVariables>(
    {
      subStorageKey: storageKeys.patentEvents,
      useQuery: ({ limit, offset, orderBy }) =>
        useGetPatentEventsQuery({
          variables: { limit, offset, patentId, orderBy },
        }),
      getTotal: (res) => res?.event_patentEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_patentEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

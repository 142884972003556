import { DeleteOutlined } from '@ant-design/icons';
import { Button, Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Select, { SelectProps } from 'ui/select';
import Spacer from 'ui/spacer';
import styles from './index.module.scss';

interface CustomFilterSelectProps {
  filterSelectProps: SelectProps<number>;
  deleteCurrentCustomFilter?: () => Promise<void>;
}

const CustomFilterSelect: React.FC<CustomFilterSelectProps> = ({
  filterSelectProps,
  deleteCurrentCustomFilter,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Spacer
        align="center"
        fullWidth
        direction="horizontal"
        size="xsmall"
        data-test-id="customFilterSelect"
      >
        <Select
          className="fullWidth"
          allowClear
          {...filterSelectProps}
          placeholder={t('pages.advancedSearch.customFilters.select')}
        />
        {deleteCurrentCustomFilter && (
          <Tooltip
            placement="topRight"
            title={t('pages.advancedSearch.customFilters.deleteTooltip')}
          >
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={deleteCurrentCustomFilter}
              data-test-id="customFilterSelectDeleteButton"
            />
          </Tooltip>
        )}
      </Spacer>
      <Divider className={styles.divider} />
    </>
  );
};

export default CustomFilterSelect;

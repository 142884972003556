import { ApolloError } from '@apollo/client';
import { isDefined } from 'technical/validation';

const codeError = {
  isNameAlreadyTaken: 'NAME_ALREADY_TAKEN',
};

export const isNameAlreadyTaken = (err: unknown) => {
  if (err instanceof ApolloError) {
    if (err.graphQLErrors.length) {
      const { extensions } = err.graphQLErrors[0];
      return (
        isDefined(extensions) &&
        isDefined(extensions.exception) &&
        isDefined(extensions.exception.code) &&
        extensions.exception.code === codeError.isNameAlreadyTaken
      );
    }
  }
  return false;
};

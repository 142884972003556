import ConnectedPage from 'business/user/helpers/connectedPage';
import { ExtractRouteParams, useParams } from 'react-router';
import Routes from 'business/router/routes';
import DomainPage from 'business/resources/domain/pages/domainPage';
import useGetDomain from 'business/resources/domain/services/useGetDomain';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';

const DomainPageContainer = () => {
  const { id } = useParams<ExtractRouteParams<Routes.DomainPage, string>>();

  const domainPageProps = useGetDomain(Number(id));

  return (
    <ResourceNotFound resourceExists={!domainPageProps.notFound}>
      <ConnectedPage
        breadcrumbs={domainPageProps.breadcrumbs}
        translationKey="domainDetails"
      >
        <DomainPage {...domainPageProps} />
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default DomainPageContainer;

import classNames from 'classnames';
import styles from './index.module.scss';

interface IconStatusProps {
  success: boolean;
  size?: number;
  style?: React.CSSProperties;
}

const IconStatus = ({ size = 12, success, style }: IconStatusProps) => {
  return (
    <div
      className={classNames(styles.iconStatus, { [styles.success]: success })}
      style={{
        ...style,
        height: size,
        width: size,
      }}
    />
  );
};

export default IconStatus;

import Card from 'ui/card';
import SimpleTable, { Records } from 'ui/simpleTable';

interface DnsServerTableProps<T> {
  dataSource: T[];
  subTitle: string;
}

const DnsServerTable = <T extends Records>({
  dataSource,
  subTitle,
}: DnsServerTableProps<T>) => {
  return (
    <Card title={subTitle} className="fullHeight">
      <SimpleTable
        className="marginTop20"
        columns={[{ key: 'serverName' }, { key: 'server' }]}
        showHeader={false}
        dataSource={dataSource}
      />
    </Card>
  );
};

export default DnsServerTable;

import { Link } from 'react-router-dom';
import { AllResults } from 'business/advancedSearch/services/types';
import { ColumnGenerationData } from 'ui/table/types';
import { TFunction } from 'i18next';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import ResourceImageDisplay, { ImageQueryHook } from 'ui/imageDisplay';

import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import { ResourceType, isResourceType } from 'business/common/services/types';
import { TableOutlined } from '@ant-design/icons';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import { getDesignImageQuery } from 'business/resources/design/services/useGetImages';
import { getTrademarkImageQuery } from 'business/resources/trademark/services/useGetTrademarkImages';

const assetResourceImageDisplayQuery: Record<
  'trademark' | 'design',
  (id: number) => ImageQueryHook
> = {
  design: getDesignImageQuery,
  trademark: getTrademarkImageQuery,
};

const displayImageComponentByAsset = (
  id: number,
  asset: 'trademark' | 'design',
) => (
  <ResourceImageDisplay
    {...FAMILIES_TABLE_IMAGE_STYLE}
    query={assetResourceImageDisplayQuery[asset](id)}
  />
);

const getAllResultsTableColumns = (
  t: TFunction,
): ColumnGenerationData<AllResults> => [
  {
    key: 'asset',
    render: (assetType: string) => {
      return t('table.common.column.asset', { context: assetType });
    },
  },
  {
    key: 'holder',
  },
  {
    key: 'title',
    render: (_, record: AllResults) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType(record.asset as ResourceType, record.id)}
      >
        {record.title}
      </Link>
    ),
  },
  {
    key: 'id',
    translationKey: 'image',
    sorter: false,
    width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
    render: (_, record: AllResults) => {
      if (!record.id) {
        return null;
      }
      if (record.asset === 'design' || record.asset === 'trademark') {
        return displayImageComponentByAsset(record.id, record.asset);
      }
      return null;
    },
  },

  {
    ...getCountryColumn(),
  },
  {
    key: 'depositNumber',
  },
  {
    key: 'depositDate',
  },
  {
    key: 'clientRef',
  },
  {
    key: 'bdlRef',
  },
  {
    key: 'misRef',
    checked: false,
  },
  {
    key: 'bdlOfficer',
    checked: false,
  },
  {
    key: 'clientOfficer',
    checked: false,
  },
  {
    key: 'status',
  },
  {
    key: 'familyId',
    translationKey: 'family',
    render: (_, { asset, familyId }: AllResults) =>
      asset &&
      isResourceType(asset) && (
        <Link to={generateFamilyPathByAssetType(asset, familyId)}>
          <TableOutlined />
        </Link>
      ),
  },
];

export default getAllResultsTableColumns;

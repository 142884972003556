import useCompanyArchiveSubscriptions from 'business/archive/hooks/useCompanyArchiveSubscriptions';
import { CompanyArchiveSubscription } from 'business/archive/services/types';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { bytesToMo } from 'technical/conversion';
import Table from 'ui/table';
import { ColumnGenerationData } from 'ui/table/types';

const defaultColumns = (
  t: TFunction,
): ColumnGenerationData<CompanyArchiveSubscription> => [
  { key: 'companyRef', translationKey: 'code', alwaysVisible: true },
  { key: 'holder', ellipsis: true },
  { key: 'subscriptionTitle', translationKey: 'flatRateName', ellipsis: true },
  { key: 'subscriptionDate' },
  { key: 'subscriptionEndDate' },
  { key: 'totalCount', translationKey: 'totalArchive' },
  { key: 'totalSize', checked: false },
  { key: 'averageSize', checked: false },
  { key: 'lastArchiveUploaded' },
  { key: 'nbDeletions' },
  {
    key: 'subscriptionDuration',
    checked: false,
    render: (duration) =>
      t('archive.storageDuration', { storageDuration: duration }),
    ellipsis: true,
  },
  {
    key: 'currentYearTotalCount',
    checked: false,
    render: (currentYearTotalCount, { maxDocument }) =>
      `${currentYearTotalCount} / ${maxDocument}`,
    ellipsis: true,
  },
  {
    key: 'currentYearTotalSize',
    checked: false,
    render: (currentYearTotalSize, { maxVolume }) => {
      const currentYearTotalSizeMo = bytesToMo(currentYearTotalSize).toFixed(2);
      const maxVolumeMo = maxVolume ?? 0;

      return `${currentYearTotalSizeMo} / ${maxVolumeMo} Mo`;
    },
    ellipsis: true,
  },
  {
    key: 'conservationDuration',
    checked: false,
    translationKey: 'conservationDuration',
    render: (duration) =>
      t('archive.storageDuration', { storageDuration: duration }),
    ellipsis: true,
  },
];

const ArchiveSubscriptionTable = () => {
  const { t } = useTranslation();
  const { data, tableProps } = useCompanyArchiveSubscriptions();

  return (
    <Table
      id="archiveSubscriptionTable"
      dataSource={data}
      defaultColumns={defaultColumns(t)}
      {...tableProps}
    />
  );
};

export default ArchiveSubscriptionTable;

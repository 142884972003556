import { theme } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider/context';
// This is where you overide basic theme of ant design
// change will be moved to the useToken

const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: '#0041B4',
    colorText: '#484848',
    colorTextHeading: '#484848',
    // To match with required spaces
    // Should be the "tiny"
    borderRadius: 10,
  },
  components: {
    Layout: {
      colorBgHeader: theme.defaultSeed.colorBgBase,
    },
  },
};

export default defaultTheme;

import { isBdlAdmin } from 'business/admin/services/permission';
import { UserWithPrivateInfos } from 'business/user/types/user';

export const canUserAccessInvoicePage = (user?: UserWithPrivateInfos) =>
  user &&
  (isBdlAdmin(user) ||
    user?.privateInfos.archiveInvoiceEnabled ||
    user?.privateInfos.domainInvoiceEnabled);

export const canUserAccessSafranRequestPage = (user: UserWithPrivateInfos) =>
  (user && isBdlAdmin(user)) || user.safranUser;

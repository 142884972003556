function sanitizeStringList(
  list?: string | null,
  delimiter = ', ',
  ifNull = '',
) {
  return list
    ? list
        .split(delimiter.trim())
        .map((element) => element.trim())
        .join(delimiter)
    : ifNull;
}

export function map<Keys extends readonly string[], T>(
  obj: { [keys in Keys[number]]: T },
  keys: Keys,
  func: (val: T) => T,
) {
  return keys.reduce<{ [keys in Keys[number]]: T }>(
    (acc, key) => ({
      [key]: func(obj[key as Keys[number]]),
      ...acc,
    }),
    {} as { [keys in Keys[number]]: T },
  );
}

export const priceFormat = (locale: string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
  });

export default sanitizeStringList;

export const sanitizeDepositNumber = (depositNumber: string) =>
  depositNumber.replace(/[^A-Za-z0-9-]/g, '');

import { Input, InputProps } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

type SearchBarProps = InputProps;

const SearchBar: React.VFC<SearchBarProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Input
      className={classNames('searchBar', styles.searchBarStyle)}
      placeholder={t('common.search')}
      allowClear
      {...props}
    />
  );
};
export default SearchBar;

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { isBdlAdmin } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { useGetArchiveDocument } from 'business/archive/hooks/useGetArchiveDocument';
import { UserArchivesDocuments } from 'business/archive/services/types';
import passwordValidationModal from 'ui/passwordValidationModal';
import notifyPromise from 'business/common/services/notifyPromise';
import { useAskArchiveDeletionMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'technical/date';
import Button from 'ui/button';
import Card from 'ui/card';
import Spacer from 'ui/spacer';

interface ArchiveDocumentTableActionColumsnProps {
  archive: UserArchivesDocuments;
  setEditingArchive: (archive: UserArchivesDocuments) => void;
  refetch?: () => void;
}
const ArchiveDocumentTableActionColumn = ({
  archive,
  refetch,
  setEditingArchive,
}: ArchiveDocumentTableActionColumsnProps) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const [
    sendArchiveDeletionRequest,
    { loading: sendArchiveDeletionRequestLoading },
  ] = useAskArchiveDeletionMutation();
  const { sendArchiveDownloadLink } = useGetArchiveDocument();

  const askArchiveDeletion = () => {
    notifyPromise(
      t,
      () =>
        sendArchiveDeletionRequest({
          variables: {
            archiveId: archive.id,
          },
        }),
      {
        refetch,
        successNotification: t(
          'pages.archive.notifications.deletionRequestSend',
          {
            archiveName: archive.fileName,
          },
        ),
      },
    );
  };

  const passwordValidationModalContent = () => {
    return (
      <Card>
        <Spacer direction="vertical" align="center" size="small">
          <h2>{archive.fileName}</h2>
          <span>
            {t('pages.archive.archivedOn', {
              date: formatDate(new Date(archive.createdAt)),
            })}
          </span>
        </Spacer>
      </Card>
    );
  };

  const confirmArchiveDeletion = () => {
    passwordValidationModal({
      onOk: () => askArchiveDeletion(),
      modalTitle: t('passwordValidationModal.modalTitle', {
        context: 'deleteDocument',
      }),
      modalContent: passwordValidationModalContent(),
      warningMessage: t('passwordValidationModal.warningMessage', {
        context: 'delete',
      }),

      instructionText: t('passwordValidationModal.instructionText', {
        context: 'delete',
      }),

      confirmButtonText: t('passwordValidationModal.confirmButtonText', {
        context: 'delete',
      }),
    });
  };

  const actionColumns = [
    // Keeping it to make a preview for small files
    // {
    //   title: 'pages.archive.table.view',
    //   icon: <EyeOutlined />,
    //   disabled: t('maintenance.downloadArchive'),
    //   hidden: !isPdf(archive.fileType),
    //   onClick: () => viewArchive(archive.id),
    //   loading: isDownloadingViewArchive,
    // },
    {
      title: 'pages.archive.table.download',
      icon: <DownloadOutlined />,
      onClick: () => sendArchiveDownloadLink(archive.id),
    },
    {
      title: 'pages.archive.table.delete',
      icon: <DeleteOutlined />,
      onClick: confirmArchiveDeletion,
      hidden: archive.authorId !== user.id && !isBdlAdmin(user),
      loading: sendArchiveDeletionRequestLoading,
    },
    {
      title: 'pages.archive.table.edit',
      icon: <EditOutlined />,
      onClick: () => setEditingArchive(archive),
      hidden: !archive.canUpdate,
    },
  ];

  return (
    <Spacer size="xxsmall">
      {actionColumns.map((actionColumn) => {
        return (
          !actionColumn.hidden && (
            <Tooltip key={actionColumn.title} title={t(actionColumn.title)}>
              <div>
                <Button
                  type="primary"
                  size="middle"
                  loading={actionColumn.loading}
                  icon={actionColumn.icon}
                  onClick={actionColumn.onClick}
                />
              </div>
            </Tooltip>
          )
        );
      })}
    </Spacer>
  );
};

export default ArchiveDocumentTableActionColumn;

import { Form, Input } from 'antd';
import Routes from 'business/router/routes';
import { email } from 'business/user/validations/string';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { formikErrorMessage } from 'technical/form';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Card from 'ui/card';
import InputError from 'ui/form/inputError';
import Link from 'ui/link';
import Spacer from 'ui/spacer';
import * as yup from 'yup';
import styles from './index.module.scss';

type Values = { login: string };
interface Props {
  onClick: (login: string) => void | Promise<any>;
}

const initialValues: Values = { login: '' };

const ForgotPasswordCard = ({ onClick }: Props) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object().shape({ login: email(t) }),
    onSubmit: (values) => onClick(values.login),
  });

  return (
    <Card>
      <Form
        className={styles.form}
        onFinish={formik.handleSubmit}
        layout="vertical"
      >
        <Spacer direction="vertical" align="center">
          <AppLogo />

          <h1 className={styles.title}>
            {t('pages.forgotPassword.title_question')}
          </h1>
          <p className={styles.description}>
            {t('pages.forgotPassword.description')}
          </p>
          <Spacer direction="vertical" size="small">
            <Form.Item label={t('notConnected.login.email')}>
              <Input
                data-test-id="email-input"
                id="login"
                onChange={(e) => {
                  formik.setTouched({ ...formik.touched, login: undefined });
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                autoComplete="username"
              />
              <InputError error={formikErrorMessage(formik, 'login')} />
            </Form.Item>
          </Spacer>
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            htmlType="submit"
            block
          >
            {t('pages.forgotPassword.submit')}
          </Button>

          <Link to={Routes.SignIn}>
            {t('pages.forgotPassword.backToSignIn')}
          </Link>
        </Spacer>
      </Form>
    </Card>
  );
};

export default ForgotPasswordCard;

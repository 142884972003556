import { Form } from 'antd';
import { Key } from 'react';
import Select, { SelectProps } from 'ui/select';
import { FilterField } from '..';

type SelectFieldFilterOptions = {
  selectProps: SelectProps<Key>;
};

const SelectFieldFilter: FilterField<SelectFieldFilterOptions> = {
  component: ({ selectProps, ...formItemProps }) => (
    <Form.Item {...formItemProps}>
      <Select {...selectProps} placeholder={formItemProps.label} />
    </Form.Item>
  ),
};

export default SelectFieldFilter;

import notifyPromise from 'business/common/services/notifyPromise';
import { useUpdatePasswordMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordFormValues } from 'business/configuration/accessManagement/components/forms/userProfilePasswordForm/types';

export const useUpdatePasswordForm = () => {
  const { t } = useTranslation();
  const [updatePasswordMutation] = useUpdatePasswordMutation();

  const updatePassword = async (
    { currentPassword, password: newPassword }: UpdatePasswordFormValues,
    resetFormField: () => void,
    onError: (err: unknown) => void,
  ) => {
    return notifyPromise(
      t,
      async () => {
        await updatePasswordMutation({
          variables: {
            currentPassword,
            newPassword,
          },
        });
        resetFormField();
      },
      {
        successNotification: t(
          'pages.profile.form.passwordUpdate.notifications.success',
        ),
        onError,
      },
    );
  };

  return {
    updatePassword,
  };
};

import { Button, Checkbox, Form, Modal } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import {
  BdlUserFormValues,
  BdlUserParams,
} from 'business/configuration/accessManagement/services/types';
import { useTranslation } from 'react-i18next';
import UserFormFields from 'business/common/form/userFormFields';
import Spacer from 'ui/spacer';
import Select from 'ui/select';
import { BdlUserRole } from 'business/admin/services/types';
import { useEffect } from 'react';
import logger from 'technical/logger';

const { useForm } = Form;
interface BdlUsersInfoModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (bdlUserToEdit: BdlUserFormValues) => Promise<void>;
  editedUser?: BdlUserParams;
}

const BdlUserModal: React.FC<BdlUsersInfoModalProps> = ({
  visible,
  onClose,
  onSubmit,
  editedUser,
}) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const [form] = useForm<BdlUserFormValues>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ osrAccess: editedUser?.osrAccess ? true : false });
  }, [editedUser]);

  const submitUser = () =>
    form
      .validateFields()
      .then(onSubmit)
      .catch((error) => logger.error(error));

  return (
    <Modal
      open={visible}
      closable
      onOk={submitUser}
      onCancel={onClose}
      forceRender
      footer={[
        <Button
          type="primary"
          onClick={submitUser}
          data-test-id="user-creation-submit"
        >
          {t('user.infoModal.okButton', {
            context: editedUser ? 'edit' : null,
          })}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          ...editedUser,
          osrAccess: editedUser?.osrAccess ? true : false,
        }}
      >
        <h1>
          {t('user.infoModal.title', {
            context: editedUser ? 'edit' : null,
          })}
        </h1>
        <UserFormFields
          additionnalProps={{ email: { field: { disabled: false } } }}
        />
        <Spacer size="small" direction="vertical">
          <Form.Item
            data-test-id="role-select"
            name="role"
            label={t('user.infoModal.role')}
          >
            <Select
              disabled={user.id === editedUser?.id}
              options={Object.values(BdlUserRole).map((role) => ({
                code: role,
                label: t(`pages.admin.bdlUsers.roles.${role}`),
              }))}
            />
          </Form.Item>
        </Spacer>
        <Form.Item
          valuePropName="checked"
          data-test-id="osrAccess"
          name="osrAccess"
        >
          <Checkbox>Accès OSR</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BdlUserModal;

import classnames from 'classnames';
import colors from 'ui/colors';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  to: string;
  children: React.ReactNode;
  prefix?: string;
  target?: string;
  color?: keyof typeof colors;
  align?: 'center' | 'left' | 'right';
  className?: string;
}

const Link = ({
  to,
  children,
  prefix,
  className,
  align,
  color,
  target,
}: Props) => {
  const LinkComponent = useMemo(() => {
    return () => (
      <ReactRouterLink
        target={target}
        className={classnames(styles.link, className, { className: !prefix })}
        style={{ color: color ? colors[color] : undefined, textAlign: align }}
        to={to}
      >
        {children}
      </ReactRouterLink>
    );
  }, [to, children, prefix, className, align, color]);

  if (prefix) {
    return (
      <p
        className={classnames(className, styles.prefixedLink)}
        style={{ textAlign: align }}
      >
        {prefix}
        {/* Space between the prefix and the link */}
        {` `}
        <LinkComponent />
      </p>
    );
  }

  return <LinkComponent />;
};

export default Link;

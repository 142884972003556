import classnames from 'classnames';
import React from 'react';
import Button from 'ui/menu/menuLink';
import styles from './index.module.scss';

interface Props {
  onClick: () => void;
  icon: React.ReactNode;
  label?: string;
  labelClassName?: string;
  className?: string;
}

const LogoSection = ({
  onClick,
  icon,
  label,
  labelClassName,
  className,
}: Props) => {
  return (
    <Button
      className={classnames(styles.logoSection, className)}
      onClick={onClick}
    >
      <span className={labelClassName}>{label}</span>
      {icon}
    </Button>
  );
};

export default LogoSection;

import { TFunction } from 'react-i18next';
import { CheckList } from './types';

export const permissionCards = (t: TFunction): CheckList[] => {
  return [
    {
      title: t('pages.patent.title'),
      key: 'patent',
      textEnabled: true,
      checkboxFormName: 'patentCheckbox',
      lists: [
        {
          value: 'unpublishedPatent',
          label: t('pages.groupCreate.card.unpublished'), // must be a key translated
        },
        {
          value: 'patentHolder',
          label: t('pages.groupCreate.card.holder'),
        },
        {
          value: 'patentInstructingParty',
          label: t('pages.groupCreate.card.instructingParty'),
        },
        {
          value: 'patentAccountantParty',
          label: t('pages.groupCreate.card.accountantParty'),
        },
        {
          value: 'patentAnnuityInstructingParty',
          label: t('pages.groupCreate.card.annuityInstructingParty'),
        },
        {
          value: 'patentAnnuityAccountantParty',
          label: t('pages.groupCreate.card.annuityAccountantParty'),
        },
        {
          value: 'patentAction',
          label: t('pages.groupCreate.card.action'),
        },
      ],
    },
    {
      title: t('pages.trademark.title'),
      key: 'trademark',
      textEnabled: true,
      checkboxFormName: 'trademarkCheckbox',
      lists: [
        {
          value: 'unpublishedTrademark',
          label: t('pages.groupCreate.card.unpublished'), // must be a key translated
        },
        {
          value: 'trademarkHolder',
          label: t('pages.groupCreate.card.holder'),
        },
        {
          value: 'trademarkInstructingParty',
          label: t('pages.groupCreate.card.instructingParty'),
        },
        {
          value: 'trademarkAccountantParty',
          label: t('pages.groupCreate.card.accountantParty'),
        },
        {
          value: 'trademarkRenewInstructingParty',
          label: t('pages.groupCreate.card.renewInstructingParty'),
        },
        {
          value: 'trademarkRenewAccountantParty',
          label: t('pages.groupCreate.card.renewAccountantParty'),
        },
        {
          value: 'trademarkAction',
          label: t('pages.groupCreate.card.action'),
        },
      ],
    },
    {
      title: t('pages.domain.title'),
      key: 'domain',
      textEnabled: true,
      checkboxFormName: 'domainCheckbox',
      lists: [
        {
          value: 'domainHolder',
          label: t('pages.groupCreate.card.holder'),
        },
        {
          value: 'domainInstructingParty',
          label: t('pages.groupCreate.card.instructingParty'),
        },
        {
          value: 'domainAccountantParty',
          label: t('pages.groupCreate.card.accountantParty'),
        },
        {
          value: 'domainRenewInstructingParty',
          label: t('pages.groupCreate.card.renewInstructingParty'),
        },
        {
          value: 'domainRenewAccountantParty',
          label: t('pages.groupCreate.card.renewAccountantParty'),
        },
        {
          value: 'domainAction',
          label: t('pages.groupCreate.card.action'),
        },
      ],
    },
    {
      title: t('pages.design.title'),
      key: 'design',
      textEnabled: true,
      checkboxFormName: 'designCheckbox',
      lists: [
        {
          value: 'unpublishedDesign',
          label: t('pages.groupCreate.card.unpublished'), // must be a key translated
        },
        {
          value: 'designHolder',
          label: t('pages.groupCreate.card.holder'),
        },
        {
          value: 'designInstructingParty',
          label: t('pages.groupCreate.card.instructingParty'),
        },
        {
          value: 'designAccountantParty',
          label: t('pages.groupCreate.card.accountantParty'),
        },
        {
          value: 'designRenewInstructingParty',
          label: t('pages.groupCreate.card.renewInstructingParty'),
        },
        {
          value: 'designRenewAccountantParty',
          label: t('pages.groupCreate.card.renewAccountantParty'),
        },
        {
          value: 'designAction',
          label: t('pages.groupCreate.card.action'),
        },
      ],
    },
    {
      title: 'Archives',
      key: 'archive',
      textEnabled: false,
      checkboxFormName: 'archiveCheckbox',
      radioFormName: 'archiveRadio',
      lists: [
        {
          value: 'archiveUpload',
          label: t('pages.groupCreate.card.archiveUpload'), // must be a key translated
        },
      ],
      radioOptions: [
        {
          value: 'archiveAccessByCompany',
          label: t('pages.groupCreate.card.archiveAccessByCompany'),
        },
        {
          value: 'archiveAccessByGroup',
          label: t('pages.groupCreate.card.archiveAccessByGroup'),
        },
      ],
    },
    {
      title: t('pages.groupCreate.card.documentAccessTitle'),
      key: 'document',
      textEnabled: false,
      checkboxFormName: 'documentCheckbox',
      radioFormName: 'archiveRadio',
      lists: [
        {
          value: 'documentAccess',
          label: t('pages.groupCreate.card.documentAccess'),
        },
      ],
    },
  ];
};

import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { resourceExists } from 'business/common/services';
import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import CompanyDomainInvoicingConfiguration from 'business/configuration/companies/components/companyDomainInvoicingConfiguration';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';
import DomainExpirationMailForm from 'business/configuration/domain/expirationEmail/components/form/domainExpirationMailForm';
import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useGetCompanyByPkQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ExtractRouteParams, useParams } from 'react-router';
import ConfigurationSection from 'ui/configurationSection';

const CompanyDomainInvoicing = () => {
  const { t } = useTranslation();
  const { canSeeCompany } = useLoggedInAppContext();
  const { id } =
    useParams<
      ExtractRouteParams<Routes.CompanyDomainInvoicingConfiguration, string>
    >();

  const companyId = Number(id);
  const { data, loading } = useGetCompanyByPkQuery({
    variables: { id: companyId },
  });

  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();

  const company = data?.company_by_pk;

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(company, { loading })}
    >
      <ConnectedPage
        translationKey="companyDomainInvoicingConfiguration"
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(company),
          { label: t('pages.admin.invoice.domainConfig.modal.title') },
        ]}
      >
        <ConfigurationSection
          title={t('pages.admin.invoice.domainConfig.modal.title')}
        >
          <CompanyDomainInvoicingConfiguration companyId={companyId} />
          <DomainExpirationMailForm companyId={companyId} />
        </ConfigurationSection>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default CompanyDomainInvoicing;

const BDL_IPR_CASES_BASE_URL = 'https://bdl.ipr-control.fr/Cases/Edit/';
const ALISSIA_CASES_BASE_URL = 'https://bdl.alissia.software/Cases/Edit/';

export const getBdlIprResourceUrl = (id: number) => {
  return `${BDL_IPR_CASES_BASE_URL}${id}`;
};

export const getAlissiaResourceUrl = (id: number) => {
  return `${ALISSIA_CASES_BASE_URL}${id}`;
};

import { TFunction } from 'i18next';
import { GeneratedTableProps } from 'technical/hooks/useQueryForTable';

const getTabLabel =
  <T,>(resourceType: string, t: TFunction) =>
  ({
    tableProps: { pagination },
  }: {
    tableProps: Pick<GeneratedTableProps<T>, 'pagination'>;
  }) =>
    `${t(`pages.${resourceType}.title`)} (${pagination?.total ?? 0})`;

export const getInvoiceTabLabel =
  <T,>(t: TFunction, invoiceType: 'domain' | 'archive') =>
  ({
    tableProps: { pagination },
  }: {
    tableProps: Pick<GeneratedTableProps<T>, 'pagination'>;
  }) =>
    `${t(`pages.invoices.${invoiceType}.title`)} (${pagination?.total ?? 0})`;

export default getTabLabel;

import { Parser } from 'technical/filters/types';
import * as yup from 'yup';

export const urlSearchParser = (
  defaultValue?: string,
): Parser<string | undefined> => ({
  defaultValue,
  readParse: (param) => yup.string().validateSync(param ?? defaultValue),
  writeParse: (value) =>
    value === defaultValue ? undefined : value?.toString(),
});

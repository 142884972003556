/* eslint-disable react/destructuring-assignment */
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Tooltip } from 'antd';
import { StatusType, statusTypes } from 'business/common/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { removeAccent } from 'technical/format/string';
import IconStatus from 'ui/iconStatus';

const statusTypeRendering: Record<
  StatusType,
  {
    color: string;
    icon: (props: Pick<AntdIconProps, 'style'>) => React.ReactNode;
  }
> = {
  notHandledByBdl: {
    color: 'orange',
    icon: (props) => <ExclamationCircleOutlined {...props} />,
  },
  nonEffective: {
    color: 'red',
    icon: () => <IconStatus success={false} />,
  },
  effective: {
    color: 'green',
    icon: () => <IconStatus success />,
  },
  inPreparation: {
    color: 'grey',
    icon: (props) => <ClockCircleOutlined {...props} />,
  },
};

interface StatusBadgeProps {
  status: string;
}

const StatusBadge: React.VFC<StatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();

  const statusType = statusTypes[removeAccent(status)];

  // If statusType isn't supported, we return the status as a string
  // This is for futurproofing new statuses without crashing the page
  if (!statusType) {
    return <p>{status}</p>;
  }
  const { icon, color } = statusTypeRendering[statusType];
  return (
    <Tooltip
      title={t('table.common.column.status', { context: removeAccent(status) })}
    >
      <div>{icon({ style: { color, fontSize: '20px' } })}</div>
    </Tooltip>
  );
};

export default StatusBadge;

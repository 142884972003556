import { isUserStatus } from 'business/user/services/status';
import { USER_STATUSES, UserStatus } from 'business/user/types/status';
import {
  GetBdlUsersQuery,
  GetBdlUsersQueryVariables,
  Order_By,
  User_Bool_Exp,
  useGetBdlUsersQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import {
  generateLocalizedSearch,
  generateTextSearchConditions,
} from 'technical/hooks/useQueryForTable/filterCondition';
import { BdlUser } from 'business/admin/services/types';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): User_Bool_Exp => ({
  _or: [
    ...generateLocalizedSearch(
      (tradKeys) => tradKeys.pages.admin.bdlUsers.roles,
      searchText,
      (stringComparison) => ({ role: stringComparison }),
    ),
    ...generateTextSearchConditions(searchText, [
      'email',
      'firstName',
      'lastName',
    ]),
  ],
});

const generateWhere = (
  searchText: string | null,
  statusFilter: UserStatus | null,
) => {
  return {
    ...(statusFilter ? { status: { _eq: statusFilter } } : {}),
    ...(searchText ? generateSearch(searchText) : {}),
  };
};
const useGetBdlUsers = () => {
  const { tableProps, ...rest } = useQueryForTable<
    BdlUser,
    GetBdlUsersQuery,
    GetBdlUsersQueryVariables
  >({
    subStorageKey: storageKeys.bdlUsers,
    commonFiltersKeys: ['userStatus', 'search'],
    useQuery: ({ limit, offset, searchText, orderBy, userStatus }) =>
      useGetBdlUsersQuery({
        variables: {
          limit,
          offset,
          orderBy: Object.keys(orderBy).length
            ? orderBy
            : { lastName: Order_By.Asc },
          where: generateWhere(searchText, userStatus),
        },
      }),
    getTotal: (res) => res?.user_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.user?.map(({ status, ...user }) => ({
        ...user,
        status: isUserStatus(status) ? status : USER_STATUSES.unknown,
      })) ?? [],
  });

  return {
    ...rest,
    tableProps,
  };
};

export default useGetBdlUsers;

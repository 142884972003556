import { useGetTrademarkFamiliesImagesQuery } from 'generated/graphql';

export const useGetFamiliesImages = (ids: string[]) => {
  const { data, error, loading } = useGetTrademarkFamiliesImagesQuery({
    variables: {
      ids: ids,
    },
  });

  return {
    imageList: data?.getImagesByFamilyListIds ?? [],
    error,
    loading,
  };
};

import notifyPromise from 'business/common/services/notifyPromise';
import {
  Domain_ExpirationContact_Insert_Input,
  useUpdateDomainExpirationMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const useUpdateDomainExpiration = () => {
  const { t } = useTranslation();
  const [updateDomainExpirationMutation] = useUpdateDomainExpirationMutation();

  const updateDomainExpiration = async (
    domainExpirationContact: Domain_ExpirationContact_Insert_Input,
  ) => {
    notifyPromise(
      t,
      async () => {
        await updateDomainExpirationMutation({
          variables: {
            object: domainExpirationContact,
          },
        });
      },
      { successNotification: t('pages.domain.expiration.emailSuccess') },
    );
  };

  return {
    updateDomainExpiration,
  };
};

export function splitArrayToChunks<T>(array: T[], chunkSize: number) {
  const res: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

export const removeDuplicates = <T>(array: T[]) => {
  return array.filter((item, index, self) => self.indexOf(item) === index);
};

import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Spacer from 'ui/spacer';
import Map from 'ui/map';
import { resourceExists } from 'business/common/services';
import { trademarkFamilyColumns } from 'business/resources/trademark/components/columns';
import Table from 'ui/table';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import { FAMILY_IMAGE_HEIGHT } from 'ui/imageDisplay/constants';
import TrademarkImage from 'business/resources/trademark/components/trademarkImage';
import HeaderTitles from 'ui/headerTitles';
import { useTrademarkBreadcrumbs } from 'business/resources/trademark/services/useGetTrademarkBreadcrumbs';
import {
  useGetTrademarkFamily,
  useGetTrademarkFamilyInfos,
  useGetTrademarkFamilyMap,
} from 'business/resources/trademark/services/useGetTrademarkFamily';
import { getTrademarkFamilyImageQuery } from 'business/resources/trademark/services/useGetTrademarkImages';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const TrademarkFamilyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: familyId } = useParams<{ id: string }>();
  const { data, tableProps } = useGetTrademarkFamily({
    filterValues: { familyId },
  });
  const { onExport, loading: onExportLoading } = useDownloadTableToXlsx();
  const { dataMap, loading } = useGetTrademarkFamilyMap(familyId);

  const { getFamilyBreadcrumbs } = useTrademarkBreadcrumbs();
  const trademarkFamily = useGetTrademarkFamilyInfos(familyId);

  return (
    <ResourceNotFound
      resourceExists={resourceExists(trademarkFamily, tableProps)}
    >
      <ConnectedPage
        breadcrumbs={getFamilyBreadcrumbs(trademarkFamily)}
        translationKey="trademarkFamily"
      >
        <HeaderTitles
          title={trademarkFamily?.title ?? ''}
          subtitle={t('pages.header.trademarkFamily')}
        />
        <Spacer direction="vertical" align="center">
          <TrademarkImage
            query={getTrademarkFamilyImageQuery(familyId)}
            title={trademarkFamily?.title ?? ''}
            height={FAMILY_IMAGE_HEIGHT}
          />
        </Spacer>
        <Spacer direction="vertical" size="large" align="center">
          <Table
            defaultColumns={trademarkFamilyColumns}
            data-test-id="trademarkFamilyTable"
            dataSource={data}
            loadingExport={onExportLoading}
            onExport={(columns) => {
              onExport(columns, 'trademark', false, { familyId });
            }}
            {...tableProps}
          />
          <Map
            resourceType="trademark"
            isDataLoading={loading}
            data={dataMap}
          />
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default TrademarkFamilyPage;

import MenuLink from 'ui/menu/menuLink';
import appLogo from 'config/appLogo.png';
import styles from './index.module.scss';

interface AppLogoProps {
  height?: string;
  path?: string;
  connected?: boolean;
  clickable?: boolean;
}

const AppLogo = ({ clickable, height }: AppLogoProps) => {
  return clickable ? (
    <MenuLink path="/" className={styles.menuLink}>
      <img
        className={styles.appLogo}
        style={{ height }}
        src={appLogo}
        alt="App logo"
      />
    </MenuLink>
  ) : (
    <img
      className={styles.appLogo}
      style={{ height }}
      src={appLogo}
      alt="App logo"
    />
  );
};

export default AppLogo;

import notifyPromise from 'business/common/services/notifyPromise';
import { useSignInMutation } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sleep } from 'technical/promises';

const DELAY_BEFORE_CAN_RESEND = 10_000;

interface UseMfaParams {
  open: boolean;
  email: string;
}

const useMfa = ({ open, email }: UseMfaParams) => {
  const { t } = useTranslation();
  const [mfaToken, setMfaToken] = useState<string>('');
  const [signIn] = useSignInMutation();
  const [canResend, setCanResend] = useState<boolean>(false);

  const updateCanResend = async () => {
    setCanResend(false);
    await sleep(DELAY_BEFORE_CAN_RESEND);
    setCanResend(true);
  };

  useEffect(() => {
    setMfaToken('');
    if (open) {
      updateCanResend();
    }
  }, [open]);

  const resendMfaToken = () =>
    notifyPromise(
      t,
      async () => {
        setMfaToken('');
        const { data } = await signIn({
          variables: { email, password: '' },
        });
        if (!data?.signIn?.mfaRequired) {
          throw new Error('Something went wrong');
        }
        updateCanResend();
      },
      {
        successNotification: t('pages.signIn.mfa.sendCodeNotification', {
          email,
        }),
      },
    );

  return { mfaToken, setMfaToken, resendMfaToken, canResend };
};

export default useMfa;

import { Rule } from 'antd/lib/form';
import { UserInfoFormValues } from 'business/configuration/accessManagement/services/types';
import { TFunction } from 'i18next';

export const USER_INFO_MODAL_RULES = (
  t: TFunction,
  requiredField?: boolean,
): Record<keyof UserInfoFormValues, Rule[]> => ({
  firstName: [
    {
      type: 'string',
      whitespace: true,
      required: requiredField ?? true,
      message: t('errors.required'),
    },
    {
      min: 2,
      transform: (value: string) => value && value.trim(),
      message: t('errors.too_short_number', { number: 2 }),
    },
    {
      max: 100,
      transform: (value: string) => value && value.trim(),
      message: t('errors.too_long_number', { number: 100 }),
    },
  ],
  lastName: [
    {
      type: 'string',
      whitespace: true,
      required: requiredField ?? true,
      message: t('errors.required'),
    },
    {
      min: 2,
      transform: (value: string) => value && value.trim(),
      message: t('errors.too_short_number', { number: 2 }),
    },
    {
      max: 100,
      transform: (value: string) => value && value.trim(),
      message: t('errors.too_long_number', { number: 100 }),
    },
  ],
  email: [
    { required: requiredField ?? true, message: t('errors.required') },
    { type: 'email', message: t('errors.wrong_email_address') },
  ],
  groupId: [{ required: requiredField ?? true, message: t('errors.required') }],
});

export const formListName = 'multiCompaniesList';

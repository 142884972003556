import { isBdlAdmin } from 'business/admin/services/permission';
import { User } from 'business/configuration/accessManagement/services/types';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { TFunction } from 'i18next';
import Spacer from 'ui/spacer';
import { ColumnGenerationData } from 'ui/table/types';
import ActionButton from 'ui/table/tableActions/actionButton';
import { ITableAction } from 'business/configuration/accessManagement/components/tables/services/types';

const defaultColumns = (
  t: TFunction,
  currentUser: UserWithPrivateInfos,
  actions: ITableAction<User>[],
): ColumnGenerationData<User> => [
  { key: 'firstName' },
  { key: 'lastName' },
  { key: 'email', alwaysVisible: true },
  {
    key: 'groups',
    sorter: false,
    render: (_, user: User) => {
      return (
        <Spacer direction="vertical">
          {user.groups.map(
            ({
              id,
              group: {
                name: groupName,
                company: { name, companyRef },
              },
            }) => {
              return (
                <span key={id}>{`${name} [${companyRef}] (${groupName})`}</span>
              );
            },
          )}
        </Spacer>
      );
    },
  },
  { key: 'companyRef' },
  { key: 'lastConnection' },
  {
    key: 'status',
    translationKey: 'isActive',
    alwaysVisible: true,
    render: (status: string) =>
      t('user.status', {
        context: status,
      }),
  },
  {
    key: 'enabledMFA',
    checked: false,
  },
  {
    key: 'isActive',
    translationKey: 'action',
    fixed: 'right',
    sorter: false,
    alwaysVisible: true,
    disabled: !isBdlAdmin(currentUser),
    render: (_val: any, data: User) => {
      return (
        <Spacer size="xxsmall">
          {actions.map((action) => {
            return (
              <ActionButton
                key={action.dataTestId}
                hide={action.hide}
                tooltipTitle={action.getTooltipTitle(data)}
                dataTestId={action.dataTestId}
                icon={action.getIcon(data)}
                disabled={action.isDisabled?.(data)}
                onClick={() => action.onClick(data)}
              />
            );
          })}
        </Spacer>
      );
    },
  },
];

export default defaultColumns;

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { GetResourceCommentQuery } from 'generated/graphql';
import { formatDate } from 'technical/date';
import Card from 'ui/card';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';

interface IComment {
  comment: GetResourceCommentQuery['getResourceComments'][number];
  userId: string;
  onEdit: (data: { id: string; comment: string }) => void;
  onDelete: (id: string) => void;
}

const Comment = ({ comment, onDelete, onEdit, userId }: IComment) => {
  return (
    <Card
      title={`${formatDate(new Date(comment.createdAt))} : ${comment?.user
        ?.fullName}`}
    >
      <Flex justify="space-between">
        <Flex
          alignContent="center"
          alignItems="center"
          style={{ width: '80%' }}
        >
          <Typography.Text type="secondary" style={{ fontSize: 15 }}>
            {comment.comment}
          </Typography.Text>
        </Flex>
        {userId === comment.user?.id ? (
          <Flex>
            <Spacer size="xsmall">
              <EditOutlined
                onClick={() => {
                  onEdit({ comment: comment.comment, id: comment.id });
                }}
              />
              <DeleteOutlined size={20} onClick={() => onDelete(comment.id)} />
            </Spacer>
          </Flex>
        ) : null}
      </Flex>
    </Card>
  );
};

export default Comment;

import notifyPromise from 'business/common/services/notifyPromise';
import { useSetUserActiveStatusMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface UpdateUserStatusParams {
  refetch?: () => void;
}
export const useUpdateUserStatus = ({ refetch }: UpdateUserStatusParams) => {
  const { t } = useTranslation();
  const [updateActiveMutation] = useSetUserActiveStatusMutation();

  const updateUserStatus = (userId: string, isActive: boolean) =>
    notifyPromise(
      t,
      async () =>
        updateActiveMutation({
          variables: {
            userId,
            active: isActive,
          },
        }),
      {
        refetch,
        successNotification: t(
          'pages.admin.bdlUsers.table.actions.notifications.set',
          { context: isActive ? 'inactive' : null },
        ),
        reportError: 'warning',
      },
    );

  return {
    updateUserStatus,
  };
};

import { SettingOutlined } from '@ant-design/icons';
import DomainFeeModal from 'business/admin/components/domainFeesModal';
import { isBdlAdmin } from 'business/admin/services/permission';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';

const DomainFeeButtonModal = ({ user }: { user: UserWithPrivateInfos }) => {
  const { t } = useTranslation();
  const [domainFeeModalVisible, setDomainFeeModalVisible] = useState(false);

  return (
    <div>
      {isBdlAdmin(user) && (
        <>
          <Button
            icon={<SettingOutlined />}
            onClick={() => setDomainFeeModalVisible(true)}
            type="link"
          >
            {t('pages.admin.invoice.domainConfig.modal.title')}
          </Button>
          <DomainFeeModal
            visible={domainFeeModalVisible}
            onClose={() => setDomainFeeModalVisible(false)}
          />
        </>
      )}
    </div>
  );
};

export default DomainFeeButtonModal;

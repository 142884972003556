import { TableOutlined } from '@ant-design/icons';
import { DomainOfFamily } from 'business/resources/domain/types';
import { Link } from 'react-router-dom';
import { ColumnGenerationData } from 'ui/table/types';
import WhoIsButton from 'business/resources/domain/components/whoisButton';
import SimpleLink from 'ui/link/SimpleLink';
import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import {
  getCountryColumn,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { TFunction } from 'react-i18next';

export const domainFamilyColumns = (
  t: TFunction,
): ColumnGenerationData<DomainOfFamily> => {
  return [
    {
      key: 'title',
      context: 'domain',
      alwaysVisible: true,
      ellipsis: true,
      render: (_, { id, title }: DomainOfFamily) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('domain', id)}
        >
          {`${title}`.toUpperCase()}
        </Link>
      ),
    },
    {
      key: 'extension',
      ellipsis: true,
    },
    {
      ...getCountryColumn(),
      render: (_, domain: DomainOfFamily) => {
        if (domain.countryCode === 'WW') {
          return <span>{t('common.generic')}</span>;
        }
        return <span>{domain[getCountryKey()]}</span>;
      },
    },
    {
      key: 'title',
      translationKey: 'link',
      alwaysVisible: true,
      render: (_, record: DomainOfFamily) => (
        <SimpleLink
          className="titleRedirect"
          data-test-id="titleRedirect"
          link={`https://www.${record.title}`}
          displayIcon={false}
        />
      ),
    },
    { key: 'reservationDate' },
    { key: 'expirationDate' },
    { key: 'bdlRef' },
    { key: 'misRef', ellipsis: true },
    { key: 'registrar', checked: false },
    {
      key: 'title',
      sorter: false,
      translationKey: 'whois',
      render: (title) => <WhoIsButton domain={title} />,
    },
    { key: 'status' },
  ];
};

export const advancedSearchDomainColumns: ColumnGenerationData<DomainOfFamily> =
  [
    {
      key: 'holder',
    },
    {
      key: 'title',
      context: 'domain',
      alwaysVisible: true,
      render: (_, { id, title }: DomainOfFamily) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('domain', id)}
        >
          {`${title}`.toUpperCase()}
        </Link>
      ),
    },
    {
      key: 'extension',
      render: (_, domain: DomainOfFamily) => (
        <p>{domain.extension?.toLowerCase()}</p>
      ),
    },
    {
      key: 'title',
      translationKey: 'link',
      alwaysVisible: true,
      ellipsis: true,
      render: (_, record: DomainOfFamily) => (
        <SimpleLink
          className="titleRedirect"
          data-test-id="titleRedirect"
          link={`https://www.${record.title}`}
          displayIcon={false}
        />
      ),
    },
    { key: 'reservationDate', ellipsis: true },
    { key: 'expirationDate', ellipsis: true },
    {
      key: 'clientRef',
    },
    { key: 'bdlRef' },
    { key: 'misRef', checked: false },
    {
      key: 'bdlOfficer',
      ellipsis: true,
    },
    {
      key: 'clientOfficer',
      ellipsis: true,
    },
    { key: 'registrar', checked: false, ellipsis: true },
    { key: 'status', ellipsis: true },
    {
      key: 'familyId',
      translationKey: 'family',
      render: (_, { familyId }: DomainOfFamily) => (
        <Link to={generateFamilyPathByAssetType('domain', familyId)}>
          <TableOutlined />
        </Link>
      ),
    },
  ];

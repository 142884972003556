import { Event } from 'business/resources/events/services/types';
import {
  GetTrademarkEventsQuery,
  GetTrademarkEventsQueryVariables,
  Order_By,
  useGetTrademarkEventsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetTrademarkEvents = (trademarkId: number) =>
  useQueryForTable<
    Event,
    GetTrademarkEventsQuery,
    GetTrademarkEventsQueryVariables
  >(
    {
      subStorageKey: storageKeys.trademarkEvents,
      useQuery: ({ limit, offset, orderBy }) =>
        useGetTrademarkEventsQuery({
          variables: { limit, offset, trademarkId, orderBy },
        }),
      getTotal: (res) =>
        res?.event_trademarkEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_trademarkEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

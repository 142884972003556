import { generatePathByAssetType } from 'business/common/services';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import { DesignEvent } from 'business/resources/events/services/types';
import { Link } from 'react-router-dom';
import ResourceImageDisplay from 'ui/imageDisplay';
import {
  EVENTS_TABLE_IMAGE_CELL_WIDTH,
  EVENTS_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';
import { ColumnGenerationData } from 'ui/table/types';
import { getDesignImageQuery } from 'business/resources/design/services/useGetImages';

const getDesignEventColumns = (): ColumnGenerationData<DesignEvent> => [
  {
    key: 'type',
    alwaysVisible: true,
    translationKey: 'eventType',
    ellipsis: true,
  },
  { key: 'holder', checked: false, ellipsis: true },
  { key: 'effectiveHolder', checked: false, ellipsis: true },
  {
    key: 'title',
    alwaysVisible: true,
    translationKey: 'designName',
    render: (_, { assetId, title }: DesignEvent) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('design', assetId)}
      >
        {title}
      </Link>
    ),
  },
  {
    key: 'id',
    translationKey: 'image',
    alwaysVisible: true,
    sorter: false,
    width: EVENTS_TABLE_IMAGE_CELL_WIDTH,
    render: (_, { assetId }) =>
      assetId ? (
        <ResourceImageDisplay
          {...EVENTS_TABLE_IMAGE_STYLE}
          query={getDesignImageQuery(assetId)}
        />
      ) : null,
  },
  { ...getCountryColumn(), alwaysVisible: true },
  { key: 'dueDate', alwaysVisible: true, ellipsis: true },
  { key: 'depositNumber', checked: false, ellipsis: true },
  { key: 'depositDate', checked: false, ellipsis: true },
  { key: 'bdlRef', checked: false, ellipsis: true },
  { key: 'clientRef', checked: false, ellipsis: true },
  { key: 'clientRef2', checked: false, ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
  { key: 'adjournedPublication', ellipsis: true },
];

export default getDesignEventColumns;

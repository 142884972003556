import { Modal, ModalFuncProps } from 'antd';
import { TFunction } from 'i18next';

/**
 * A function to quickly add a confirmation modal to any function call
 * @param t TFunction for translations
 * @param onOk The function called when the confirm button is pressed
 * @param title The string that will be displayed in the modal title
 * @param props Additionnal props for Antd Modal component
 */
const confirmationModal = (
  t: TFunction,
  onOk: () => void | Promise<void>,
  title?: string,
  props?: ModalFuncProps,
) =>
  Modal.confirm({
    type: 'info',
    title,
    okText: t('common.confirm'),
    cancelText: t('common.cancel'),
    ...props,
    onOk,
  });

export default confirmationModal;

import { CommonUserFieldsFragment, QueryMyUserQuery } from 'generated/graphql';

export interface User {
  id: any;
  firstName: string;
  lastName: string;
  role: string;
  companyIds: number[];
  safranUser: boolean;
  language: Language;
  enabledMFA: boolean;
  osrAccess: boolean;
  patentAccess: boolean;
  trademarkAccess: boolean;
  designAccess: boolean;
  domainAccess: boolean;
}

type ISOString = string;

export type Language = 'fr-FR' | 'en';

export interface UserWithPrivateInfos extends User {
  privateInfos: {
    identityId: string | null;
    email: string;
    isActive: boolean;
    createdAt: ISOString;
    updatedAt: ISOString;
    archiveEnabledCompanies: number[];
    archivePageEnabled: boolean;

    archiveInvoiceEnabled: boolean;
    domainInvoiceEnabled: boolean;
  };
}

export type UserWithConsent = UserWithPrivateInfos & {
  consent: QueryMyUserQuery['getUserConsent'];
};

export function isUserWithPrivateInfos(
  user: CommonUserFieldsFragment,
): user is UserWithPrivateInfos {
  if (!user || user.privateInfos == null) {
    return false;
  }
  return true;
}

import Routes from 'business/router/routes';
import CreatePasswordCard from 'business/user/components/createPasswordCard';
import authService from 'business/user/services/authService';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import history from 'technical/history';
import PageAuth from 'business/user/pages/pageAuth';
import { notification } from 'antd';

interface ResetPasswordRouteProps {
  token?: string; // Generated by the back when user request a change of password
}

const ResetPasswordPage = () => {
  const [errorContext, setErrorContext] = useState<string | null>(null);

  const { params } = useRouteMatch<ResetPasswordRouteProps>();
  const { t } = useTranslation();

  const token = params.token || null;

  const handleSubmitClick = async (password: string) => {
    setErrorContext(null);
    try {
      await authService.resetPassword(token, password, password);
      history.push(Routes.SignIn);
    } catch (error) {
      setErrorContext(getAuthErrorContextFromAuthProviderError(error));
    }
  };

  useEffect(() => {
    if (errorContext) {
      notification.error({
        message: errorContext,
      });
    }
  }, [errorContext]);

  return (
    <PageAuth>
      <CreatePasswordCard
        submitButton={{
          label: t('pages.resetPassword.submit'),
          onClick: handleSubmitClick,
        }}
        description={t('pages.resetPassword.description')}
      />
    </PageAuth>
  );
};

export default ResetPasswordPage;

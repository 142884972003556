import passwordValidationModal from 'ui/passwordValidationModal';
import notifyPromise from 'business/common/services/notifyPromise';
import { useDeleteUserGroupMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface DeleteUserHookParam {
  refetch?: () => void;
}

const useDeleteUserGroup = ({ refetch }: DeleteUserHookParam) => {
  const [deleteUserGroupMutation] = useDeleteUserGroupMutation();
  const { t } = useTranslation();

  const deleteUser = async (userGroupId: number) =>
    notifyPromise(
      t,
      () => deleteUserGroupMutation({ variables: { id: userGroupId } }),
      {
        refetch,
      },
    );

  const confirmDeleteUserGroup = (userGroupId: number) => {
    passwordValidationModal({
      onOk: () => deleteUser(userGroupId),
      modalTitle: t('passwordValidationModal.modalTitle', {
        context: 'deleteUser',
      }),
      warningMessage: t('passwordValidationModal.warningMessage', {
        context: 'delete',
      }),

      instructionText: t('passwordValidationModal.instructionText', {
        context: 'delete',
      }),

      confirmButtonText: t('passwordValidationModal.confirmButtonText', {
        context: 'delete',
      }),
    });
  };

  return {
    confirmDeleteUserGroup,
  };
};

export default useDeleteUserGroup;

import ArchiveConfigurationTable from 'business/archive/components/archiveConfigurationTable';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { notification } from 'antd';
import { useState } from 'react';
import { useGetArchiveConfigurationList } from 'business/archive/hooks/useGetArchiveConfigurationList';
import Button from 'ui/button';
import Flex from 'ui/flex';
import ArchiveConfigurationFormModal from 'business/archive/components/archiveConfigurationForm';
import logger from 'technical/logger';
import {
  GetActiveArchivePackageDocument,
  useInsertArchivePackageOneMutation,
} from 'generated/graphql';
import { Archive } from 'business/archive/services/types';
import { getSectionOptions } from './constant';

const ArchiveConfiguration = () => {
  const { t } = useTranslation();
  const { data, tableProps } = useGetArchiveConfigurationList();
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [insertArchivePackage] = useInsertArchivePackageOneMutation();

  const onFinish = async (values: Archive) => {
    try {
      await insertArchivePackage({
        variables: {
          ...values,
          arkhineoSectionCode: values.storageDuration === 30 ? 'P30Y' : 'P10Y',
        },
        refetchQueries: [{ query: GetActiveArchivePackageDocument }],
      });
      notification.success({
        message: t('archive.config.success'),
      });
      tableProps.refetch?.();
      setIsCreationModalOpen(false);
    } catch (err) {
      logger.error(err);
      notification.error({
        message: t('archive.config.error'),
      });
    }
  };

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('archive.config.title') }]}
      translationKey="archiveConfiguration"
    >
      <Spacer direction="vertical" size="middle">
        <h1 className="headerTitle">{t('archive.config.title')}</h1>
        <ArchiveConfigurationTable
          {...tableProps}
          showActions
          data-test-id="archiveConfigurationTable"
          dataSource={data}
        />
        <Flex justify="flex-start">
          <Button
            data-test-id="archivePackageModalOpen"
            onClick={() => setIsCreationModalOpen(true)}
            type="primary"
          >
            {t('archive.config.create')}
          </Button>
        </Flex>
      </Spacer>
      <ArchiveConfigurationFormModal
        onFinish={onFinish}
        onCancel={() => setIsCreationModalOpen(false)}
        visible={isCreationModalOpen}
        sectionOptions={getSectionOptions(t)}
      />
    </ConnectedPage>
  );
};

export default ArchiveConfiguration;

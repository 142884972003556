import frTranslation from 'translations/fr.json';
import enTranslation from 'translations/en.json';
import { i18n } from 'translations';

type TranslationFileObject = Record<string, string>;

export const getTranslationFileByLanguage = () => {
  const frLanguage = i18n.language === 'fr' || 'fr_FR';
  return frLanguage ? frTranslation : enTranslation;
};

export const findKeyInTranslationFileByRegex = (
  object: TranslationFileObject,
  expression: string,
  flags = 'g',
) => {
  const regexToMatch = new RegExp(expression, flags);
  const keys: string[] = [];
  Object.keys(object).forEach((key) => {
    if (object[key].match(regexToMatch)) {
      keys.push(key);
    }
  });
  return keys;
};

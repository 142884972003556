import {
  useGetDesignByMisIdQuery,
  useGetDomainByMisIdQuery,
  useGetPatentByMisIdQuery,
  useGetTrademarkByMisIdQuery,
} from 'generated/graphql';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

type DomainType = 'patent' | 'domain' | 'trademark' | 'design';

function isDomainType(value: string): value is DomainType {
  return ['patent', 'domain', 'trademark', 'design'].includes(value);
}

const getQueryByResourceType = (type: DomainType) => {
  const queries = {
    domain: useGetDomainByMisIdQuery,
    trademark: useGetTrademarkByMisIdQuery,
    design: useGetDesignByMisIdQuery,
    patent: useGetPatentByMisIdQuery,
  };
  return queries[type];
};

const useGetResourceV2Url = (id: number, type: string) => {
  const history = useHistory();
  if (!isDomainType(type)) {
    return {
      error: 'Invalid url',
    };
  }

  const query = getQueryByResourceType(type);
  const { data, loading, error } = query({
    variables: {
      misId: id,
    },
  });

  useEffect(() => {
    const resourceId = data?.resource[0]?.id ?? 0;
    if (resourceId !== 0) {
      history.push(`/${type}/${resourceId}`);
    }
  }, [data]);

  return {
    loading: loading,
    error,
  };
};

export default useGetResourceV2Url;

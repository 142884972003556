import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { getCountryLabel } from 'technical/countriesTranslation';
import { Country } from 'technical/countriesTranslation/types';
import { BreadcrumbSegment } from 'ui/breadcrumb';

type ResourceType = 'patent' | 'design' | 'trademark' | 'domain';

type BreadcrumbResourceFamily = {
  familyId?: string | null;
  holder?: string | null;
  title?: string | null;
};

export type BreadcrumbResource = {
  id: number;
  familyId?: string | null;
  holder?: string | null;
  title?: string | null;
  country?: Country | null;
};

export const useResourceBreadcrumbs = ({
  type,
  rootRoute,
  familyRoute,
}: {
  type: ResourceType;
  rootRoute: Routes;
  familyRoute: Routes;
}) => {
  const { t } = useTranslation();

  const getBreadcrumbs = ({
    family,
    resource,
  }: {
    family?: {
      holder: string;
      title: string;
      familyId?: string | null;
      loading?: boolean;
    };
    resource?: {
      country: string;
      loading?: boolean;
    };
  }): BreadcrumbSegment[] => {
    let breadcrumbs: BreadcrumbSegment[] = [
      { label: t(`table.common.column.${type}s`), link: rootRoute },
    ];
    if (family) {
      const { familyId: id, holder, title, loading } = family;
      breadcrumbs = [
        ...breadcrumbs,
        {
          label: holder,
          link: `${rootRoute}?${new URLSearchParams({ search: holder })}`,
          loading,
        },
        {
          label: title,
          link: id ? generatePath(familyRoute, { id }) : undefined,
          loading,
        },
      ];
    }
    if (resource) {
      const { country, loading } = resource;
      breadcrumbs = [...breadcrumbs, { label: country, loading }];
    }
    return breadcrumbs;
  };

  return {
    getFamilyBreadcrumbs: (
      family: BreadcrumbResourceFamily | undefined | null,
    ) =>
      getBreadcrumbs({
        family: {
          holder: family?.holder ?? '',
          title: family?.title ?? '',
          loading: !family,
        },
      }),
    getResourceBreadcrumbs: (resource: BreadcrumbResource | undefined | null) =>
      getBreadcrumbs({
        family: {
          familyId: resource?.familyId ?? '',
          holder: resource?.holder ?? '',
          title: resource?.title ?? '',
          loading: !resource,
        },
        resource: {
          country: getCountryLabel(resource?.country),
          loading: !resource,
        },
      }),
  };
};

export const useCompanyBreacrumbs = () => {
  const { t } = useTranslation();
  const root: BreadcrumbSegment = {
    label: t('pages.companies.title'),
    link: Routes.Companies,
  };

  return {
    root: [root],
    getCompanyBaseBreadcrumbs: (
      company?: { name: string; id: number } | null,
    ) => [
      root,
      {
        label: company?.name ?? '',
        link: company
          ? generatePath(Routes.CompanyDetails, { id: company.id })
          : undefined,
        loading: !company,
      },
    ],
  };
};

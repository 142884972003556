import {
  GetDomainEventsQuery,
  GetDomainEventsQueryVariables,
  useGetDomainEventsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { Event } from 'business/resources/events/services/types';
import { useTranslation } from 'react-i18next';
import { OrderBy } from 'technical/filters/parser/commonParser/orderByParser';

const generateOrderBy = (orderBy: OrderBy<Event>) => {
  if ('dueDate' in orderBy) {
    return { gandiOperation: { operationDate: orderBy.dueDate } };
  }
  if ('type' in orderBy) {
    return { gandiOperation: { operationDate: orderBy.type } };
  }
  return orderBy;
};

export const useGetDomainEvents = (domainId: number) => {
  const { t } = useTranslation();

  return useQueryForTable<
    Event,
    GetDomainEventsQuery,
    GetDomainEventsQueryVariables
  >({
    subStorageKey: 'domainEvents',
    useQuery: ({ limit, offset, orderBy }) =>
      useGetDomainEventsQuery({
        variables: {
          limit,
          offset,
          domainId,
          orderBy: generateOrderBy(orderBy),
        },
      }),
    getTotal: (res) => res?.event_domainEvent_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.event_domainEvent.map((event) => {
        return {
          id: event.id,
          dueDate: event.gandiOperation?.operationDate,
          completionDate: event.completionDate,
          reminderDate: event.reminderDate,
          type: t('table.common.column.domainType', {
            context: event.gandiOperation?.type ?? '',
          }),
        };
      }) ?? [],
  });
};

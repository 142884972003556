import ConnectedPage from 'business/user/helpers/connectedPage';

import Flex from 'ui/flex';
import SearchBar from 'ui/searchBar';
import { useHistory } from 'react-router';
import Routes from 'business/router/routes';
import { SearchOutlined } from '@ant-design/icons';
import HomeLogo from 'ui/appLogo/homeLogo';
import styles from './index.module.scss';

const HomePage: React.FC = () => {
  const history = useHistory();

  const onPressEnter = (str: string) => {
    history.push({
      pathname: Routes.Search,
      search: '?' + new URLSearchParams({ search: str }),
    });
  };

  return (
    <ConnectedPage>
      <Flex className={styles.logoFlex} justify="center" alignItems="center">
        <div className={styles.logoContainer}>
          <SearchBar
            onPressEnter={(e) =>
              onPressEnter((e.target as HTMLInputElement).value)
            }
            className={styles.searchBar}
            suffix={<SearchOutlined className={styles.searchIcon} />}
          />
          <HomeLogo className={styles.homeLogo} />
        </div>
      </Flex>
    </ConnectedPage>
  );
};

export default HomePage;

import styles from './index.module.scss';

interface TagProps {
  label: string;
  'data-test-id'?: string;
}

const Tag: React.VFC<TagProps> = ({ label, 'data-test-id': dataTestId }) => {
  return (
    <div className={styles.tag} data-test-id={dataTestId}>
      {label}
    </div>
  );
};

export default Tag;

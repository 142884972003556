import { useTranslation } from 'react-i18next';
import Button from 'ui/button';

interface OfficalLinkButtonProps {
  link: string;
}
const OfficalLinkButton = ({ link }: OfficalLinkButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      className="fullWidth flex1"
      onClick={() => window.open(link, '_blank')}
    >
      {t('metadata.officialLink')}
    </Button>
  );
};

export default OfficalLinkButton;

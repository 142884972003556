import {
  useSendBackArchiveInvoiceMutation,
  useSendBackDomainInvoiceMutation,
} from 'generated/graphql';
import { InvoiceType } from 'business/invoice/services/types';

type SendBackHook = () => readonly [
  (param: {
    variables: {
      id: number;
    };
  }) => Promise<any>,
  any,
];

const sendBackArchiveInvoiceHooks: Record<InvoiceType, SendBackHook> = {
  archive: useSendBackArchiveInvoiceMutation,
  domain: useSendBackDomainInvoiceMutation,
};

const useSendBackInvoice = (type: InvoiceType) => {
  const [sendBackArchiveInvoice] = sendBackArchiveInvoiceHooks[type]();

  const sendBackInvoice = async (id: number) => {
    await sendBackArchiveInvoice({
      variables: { id },
    });
  };

  return [sendBackInvoice] as const;
};

export default useSendBackInvoice;

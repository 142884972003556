import { Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { i18n } from 'translations';
import Button from 'ui/button';

type Props = object;

interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    errorReporting.warning(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Result
          status="warning"
          title={i18n.t('errors.generic')}
          extra={
            <Link to="/">
              <Button type="primary">{i18n.t('pages.common.goToHome')}</Button>
            </Link>
          }
        />
      );
    }

    return children;
  }
}

const ErrorBoundary = ErrorBoundaryComponent;

export default ErrorBoundary;

import { useGetArchiveDefaultKeywordsQuery } from 'generated/graphql';

const useGetDefaultKeywords = () => {
  const { data, loading, error, refetch } = useGetArchiveDefaultKeywordsQuery();

  return {
    keywords: data?.archive_documentType[0].options ?? [],
    loading,
    error,
    refetch,
  };
};

export default useGetDefaultKeywords;

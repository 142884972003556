import {
  Domain_Invoice_Bool_Exp,
  GetDomainInvoicesQuery,
  GetDomainInvoicesQueryVariables,
  useGetDomainInvoicesQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { isDefined } from 'technical/validation';
import { DomainInvoice } from 'business/admin/services/types';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): Domain_Invoice_Bool_Exp => ({
  _or: [
    ...generateTextSearchConditions(searchText, [
      'invoiceNumber',
      'holder',
      'clientRef',
      'bdlOfficer',
      'section',
      'domains',
    ]),
  ],
});

interface DomainInvoicesProps {
  where?: Domain_Invoice_Bool_Exp;
}

const useGetDomainInvoices = ({ where }: DomainInvoicesProps) => {
  const query = useQueryForTable<
    DomainInvoice,
    GetDomainInvoicesQuery,
    GetDomainInvoicesQueryVariables
  >({
    subStorageKey: storageKeys.domainInvoices,
    commonFiltersKeys: ['from', 'to', 'search'],
    useQuery: ({ limit, offset, searchText, from, to, orderBy }) => {
      return useGetDomainInvoicesQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...where,
            ...(searchText ? generateSearch(searchText) : {}),
            date: {
              ...(isDefined(from) ? { _gte: from } : {}),
              ...(isDefined(to) ? { _lte: to } : {}),
            },
          },
        },
      });
    },
    getTotal: (res) => res?.domain_invoice_aggregate.aggregate?.count ?? 0,
    map: (res) => res?.domain_invoice ?? [],
  });

  return { ...query };
};

export default useGetDomainInvoices;

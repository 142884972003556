import { StorageType } from './types';

export const storages: Record<StorageType, Storage> = {
  local: localStorage,
  session: sessionStorage,
};

export const storageKeys = {
  logs: 'logs',
  bdlUsers: 'bdlUsers',
  users: 'users',
  companies: 'companies',
  companyGroups: 'companyGroups',
  userGroups: 'userGroups',
  companyArchiveSubscription: 'companyArchiveSubscription',
  archiveConfigurations: 'archiveConfigurations',
  archiveDeletionRequests: 'archiveDeletionRequest',
  archiveDocuments: 'archiveDocuments',
  archiveInvoices: 'archiveInvoices',
  domainInvoices: 'domainInvoices',
  allSearch: 'allSearch',
  designSearch: 'designSearch',
  domainSearch: 'domainSearch',
  patentSearch: 'patentSearch',
  trademarkSearch: 'trademarkSearch',
  designEvents: 'designEvents',
  designFamilies: 'designFamily',
  domainFamilies: 'domainFamily',
  patentFamilies: 'patentFamily',
  trademarkFamilies: 'trademarkFamily',
  allEvents: 'allEvents',
  domainEvents: 'domainEvents',
  patentEvents: 'patentEvents',
  trademarkEvents: 'trademarkEvents',
  homePage: 'homePage',
  eventPage: 'eventPage',
  visibleColumns: 'visibleColumns',
  userConsent: 'userConsent',
  advancedFilters: 'advancedFilters',
  eventAdvancedFilters: 'eventAdvancedFilters',
  patentOpsDocuments: 'patentOpsDocuments',
} as const;

export type StorageKey = keyof typeof storageKeys;

export const filtersStorageKey = 'filters';

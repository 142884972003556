import { ApolloClient } from '@apollo/client';
import {
  UserWithConsent,
  UserWithPrivateInfos,
  isUserWithPrivateInfos,
} from 'business/user/types/user';
import {
  QueryMyUserDocument,
  QueryMyUserQuery,
  QueryMyUserQueryVariables,
} from 'generated/graphql';

const computeInvoiceAccess = (
  groups: NonNullable<
    QueryMyUserQuery['userPrivate'][number]['user']
  >['groups'],
): Pick<
  UserWithPrivateInfos['privateInfos'],
  'archiveInvoiceEnabled' | 'domainInvoiceEnabled'
> => {
  const invoicingAccesses = groups.map(({ group }) => group.invoicingAccess);

  return {
    archiveInvoiceEnabled: invoicingAccesses.some(
      ({ archiveInvoiceEnabled }) => archiveInvoiceEnabled,
    ),
    domainInvoiceEnabled: invoicingAccesses.some(
      ({ domainInvoiceEnabled }) => domainInvoiceEnabled,
    ),
  };
};

export default async function fetchUser(
  client: ApolloClient<object>,
): Promise<UserWithConsent | undefined> {
  // try to get my user
  const { data } = await client.query<
    QueryMyUserQuery,
    QueryMyUserQueryVariables
  >({
    query: QueryMyUserDocument,
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return undefined;
  }
  const {
    userPrivate: [finalUser],
  } = data;

  if (!finalUser?.user) {
    return undefined;
  }
  const { groups, ...privateInfos } = finalUser.user;

  if (!isUserWithPrivateInfos(privateInfos)) {
    throw new Error('cannot access private infos');
  }
  const consent = data.getUserConsent;

  return {
    consent,
    ...privateInfos,
    privateInfos: {
      ...privateInfos.privateInfos,
      ...computeInvoiceAccess(groups),
    },
    companyIds: groups.map(({ group }) => group.companyId),
  };
}

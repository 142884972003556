import { String_Comparison_Exp } from 'generated/graphql';
import {
  findKeyInTranslationFileByRegex,
  getTranslationFileByLanguage,
} from 'technical/translations';

export interface Filter {
  search?: string;
  from?: Date | string;
  to?: Date | string;
}

export function generateTextSearchCondition<T>(
  searchValue: string,
  key: keyof T,
) {
  return { [key]: { _ilike: `%${searchValue}%` } };
}

export function generateTextSearchConditions<T>(
  searchValue: string,
  keys: (keyof T)[],
): { [key in keyof T]: String_Comparison_Exp }[] {
  return keys.map(
    (key) =>
      generateTextSearchCondition(searchValue, key) as {
        [key in keyof T]: String_Comparison_Exp;
      },
  );
}

export function generateLocalizedSearch<T>(
  get: (
    translationFile: ReturnType<typeof getTranslationFileByLanguage>,
  ) => Record<string, string>,
  searchValue: string,
  path: (stringComparison: String_Comparison_Exp) => T,
): T[] {
  // Get correct translation file based on i18n language
  const translations = get(getTranslationFileByLanguage());
  // match the value
  const translationKeys = findKeyInTranslationFileByRegex(
    translations,
    searchValue,
    'gi',
  );
  // create or object and return it
  return translationKeys.map((key) => path({ _eq: key }));
}

import CreatePasswordCard from 'business/user/components/createPasswordCard';
import authService from 'business/user/services/authService';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import PageAuth from 'business/user/pages/pageAuth';

interface WelcomeSetPasswordRouteProps {
  token?: string; // Generated by the back when user request a change of password
}

const WelcomeSetPasswordPage = () => {
  const { params } = useRouteMatch<WelcomeSetPasswordRouteProps>();
  const { t } = useTranslation();

  const token = params.token || null;

  const handleSubmitClick = async (password: string) => {
    await authService.createPassword(token, password);
  };

  return (
    <PageAuth>
      <CreatePasswordCard
        title={t('pages.welcomeSetPassword.title')}
        submitButton={{
          label: t('pages.welcomeSetPassword.submit'),
          onClick: handleSubmitClick,
        }}
        description={t('pages.welcomeSetPassword.description')}
        acceptTou
      />
    </PageAuth>
  );
};

export default WelcomeSetPasswordPage;

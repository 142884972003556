import { Col, Form, Row } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import Spacer from 'ui/spacer';
import { requestOptions } from 'business/exportQuery/services/constant';
import { useSendExportQueryRequest } from 'business/exportQuery/services/useSendExportQueryRequest';
import RequestOptionFormItem from 'business/exportQuery/components/requestOptionFormItem';
import { useTranslation } from 'react-i18next';
import SubmitButtonForm from 'ui/submitButtonForm';

const SafranRequestPage = () => {
  const { t } = useTranslation();
  const { sendExportQueryRequest } = useSendExportQueryRequest();

  return (
    <ConnectedPage>
      <h1 className="headerTitle">{t('pages.exportQuery.title')}</h1>
      <Spacer size="large" direction="vertical">
        <Row>
          <Col span={8}>
            <Form onFinish={sendExportQueryRequest}>
              <Spacer size="small" direction="vertical">
                <div className="smallMarginBottom15">
                  {requestOptions({
                    t,
                  }).map((request) => (
                    <RequestOptionFormItem request={request} />
                  ))}
                </div>
                <SubmitButtonForm label={t('pages.exportQuery.button')} />
              </Spacer>
            </Form>
          </Col>
        </Row>
      </Spacer>
    </ConnectedPage>
  );
};

export default SafranRequestPage;

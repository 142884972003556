import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import FR from './fr.json';
import EN from './en.json';

enum ILanguage {
  en = 'en',
  'fr-FR' = 'fr-FR',
}

const resources = {
  en: {
    translation: EN,
  },
  fr: {
    translation: FR,
  },
};

i18n
  .use(LanguageDetector) // detect browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { ILanguage, i18n };

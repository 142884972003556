import ConnectedPage from 'business/user/helpers/connectedPage';
import React from 'react';
import { useParams } from 'react-router';
import Spacer from 'ui/spacer';
import {
  useGetPatentDrawingsFromDocuments,
  usePatentBreadcrumbs,
} from 'business/resources/patent/services';
import ErrorOverlay from 'ui/errorOverlay';
import { resourceExists } from 'business/common/services';
import HeaderTitles from 'ui/headerTitles';
import { getCountryLabel } from 'technical/countriesTranslation';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import { useGetPatent } from 'business/resources/patent/services/useGetPatent';
import { getPatentDetail } from 'business/resources/patent/components/descriptionPanel';
import Carousel from 'ui/carousel';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import { getPatentOfficialLink } from 'business/common/services/officialRegister/getPatentOfficialLink';
import { Patent } from 'business/resources/patent/types';
import ResourcePanel from 'business/resources/shared/components/resourcePanel/resourcePanel';
import { useTranslation } from 'react-i18next';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import ResourceLinks from 'business/common/components/resourceLinks';
import Routes from 'business/router/routes';
import { useGetPatentEpoDocument } from 'business/resources/patent/services/useGetPatentEpoDocument';
import getPatentTabs from 'business/resources/patent/services/getPatentTabs';
import Timeline from 'ui/timeline';

const PatentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const patentId = Number(id);
  const { patent, timeline, patentDataState } = useGetPatent(patentId);
  const { patentDocuments, loading: patentDocumentLoading } =
    useGetPatentEpoDocument({
      id: patentId,
      publicationDate: patent?.publicationDate,
    });

  const { getResourceBreadcrumbs } = usePatentBreadcrumbs();
  const { images, loading: imgLoading } = useGetPatentDrawingsFromDocuments(
    patentDocuments,
    patentDocumentLoading,
  );
  const links = useGetOfficialLinks<Patent>(getPatentOfficialLink, patent);

  const { t } = useTranslation();

  return (
    <ResourceNotFound resourceExists={resourceExists(patent, patentDataState)}>
      <ConnectedPage
        breadcrumbs={getResourceBreadcrumbs(patent)}
        translationKey="patentDetails"
      >
        <Spacer direction="vertical" size="middle">
          <ErrorOverlay {...patentDataState}>
            <Spacer direction="vertical" size="small">
              <HeaderTitles
                title={getCountryLabel(patent?.country)}
                subtitle={patent?.title ?? ''}
                dataTestId="patentPage"
                family={{
                  id: patent?.familyId,
                  route: Routes.PatentFamily,
                }}
              />

              <ResourceLinks resource={patent} />

              <Timeline events={timeline} />
            </Spacer>

            <ResourcePanel
              description={{
                data: patent ?? null,
                panels: patent
                  ? getPatentDetail(t, patent)
                  : getPatentDetail(t, null),
              }}
              resource="patent"
              resourceId={patentId}
              image={<Carousel urls={images} loading={imgLoading} />}
              additionalActions={links && <OfficalLinkButton link={links[0]} />}
              additionalTabs={getPatentTabs({
                id: patentId,
                t,
                patentDocuments,
                patentDocumentLoading,
                publicationDate: patent?.publicationDate,
              })}
            />
          </ErrorOverlay>
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default PatentPage;

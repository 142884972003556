import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface RoleCheckerProps {
  roles: string[];
}

const RolesChecker: React.FC<RoleCheckerProps> = ({ roles, children }) => {
  const { user } = useLoggedInAppContext();

  return roles.includes(user.role) ? <>{children}</> : null;
};

export default RolesChecker;

import { useGetDomainDnsDataQuery } from 'generated/graphql';

export const useGetPublicDns = (domainId: number) => {
  const { data, ...publicDomainDNSDataState } = useGetDomainDnsDataQuery({
    variables: { id: domainId },
    fetchPolicy: 'no-cache',
  });

  const dns = data?.domain_by_pk?.dns ?? null;

  const zones = (dns?.records ?? []).map(
    ({ address: value, domainName: name, ...rest }) => ({
      ...rest,
      name,
      value,
    }),
  );

  const servers =
    dns?.servers?.map((server, i) => ({
      serverName: `DNS ${i + 1}`,
      server: server.address,
    })) ?? [];

  return {
    zones,
    publicDomainDNSDataState,
    servers,
    certificate: dns?.certificate,
  };
};

import SearchBar from 'ui/searchBar';
import { TrademarkFamilies } from 'business/resources/trademark/services/types';
import MosaicList from 'business/resources/trademark/components/mosaic/list';
import { Checkbox, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'technical/hooks/useDebounce';
import { AllTableFilter } from 'technical/filters/types';
import { useEffect } from 'react';
import Routes from 'business/router/routes';
import RedirectionButton from 'ui/button/redirectionButton';

interface ITrademarkMosaic {
  setFilterValue: (
    keyFilter: keyof AllTableFilter<TrademarkFamilies>,
    value: any,
  ) => void;
  filtersValues: AllTableFilter<TrademarkFamilies>;
  data: TrademarkFamilies[];
  loading: boolean;
  pagination?: TablePaginationConfig;
}

const TrademarkFamiliesMosaic = ({
  setFilterValue,
  filtersValues,
  data,
  loading,
  pagination,
}: ITrademarkMosaic) => {
  const { t } = useTranslation();
  const { value, setValue, debouncedValue } = useDebounce({
    defaultValue: filtersValues.search ?? '',
  });

  useEffect(() => {
    setFilterValue('search', debouncedValue);
  }, [debouncedValue]);

  return (
    <MosaicList<TrademarkFamilies>
      actions={
        <>
          <SearchBar
            onChange={(e) => setValue(e.target.value)}
            value={value ?? ''}
          />

          <RedirectionButton
            to={{
              pathname: Routes.Home,
              search: `?family=trademark&filtersOpen=true`,
            }}
            text={t('table.common.advancedSearch')}
          />

          <Checkbox
            checked={filtersValues.isEffective}
            onChange={(e) => setFilterValue('isEffective', e.target.checked)}
          >
            {t('table.common.effective')}
          </Checkbox>
        </>
      }
      data={data}
      config={{
        pagination: pagination,
        loading: loading,
      }}
    />
  );
};

export default TrademarkFamiliesMosaic;

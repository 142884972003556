import { Archive } from 'business/archive/services/types';
import { TFunction, useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import IconStatus from 'ui/iconStatus';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import ArchiveConfigurationActionsColumn from './actionsColumns';

type ArchiveConfigurationTableProps = Omit<
  TableProps<Archive>,
  'defaultColumns'
>;

const defaultColumns = (
  t: TFunction,
  refetch?: () => void,
  showActions?: boolean,
): ColumnGenerationData<NonNullable<Archive>> => {
  const columns: ColumnGenerationData<NonNullable<Archive>> = [
    {
      key: 'active',
      width: 80,
      render: (active: boolean) => (
        <Flex justify="center">
          <IconStatus success={active} />
        </Flex>
      ),
    },
    {
      key: 'title',
    },
    {
      key: 'annualCost',
    },
    {
      key: 'nbDocumentPerYear',
    },
    {
      key: 'additionalFileCost',
    },
    {
      key: 'volume',
    },
    {
      key: 'subscriptionDuration',
    },
    {
      key: 'additionalVolumeCost',
    },
    {
      key: 'storageDuration',
      render: (storageDuration) => (
        <span>{t('archive.storageDuration', { storageDuration })}</span>
      ),
    },
  ];

  if (showActions) {
    columns.push({
      key: 'active',
      translationKey: 'actions',
      render: (_: any, archive: Archive) => (
        <ArchiveConfigurationActionsColumn
          archive={archive}
          refetch={refetch}
        />
      ),
    });
  }

  return columns;
};

const ArchiveConfigurationTable: React.FC<ArchiveConfigurationTableProps> = ({
  refetch,
  showActions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Table
      id="archiveConfigurationTable"
      {...props}
      defaultColumns={defaultColumns(t, refetch, showActions)}
    />
  );
};

export default ArchiveConfigurationTable;

import { Card, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { Store } from 'antd/lib/form/interface';
import { GROUP_NAME_RULES } from 'business/groups/services/constant';
import {
  CheckList,
  GroupSubmitFormValues,
} from 'business/groups/services/types';
import Button from 'ui/button';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { Link } from 'react-router-dom';
import CheckboxOptions from 'business/groups/components/checkboxOptions';
import RadioOptions from 'business/groups/components/radioOptions';
import styles from './index.module.scss';

interface GroupFormProps {
  onFinish: (values: GroupSubmitFormValues) => Promise<void>;
  formName: string;
  initialValues: Store;
  companyName?: string;
  visiblePermissions: CheckList[];
  cancelLink: string;
}

const GroupForm = ({
  formName,
  initialValues,
  companyName,
  visiblePermissions,
  onFinish,
  cancelLink,
}: GroupFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const displayCards = () => {
    return visiblePermissions.map((permissionCard) => {
      const isPermissionCardVisible =
        !!permissionCard.lists.length || !!permissionCard.radioOptions?.length;

      return (
        isPermissionCardVisible && (
          <Card key={permissionCard.key} className={styles.card}>
            <CheckboxOptions
              key={permissionCard.key}
              card={permissionCard}
              initialValues={
                initialValues[permissionCard.checkboxFormName] ?? []
              }
              text={
                permissionCard.textEnabled
                  ? t('pages.groupCreate.permissionHeader', { companyName })
                  : undefined
              }
            />
            {permissionCard.radioOptions && (
              <RadioOptions card={permissionCard} />
            )}
          </Card>
        )
      );
    });
  };

  return (
    <Form
      form={form}
      name={formName}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        className={styles.inputFormItem}
        label={t('pages.groupCreate.label')}
        name="name"
        rules={GROUP_NAME_RULES(t)}
      >
        <Input />
      </Form.Item>
      <Flex column alignItems="flex-start" className={styles.cardContainer}>
        <Spacer justify="space-between">
          <h2>{t('pages.groupCreate.userRights')}</h2>
          <Spacer justify="center" size="xsmall">
            <Link to={cancelLink}>
              <Button size="large">
                {t('pages.groupCreate.cancel', { context: formName })}
              </Button>
            </Link>
            <Button htmlType="submit" type="primary" size="large">
              {t('pages.groupCreate.save')}
            </Button>
          </Spacer>
        </Spacer>
        <Form.Item name="isAdmin" valuePropName="checked">
          <Checkbox>{t('pages.groupCreate.companyManagement')}</Checkbox>
        </Form.Item>
      </Flex>
      <Flex column className={styles.cardContainer}>
        <h2>{t('pages.groupCreate.perimeter')}</h2>
        <Flex justify="space-around" className={styles.container}>
          {displayCards()}
        </Flex>
      </Flex>
    </Form>
  );
};

export default GroupForm;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import Card from 'ui/card';
import Spacer from 'ui/spacer';
import TermCheckbox from 'business/user/components/termCheckbox';
import { Form } from 'antd';
import SubmitButtonForm from 'ui/submitButtonForm';
import { PasswordConfirmFormValues } from 'ui/form/passwordConfirm/types';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import PasswordConfirmationForm from 'ui/form/passwordConfirm';
import styles from './index.module.scss';

export interface Values {
  password: string;
}
interface Props {
  submitButton: {
    label: string;
    onClick: (password: string) => Promise<void>;
  };
  description: string;
  acceptTou?: boolean;
  title?: string;
}
const CreatePasswordCard = ({
  submitButton,
  title,
  description,
  acceptTou,
}: Props) => {
  const { t } = useTranslation();
  const [touPath, setTouPath] = useState<string>('');
  const [form] = Form.useForm<PasswordConfirmFormValues>();

  useEffect(() => {
    setTouPath('');
  }, []);

  const onFinish = async ({ password }: PasswordConfirmFormValues) => {
    submitButton.onClick(password).catch((err) => {
      form.setFields([
        {
          name: 'password',
          errors: [
            t('errors.signUp', {
              context: getAuthErrorContextFromAuthProviderError(err),
            }),
          ],
        },
      ]);
    });
  };

  return (
    <Card width={600}>
      <Spacer direction="vertical" align="center" size="small">
        <AppLogo />

        {title && (
          <h1 className={styles.title}>
            {t('pages.welcomeSetPassword.title')}
          </h1>
        )}
        <p className={styles.description}>{description}</p>
        <Form
          form={form}
          className={styles.form}
          onFinish={onFinish}
          layout="vertical"
        >
          <PasswordConfirmationForm />
          {acceptTou && touPath && (
            <TermCheckbox termPath={touPath} type="tou" />
          )}
          <SubmitButtonForm
            className={styles.submitButton}
            label={submitButton.label}
          />
        </Form>
      </Spacer>
    </Card>
  );
};

export default CreatePasswordCard;

import { isUserStatus } from 'business/user/services/status';
import { UserStatus } from 'business/user/types/status';
import { Parser } from 'technical/filters/types';

export const urlUserStatusParser = (): Parser<
  UserStatus | undefined | null
> => ({
  defaultValue: undefined,
  readParse: (param) => (isUserStatus(param) ? param : undefined),
  writeParse: (value) => (isUserStatus(value) ? value : undefined),
});

import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { TableProps } from 'ui/table/types';
import { Event } from 'business/resources/events/services/types';
import Flex from 'ui/flex';
import { Checkbox } from 'antd';
import { useState } from 'react';
import { defaultColumns, filterOutAnnuitiesFromEvents } from './contants';

type EventTableProps = {
  displayAnnuityAction?: boolean;
  defaultShowAnnuities?: boolean;
} & Omit<TableProps<Event>, 'defaultColumns'>;

const EventTable = ({
  displayAnnuityAction = true,
  defaultShowAnnuities = false,
  dataSource,
  ...props
}: EventTableProps) => {
  const { t } = useTranslation();
  const [showAnnuities, setShowAnnuities] = useState(defaultShowAnnuities);

  return (
    <Flex column>
      <Table<Event>
        {...props}
        defaultColumns={defaultColumns}
        dataSource={
          showAnnuities ? dataSource : filterOutAnnuitiesFromEvents(dataSource)
        }
        customAction={
          displayAnnuityAction ? (
            <Checkbox
              data-test-id="displayMaskedEvents"
              type="primary"
              checked={showAnnuities}
              onClick={() => setShowAnnuities(!showAnnuities)}
            >
              {t('table.common.showAnnuities')}
            </Checkbox>
          ) : null
        }
      />
    </Flex>
  );
};

export default EventTable;

import { CheckList, GroupSubmitFormValues } from './types';

const transformFormValuesToObject = (
  array: CheckList['lists'] | CheckList['radioOptions'],
  key: string,
  formValues: GroupSubmitFormValues,
) => {
  return array?.reduce((a, list) => {
    if (formValues[key]?.includes(list.value)) {
      return {
        ...a,
        [list.value]: true,
      };
    }
    return { ...a, [list.value]: false };
  }, {});
};

export const formatFormValues = (
  values: GroupSubmitFormValues,
  visiblePermissions: CheckList[],
) => {
  return visiblePermissions.reduce((prev, permissionCard) => {
    const accessData = transformFormValuesToObject(
      permissionCard.lists,
      permissionCard.checkboxFormName,
      values,
    );
    const radioOptions = permissionCard.radioFormName
      ? transformFormValuesToObject(
          permissionCard.radioOptions,
          permissionCard.radioFormName,
          values,
        )
      : {};

    return {
      ...prev,
      ...accessData,
      ...radioOptions,
    };
  }, {});
};

import {
  Fields,
  FilterFieldOptions,
  FiltersData,
  TabsFamily,
} from 'business/common/services/types';
import { TFunction } from 'i18next';
import { FilterField } from 'ui/filters';
import CheckboxesFieldFilter from 'ui/filters/checkboxesField';
import CheckboxFieldFilter from 'ui/filters/checkboxField';
import DateRangeFieldFilter from 'ui/filters/dateRangeField';
import SelectFieldFilter from 'ui/filters/selectField';
import StringFieldFilter from 'ui/filters/stringField';

/**
 * Create a advanced filter from a component and filterInUrl
 * @param options options to pass to the component
 * @param displayOptions options to display the filter (translation related mainly)
 * @returns
 */
const createFilter = <T extends FilterField<any>>(
  { component }: T,
  options?: FilterFieldOptions<T>,
  displayOptions?: {
    translationKey?: string;
    translation?: string;
    context?: string;
  },
) => ({
  func: (
    t: TFunction,
    {
      family,
      name,
    }: {
      family: TabsFamily;
      name: string;
    },
  ) =>
    component({
      name,
      label:
        displayOptions?.translation ??
        t(`table.common.column.${displayOptions?.translationKey ?? name}`, {
          context: (displayOptions?.context ?? '') + family,
        }),
      key: name,
      ...options,
    }),
});

const getFieldToFilter = (
  t: TFunction,
  filtersData: FiltersData | null,
): Record<Fields, ReturnType<typeof createFilter>> => ({
  countryCode: createFilter(
    SelectFieldFilter,
    {
      selectProps: {
        loading: !filtersData,
        options: filtersData?.countryCode ?? [],
        searchByLabel: true,
        alphabeticallySortOptions: true,
        mode: 'multiple',
        maxTagCount: 'responsive',
      },
    },
    { translationKey: 'country' },
  ),
  type: createFilter(SelectFieldFilter, {
    selectProps: {
      loading: !filtersData,
      options: filtersData?.eventTypes ?? [],
      mode: 'multiple',
      maxTagCount: 'responsive',
    },
  }),
  status: createFilter(
    CheckboxesFieldFilter,
    { checkboxesProps: { options: filtersData?.statuses ?? [] } },
    { translationKey: 'active' },
  ),
  protectionCode: createFilter(
    SelectFieldFilter,
    {
      selectProps: {
        loading: !filtersData,
        options: filtersData?.protectionCode ?? [],
        mode: 'multiple',
        maxTagCount: 'responsive',
      },
    },
    { translationKey: 'protection' },
  ),
  extension: createFilter(SelectFieldFilter, {
    selectProps: {
      loading: !filtersData,
      options: filtersData?.extensions ?? [],
      mode: 'multiple',
      maxTagCount: 'responsive',
      searchByLabel: true,
    },
  }),
  ownershipType: createFilter(CheckboxesFieldFilter, {
    checkboxesProps: {
      options: ['co', 'mono'].map((code) => ({
        code,
        label: t('pages.advancedSearch.filtersDrawer.ownershipType', {
          context: code,
        }),
      })),
    },
  }),
  adjournedPublication: createFilter(CheckboxFieldFilter, {
    label: t('table.common.column.adjournedPublication', { context: 'filter' }),
  }),
  showMaskedEvents: createFilter(
    CheckboxesFieldFilter,
    {
      checkboxesProps: {
        options: [
          {
            code: 'true',
            label: t('pages.advancedSearch.filtersDrawer.showMaskedEvents'),
          },
        ],
      },
    },
    { translation: '' },
  ),
  title: createFilter(StringFieldFilter, undefined, {
    context: `filter`,
  }),
  depositDate: createFilter(DateRangeFieldFilter, undefined, {
    context: 'filter',
  }),
  publicationDate: createFilter(DateRangeFieldFilter),
  dueDate: createFilter(DateRangeFieldFilter),
  completionDate: createFilter(DateRangeFieldFilter),
  issueDate: createFilter(DateRangeFieldFilter),
  reservationDate: createFilter(DateRangeFieldFilter),
  registrationDate: createFilter(DateRangeFieldFilter),
  lastRegistrationDate: createFilter(DateRangeFieldFilter),
  firstRegistrationDate: createFilter(DateRangeFieldFilter),
  nextRegistrationDate: createFilter(DateRangeFieldFilter),
  expirationDate: createFilter(DateRangeFieldFilter),
  clientRef: createFilter(StringFieldFilter),
  clientRef2: createFilter(StringFieldFilter),
  depositPublicationIssueNumber: createFilter(StringFieldFilter),
  holder: createFilter(StringFieldFilter),
  classes: createFilter(StringFieldFilter, undefined, {
    translationKey: 'classes_event',
  }),
  effectiveHolder: createFilter(StringFieldFilter),
  bdlOfficer: createFilter(StringFieldFilter),
  clientOfficer: createFilter(StringFieldFilter),
  inventors: createFilter(StringFieldFilter),
  id: createFilter(StringFieldFilter, {}, { translationKey: 'caseNumber' }),
});

const getFilters = (
  t: TFunction,
  family: TabsFamily,
  data: FiltersData | null,
  fieldNamesPerTabs: Record<TabsFamily, Readonly<Fields[]>>,
) => {
  const fieldToFilter = getFieldToFilter(t, data);

  const filters = fieldNamesPerTabs[family].map((field) => ({
    field,
    ...fieldToFilter[field],
  }));

  const components = filters.map(({ func, field }) =>
    func(t, { family, name: field }),
  );

  return { components, filters };
};

export default getFilters;

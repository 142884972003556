import LogsTable from 'business/admin/components/logsTable';
import useGetLogs from 'business/admin/hooks/useGetLogs';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';

const LogsPage = () => {
  const { t } = useTranslation();
  const { data, tableProps } = useGetLogs();

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('pages.logs.title') }]}
      translationKey="logs"
    >
      <LogsTable data-test-id="logsTable" dataSource={data} {...tableProps} />
    </ConnectedPage>
  );
};

export default LogsPage;

import { Avatar } from 'antd';
import Flex from 'ui/flex';

interface IOsrColorDescription {
  colors: { color: string; label: string }[];
}

const OsrColorDescription = (props: IOsrColorDescription) => {
  return (
    <Flex>
      {props.colors.map((color) => {
        return (
          <Flex alignItems="center">
            <Avatar
              shape="square"
              size="default"
              className="noShrink"
              style={{ backgroundColor: color.color, marginRight: '10px' }}
            />
            <span style={{ marginRight: '10px' }}>{color.label}</span>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default OsrColorDescription;

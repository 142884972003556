import {
  BreadcrumbResource,
  useResourceBreadcrumbs,
} from 'business/common/services/breadcrumbs';
import { DomainOfFamily } from 'business/resources/domain/types';
import Routes from 'business/router/routes';
import { GetDomainQuery } from 'generated/graphql';

const DOMAIN_EXTENSION_REGEX = /\..+/;

export const removeExtension = (domain: string) =>
  domain.replace(DOMAIN_EXTENSION_REGEX, '');

export const useDomainBreadcrumbs = () => {
  const { getResourceBreadcrumbs, getFamilyBreadcrumbs } =
    useResourceBreadcrumbs({
      type: 'domain',
      familyRoute: Routes.DomainFamily,
      rootRoute: Routes.Domain,
    });

  return {
    getFamilyBreadcrumbs: (domain: Pick<DomainOfFamily, 'title'> | null) =>
      getFamilyBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title ?? ''),
            }
          : null,
      ),
    getResourceBreadcrumbs: (
      domain: null | Pick<
        NonNullable<GetDomainQuery['domain_by_pk']>,
        keyof BreadcrumbResource
      >,
    ) =>
      getResourceBreadcrumbs(
        domain
          ? {
              ...domain,
              title: removeExtension(domain.title),
              country: {
                code: domain.title,
                labelEn: domain.title,
                labelFr: domain.title,
              },
            }
          : null,
      ),
  };
};

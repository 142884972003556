import { useGenerateOsrExcelFileDataLazyQuery } from 'generated/graphql';
import { convertToBinaryAndDownloadFile } from 'technical/fileManagement';
import { MIME_TYPES_BY_FILE_FORMAT } from 'technical/fileManagement/constants';
import { OsrFilterState } from './types';
import { useState } from 'react';
import logger from 'technical/logger';
import errorReporting from 'technical/error-reporting';

export const useGenerateExcelFile = (
  resourceType: 'design' | 'trademark',
  filters: OsrFilterState,
) => {
  const [loading, setLoading] = useState(false);
  const [generateOsrExcelFileQuery] = useGenerateOsrExcelFileDataLazyQuery();

  const generateExcel = async (
    type: 'RENEWS' | 'ANOMALIES' | 'AFFIDAVIT' | 'ACUMASS' | 'BUDGET',
  ) => {
    try {
      setLoading(true);
      const { data } = await generateOsrExcelFileQuery({
        fetchPolicy: 'no-cache',
        variables: {
          osrDataType: type,
          resourceType: resourceType,
          where: {
            ...filters,
            eventStatus: filters.eventStatus as string[],
          },
        },
      });
      const document = data?.generateOsrExcelFile;

      if (!document) {
        return '';
      }

      return convertToBinaryAndDownloadFile(
        document,
        MIME_TYPES_BY_FILE_FORMAT.xlsx,
        'OSR_EXPORT',
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    generateExcel,
  };
};

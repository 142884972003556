type CountryCode = string;

export type MapData = Record<CountryCode, MapStatus>;

export type Style = {
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  outline?: string;
};

export type ThemeValue = {
  default: Style;
  hover?: Style;
  pressed?: Style;
};

export enum MapStatus {
  Valid = 'valid',
  NotEffective = 'notEffective',
  Unprotected = 'unprotected',

  // Patent specific statuses
  Published = 'published',
  Deposited = 'deposited',
  PlannedDeposit = 'plannedDeposit',
  MandatedOut = 'mandatedOut',
}

export type MapTheme = Record<MapStatus, ThemeValue>;

export type NotShowCountry = {
  code: string;
  status: MapStatus;
  tooltip: string | null;
};

import { List } from 'antd';
import Card from 'ui/card';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import styles from './index.module.scss';

interface IMosaicCard {
  header: React.ReactNode;
  children: React.ReactNode;
  onClick: () => void;
}

const MosaicCard = ({ header, children, onClick }: IMosaicCard) => {
  return (
    <List.Item onClick={onClick}>
      <Card className={styles.mosaicCard}>
        <Spacer direction="vertical" size="small">
          <Flex justify="center" alignItems="center">
            <div>{header}</div>
          </Flex>
          {children}
        </Spacer>
      </Card>
    </List.Item>
  );
};

export default MosaicCard;

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import './App.scss';
import useUpdateLocale from 'technical/hooks/useUpdateLocale';
import { useTranslation } from 'react-i18next';
import { AppProvider } from './AppBootstrapper';
import Router from './router';
import defaultTheme from './theme';

const App = () => {
  const { i18n } = useTranslation();
  const { locale } = useUpdateLocale(i18n.language);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <ConfigProvider
            locale={locale}
            componentSize="large"
            theme={defaultTheme}
          >
            <Router />
          </ConfigProvider>
        </AppProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;

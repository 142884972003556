import { Checkbox, Form } from 'antd';
import { FilterField } from '..';
import styles from './index.module.scss';

const CheckboxFieldFilter: FilterField<object> = {
  component: ({ label, ...formItemProps }) => (
    <Form.Item
      {...formItemProps}
      valuePropName="checked"
      className={styles.noLabelStyle}
    >
      <Checkbox>{label}</Checkbox>
    </Form.Item>
  ),
};

export default CheckboxFieldFilter;

import { useTranslation } from 'react-i18next';
import EditableList from 'ui/editableStringList';

interface KeywordsListEditionProps {
  options: string[];
  onChange: (value: string[]) => void;
}

const KeywordsListEdition = ({
  options,
  onChange,
}: KeywordsListEditionProps) => {
  const { t } = useTranslation();

  return (
    <EditableList
      header={<h2 className="subTitle">{t('archive.keywords.subTitle')}</h2>}
      defaultValue={options}
      size="small"
      onChange={onChange}
      addOptionLabel={t('archive.keywords.addKeyword')}
    />
  );
};

export default KeywordsListEdition;

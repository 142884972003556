import { useGetPatentClaimsQuery } from 'generated/graphql';

export const useGetClaims = (patentId: number) => {
  const { data, error, loading, refetch } = useGetPatentClaimsQuery({
    variables: {
      id: patentId,
    },
  });

  return {
    claims: data?.getPatentClaims.claims ?? [],
    type: data?.getPatentClaims.type,
    loading,
    refetch,
    error,
  };
};

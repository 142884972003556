import { ResourceImageStyleProps } from '.';

const TABLE_CELL_PADDING = 16;

const EVENTS_TABLE_IMAGE_SIZE = 100;
const FAMILIES_TABLE_IMAGE_SIZE = 130;

export const FAMILIES_TABLE_IMAGE_CELL_WIDTH =
  FAMILIES_TABLE_IMAGE_SIZE + TABLE_CELL_PADDING;

export const EVENTS_TABLE_IMAGE_CELL_WIDTH =
  EVENTS_TABLE_IMAGE_SIZE + TABLE_CELL_PADDING;

export const FAMILY_IMAGE_HEIGHT = 200;
export const DETAIL_IMAGE_HEIGHT = 200;

// For images displayed in the ressource families table
export const FAMILIES_TABLE_IMAGE_STYLE: ResourceImageStyleProps = {
  width: FAMILIES_TABLE_IMAGE_SIZE,
  height: FAMILIES_TABLE_IMAGE_SIZE,
};

export const EVENTS_TABLE_IMAGE_STYLE: ResourceImageStyleProps = {
  width: EVENTS_TABLE_IMAGE_SIZE,
  height: EVENTS_TABLE_IMAGE_SIZE,
};

import { Tabs } from 'antd';
import { TabsFamily, isTabsFamily } from 'business/common/services/types';
import { TabsContent } from 'ui/tabs/types';
import {
  AllEvents,
  DesignEvent,
  DomainEvent,
  EventAdvancedFilters,
  EventResourcesData,
  PatentEvent,
  TrademarkEvent,
} from 'business/resources/events/services/types';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import getPatentEventColumns from 'business/resources/events/components/columns/patentEventColumns';
import getDesignEventColumns from 'business/resources/events/components/columns/designEventColumns';
import getDomainEventColumns from 'business/resources/events/components/columns/domainEventColumns';
import getTrademarkEventColumns from 'business/resources/events/components/columns/trademarkEventColumns';
import EventTable from 'business/resources/events/components/eventTable';
import getTabLabel from 'ui/tabs/helper';
import { useMemo } from 'react';
import AdvancedFiltersButton, {
  getFilterNumber,
} from 'business/advancedSearch/components/advancedFiltersButton';
import getAllEventColumns from 'business/resources/events/components/columns/getAllEventColumns';

interface EventsSearchTabsProps {
  family: TabsFamily;
  onClickTabFamily: (family: TabsFamily) => void;
  onClickAdvancedFilter: () => void;
  data: EventResourcesData;
  filters: EventAdvancedFilters;
}

const EventsSearchTabs = ({
  family,
  onClickTabFamily,
  onClickAdvancedFilter,
  data,
  filters,
}: EventsSearchTabsProps) => {
  const { t } = useTranslation();

  const advancedFiltersButton = useMemo(
    () => (
      <AdvancedFiltersButton
        onClickAdvancedFilter={onClickAdvancedFilter}
        filterNumber={getFilterNumber(filters[family]?.filters)}
      />
    ),
    [family, filters],
  );

  const tabs: TabsContent = [
    {
      key: 'patent',
      label: getTabLabel('patent', t)(data.patentEvents),
      children: (
        <EventTable<PatentEvent>
          id="advancedSearchEventsPatent"
          defaultColumns={getPatentEventColumns()}
          {...data.patentEvents.tableProps}
          showDisplayAnnuitiesCheckbox
          showOwnCheckbox
          dataSource={data.patentEvents.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'design',
      label: getTabLabel('design', t)(data.designEvents),
      children: (
        <EventTable<DesignEvent>
          id="advancedSearchEventsDesign"
          defaultColumns={getDesignEventColumns()}
          {...data.designEvents.tableProps}
          showDisplayAnnuitiesCheckbox
          showOwnCheckbox
          dataSource={data.designEvents.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'trademark',
      label: getTabLabel('trademark', t)(data.trademarkEvents),
      children: (
        <EventTable<TrademarkEvent>
          id="advancedSearchEventsTrademark"
          defaultColumns={getTrademarkEventColumns()}
          {...data.trademarkEvents.tableProps}
          showDisplayAnnuitiesCheckbox
          showOwnCheckbox
          dataSource={data.trademarkEvents.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'domain',
      label: getTabLabel('domain', t)(data.domainEvents),
      children: (
        <EventTable<DomainEvent>
          id="advancedSearchEventsDomain"
          defaultColumns={getDomainEventColumns(t)}
          {...data.domainEvents.tableProps}
          showDisplayAnnuitiesCheckbox
          showOwnCheckbox
          dataSource={data.domainEvents.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'all',
      label: getTabLabel('all', t)(data.allEvents),
      children: (
        <EventTable<AllEvents>
          id="advancedSearchEventsAll"
          defaultColumns={getAllEventColumns(t)}
          {...data.allEvents.tableProps}
          showOwnCheckbox
          showDisplayAnnuitiesCheckbox
          dataSource={data.allEvents.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
  ];

  const onClickTab = (tabClicked: string) => {
    if (isTabsFamily(tabClicked)) {
      onClickTabFamily(tabClicked);
    }
  };

  return (
    <Spacer direction="vertical" size="large">
      <Tabs
        defaultActiveKey={family}
        type="card"
        items={tabs}
        onTabClick={onClickTab}
      />
    </Spacer>
  );
};

export default EventsSearchTabs;

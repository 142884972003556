import { Button, Tooltip } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import React from 'react';
import history from 'technical/history';
import styles from './index.module.scss';

interface ButtonProps extends AntdButtonProps {
  className?: string;
  path?: string;
  onClick?: () => void;
  children: React.ReactNode;
  tooltipText?: string;
}

type Props = ButtonProps;
const MenuLink = ({
  children,
  tooltipText,
  className,
  path,
  onClick,
  ...props
}: Props) => {
  function handleOnClick() {
    if (path) {
      history.push(path);
    }
  }

  return (
    <Tooltip title={tooltipText}>
      <Button
        type="link"
        onClick={onClick || handleOnClick}
        className={classnames(className, styles.menuLink)}
        {...props}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default MenuLink;

import { Parser } from 'technical/filters/types';
import { isInArray } from 'technical/types';

export const stringEnumParser = <T extends string>(
  values: readonly T[],
): Parser<T | undefined> => ({
  readParse: (param) =>
    param !== null && isInArray(values, param) ? param : undefined,
  writeParse: (value) =>
    value && values.includes(value) ? value.toString() : undefined,
});

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import authService from 'business/user/services/authService';
import config from 'config/index';
import { logout } from 'technical/auth/providers/custom';
import logger from 'technical/logger';

const authLink = setContext((_, { headers }: any) => {
  // get the authentication token from auth service if it exists
  const token = authService.getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // if token does not exist do not set authorization header
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});

export function makeFetch(
  fetch: WindowOrWorkerGlobalScope['fetch'],
  loggerInstance: typeof logger,
  authServiceInstance: typeof authService,
): WindowOrWorkerGlobalScope['fetch'] {
  return (input, init) =>
    fetch(input, init).then(async (response) => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'access-denied') {
        loggerInstance.info('Renewing token');
        try {
          await authServiceInstance.renewToken();
          const newToken = authServiceInstance.getAccessToken();
          loggerInstance.info('Token renewed!');
          // Updating headers with new token
          return await fetch(input, {
            ...init,
            headers: {
              ...init?.headers,
              authorization: `Bearer ${newToken}`,
            },
          });
        } catch (err) {
          if (err instanceof Error && err.message === 'jwt expired') {
            logger.info('JWT token has expired');
            logout();
          } else {
            throw err;
          }
        }
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    });
}

const httpLink = new HttpLink({
  uri: `${config.graphqlUri}/v1/graphql`,
  credentials: 'include',
  // Custom fetch to handle reconnection on jwt expired
  fetch: makeFetch(fetch, logger, authService),
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;

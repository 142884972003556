import { Typography } from 'antd';

const { Text } = Typography;

const TextWithEllipsis = ({ text, name }: { text: string; name: string }) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{name}: </span>
      <Text ellipsis={{ tooltip: text }}>{text}</Text>
    </>
  );
};

export default TextWithEllipsis;

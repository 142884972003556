import { useDeleteAndCreateUserGroupsMutation } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

export const useUpdateUserGroups = () => {
  const [deleteAndInsertUserGroup] = useDeleteAndCreateUserGroupsMutation();

  const updateUserGroups = async ({
    userId,
    userGroupToDelete,
    userGroupToInsert,
  }: {
    userId: string;
    userGroupToDelete: number[];
    userGroupToInsert: { userId: string; groupId: string }[];
  }) => {
    try {
      await deleteAndInsertUserGroup({
        variables: {
          userId,
          userGroupToDelete,
          userGroupToInsert,
        },
      });
    } catch (err) {
      logger.error(err);
      errorReporting.error(err);
      throw err;
    }
  };

  return {
    updateUserGroups,
  };
};

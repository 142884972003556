import { isBdlAdmin } from 'business/admin/services/permission';
import { UserWithGroups } from 'business/groups/services/types';
import { TFunction } from 'i18next';
import { ColumnGenerationData } from 'ui/table/types';
import Spacer from 'ui/spacer';
import ActionButton from 'ui/table/tableActions/actionButton';
import { UserTableActionColumnProps } from 'business/configuration/accessManagement/components/tables/services/types';

const defaultColumns = (
  t: TFunction,
  userActionProps: Omit<UserTableActionColumnProps<UserWithGroups>, 'user'>,
  actions: {
    tooltipTitle: string;
    icon: React.ReactElement;
    dataTestId: string;
    onClick: (data: UserWithGroups) => void;
    hide?: boolean;
    isDisabled?: (data: UserWithGroups) => boolean;
  }[],
): ColumnGenerationData<UserWithGroups> => [
  {
    key: 'fullName',
  },
  {
    key: 'email',
  },
  {
    key: 'id',
    translationKey: 'groupName',
    alwaysVisible: true,
    render: (_, user: UserWithGroups) => (
      <span>{user.companyGroup[0].group.name}</span>
    ),
  },
  {
    key: 'lastConnection',
  },
  {
    key: 'status',
    translationKey: 'isActive',
    alwaysVisible: true,
    render: (_, { status }) =>
      t('user.status', {
        context: status,
      }),
  },
  {
    key: 'isActive',
    translationKey: 'actions',
    fixed: 'right',
    sorter: false,
    alwaysVisible: true,
    disabled: !isBdlAdmin(userActionProps.currentUser),
    render: (_, data) => {
      return (
        <Spacer size="xxsmall">
          {actions.map((action) => {
            return (
              <ActionButton
                hide={action.hide}
                tooltipTitle={action.tooltipTitle}
                dataTestId={action.dataTestId}
                icon={action.icon}
                disabled={action.isDisabled?.(data)}
                onClick={() => action.onClick(data)}
              />
            );
          })}
        </Spacer>
      );
    },
  },
];

export default defaultColumns;

import { MapTheme } from './types';

type TranslateExtent = [[number, number], [number, number]];

export const MAP_HEIGHT = 500;
export const MAP_WIDTH = 1000;

const OFFSET = 0.1;
export const MAP_TRANSLATE_EXTENT: TranslateExtent = [
  [MAP_WIDTH * OFFSET, MAP_HEIGHT * -OFFSET],
  [MAP_WIDTH * (1 - OFFSET), MAP_HEIGHT * (1 + OFFSET)],
];

export const mapStyles: MapTheme = {
  unprotected: {
    default: {
      fill: '#CCCCCC',
      stroke: 'white',
      strokeWidth: '0.5px',
    },
    hover: {
      fill: '#01A9B4',
      outline: 'none',
    },
    pressed: {
      fill: 'none',
      outline: 'none',
    },
  },
  valid: {
    default: {
      fill: '#00b050',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: '#00b050',
      outline: 'none',
    },
  },
  notEffective: {
    default: {
      fill: 'red',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: 'red',
    },
  },

  // Patent specific styles
  plannedDeposit: {
    default: {
      fill: '#099bf6',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: '#099bf6',
    },
  },
  deposited: {
    default: {
      fill: '#fff200',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: '#fff200',
    },
  },
  published: {
    default: {
      fill: '#9bf609',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: '#9bf609',
    },
  },
  mandatedOut: {
    default: {
      fill: 'rgb(240, 173, 78)',
      outline: 'none',
      stroke: 'white',
    },
    hover: {
      fill: 'rgb(240, 173, 78)',
    },
  },
};

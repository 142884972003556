import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface OpenModalButtonProps {
  disabled: boolean;
  onClick: () => void;
}
const OpenModalButton = ({ disabled, onClick }: OpenModalButtonProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={disabled ? t('pages.archive.notAllowedOpeningModal') : undefined}
    >
      <Button
        type="link"
        onClick={onClick}
        icon={<PlusCircleOutlined />}
        disabled={disabled}
        data-testid="openArchiveUploadModalButton"
      >
        <span>{t('pages.archive.addArchive')}</span>
      </Button>
    </Tooltip>
  );
};

export default OpenModalButton;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Drawer, DrawerProps, Form } from 'antd';
import { TabsFamily } from 'business/common/services/types';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NullableDateRange } from 'technical/filters/types';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import useCustomFilters from 'business/advancedSearch/hooks/useCustomFilters';
import CustomFilterCreationModal from 'business/advancedSearch/components/createCustomFilterModal';
import CustomFilterSelect from 'business/advancedSearch/components/customFilterSelect';
import AdvancedFiltersDrawerActions from 'business/advancedSearch/components/advancedFiltersDrawerActions';
import styles from './index.module.scss';

type FilterValue =
  | string
  | string[]
  | number
  | null
  | undefined
  | NullableDateRange;

type FilterObject = { [key: string]: FilterValue };

interface AdvancedFiltersDrawerProps<T extends FilterObject>
  extends DrawerProps {
  family: TabsFamily;
  onApplyFilters: (filters: T, closeDrawer?: boolean) => void;
  filterComponents: ReactNode[];
  filtersValues?: T;
}

const AdvancedFiltersDrawer = <T extends FilterObject = FilterObject>({
  family,
  onApplyFilters,
  filterComponents,
  filtersValues,
  ...props
}: AdvancedFiltersDrawerProps<T>) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<T>();
  const {
    advancedFiltersDrawerActionsProps,
    createCustomFilterModalProps,
    deleteCurrentCustomFilter,
    customFilters,
    filterSelectProps,
  } = useCustomFilters({ form, family, onApplyFilters });

  useEffect(() => {
    if (props.open) {
      form.resetFields();
    }
  }, [family, props.open]);

  return (
    <>
      <Drawer {...props} closable={false} width={415}>
        <Form
          layout="vertical"
          form={form}
          initialValues={filtersValues}
          onFinish={onApplyFilters}
          className={styles.form}
        >
          <Flex justify="flex-end" className={styles.closeIconContainer}>
            <CloseCircleOutlined
              className={styles.closeIcon}
              onClick={props.onClose}
            />
          </Flex>

          <h1>
            {t('pages.advancedSearch.filtersDrawer.title', {
              resource: t(`table.common.column.${family}s`),
            })}
          </h1>
          <Spacer direction="vertical" size="xsmall" className={styles.filters}>
            {customFilters.length > 0 && (
              <CustomFilterSelect
                deleteCurrentCustomFilter={deleteCurrentCustomFilter}
                filterSelectProps={filterSelectProps}
              />
            )}

            {filterComponents}
          </Spacer>

          <AdvancedFiltersDrawerActions
            {...advancedFiltersDrawerActionsProps}
          />
        </Form>
      </Drawer>
      <CustomFilterCreationModal {...createCustomFilterModalProps} />
    </>
  );
};

export default AdvancedFiltersDrawer;

import { useTranslation } from 'react-i18next';
import Tag from 'ui/tag';

interface TagResultsProps {
  dataTestId: string;
  total: number;
}
const TagResults = ({ total, dataTestId }: TagResultsProps) => {
  const { t } = useTranslation();
  return (
    <Tag
      data-test-id={dataTestId}
      label={t('table.common.results', {
        context: total > 0 ? 'plural' : null,
        count: total,
      })}
    />
  );
};

export default TagResults;

import { useGetOptOut } from 'business/resources/patent/services/useGetOptOut';
import { onlyNumbers, removeSpaces } from 'technical/format/string';
import IsLoading from 'ui/isLoading';

interface OptOutProps {
  publicationNumber: string;
}

const OptOut = ({ publicationNumber }: OptOutProps) => {
  const patentNumber = `${onlyNumbers(removeSpaces(publicationNumber))}`;
  const { loading, optOut } = useGetOptOut(patentNumber);

  return (
    <IsLoading loading={loading}>
      <span>{optOut}</span>
    </IsLoading>
  );
};

export default OptOut;

import { Select } from 'antd';
import { USER_STATUSES, UserStatus } from 'business/user/types/status';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface UserStatusFilterProps {
  status: UserStatus;
  setStatusFilter: (value: UserStatus) => void;
}
const UserStatusFilter = ({
  setStatusFilter,
  status,
}: UserStatusFilterProps) => {
  const { t } = useTranslation();
  const statuses = Object.keys(USER_STATUSES).filter(
    (stat) => stat !== 'unknown',
  );

  return (
    <Select<UserStatus>
      options={statuses.map((stat) => ({
        label: t('user.status', { context: stat }),
        value: stat,
      }))}
      placeholder={t('user.status-placeholder')}
      allowClear
      onClear={() => setStatusFilter('unknown')}
      value={status}
      onChange={setStatusFilter}
      className={styles.userStatusSelect}
    />
  );
};

export default UserStatusFilter;

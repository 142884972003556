import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMoney from 'ui/inputMoney';
import style from './index.module.scss';

interface DomainFeeFormFieldsProps {
  registerFee?: boolean;
  renewFee?: boolean;
  transferFee?: boolean;
  corporateCost?: boolean;
}

const DomainFeeFormFields = ({
  registerFee,
  renewFee,
  transferFee,
  corporateCost,
}: DomainFeeFormFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {registerFee && (
        <Form.Item
          name="registerFee"
          label={t(`pages.admin.invoice.domainConfig.modal.fields.registerFee`)}
        >
          <InputMoney className={style.input} />
        </Form.Item>
      )}
      {renewFee && (
        <Form.Item
          name="renewFee"
          label={t(`pages.admin.invoice.domainConfig.modal.fields.renewFee`)}
        >
          <InputMoney className={style.input} />
        </Form.Item>
      )}
      {transferFee && (
        <Form.Item
          name="transferFee"
          label={t(`pages.admin.invoice.domainConfig.modal.fields.transferFee`)}
        >
          <InputMoney className={style.input} />
        </Form.Item>
      )}
      {corporateCost && (
        <Form.Item
          name="corporateCost"
          label={t(
            `pages.admin.invoice.domainConfig.modal.fields.corporateCost`,
          )}
        >
          <InputMoney className={style.input} />
        </Form.Item>
      )}
    </>
  );
};

export default DomainFeeFormFields;

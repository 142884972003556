import {
  CompanyGroupsQuery,
  CompanyGroupsQueryVariables,
  Group_Bool_Exp,
  useCompanyGroupsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { CompanyGroup } from 'business/groups/services/types';
import { storageKeys } from 'technical/storage/constants';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';

const generateSearch = (searchText: string): Group_Bool_Exp => ({
  _or: [...generateTextSearchConditions(searchText, ['name'])],
});

export const useGetCompanyGroups = (companyId: number) =>
  useQueryForTable<
    CompanyGroup,
    CompanyGroupsQuery,
    CompanyGroupsQueryVariables
  >({
    subStorageKey: storageKeys.companyGroups,
    useQuery: ({ limit, offset, searchText, orderBy }) =>
      useCompanyGroupsQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            companyId: { _eq: companyId },
            ...(searchText ? generateSearch(searchText) : {}),
          },
        },
        fetchPolicy: 'cache-and-network',
      }),
    getTotal: (res) => res?.group.length ?? 0,
    map: (res) =>
      res?.group.map((grp) => ({
        id: grp.id,
        companyId: grp.companyId,
        name: grp.name,
        nbUsers: grp.userGroups_aggregate.aggregate?.count ?? 0,
        isAdmin: grp.access?.isAdmin || false,
        canEdit: grp.canEdit,
      })) ?? [],
  });

import { Button } from 'antd';
import notifyPromise from 'business/common/services/notifyPromise';
import KeywordsListEdition from 'business/configuration/keywords/components/listEdition';
import { useTranslation } from 'react-i18next';
import Card from 'ui/card';
import ErrorOverlay from 'ui/errorOverlay';
import { useHandleCompanyKeywordsCard } from 'business/configuration/keywords/services/useHandleCompanyKeywordsCard';

interface CompanyArchiveKeywordsProps {
  companyId: number;
}

const CompanyArchiveKeywords = ({ companyId }: CompanyArchiveKeywordsProps) => {
  const { t } = useTranslation();

  const { companyKeywords, setKeywords, updateKeywords, error, loading } =
    useHandleCompanyKeywordsCard(companyId);

  return (
    <Card className="elevation1">
      <ErrorOverlay error={error} loading={loading}>
        <KeywordsListEdition options={companyKeywords} onChange={setKeywords} />
      </ErrorOverlay>
      <Button
        onClick={() =>
          notifyPromise(t, updateKeywords, {
            successNotification: t('archive.keywords.success-notification'),
          })
        }
      >
        {t('common.save')}
      </Button>
    </Card>
  );
};

export default CompanyArchiveKeywords;

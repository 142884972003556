import { LinkOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface SimpleLinkProps {
  link: string;
  label?: string;
  displayIcon?: boolean;
  className?: string;
}

export const SimpleLink = ({
  link,
  label,
  className,
  displayIcon = true,
}: SimpleLinkProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Link
        className={classNames('smallMarginRight', className)}
        to={{ pathname: link }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label || t('table.common.column.link')}
      </Link>
      {displayIcon && <LinkOutlined />}
    </>
  );
};

export default SimpleLink;

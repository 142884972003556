import { InputNumber, InputNumberProps } from 'antd';
import NumberParser from 'technical/format/numberParser';
import { isDefined } from 'technical/validation';
import { i18n } from 'translations';

type InputMoneyProps = InputNumberProps<number>;

const InputMoney = ({
  addonAfter = '€',
  min = 0,
  ...props
}: InputMoneyProps) => {
  const numberFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
  });
  const numberParse = new NumberParser(i18n.language);

  return (
    <InputNumber<number>
      formatter={(val) =>
        isDefined(val)
          ? numberFormatter.format(val).replace('EUR', '').trim()
          : ''
      }
      parser={(str) => (isDefined(str) ? numberParse.parse(str) : 0)}
      addonAfter={addonAfter}
      min={min}
      {...props}
    />
  );
};
export default InputMoney;

import { OsrFilterState } from 'business/resources/osr/services/types';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import { Button } from 'antd';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { TRADEMARK_ANOMALY_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';

interface ITrademarkAnomaly {
  filters: OsrFilterState;
}

const TrademarkAnomaly = (props: ITrademarkAnomaly) => {
  const { t } = useTranslation();
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);
  const {
    data: dataWithoutClientRef,
    tableProps: dataWithoutClientRefTableProps,
  } = useGetTrademarkOsrData('clientRefIsNull', {
    companyRef: props.filters.companyRef,
    countryCode: props.filters.countryCode,
  });

  const {
    data: dataDuplicateClientRef,
    tableProps: dataDuplicateClientRefTableProps,
  } = useGetTrademarkOsrData('duplicateClientRef', {
    companyRef: props.filters.companyRef,
    countryCode: props.filters.countryCode,
  });

  const {
    data: dataDuplicateProcessAgentRef,
    tableProps: dataDuplicateProcessAgentRefTableProps,
  } = useGetTrademarkOsrData('duplicateRenewAgentRef', {
    companyRef: props.filters.companyRef,
    countryCode: props.filters.countryCode,
  });

  const { data: dataAcumassAnomaly, tableProps: dataAcumassAnomalyTableProps } =
    useGetTrademarkOsrData('acumassReferenceAnomaly', {
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    });

  const {
    data: dataAcumassMissingReference,
    tableProps: dataAcumassMissingReferenceTableProps,
  } = useGetTrademarkOsrData('acumassMissingReferenceAnomaly', {
    companyRef: props.filters.companyRef,
    countryCode: props.filters.countryCode,
  });

  return (
    <OsrTabs
      actions={
        <Button type="primary" onClick={() => generateExcel('ANOMALIES')}>
          Exporter les anomalies
        </Button>
      }
      tabsItems={[
        {
          key: 'withoutClientRef',
          label: `${t('pages.osr.tableAnomaly', {
            context: 'withoutClientRef',
          })} (${dataWithoutClientRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_ANOMALY_COLUMNS}
              dataSource={dataWithoutClientRef}
              {...dataWithoutClientRefTableProps}
            />
          ),
        },
        {
          key: 'clientRef',
          label: `${t('pages.osr.tableAnomaly', {
            context: 'duplicateClientRef',
          })} (${dataDuplicateClientRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={TRADEMARK_ANOMALY_COLUMNS}
              searchBarEnabled={false}
              dataSource={dataDuplicateClientRef}
              {...dataDuplicateClientRefTableProps}
            />
          ),
        },
        {
          key: 'processAgentRef',
          label: `Doublons référence ACUMASS (${dataDuplicateProcessAgentRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={TRADEMARK_ANOMALY_COLUMNS}
              dataSource={dataDuplicateProcessAgentRef}
              searchBarEnabled={false}
              {...dataDuplicateProcessAgentRefTableProps}
            />
          ),
        },
        {
          key: 'AcumassAnomalies',
          label: `Problème référence ACUMASS (${dataAcumassAnomalyTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={TRADEMARK_ANOMALY_COLUMNS}
              dataSource={dataAcumassAnomaly}
              searchBarEnabled={false}
              name="AcumassAnomalies"
              {...dataAcumassAnomalyTableProps}
            />
          ),
        },
        {
          key: 'AcumassMissingReferenceAnomalies',
          label: `Marque Nat. Etrangère sans ACUMASS (${dataAcumassMissingReferenceTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={TRADEMARK_ANOMALY_COLUMNS}
              dataSource={dataAcumassMissingReference}
              searchBarEnabled={false}
              name="AcumassMissingReferenceAnomalies"
              {...dataAcumassMissingReferenceTableProps}
            />
          ),
        },
      ]}
    />
  );
};

export default TrademarkAnomaly;

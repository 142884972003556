import { useEffect, useMemo } from 'react';
import { filtersStorageKey } from 'technical/storage/constants';
import { useSessionStorage } from 'technical/storage/useSessionStorage';
import { useUrl } from 'technical/url/useUrl';
import { getStorageFiltersFromKey, getUrlFiltersFromKey } from './filters';
import {
  AllTableFilter,
  StorageTableFilter,
  TableFiltersKeys,
  UrlTableFilter,
} from './types';

export const useFilters = <T>({
  storageKey,
  storageFilterKeys,
  urlFilterKeys,
  defaultValues,
  subStorageKey,
}: TableFiltersKeys<T>) => {
  const urlFilters = getUrlFiltersFromKey(urlFilterKeys ?? [], defaultValues);
  const storageFilters = getStorageFiltersFromKey(
    storageFilterKeys ?? [],
    defaultValues,
  );

  const { urlValues, setUrlFilterValue, setUrlFiltersValues } =
    useUrl<UrlTableFilter<T>>(urlFilters);

  const {
    storageValues,
    setStorageValues,
    setStorageValue,
    resetToPreviousValue,
  } = useSessionStorage<StorageTableFilter<T>>({
    storageKey: storageKey ?? filtersStorageKey,
    filters: storageFilters,
    subKey: subStorageKey,
  });

  const mergedFiltersValues = useMemo(() => {
    return { ...(storageValues ?? {}), ...(urlValues ?? {}) };
  }, [storageValues, urlValues]);

  const setFilterValue = (keyFilter: keyof AllTableFilter<T>, value: any) => {
    setStorageValue(keyFilter as keyof StorageTableFilter<T>, value);
    setUrlFilterValue(keyFilter as keyof UrlTableFilter<T>, value);
  };

  const setFiltersValues = (value: AllTableFilter<T>) => {
    setStorageValues(value);
    setUrlFiltersValues(value);
  };

  useEffect(() => {
    setStorageValues(mergedFiltersValues);
  }, []);

  return {
    filtersValues: mergedFiltersValues,
    filtersActive: Object.keys(mergedFiltersValues).length > 0,
    setFilterValue,
    setFiltersValues,
    resetToPreviousValue,
  };
};

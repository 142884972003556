import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import notifyPromise from 'business/common/services/notifyPromise';
import {
  useConfirmArchiveDeletionRequestMutation,
  useRefuseArchiveDeletionRequestMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import confirmationModal from 'ui/modalConfirm';
import Spacer from 'ui/spacer';

interface ArchiveDeletionTableActionColumnsProps {
  archiveId: number;
  archiveName: string;
  refetch?: () => void;
}
const ArchiveDeletionTableActionColumn = ({
  archiveId,
  refetch,
  archiveName,
}: ArchiveDeletionTableActionColumnsProps) => {
  const { t } = useTranslation();

  const {
    user: { id },
  } = useLoggedInAppContext();

  const [confirmArchiveDeletionRequestMutation] =
    useConfirmArchiveDeletionRequestMutation();
  const [refuseArchiveDeletionRequestMutation] =
    useRefuseArchiveDeletionRequestMutation();

  const confirmArchiveDeletionRequest = () => {
    notifyPromise(
      t,
      () =>
        confirmArchiveDeletionRequestMutation({
          variables: {
            archiveId,
            deletedByUserId: id,
          },
        }),
      {
        refetch,
        successNotification: t('pages.archive.notifications.confirmDelete', {
          archiveName,
        }),
      },
    );
  };

  const confirmArchiveDeletion = () => {
    confirmationModal(
      t,
      () => confirmArchiveDeletionRequest(),
      t('pages.archive.table.confirmDelete', {
        archiveName,
      }),
    );
  };

  const refuseArchiveDeletionRequest = () => {
    notifyPromise(
      t,
      () =>
        refuseArchiveDeletionRequestMutation({
          variables: { archiveId },
        }),
      {
        refetch,
        successNotification: t('pages.archive.notifications.refuseDelete', {
          archiveName,
        }),
      },
    );
  };

  const actionColumns = [
    {
      title: 'pages.archive.table.acceptDelete',
      icon: <CheckOutlined />,
      onClick: confirmArchiveDeletion,
      confirm: true,
    },
    {
      title: 'pages.archive.table.refuseDelete',
      icon: <CloseOutlined />,
      onClick: refuseArchiveDeletionRequest,
    },
  ];

  return (
    <Spacer size="xxsmall">
      {actionColumns.map((actionColumn) => {
        return (
          <Tooltip key={actionColumn.title} title={t(actionColumn.title)}>
            <Button
              type="primary"
              size="middle"
              icon={actionColumn.icon}
              onClick={actionColumn.onClick}
            />
          </Tooltip>
        );
      })}
    </Spacer>
  );
};

export default ArchiveDeletionTableActionColumn;

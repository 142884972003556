import { isUserStatus } from 'business/user/services/status';
import { USER_STATUSES, UserStatus } from 'business/user/types/status';
import {
  CompanyUserGroupsQuery,
  CompanyUserGroupsQueryVariables,
  User_Bool_Exp,
  useCompanyUserGroupsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { UserWithGroups } from 'business/groups/services/types';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): User_Bool_Exp => ({
  _or: [...generateTextSearchConditions(searchText, ['email', 'fullName'])],
});

const generateWhere = (
  searchText: string | null,
  statusFilter: UserStatus | null,
) => {
  return {
    ...(statusFilter ? { status: { _eq: statusFilter } } : {}),
    ...(searchText ? generateSearch(searchText) : {}),
  };
};

const useGetUserGroups = (companyId: number) => {
  const { tableProps, ...rest } = useQueryForTable<
    UserWithGroups,
    CompanyUserGroupsQuery,
    CompanyUserGroupsQueryVariables
  >({
    subStorageKey: storageKeys.userGroups,
    commonFiltersKeys: ['userStatus', 'search'],
    useQuery: ({ limit, offset, searchText, userStatus }) =>
      useCompanyUserGroupsQuery({
        variables: {
          limit,
          offset,
          where: generateWhere(searchText, userStatus),
          companyId,
        },
        fetchPolicy: 'no-cache',
      }),
    getTotal: (res) => res?.user_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.user.map((user) => ({
        ...user,
        status: isUserStatus(user.status) ? user.status : USER_STATUSES.unknown,
        companyGroup: user.companyGroup,
        multiCompaniesGroup: user.multiCompaniesGroups,
      })) ?? [],
  });
  return {
    ...rest,
    tableProps,
  };
};

export default useGetUserGroups;

import { i18n } from 'translations';
import frLocale from 'antd/lib/locale/fr_FR';
import jaLocale from 'antd/lib/locale/ja_JP';
import enUsLocale from 'antd/lib/locale/en_US';
import { useEffect, useState } from 'react';

type LocaleType = typeof frLocale | typeof jaLocale;

const localeResources: Record<string, () => LocaleType> = {
  fr: () => {
    if (frLocale.DatePicker?.lang.locale) {
      frLocale.DatePicker.lang.locale = 'fr';
    }
    return frLocale;
  },
  'fr-FR': () => {
    if (frLocale.DatePicker?.lang.locale) {
      frLocale.DatePicker.lang.locale = 'fr';
    }
    return frLocale;
  },
  ja: () => {
    return jaLocale;
  },
  en: () => {
    // default Value
    return enUsLocale;
  },
};

/**
 * Return the correct locale for the <ConfigProvider /> component
 * the default locale return is US english value
 * see https://ant.design/docs/react/i18n
 */
const useUpdateLocale = (lng: string) => {
  const [locale, setLocale] = useState<LocaleType>(
    localeResources[lng] ? localeResources[lng]() : localeResources.en,
  );
  useEffect(() => {
    setLocale(
      localeResources[lng] ? localeResources[lng]() : localeResources.en,
    );
  }, [i18n.language]);

  return {
    locale,
  };
};

export default useUpdateLocale;

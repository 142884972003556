import React from 'react';
import Link from 'ui/link';

interface LinkListProps {
  links: { path: string; label: string }[];
}

const LinkList = ({ links }: LinkListProps) =>
  links.length ? (
    <>
      <span style={{ marginLeft: 5 }}>{'( '}</span>
      {links.map((link, index) => {
        return (
          <React.Fragment key={link.path}>
            <Link target="_blank" to={link.path}>
              {link.label}
            </Link>
            <span>{index < links.length - 1 ? ', ' : ''} </span>
          </React.Fragment>
        );
      })}
      <span>)</span>
    </>
  ) : null;

export default LinkList;

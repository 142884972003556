import { Companies } from 'business/admin/services/types';
import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

type CompaniesTableProps = Omit<TableProps<Companies>, 'defaultColumns'>;

const numberColumnWidth = 100;

const defaultColumns = (): ColumnGenerationData<NonNullable<Companies>> => [
  {
    key: 'name',
    translationKey: 'socialReason',
    alwaysVisible: true,
    render: (_: any, record: Companies) => (
      <Link
        data-test-id="nameRedirect"
        className="titleRedirect"
        to={{
          pathname: generatePath(Routes.CompanyDetails, { id: record.id ?? 0 }),
          state: record,
        }}
      >
        {record.name}
      </Link>
    ),
  },
  { key: 'companyRef', translationKey: 'societyReference' },
  {
    key: 'patentCount',
    translationKey: 'patents',
    width: numberColumnWidth,
  },
  {
    key: 'designCount',
    translationKey: 'designs',
    width: numberColumnWidth,
  },
  {
    key: 'trademarkCount',
    translationKey: 'trademarks',
    width: numberColumnWidth,
  },
  {
    key: 'domainCount',
    translationKey: 'domains',
    width: numberColumnWidth,
  },
  { key: 'userCount', translationKey: 'users', width: numberColumnWidth },
  {
    key: 'groupCount',
  },
  { key: 'lastConnection' },
];

const CompaniesTable: React.FC<CompaniesTableProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Table
      id="companiesTable"
      {...props}
      defaultColumns={defaultColumns()}
      header={<h1 className="headerTitle">{t('pages.companies.title')}</h1>}
    />
  );
};

export default CompaniesTable;

import { generatePathByAssetType } from 'business/common/services';
import { DomainEvent } from 'business/resources/events/services/types';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ColumnGenerationData } from 'ui/table/types';

const getDomainEventColumns = (
  t: TFunction,
): ColumnGenerationData<DomainEvent> => [
  {
    key: 'type',
    translationKey: 'eventType',
    alwaysVisible: true,
    ellipsis: true,
    render: (context) => t('table.common.column.domainType', { context }),
  },
  { key: 'holder', checked: false, ellipsis: true },
  {
    key: 'title',
    context: 'domain',
    alwaysVisible: true,
    render: (_, { assetId, title }: DomainEvent) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('domain', assetId)}
      >
        {title}
      </Link>
    ),
  },
  { key: 'extension', alwaysVisible: true },
  { key: 'dueDate', alwaysVisible: true, ellipsis: true },
  { key: 'depositDate', checked: false, ellipsis: true },
  { key: 'bdlRef', checked: false, ellipsis: true },
  { key: 'clientRef', checked: false, ellipsis: true },
  { key: 'clientRef2', checked: false, ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
];

export default getDomainEventColumns;

import { useGetGroupAccessQuery } from 'generated/graphql';
import { TFunction } from 'i18next';
import { permissionCards } from 'business/groups/services/permissionCards';
import {
  CheckList,
  CheckListKey,
  GroupAccess,
} from 'business/groups/services/types';

export const useGetCompanyGroupAccess = (
  t: TFunction,
  companyId: number,
  groupId?: string,
) => {
  const { data, error, loading } = useGetGroupAccessQuery({
    variables: { groupId, companyId },
    fetchPolicy: 'cache-and-network',
  });
  const group = data?.getGroupEditablePermission;

  const isKeyOfGroupAccess = (
    key: string,
    obj: GroupAccess,
  ): key is keyof GroupAccess => {
    return !!obj && key in obj;
  };

  const isInCheckboxGroup = (permissionCard: CheckList, key: string) => {
    return !!permissionCard.lists.find(
      (permission) => permission.value === key,
    );
  };

  const checkRessourceAccessIsDefined = (
    ressourceAccess: GroupAccess,
    key: keyof GroupAccess,
  ) => {
    return (
      ressourceAccess &&
      ressourceAccess[key] !== null &&
      ressourceAccess[key] !== undefined
    );
  };
  const getOnlyNonNullableAccessKeys = (
    key: CheckListKey,
    onlyTrue: boolean,
  ) => {
    const currentRessourceAccess = group?.[key];
    if (currentRessourceAccess) {
      return Object.keys(currentRessourceAccess).filter((accessKey) => {
        return (
          isKeyOfGroupAccess(accessKey, currentRessourceAccess) &&
          accessKey !== '__typename' &&
          checkRessourceAccessIsDefined(currentRessourceAccess, accessKey) &&
          (onlyTrue ? currentRessourceAccess[accessKey] === true : true)
        );
      });
    }

    return [];
  };

  const visiblePermissions = permissionCards(t).map((permissionCard) => {
    const nonNullableKeys = getOnlyNonNullableAccessKeys(
      permissionCard.key,
      false,
    );
    return {
      ...permissionCard,
      lists: group?.[permissionCard.key]
        ? permissionCard.lists.filter((list) =>
            nonNullableKeys.includes(list.value),
          )
        : permissionCard.lists,
    };
  });

  const groupAccess = visiblePermissions.reduce((prev, curr) => {
    const groupAccessData = getOnlyNonNullableAccessKeys(curr.key, true);

    const radio = groupAccessData.find(
      (accessKey) => !isInCheckboxGroup(curr, accessKey),
    );

    if (radio) {
      const indexRadio = groupAccessData.indexOf(radio);
      groupAccessData.splice(indexRadio, 1);
    }

    const formattedGroupAccess = {
      ...prev,
      [curr.checkboxFormName]: groupAccessData,
      ...(curr.radioFormName && radio ? { [curr.radioFormName]: radio } : {}),
    };
    return formattedGroupAccess;
  }, {});

  return {
    group,
    groupAccess,
    error,
    loading,
    visiblePermissions,
  };
};

import { ArchiveInvoices } from 'business/archive/services/types';
import InvoiceTableActions from 'business/common/components/invoiceTable/actions';
import notifyPromise from 'business/common/services/notifyPromise';
import useOpenInvoiceInNewTab from 'business/invoice/hooks/useOpenInvoiceInNewTab';
import useSendBackInvoice from 'business/invoice/hooks/useSendBackInvoice';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import confirmationModal from 'ui/modalConfirm';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

type ArchiveInvoicesListProps = Omit<
  TableProps<ArchiveInvoices>,
  'defaultColumns'
> & {
  canSendBackInvoiceByMail?: boolean;
};

const defaultColumns = (
  t: TFunction,
  openInvoice: (id: number) => Promise<void>,
  sendBackEmail?: (id: number) => Promise<void>,
): ColumnGenerationData<ArchiveInvoices> => {
  return [
    {
      key: 'id',
      render: (_, record: ArchiveInvoices) => {
        return record.invoiceNumber;
      },
    },
    {
      key: 'date',
    },
    {
      key: 'archivePackageName',
    },
    {
      key: 'holder',
      translationKey: 'holder',
      render: (_, record: ArchiveInvoices) => {
        return record.holder;
      },
    },
    {
      key: 'id',
      fixed: 'right',
      translationKey: 'action',
      alwaysVisible: true,
      width: 85,
      sorter: false,
      title: t('table.common.column.action'),
      render: (_, record: ArchiveInvoices) => {
        return (
          <InvoiceTableActions
            onClickView={() => {
              openInvoice(record.id);
            }}
            onClickMail={
              sendBackEmail
                ? () => {
                    sendBackEmail(record.id);
                  }
                : undefined
            }
          />
        );
      },
    },
  ];
};

const ArchiveInvoiceTable: React.FC<ArchiveInvoicesListProps> = ({
  canSendBackInvoiceByMail = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [sendBackArchiveInvoice] = useSendBackInvoice('archive');
  const [openArchiveInvoiceInNewTab] = useOpenInvoiceInNewTab('archive');

  const openInvoiceInNewTab = async (id: number) =>
    notifyPromise(t, async () => openArchiveInvoiceInNewTab(id), {
      successNotification: null,
    });

  const sendBackEmail = async (id: number) => {
    confirmationModal(
      t,
      () => {
        notifyPromise(t, async () => sendBackArchiveInvoice(id), {
          successNotification: t('pages.admin.invoice.sendBackInvoice.success'),
        });
      },
      t('pages.admin.invoice.sendBackInvoice.modal.title'),
    );
  };

  return (
    <Table
      id="archiveInvoiceTable"
      {...props}
      defaultColumns={defaultColumns(
        t,
        openInvoiceInNewTab,
        canSendBackInvoiceByMail ? sendBackEmail : undefined,
      )}
    />
  );
};

export default ArchiveInvoiceTable;

import countries from 'i18n-iso-countries';
import i18n from 'i18next';
import logger from 'technical/logger';
import { isDefined } from 'technical/validation';
import { countriesKeys } from './constants';
import { Country } from './types';

// i18n language is different between browsers
export const getLanguageUsed = () =>
  i18n.language === 'fr-FR' ? 'fr' : i18n.language;

export async function setCountry() {
  try {
    const country = await import(
      `i18n-iso-countries/langs/${getLanguageUsed()}.json`
    );
    countries.registerLocale(country);
  } catch (err) {
    logger.error({
      err,
      languageUsed: getLanguageUsed(),
      message: 'Failed while importing language ',
    });
    const country = await import('i18n-iso-countries/langs/en.json');
    countries.registerLocale(country);
  }
}
export const translateCountry = (countryCode: string) => {
  return countries.getName(countryCode, getLanguageUsed());
};

export const getCountryLabel = (country?: Country | null) => {
  if (!country) {
    return '';
  }
  return getLanguageUsed() === 'en' ? country.labelEn : country.labelFr;
};

export const isCountryKey = (key: string) => {
  return countriesKeys.includes(key);
};

export const isEuropean = (country?: Country | null) => {
  return isDefined(country) && country.code === 'EP';
};

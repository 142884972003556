import { CloseCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Modal } from 'antd';
import { Rule } from 'antd/lib/form';
import { Archive } from 'business/archive/services/types';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import {
  positiveNumberRule,
  requiredRule,
} from 'technical/validation/antFormRules';
import Select from 'ui/select';

interface ArchiveConfigurationFormProps {
  onFinish: (values: Archive) => void;
  onCancel: () => void;

  visible: boolean;
  sectionOptions: { label: string; code: number }[];
}

const ArchiveConfigurationFormModal = ({
  onFinish,
  onCancel,
  sectionOptions,
  visible,
}: ArchiveConfigurationFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Archive>();
  const INPUT_NUMBER_RULES: Rule[] = [requiredRule, positiveNumberRule];
  const INPUT_NUMBER_STYLE = { width: '100%' };
  return (
    <Modal
      onCancel={onCancel}
      closable
      open={visible}
      closeIcon={<CloseCircleOutlined />}
      onOk={() =>
        form
          .validateFields()
          .then(onFinish)
          .catch((err) => logger.error(err))
      }
    >
      <Form<Archive> layout="vertical" form={form}>
        <Form.Item
          data-test-id="title"
          name="title"
          label={t('table.common.column.title')}
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          data-test-id="annualCost"
          name="annualCost"
          label={t('table.common.column.annualCost')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber style={INPUT_NUMBER_STYLE} type="number" />
        </Form.Item>
        <Form.Item
          data-test-id="nbDocumentPerYear"
          name="nbDocumentPerYear"
          label={t('table.common.column.nbDocumentPerYear')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber type="number" style={INPUT_NUMBER_STYLE} />
        </Form.Item>
        <Form.Item
          data-test-id="volume"
          name="volume"
          label={t('table.common.column.volume')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber type="number" style={INPUT_NUMBER_STYLE} />
        </Form.Item>
        <Form.Item
          data-test-id="subscriptionDuration"
          name="subscriptionDuration"
          label={t('table.common.column.subscriptionDuration')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber type="number" style={INPUT_NUMBER_STYLE} />
        </Form.Item>
        <Form.Item
          data-test-id="additionalFileCost"
          name="additionalFileCost"
          label={t('table.common.column.additionalFileCost')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber type="number" style={INPUT_NUMBER_STYLE} />
        </Form.Item>
        <Form.Item
          data-test-id="additionalVolumeCost"
          name="additionalVolumeCost"
          label={t('table.common.column.additionalVolumeCost')}
          rules={INPUT_NUMBER_RULES}
        >
          <InputNumber type="number" style={INPUT_NUMBER_STYLE} />
        </Form.Item>
        <Form.Item
          data-test-id="storageDuration"
          name="storageDuration"
          label={t('table.common.column.storageDuration')}
          rules={[{ required: true }]}
        >
          <Select options={sectionOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ArchiveConfigurationFormModal;

import { useTranslation } from 'react-i18next';
import DatePicker from 'ui/date/datePicker';

interface Filter {
  from: Date | null;
  to: Date | null;
}

type DatePickerProps = Omit<
  (typeof DatePicker)['propTypes'],
  'onChange' | 'value'
>;

interface DateRangeTableFilterProps<T extends Filter> {
  filtersValues: T;
  setFiltersValues: (value: T) => void;

  fromDateFilterProps?: DatePickerProps;
  toDateFilterProps?: DatePickerProps;
}

const DateRangeTableFilter = <T extends Filter>({
  filtersValues,
  setFiltersValues,
  fromDateFilterProps,
  toDateFilterProps,
}: DateRangeTableFilterProps<T>) => {
  const { t } = useTranslation();

  return (
    <>
      <DatePicker
        placeholder={t('table.common.filters.from')}
        value={filtersValues.from}
        onChange={(from) => setFiltersValues({ ...filtersValues, from })}
        {...fromDateFilterProps}
      />
      <DatePicker
        placeholder={t('table.common.filters.to')}
        value={filtersValues.to}
        onChange={(to) => setFiltersValues({ ...filtersValues, to })}
        {...toDateFilterProps}
      />
    </>
  );
};

export default DateRangeTableFilter;

import {
  GetDesignEventSearchQuery,
  GetDesignEventSearchQueryVariables,
  useGetDesignEventSearchQuery,
} from 'generated/graphql';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { DesignEvent } from 'business/resources/events/services/types';
import { storageKeys } from 'technical/storage/constants';

interface UseGetDesignEventParams {
  filterValues: GetDesignEventSearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<DesignEvent>;
  search?: string;
}

export const useGetDesignEvent = ({
  filterValues,
  overrideOrderBy,
  search,
}: UseGetDesignEventParams) =>
  useQueryForTable<
    DesignEvent,
    GetDesignEventSearchQuery,
    GetDesignEventSearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKeys.designEvents,
    useQuery: ({
      limit,
      offset,
      orderBy,
      displayAFU,
      displayAnnuities,
      isOwn,
    }) =>
      useGetDesignEventSearchQuery({
        variables: {
          where: {
            ...filterValues,
            search,
            displayAFU,
            displayAnnuities,
            isOwn,
          },
          limit,
          offset,
          orderBy,
        },
      }),
    getTotal: (res) => res?.designEventSearch?.count ?? 0,
    map: (res) => res?.designEventSearch?.resources ?? [],
  });

import {
  DescriptionsProps as AntdDescriptionsProps,
  Col,
  Descriptions,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionProps } from './types';
import {
  createLabelValue,
  displayItemValue,
  shouldDisplayRow,
} from './helpers';
import { isString } from 'technical/types';
import styles from './index.module.scss';

const Description = <T,>({
  details,
  data,
  labelWidth = 180,
}: DescriptionProps<T>) => {
  const { t } = useTranslation();
  const descriptionProps: AntdDescriptionsProps = {
    labelStyle: { width: `${labelWidth}px` },
    contentStyle: { fontWeight: '700' },
  };

  return (
    <Row className="marginTop20">
      {Object.keys(details).map((detail) => {
        return (
          <Col lg={12} xl={12} key={detail}>
            <Descriptions {...descriptionProps} key={String(detail)} column={1}>
              {details[detail as keyof typeof details].map(
                ({
                  key,
                  label,
                  context,
                  translationKey,
                  hideRow,
                  render,
                  suffix,
                }) => {
                  if (!data || !shouldDisplayRow(data, hideRow, data?.[key])) {
                    return null;
                  }

                  const labelString = isString(label) ? label : label?.(data);

                  const labelValue = createLabelValue({
                    key: String(key),
                    context,
                    translationKey,
                    label: labelString,
                    t,
                    suffix: suffix?.(data),
                  });

                  return (
                    <Descriptions.Item
                      key={String(key)}
                      label={labelValue}
                      className={styles.descriptionItem}
                    >
                      {displayItemValue({
                        dataRendered: render?.(data),
                        value: data[key],
                        key,
                        t,
                      })}
                    </Descriptions.Item>
                  );
                },
              )}
            </Descriptions>
          </Col>
        );
      })}
    </Row>
  );
};

export default Description;

import { message } from 'antd';
import ActionCard from 'business/user/components/actionCard';
import ForgotPasswordCard from 'business/user/components/forgotPasswordCard';
import authService from 'business/user/services/authService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageAuth from 'business/user/pages/pageAuth';

const ForgotPasswordPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  const handleSubmitClick = async (login: string) => {
    try {
      await authService.requestPasswordReset(login);
      setShowSuccess(true);
    } catch (error) {
      // No error possible from backend so only network error is possible here
      message.error(t('errors.generic'));
    }
  };

  if (showSuccess) {
    return (
      <PageAuth>
        <ActionCard
          dataTestId="success-forgot-password"
          title={t('pages.forgotPassword.success.title')}
          hint={t('pages.forgotPassword.success.description')}
          btnText={t('pages.common.goToHome')}
        />
      </PageAuth>
    );
  }

  return (
    <PageAuth>
      <ForgotPasswordCard onClick={handleSubmitClick} />
    </PageAuth>
  );
};

export default ForgotPasswordPage;

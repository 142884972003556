import {
  useGetTrademarkFamilyImageQuery,
  useGetTrademarkImageQuery,
  useGetTrademarkImagesQuery,
} from 'generated/graphql';
import { ImageQueryHook } from 'ui/imageDisplay';

export const getTrademarkImageQuery =
  (trademarkId: string | number): ImageQueryHook =>
  ({ skip }) =>
    useGetTrademarkImageQuery({
      variables: {
        id: Number(trademarkId),
      },
      skip,
    });

export const getTrademarkFamilyImageQuery =
  (trademarkFamilyId: string): ImageQueryHook =>
  ({ skip }) =>
    useGetTrademarkFamilyImageQuery({
      variables: { trademarkFamilyId },
      skip,
    });

export const useGetTrademarkImages = (ids: number[]) => {
  const { data, loading } = useGetTrademarkImagesQuery({
    variables: { ids },
  });

  return {
    imageList: data?.getImagesByListIds ?? [],
    loading,
  };
};

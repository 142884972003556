import { Tabs, TabsProps } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import DomainInvoiceTable from 'business/invoice/components/domainInvoiceTable';
import ArchiveInvoiceTable from 'business/invoice/components/archiveInvoiceTable';
import { useTranslation } from 'react-i18next';
import DateRangeTableFilter from 'business/common/components/dateRangeTableFilter';
import useGetDomainInvoices from 'business/invoice/hooks/useGetDomainInvoices';
import { useGetArchiveInvoices } from 'business/invoice/hooks/useGetArchiveInvoices';
import { getInvoiceTabLabel } from 'ui/tabs/helper';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { isBdlAdmin } from 'business/admin/services/permission';
import { useMemo } from 'react';
import NotFoundPage from 'ui/notFoundPage';
import Spacer from 'ui/spacer';
import DomainFeeButtonModal from 'business/invoice/components/domainFeeButtonModal';
import { canUserAccessInvoicePage } from 'business/user/services';

const InvoicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();

  const domainInvoices = useGetDomainInvoices({});
  const archiveInvoices = useGetArchiveInvoices();

  const tabs: TabsProps['items'] = useMemo(() => {
    const tabsT = [];
    if (user.privateInfos.domainInvoiceEnabled || isBdlAdmin(user)) {
      tabsT.push({
        key: 'domainInvoices',
        label: getInvoiceTabLabel(t, 'domain')(domainInvoices),
        children: (
          <DomainInvoiceTable
            canSendBackInvoiceByMail={isBdlAdmin(user)}
            dataSource={domainInvoices.data}
            {...domainInvoices.tableProps}
            customAction={
              <DateRangeTableFilter
                {...domainInvoices.filters}
                fromDateFilterProps={{ style: { marginLeft: 'auto' } }}
              />
            }
          />
        ),
      });
    }
    if (user.privateInfos.archiveInvoiceEnabled || isBdlAdmin(user)) {
      tabsT.push({
        key: 'archiveInvoices',
        label: getInvoiceTabLabel(t, 'archive')(archiveInvoices),
        children: (
          <ArchiveInvoiceTable
            canSendBackInvoiceByMail={isBdlAdmin(user)}
            dataSource={archiveInvoices.data}
            {...archiveInvoices.tableProps}
            customAction={
              <DateRangeTableFilter
                {...archiveInvoices.filters}
                fromDateFilterProps={{ style: { marginLeft: 'auto' } }}
              />
            }
          />
        ),
      });
    }
    return tabsT;
  }, [archiveInvoices, domainInvoices, t, user]);

  if (!canUserAccessInvoicePage(user)) {
    return <NotFoundPage />;
  }

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('metadata.invoices') }]}
      translationKey="invoices"
    >
      <Spacer size="small" direction="vertical">
        <h1 className="headerTitle">{t('pages.invoices.title')}</h1>
        <DomainFeeButtonModal user={user} />
      </Spacer>
      <Tabs defaultActiveKey="domainInvoices" items={tabs} type="card" />
    </ConnectedPage>
  );
};

export default InvoicesPage;

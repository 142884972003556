import Flex from 'ui/flex';
import styles from './index.module.scss';

interface StatisticProps {
  title: string;
  count: number;
}

const Statistic = ({ title, count }: StatisticProps) => {
  return (
    <Flex justify="space-between" alignItems="center">
      <span className={styles.title}>{title}</span>
      <span className={styles.count}>{count}</span>
    </Flex>
  );
};

export default Statistic;

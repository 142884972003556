import ConnectedPage from 'business/user/helpers/connectedPage';
import { useEffect } from 'react';
import { useFilters } from 'technical/filters/useFilters';
import { storageKeys } from 'technical/storage/constants';
import { isDefined } from 'technical/validation';
import { defaultTabOpened } from 'business/advancedSearch/components/constants';
import AdvancedSearchPage from 'business/advancedSearch/components';

const SearchPage = () => {
  const { filtersValues, setFiltersValues, setFilterValue } = useFilters({
    subStorageKey: storageKeys.homePage,
    urlFilterKeys: ['family', 'filtersOpen', 'search'],
  });

  const { family, search, filtersOpen } = filtersValues;

  useEffect(() => {
    if (search && !isDefined(family)) {
      setFilterValue('family', defaultTabOpened);
    }
  }, [search]);
  return (
    <ConnectedPage>
      <AdvancedSearchPage
        family={family ?? defaultTabOpened}
        filtersOpen={filtersOpen}
        search={search}
        setSearch={(newSearch) => setFilterValue('search', newSearch)}
        setFamily={(newFamily) =>
          setFiltersValues({ ...filtersValues, family: newFamily, page: 1 })
        }
        setFiltersOpen={(open) => setFilterValue('filtersOpen', open)}
      />
    </ConnectedPage>
  );
};

export default SearchPage;

import { useCreateBdlUserMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import notifyPromise from 'business/common/services/notifyPromise';

export const useCreateBdlUser = () => {
  const { t } = useTranslation();
  const [createBdlUserMutation] = useCreateBdlUserMutation();

  const createBdlUser = async (values: {
    firstName: string;
    lastName: string;
    role: string;
    email: string;
  }) =>
    notifyPromise(t, async () => {
      await createBdlUserMutation({
        variables: {
          ...values,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        },
      });
    });

  return {
    createBdlUser,
  };
};

import { InboxOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

import Dragger from 'antd/lib/upload/Dragger';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'technical/fileManagement/types';

import Spacer from 'ui/spacer';
import { allowedFilesTypes } from './constant';

const UploadDragger = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="files"
      rules={[{ required: true, message: t('errors.required') }]}
      getValueFromEvent={(e: UploadChangeParam<RcFile>) => e.fileList}
      valuePropName="fileList"
    >
      <Dragger
        accept={allowedFilesTypes.join(', ')}
        maxCount={10}
        multiple
        beforeUpload={() => false /** prevent automatic upload */}
      >
        <Spacer direction="vertical" size="small">
          <span>{t('pages.archive.form.uploadLabel')}</span>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
        </Spacer>
      </Dragger>
    </Form.Item>
  );
};

export default UploadDragger;

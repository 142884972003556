import { Badge, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { isDefined } from 'technical/validation';
import classes from './index.module.scss';

export const getFilterNumber = (filters?: object) =>
  Object.values(filters ?? {}).filter(isDefined).length;

interface AdvancedFiltersButtonProps {
  filterNumber?: number;
  onClickAdvancedFilter: () => void;
}

const AdvancedFiltersButton: React.VFC<AdvancedFiltersButtonProps> = ({
  filterNumber = 0,
  onClickAdvancedFilter,
}) => {
  const { t } = useTranslation();

  return (
    <Badge count={filterNumber}>
      <Button
        onClick={onClickAdvancedFilter}
        className={classes.filterButton}
        type="primary"
        data-test-id="advancedFiltersButton"
      >
        {t('pages.advancedSearch.advancedFiltersButton')}
      </Button>
    </Badge>
  );
};

export default AdvancedFiltersButton;

import { Checkbox, Form } from 'antd';
import { useState } from 'react';
import Flex from 'ui/flex';
import {
  CheckList,
  GroupSubmitFormValues,
} from 'business/groups/services/types';
import Typography from 'ui/typography';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styles from './index.module.scss';

const CheckboxOptions = ({
  card,
  text,
  initialValues,
}: {
  card: CheckList;
  text?: string;
  initialValues: string[];
}) => {
  const isIndeterminate = (checkedValues: CheckboxValueType[]) =>
    !!checkedValues.length && checkedValues.length < card.lists.length;
  const isInitiallyIndeterminate = isIndeterminate(initialValues);

  const [indeterminate, setIndeterminate] = useState(isInitiallyIndeterminate);

  const [checkAll, setCheckAll] = useState(
    !!initialValues.length && !isInitiallyIndeterminate,
  );

  return (
    <>
      <Form.Item
        shouldUpdate={(
          prev: GroupSubmitFormValues,
          curr: GroupSubmitFormValues,
        ) =>
          prev[card.checkboxFormName]?.length !==
          curr[card.checkboxFormName]?.length
        }
      >
        {({ setFieldsValue }) => {
          return (
            <Checkbox
              indeterminate={indeterminate}
              onChange={(e) => {
                setFieldsValue({
                  [card.checkboxFormName]: e.target.checked
                    ? card.lists.map((l) => l.value)
                    : [],
                });
                setIndeterminate(false);
                setCheckAll(e.target.checked);
              }}
              checked={checkAll}
            >
              <Typography.Title level={4}>{card.title}</Typography.Title>
              <span>{text}</span>
            </Checkbox>
          );
        }}
      </Form.Item>
      <Flex column>
        <Form.Item
          noStyle
          shouldUpdate={(
            prev: GroupSubmitFormValues,
            curr: GroupSubmitFormValues,
          ) =>
            prev[card.checkboxFormName]?.length !==
            curr[card.checkboxFormName]?.length
          }
        >
          {({ setFieldsValue }) => (
            <Form.Item name={card.checkboxFormName}>
              <Checkbox.Group
                className={styles.checkboxGroup}
                options={card.lists}
                onChange={(e) => {
                  setFieldsValue({ [card.title]: e });
                  setCheckAll(e.length === card.lists.length);
                  setIndeterminate(!!e.length && e.length < card.lists.length);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Flex>
    </>
  );
};

export default CheckboxOptions;

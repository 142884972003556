import {
  QueryMyUserQuery,
  useAcceptTosMutation,
  useAcceptTouMutation,
} from 'generated/graphql';
import touValidationModal from 'business/user/components/touModal';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'business/AppBootstrapper';

type TermType = 'tou' | 'tos';
export const useUserConsent = () => {
  const { user } = useAppContext();
  const { t } = useTranslation();
  const [acceptTos] = useAcceptTosMutation();
  const [acceptTou] = useAcceptTouMutation();

  const validateTerm = async (type: TermType) => {
    await (type === 'tos' ? acceptTos() : acceptTou());
  };

  const validateTos = async () => {
    await validateTerm('tos');
  };

  const getUserConsent = () => {
    return user?.consent;
  };

  const verifyTermOfUseConsent = async (
    tou?: QueryMyUserQuery['getUserConsent']['tou'],
  ) => {
    if (tou?.consent === false) {
      touValidationModal(
        tou.path,
        t('pages.home.acceptingNewTouTitle'),
        t('common.confirm'),
      );
    }
  };

  return {
    verifyTermOfUseConsent,
    validateTerm,
    getUserConsent,
    validateTos,
  };
};

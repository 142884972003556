import { useGetAllKeywordsQuery } from 'generated/graphql';
import { useMemo } from 'react';

const useGetAllKeywords = () => {
  const { data, ...rest } = useGetAllKeywordsQuery();

  const allKeywords: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          data?.archive_documentType.flatMap(({ options }) => options) ?? [],
        ),
      ),
    [data],
  );

  return {
    allKeywords,
    ...rest,
  };
};

export default useGetAllKeywords;

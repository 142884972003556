import { useEffect } from 'react';
import { useDebounce } from 'technical/hooks/useDebounce';
import SearchBar from 'ui/searchBar';

interface EventTablesActions {
  onClickFiltersButton?: () => void;
  onClickExportButton?: () => void;
  onSearch?: (value: string) => void;
  searchDefaultValue?: string;
}

const EventTablesActions: React.VFC<EventTablesActions> = ({
  onSearch,
  searchDefaultValue,
}) => {
  const { value, setValue, debouncedValue } = useDebounce({
    defaultValue: searchDefaultValue ?? '',
  });

  useEffect(() => {
    onSearch?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <SearchBar
      onChange={(e) => setValue(e.target.value)}
      defaultValue={searchDefaultValue}
      value={value}
    />
  );
};

export default EventTablesActions;

import { Parser } from 'technical/filters/types';
import * as yup from 'yup';

export const urlBooleanParser = (defaultValue: boolean): Parser<boolean> => ({
  defaultValue,
  readParse: (param) =>
    yup
      .boolean()
      .required()
      .validateSync(param ?? defaultValue),
  writeParse: (value) =>
    value === defaultValue ? undefined : value.toString(),
});

import { useState } from 'react';
import {
  BdlUserFormValues,
  BdlUserParams,
} from 'business/configuration/accessManagement/services/types';
import { useUpdateBdlUser } from 'business/configuration/accessManagement/services/useUpdateBdlUser';
import { useCreateBdlUser } from 'business/configuration/accessManagement/services/useCreateBdlUser';

const useHandleBdlUserModal = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedUser, setSelectedUser] = useState<BdlUserParams | undefined>();
  const { updateBdlUser } = useUpdateBdlUser();
  const { createBdlUser } = useCreateBdlUser();

  const openBdlUserCreationModal = () => {
    setSelectedUser(undefined);
    setModalVisibility(true);
  };

  const openBdlUserEditionModal = (bdlUserToEdit: BdlUserParams) => {
    setSelectedUser(bdlUserToEdit);
    setModalVisibility(true);
  };

  const submitBdlUser = async (bdlUserToEdit: BdlUserFormValues) => {
    await (selectedUser
      ? updateBdlUser({ ...bdlUserToEdit, id: selectedUser.id })
      : createBdlUser(bdlUserToEdit));

    setModalVisibility(false);
  };

  return {
    openBdlUserCreationModal,
    openBdlUserEditionModal,
    submitBdlUser,
    isModalVisible,
    selectedUser,
    closeModal: () => setModalVisibility(false),
  };
};

export default useHandleBdlUserModal;

import { useCreateUserMutation } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

import { UserFormValues } from 'business/configuration/accessManagement/services/types';

export const useCreateUser = () => {
  const [createUserMutation] = useCreateUserMutation();

  const createUser = async (values: UserFormValues) => {
    try {
      await createUserMutation({
        variables: {
          ...values,
          groupsIds: values.groupsIds,
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        },
      });
    } catch (err) {
      logger.error(err);
      errorReporting.error(err);
      throw err;
    }
  };

  return {
    createUser,
  };
};

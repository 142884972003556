import notifyPromise from 'business/common/services/notifyPromise';
import { useSendBackRegistrationMailMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const useSendbackRegistration = () => {
  const [sendBackRegistrationMutation] = useSendBackRegistrationMailMutation();
  const { t } = useTranslation();

  const sendBackRegistration = (userId: string, email: string) => {
    notifyPromise(
      t,
      async () => {
        await sendBackRegistrationMutation({
          variables: { userId },
        });
      },
      {
        successNotification: t(
          'pages.admin.bdlUsers.table.actions.notifications.sendEmailSuccess',
          { email },
        ),
        errorNotification: t(
          'pages.admin.bdlUsers.table.actions.notifications.sendEmailError',
          { email },
        ),
      },
    );
  };
  return {
    sendBackRegistration,
  };
};

import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Map from 'ui/map';
import Spacer from 'ui/spacer';
import { resourceExists } from 'business/common/services';
import { getDesignFamilyColumns } from 'business/resources/design/components/columns';
import Carousel from 'ui/carousel';
import Table from 'ui/table';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import styles from './index.module.scss';
import HeaderTitles from 'ui/headerTitles';
import { useGetDesignFamily } from 'business/resources/design/services/useGetDesignFamily';
import { useGetDesignFamilyMap } from 'business/resources/design/services/useGetDesignFamilyMap';
import { useGetDesignFamilyImages } from 'business/resources/design/services/useGetImages';
import { useDesignBreadcrumbs } from 'business/resources/design/services/useGetDesignBreadCrumbs';
import { useGetDesignFamilyInfos } from 'business/resources/design/services/useGetDesignFamilyInfos';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const MAX_CAROUSEL_IMAGE_COUNT = 4;

const DesignFamilyPage: React.VFC = () => {
  const { t } = useTranslation();
  const { id: familyId } = useParams<{ id: string }>();
  const { data, tableProps } = useGetDesignFamily({
    filterValues: { familyId },
  });
  const { dataMap, loading } = useGetDesignFamilyMap(familyId);
  const { images, loading: imgLoading } = useGetDesignFamilyImages(familyId);
  const { onExport, loading: onExportLoading } = useDownloadTableToXlsx();

  const { getFamilyBreadcrumbs } = useDesignBreadcrumbs();
  const designFamily = useGetDesignFamilyInfos(familyId);

  return (
    <ResourceNotFound resourceExists={resourceExists(designFamily, tableProps)}>
      <ConnectedPage
        breadcrumbs={getFamilyBreadcrumbs(designFamily)}
        translationKey="designFamily"
      >
        <HeaderTitles
          title={designFamily?.title ?? ''}
          subtitle={t('pages.header.designFamily')}
        />
        <Spacer direction="vertical" align="center">
          <Carousel
            urls={images}
            loading={imgLoading}
            slidesToShow={Math.min(images.length, MAX_CAROUSEL_IMAGE_COUNT)}
            className={styles.carousel}
          />
        </Spacer>
        <Spacer direction="vertical" size="large" align="center">
          <Table
            defaultColumns={getDesignFamilyColumns()}
            data-test-id="patentFamiliesTable"
            dataSource={data}
            loadingExport={onExportLoading}
            onExport={(columns) => {
              onExport(columns, 'design', false, { familyId });
            }}
            {...tableProps}
          />
          <Map resourceType="design" isDataLoading={loading} data={dataMap} />
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default DesignFamilyPage;

import {
  GetDesignFamiliesSearchQuery,
  GetDesignFamiliesSearchQueryVariables,
  useGetDesignFamiliesSearchQuery,
} from 'generated/graphql';
import { DesignFamily } from './types';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';
import { isDefined } from 'technical/validation';
import { useGetDesignFamiliesImages } from './useGetImages';

export const useGetDesignFamiliesData = () =>
  useQueryForTable<
    DesignFamily,
    GetDesignFamiliesSearchQuery,
    GetDesignFamiliesSearchQueryVariables
  >({
    subStorageKey: storageKeys.designFamilies,
    commonFiltersKeys: ['search', 'isEffective'],
    useQuery: ({ searchText, isEffective, ...filters }) =>
      useGetDesignFamiliesSearchQuery({
        variables: {
          text: searchText,
          where: {
            isEffective: !!isEffective,
            search: searchText,
          },
          ...filters,
        },
        fetchPolicy: 'network-only',
      }),
    getTotal: (res) => res?.designFamilySearch?.count ?? 0,
    map: (res) => res?.designFamilySearch?.resources ?? [],
  });

export const useGetDesignFamilies = () => {
  const designData = useGetDesignFamiliesData();
  const { imageList, loading: imageLoading } = useGetDesignFamiliesImages(
    designData.data.map((d) => d.id).filter(isDefined),
  );

  const designWithImages = designData.data.map((d) => {
    const image = imageList.filter((i) => i.id === d.id);
    return {
      ...d,
      image: imageLoading ? 'loading' : image[0]?.image,
    };
  });

  return {
    ...designData,
    data: designWithImages,
  };
};

import notifyPromise from 'business/common/services/notifyPromise';
import { Language } from 'business/user/types/user';
import { useUpdateLanguageMutation } from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILanguage } from 'translations';

const languageNames: Record<Language, string> = {
  en: 'English',
  'fr-FR': 'Français',
};
const useLanguageSelection = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<Language>('en');

  const languageOptions = Object.values(ILanguage).map((code) => ({
    code,
    label: languageNames[code],
  }));

  const [updateLanguageMutation] = useUpdateLanguageMutation();

  const updateLanguage = () => {
    notifyPromise(
      t,
      async () => {
        await updateLanguageMutation({
          variables: { language },
        });
        i18n.changeLanguage(language);
      },
      {
        successNotification: t(
          'pages.profile.form.accountManagement.languageUpdate.success',
        ),
      },
    );
  };

  const defaultValue = languageOptions.find((o) => o.code === i18n.language);

  return {
    defaultValue,
    updateLanguage,
    language,
    setLanguage,
    languageOptions,
  };
};

export default useLanguageSelection;

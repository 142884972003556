import CertificateTable from 'business/resources/domain/components/certificateTable';
import PrivateDnsServerTable from 'business/resources/domain/components/dnsServerTable/privateDnsServerTable';
import PublicDnsServerTable from 'business/resources/domain/components/dnsServerTable/publicDnsServerTable';
import PrivateZonesTable from 'business/resources/domain/components/zonesTable/privateZonesTable';
import PublicZonesTable from 'business/resources/domain/components/zonesTable/publicZonesTable';
import { useGetPrivateDns } from 'business/resources/domain/services/useGetPrivateDns';
import { useGetPublicDns } from 'business/resources/domain/services/useGetPublicDns';
import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import Spacer from 'ui/spacer';
import DnsDataRow from './dnsDataRow';
import DnsDataTitle from './dnsDataTitle';

interface DnsDataProps {
  showPrivateDns: boolean;
  domainId: number;
}

const DnsData = ({ showPrivateDns, domainId }: DnsDataProps) => {
  const { t } = useTranslation();

  const {
    certificate,
    publicDomainDNSDataState,
    zones: publicZones,
    servers: publicServers,
  } = useGetPublicDns(domainId);

  const {
    servers: privateServers,
    privateDomainDNSDataState,
    zones: privateZones,
  } = useGetPrivateDns({
    domainId,
    skip: !showPrivateDns,
  });

  return (
    <Spacer direction="vertical" size="small">
      <DnsDataRow
        leftComponent={
          <DnsDataTitle title={t('pages.domain.dnsPublicData.title')} />
        }
        rightComponent={
          <DnsDataTitle title={t('pages.privateDomain.dnsData.title')} />
        }
        showRightComponent={showPrivateDns}
      />
      <DnsDataRow
        leftComponent={
          <ErrorOverlay {...publicDomainDNSDataState}>
            <PublicDnsServerTable servers={publicServers} />
          </ErrorOverlay>
        }
        rightComponent={
          <ErrorOverlay {...privateDomainDNSDataState}>
            <PrivateDnsServerTable servers={privateServers} />
          </ErrorOverlay>
        }
        showRightComponent={showPrivateDns}
      />
      <DnsDataRow
        leftComponent={
          <ErrorOverlay {...publicDomainDNSDataState}>
            <PublicZonesTable zones={publicZones} />
          </ErrorOverlay>
        }
        rightComponent={
          <ErrorOverlay {...privateDomainDNSDataState}>
            <PrivateZonesTable zones={privateZones} />
          </ErrorOverlay>
        }
        showRightComponent={showPrivateDns}
      />
      {certificate && (
        <ErrorOverlay {...publicDomainDNSDataState}>
          <CertificateTable certificate={[certificate]} />
        </ErrorOverlay>
      )}
    </Spacer>
  );
};

export default DnsData;

import {
  useGetCompanyDomainInvoiceConfigurationQuery,
  useUpdateCompanyDomainInvoiceConfigurationByPkMutation,
} from 'generated/graphql';
import { useMemo } from 'react';
import { assertIsDefined } from 'technical/assert';
import { map } from 'technical/format';
import { isDefined } from 'technical/validation';
import {
  CompanyDomainFee,
  InvoiceContact,
  MoneyFields,
  moneyFields,
} from 'business/configuration/companies/services/types';

export interface InvoiceConfiguration extends CompanyDomainFee, InvoiceContact {
  automaticInvoicing: boolean;
  overrideDefaultFee: boolean;
}

const convertCents = (k: { [key in MoneyFields]: number }, fromCents = true) =>
  map<typeof moneyFields, number>(k, moneyFields, (m) =>
    fromCents ? m / 100 : m * 100,
  );

const useCompanyInvoiceConfiguration = (companyId: number) => {
  const { data, ...rest } = useGetCompanyDomainInvoiceConfigurationQuery({
    variables: { companyId: Number(companyId) },
  });

  const [updateDomainInvoiceConfiguration] =
    useUpdateCompanyDomainInvoiceConfigurationByPkMutation();

  const companyDomainFee = data?.domain_domainFee?.[0];
  const defaultFee = data?.defaultDomainFee?.[0];
  const invoiceContact = data?.domain_invoiceContact?.[0];

  const convertedDefaultFee = defaultFee ? convertCents(defaultFee) : null;

  async function onSubmit({
    automaticInvoicing,
    overrideDefaultFee,
    registerFee,
    renewFee,
    transferFee,
    holderVATnumber,
    firstName,
    lastName,
    email,
    cc,
    cci,
    SIRENnumber,
    clientReference,
  }: Partial<InvoiceConfiguration>) {
    assertIsDefined(convertedDefaultFee, {
      message:
        '[COMPANY DOMAIN INVOICE CONFIG] Unexpected: defaultFee is undefined',
    });

    const domainFee = convertCents(
      {
        registerFee: registerFee ?? 0,
        renewFee: renewFee ?? 0,
        transferFee: transferFee ?? 0,
        corporateCost: convertedDefaultFee.corporateCost,
      },
      false,
    );

    await updateDomainInvoiceConfiguration({
      variables: {
        companyId,

        invoiceContact: automaticInvoicing
          ? {
              companyId,
              firstName,
              lastName,
              email,
              cc,
              cci,
              holderVATnumber,
              SIRENnumber,
              clientReference,
            }
          : [],
        domainFee:
          automaticInvoicing && overrideDefaultFee
            ? {
                companyId,
                ...domainFee,
              }
            : [],
      },
    });
  }

  const memoizedData = useMemo(() => {
    return {
      data: {
        automaticInvoicing: isDefined(invoiceContact),
        overrideDefaultFee:
          isDefined(invoiceContact) && isDefined(companyDomainFee),
        ...invoiceContact,
        ...convertedDefaultFee,
        ...(companyDomainFee ? convertCents(companyDomainFee) : {}),
      },
      defaultFee: convertedDefaultFee,
    };
  }, [data]);

  return {
    ...memoizedData,
    onSubmit,
    ...rest,
  };
};

export default useCompanyInvoiceConfiguration;

import { useApolloClient } from '@apollo/client';
import authService from 'business/user/services/authService';
import fetchUser from 'business/user/services/user';
import { UserWithConsent } from 'business/user/types/user';
import { useCallback, useEffect, useState } from 'react';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { i18n } from 'translations';
import { useUserConsent } from './useUserConsent';

function useUserData() {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<UserWithConsent | undefined>(undefined);
  const client = useApolloClient();
  const { verifyTermOfUseConsent } = useUserConsent();

  // @todo use subscription for user data when below error is handled
  // (cf this commit to "revert" code)
  // BEWARE: on invalid JWT, subscriptions are automatically disconnected and not re-subscribed
  // Error: cannot start as connection_init failed with : Malformed Authorization header

  const fetchAndStoreUser = useCallback(
    async function refetchUser() {
      const targetUser = await fetchUser(client);
      setUser(targetUser);
      verifyTermOfUseConsent(targetUser?.consent.tou);
    },
    [client],
  );

  /**
   * Fonction to be called if needed typcially when authentication status changes
   * (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    async function requestRebootstrap() {
      const authResult = authService.getAuthResult();
      if (authResult) {
        setUser(authResult);
        verifyTermOfUseConsent(authResult?.consent.tou);
        setIsBootstraped(true);
        return;
      }

      try {
        await authService.initAuthentication();
        if (!authService.isAuthenticated()) {
          return;
        }
        await fetchAndStoreUser();
      } catch (error) {
        if (error instanceof Error) {
          errorReporting.warning(error);
        }
        logger.error(error);
        throw error;
      } finally {
        setIsBootstraped(true);
      }
    },
    [fetchAndStoreUser],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  // user sync with providers
  useEffect(() => {
    if (user) {
      errorReporting.setUser({
        id: user.id,
        email: user.privateInfos.email,
      });
      i18n.changeLanguage(user.language);
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user,
    isConnected: !!user,
    isBootstraped,
    requestRebootstrap,
  };
}

export default useUserData;

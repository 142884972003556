export type MailProps = {
  to: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
};

export const formatMailTo = ({ to, ...params }: MailProps) => {
  const queryString = Object.keys(params)
    .filter((key) => params[key as keyof Omit<MailProps, 'to'>])
    .map(
      (key) =>
        `${key}=${encodeURIComponent(
          params[key as keyof Omit<MailProps, 'to'>] as string,
        )}`,
    )
    .join('&');

  return `mailto:${to}?${queryString}`;
};

import {
  isResourceNotEffective,
  resourcesToMapData,
} from 'business/common/services';
import { PatentMap } from 'business/resources/patent/types';
import { useGetPatentFamilyMapQuery } from 'generated/graphql';
import { isDefined } from 'technical/validation';
import { MapStatus } from 'ui/map/types';
import { isAnnuityNotHandledByBdl } from './isAnnuityNotHandledByBdl';

const patentTestsToMapStatus: {
  cond: (patent: PatentMap) => boolean;
  status: MapStatus;
}[] = [
  { cond: isAnnuityNotHandledByBdl, status: MapStatus.MandatedOut },
  { cond: isResourceNotEffective, status: MapStatus.NotEffective },
  {
    cond: ({ issueNumber }) => isDefined(issueNumber),
    status: MapStatus.Valid,
  },
  {
    cond: ({ publicationDate }) => isDefined(publicationDate),
    status: MapStatus.Published,
  },
  {
    cond: ({ depositDate }) => isDefined(depositDate),
    status: MapStatus.Deposited,
  },
];

export const patentMapToMapStatus = (patent: PatentMap): MapStatus => {
  for (let i = 0; i < patentTestsToMapStatus.length; i += 1) {
    const { cond, status } = patentTestsToMapStatus[i];
    if (cond(patent)) {
      return status;
    }
  }
  return MapStatus.PlannedDeposit;
};

export const useGetPatentFamilyMap = (familyId: string) => {
  const { data, loading } = useGetPatentFamilyMapQuery({
    variables: { id: familyId },
  });

  return {
    dataMap: resourcesToMapData(data?.patent, patentMapToMapStatus),
    loading,
  };
};

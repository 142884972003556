import { useGetActiveArchiveSubscriptionByCompanyIdQuery } from 'generated/graphql';

const useGetCompanyArchiveSubscription = (companyId: number) => {
  // Fetch company archive subscription
  const {
    data: companySub,
    refetch,
    loading,
    error,
  } = useGetActiveArchiveSubscriptionByCompanyIdQuery({
    variables: {
      companyId: Number(companyId),
    },
    fetchPolicy: 'network-only',
  });

  return {
    refetch,
    companyArchiveSubscription: companySub?.archive_archivePackageCompany,
    error,
    loading,
  };
};

export default useGetCompanyArchiveSubscription;

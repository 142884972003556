import { Card, Col, Row, TabsProps } from 'antd';
import { Resource } from 'business/resources/shared/types';
import { Panel } from 'ui/description/types';
import Spacer from 'ui/spacer';
import styles from './index.module.scss';
import ResourcePanelTabs from './tabs';

interface ResourcePanelProps<T> {
  description: {
    panels: Panel<T>;
    data: T | null;
  };
  resource: Resource;
  resourceId?: number;
  image?: React.ReactNode;
  additionalActions?: React.ReactNode;
  additionalTabs?: TabsProps['items'];
}

const ResourcePanel = <T,>({
  description,
  resource,
  resourceId,
  image,
  additionalActions,
  additionalTabs,
}: ResourcePanelProps<T>) => {
  return (
    <Row className="detailResourceContainer" gutter={[16, 16]} justify="center">
      <Col md={12} lg={12} xl={8} xxl={7}>
        <Spacer direction="vertical" size="small">
          <div className={styles.imageContainer}>{image}</div>
          <div>{additionalActions}</div>
        </Spacer>
      </Col>
      <Col lg={24} xl={16} xxl={17}>
        <Card className={styles.tabsContainer}>
          <ResourcePanelTabs
            description={description}
            resource={resource}
            resourceId={resourceId}
            additionalTabs={additionalTabs}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ResourcePanel;

import UserGroupModal from 'business/configuration/accessManagement/components/modals/userGroups.modal';
import UserStatusFilter from 'business/admin/components/userStatusFilter';
import useGetUsers from 'business/configuration/accessManagement/services/useGetUsers';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import UsersTable from 'business/configuration/accessManagement/components/tables/usersTable';
import { User } from 'business/configuration/accessManagement/services/types';

const UsersPage: FC = () => {
  const { t } = useTranslation();
  const {
    data,
    tableProps,
    filters: { filtersValues, setFilterValue },
  } = useGetUsers();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('pages.admin.users.title') }]}
      translationKey="clientUsers"
    >
      <Flex column>
        <h1 className="headerTitle">{t('pages.admin.users.title')}</h1>
      </Flex>

      <UsersTable
        data-test-id="usersTable"
        dataSource={data}
        editUser={(user) => {
          setEditingUser(user);
          setEditModalIsOpen(true);
        }}
        customAction={
          <UserStatusFilter
            status={filtersValues.userStatus}
            setStatusFilter={(value) => setFilterValue('userStatus', value)}
          />
        }
        {...tableProps}
      />
      {editingUser && (
        <UserGroupModal
          visible={editModalIsOpen}
          handleClose={() => {
            setEditModalIsOpen(false);
            setEditingUser(null);
            tableProps.refetch?.();
          }}
          user={editingUser}
        />
      )}
    </ConnectedPage>
  );
};

export default UsersPage;

import { addYears, endOfDay, endOfYear, startOfYear } from 'date-fns';
import Button from 'ui/button';
import { RangePicker } from 'ui/date/datePicker';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';
import { DESIGN_BUDGET_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import Table from 'ui/table';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { budgetDesignColor } from 'business/resources/osr/components/common/osrRowColors';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import TagResults from 'ui/tag/tagResults';
import { IFiltersActions } from 'business/resources/osr/services/useOsrFilters';
import { Checkbox, Radio } from 'antd';

interface IBudgetTracking {
  filters: OsrFilterState;
  actions: IFiltersActions;
}

const BudgetTracking = (props: IBudgetTracking) => {
  const { t } = useTranslation();
  const [rangeDate, setRangeDate] = useState<{
    gte: Date | null;
    lte: Date | null;
  }>({
    gte: startOfYear(addYears(new Date(), 1)),
    lte: endOfYear(addYears(new Date(), 1)),
  });

  const { generateExcel, loading } = useGenerateExcelFile('design', {
    ...props.filters,
    eventStatus: props.filters.eventStatus,
    dueDate: rangeDate,
  });

  const { data, tableProps } = useGetDesignOsrData(
    {
      ...props.filters,
      eventStatus: props.filters.eventStatus,
      dueDate: rangeDate,
    },
    'BUDGET',
  );
  return (
    <Spacer direction="vertical" size="small">
      <Flex alignItems="center" justify="space-between">
        <Flex alignItems="center" justify="space-between">
          <RangePicker
            allowClear
            inputReadOnly={true}
            defaultValue={[
              startOfYear(addYears(new Date(), 1)),
              endOfYear(addYears(new Date(), 1)),
            ]}
            onChange={(e) => {
              setRangeDate({
                gte: e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                lte: e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
              });
            }}
          />
        </Flex>

        <Flex>
          <Checkbox.Group
            options={[
              { label: 'Simulé', value: 'SIMULE' },
              { label: 'En cours', value: 'ENCOUR' },
              { label: 'Traité', value: 'TRAITE' },
            ]}
            defaultValue={['SIMULE', 'ENCOUR', 'TRAITE']}
            onChange={props.actions.onChangeEventStatus}
          ></Checkbox.Group>
        </Flex>

        <Flex>
          <Radio.Group
            defaultValue={'all'}
            onChange={(e) => props.actions.onChangeOffice(e.target.value)}
          >
            <Radio value={'all'}>
              {t('pages.osr.filters.office', {
                context: 'all',
              })}
            </Radio>
            <Radio value={'Europe'}>
              {t('pages.osr.filters.office', {
                context: 'europe',
              })}
            </Radio>
            <Radio value={'France'}>
              {t('pages.osr.filters.office', {
                context: 'france',
              })}
            </Radio>
            <Radio value={'InterDetail'}>
              {t('pages.osr.filters.office', {
                context: 'international',
              })}
            </Radio>
            <Radio value={'Others'}>
              {t('pages.osr.filters.office', {
                context: 'others',
              })}
            </Radio>
          </Radio.Group>
        </Flex>
      </Flex>
      <Flex>
        <Button
          loading={loading}
          type="primary"
          onClick={() => generateExcel('BUDGET')}
        >
          Exporter le budget
        </Button>
      </Flex>

      <Table
        {...tableProps}
        dataSource={data}
        header={
          <TagResults
            total={tableProps.pagination?.total ?? 0}
            dataTestId="nbBudget"
          />
        }
        handleTableRowColor={budgetDesignColor}
        defaultColumns={DESIGN_BUDGET_COLUMNS(t)}
        name="budget"
      />
    </Spacer>
  );
};

export default BudgetTracking;

import { Typography } from 'antd';
import ArchiveConfigurationTable from 'business/archive/components/archiveConfigurationTable';
import { Archive } from 'business/archive/services/types';
import { useHandleSubCancellation } from 'business/configuration/companies/services/useHandleSubCancellation';
import { CompanyArchiveSubscription } from 'business/configuration/companies/services/types';
import { useTranslation } from 'react-i18next';
import { AllTableFilter } from 'technical/filters/types';
import logger from 'technical/logger';
import RolesChecker from 'technical/rolesChecker';
import Button from 'ui/button';

import Flex from 'ui/flex';
import confirmationModal from 'ui/modalConfirm';
import Spacer from 'ui/spacer';

interface SubscriptionInfoProps {
  companySubInfo: CompanyArchiveSubscription;
  refetch: () => void;
}

const CompanySubscriptionInfo = ({
  companySubInfo,
  refetch,
}: SubscriptionInfoProps) => {
  const { t } = useTranslation();
  const { deleteSubscription, cancelSubscription, subscriptionEndMessage } =
    useHandleSubCancellation(companySubInfo[0]); // Send first element because hasura always return an array

  const openConfirmationModal = (
    action: () => Promise<void>,
    context: 'deleteSubscription' | 'cancelSubscription',
  ) => {
    confirmationModal(
      t,
      () =>
        action()
          .then(refetch)
          .catch((error) => {
            logger.error(error);
          }),
      t('archive.config.confirmationModalTitle', {
        context,
      }),
    );
  };

  return (
    <Spacer direction="vertical">
      <ArchiveConfigurationTable
        filtersValues={{} as AllTableFilter<Archive>}
        setFilterValue={() => {}}
        editButtonEnabled={false}
        resetButtonEnabled={false}
        searchBarEnabled={false}
        dataSource={companySubInfo.map((c) => c.archivePackage)}
      />
      <Flex alignItems="center">
        <Spacer>
          <RolesChecker roles={['bdl-admin']}>
            <Button
              onClick={() =>
                openConfirmationModal(deleteSubscription, 'deleteSubscription')
              }
              type="primary"
              danger
            >
              {t('archive.config.button', {
                context: 'deleteSubscription',
              })}
            </Button>
          </RolesChecker>

          <Flex alignItems="center">
            {subscriptionEndMessage ? (
              <Typography.Title level={5}>
                {subscriptionEndMessage}
              </Typography.Title>
            ) : (
              <RolesChecker roles={['bdl-admin']}>
                <Button
                  onClick={() =>
                    openConfirmationModal(
                      cancelSubscription,
                      'cancelSubscription',
                    )
                  }
                  type="primary"
                >
                  {t('archive.config.button', {
                    context: 'cancelSubscription',
                  })}
                </Button>
              </RolesChecker>
            )}
          </Flex>
        </Spacer>
      </Flex>
    </Spacer>
  );
};

export default CompanySubscriptionInfo;

import { GetDesignQuery } from 'generated/graphql';
import { TFunction } from 'i18next';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';

export const getDesignTimeline = (
  t: TFunction,
  design: GetDesignQuery['design_by_pk'],
) => {
  const nextRegistration = design?.events ?? [];
  const timelineEvents: TimelineEvent[] = [
    {
      title: t('table.common.column.deposit'),
      date: design?.depositDate,
      reference: design?.depositNumber,
    },
    {
      title: t('table.common.column.registration'),
      date: design?.registrationDate,
      reference: design?.registrationNumber,
    },
    ...nextRegistration.map((n) => ({
      title: t('table.common.column.nextAnnuity', { context: 'design' }),
      date: n.dueDate,
    })),
  ];
  return createTimelineEvents(timelineEvents);
};

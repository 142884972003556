import {
  GetAllOpsDocumentsQuery,
  GetBdlUsersQuery,
  GetCompaniesQuery,
  GetDomainInvoicesQuery,
  GetLogsQuery,
} from 'generated/graphql';

export enum BdlUserRole {
  BDL_ADMIN = 'bdl-admin',
  BDL_USER = 'bdl-user',
}

export type BdlUser = Omit<
  NonNullable<GetBdlUsersQuery['user'][number]>,
  'identity'
>;

export type BdlUserData = Pick<
  BdlUser,
  'email' | 'firstName' | 'lastName' | 'role'
>;

type Logs = Omit<NonNullable<GetLogsQuery['logs'][number]>, '__typename'>;
export type Log = Pick<Logs, 'id' | 'action' | 'date' | 'details'> &
  Pick<
    NonNullable<NonNullable<Logs['identity']>['user']>,
    'firstName' | 'email'
  >;

export type Companies = NonNullable<GetCompaniesQuery['companyMV']>[number];

type RawDomainInvoice = GetDomainInvoicesQuery['domain_invoice'][number];

export type DomainInvoice = Omit<RawDomainInvoice, 'pdf'>;

export type OpsDocument = NonNullable<
  GetAllOpsDocumentsQuery['patentDocumentSearch']
>['resources'][number];

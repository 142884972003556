import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Archive } from 'business/archive/services/types';
import notifyPromise from 'business/common/services/notifyPromise';
import {
  GetActiveArchivePackageDocument,
  useDeleteArchivePackageMutation,
  useUpdateArchivePackageActiveMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import confirmationModal from 'ui/modalConfirm';
import Spacer from 'ui/spacer';

interface ArchiveConfigurationActionsColumnProps {
  archive: Archive;
  refetch?: () => void;
}
const ArchiveConfigurationActionsColumn = ({
  archive,
  refetch,
}: ArchiveConfigurationActionsColumnProps) => {
  const { t } = useTranslation();
  const [deleteArchivePackageMutation] = useDeleteArchivePackageMutation();
  const [updateArchivePackageActiveMutation] =
    useUpdateArchivePackageActiveMutation();

  const deleteArchivePackage = () => {
    notifyPromise(
      t,
      () =>
        deleteArchivePackageMutation({
          variables: {
            id: archive.id,
          },
          refetchQueries: [{ query: GetActiveArchivePackageDocument }],
        }),
      {
        refetch,
        successNotification: t(
          'pages.archive.notifications.deleteArchivePackageSuccess',
          {
            archiveName: archive.title,
          },
        ),
      },
    );
  };

  const updateArchivePackageActive = () => {
    notifyPromise(
      t,
      () =>
        updateArchivePackageActiveMutation({
          variables: {
            id: archive.id,
            active: !archive.active,
          },
          refetchQueries: [{ query: GetActiveArchivePackageDocument }],
        }),
      {
        refetch,
        successNotification: t(
          'pages.archive.notifications.updateArchivePackageVisibilitySuccess',
          {
            archiveName: archive.title,
          },
        ),
      },
    );
  };

  const confirmDeleteArchivePackage = () => {
    confirmationModal(
      t,
      () => deleteArchivePackage(),
      t('pages.archive.notifications.deleteArchivePackageConfirmation', {
        archiveName: archive.title,
      }),
    );
  };

  const confirmUpdateArchivePackageActive = () => {
    confirmationModal(
      t,
      () => updateArchivePackageActive(),
      t(
        'pages.archive.notifications.updateArchivePackageVisibilityConfirmation',
        {
          archiveName: archive.title,
        },
      ),
    );
  };

  return archive.isUsedByCompanies ? null : (
    <Spacer size="xsmall">
      <Tooltip
        title={
          archive.active
            ? t('common.deactivate')
            : t('table.common.column.activePackage')
        }
      >
        <Button
          type="primary"
          size="middle"
          onClick={confirmUpdateArchivePackageActive}
          icon={archive.active ? <CloseOutlined /> : <CheckOutlined />}
        />
      </Tooltip>
      <Tooltip title={t('common.delete')}>
        <Button
          type="primary"
          size="middle"
          onClick={confirmDeleteArchivePackage}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
    </Spacer>
  );
};

export default ArchiveConfigurationActionsColumn;

import { UserWithPrivateInfos } from 'business/user/types/user';
import { useGetArchiveDataQuery } from 'generated/graphql';
import { formatBytes } from 'technical/fileManagement';

export const hasUserArchiveAccess = (user: UserWithPrivateInfos) => {
  return user.privateInfos.archivePageEnabled;
};

export const useGetArchiveSubscriptionInformation = () => {
  const { data, loading, error } = useGetArchiveDataQuery();

  return {
    total:
      data?.archive_archiveDocumentMetadata_aggregate.aggregate?.count ?? 0,
    nbDeletions: data?.archive_deletionRequest_aggregate?.aggregate?.count ?? 0,
    volume: formatBytes(
      data?.archive_archiveDocumentMetadata_aggregate.aggregate?.sum
        ?.fileSize ?? 0,
    ),
    loading,
    error,
  };
};

import { Image, Tooltip } from 'antd';
import { TrademarkFamilies } from 'business/resources/trademark/services/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import { ellipsisStyle } from 'ui/table/columns';
import {
  FAMILIES_TABLE_IMAGE_CELL_WIDTH,
  FAMILIES_TABLE_IMAGE_STYLE,
} from 'ui/imageDisplay/constants';

import {
  getCountriesColumn,
  getCountriesKey,
} from 'technical/countriesTranslation/constants';
import Loader from 'ui/loader';

type TableTrademarkFamiliesProps = Omit<
  TableProps<TrademarkFamilies>,
  'defaultColumns'
> & {
  displayHeader?: boolean;
};

const defaultColumns: ColumnGenerationData<TrademarkFamilies> = [
  { key: 'holder', ellipsis: true },
  {
    key: 'title',
    ellipsis: true,
    alwaysVisible: true,
    translationKey: 'title_trademark',
    render: (_: any, record: TrademarkFamilies, index) => (
      <Link
        className="titleRedirect"
        data-test-id={`titleRedirect-${index}`}
        to={`/trademark/family/${record.id}`}
      >
        {record.title}
      </Link>
    ),
  },
  {
    key: 'image',
    translationKey: 'image',
    sorter: false,
    width: FAMILIES_TABLE_IMAGE_CELL_WIDTH,
    render: (_: any, { image }) => {
      if (image === 'loading') {
        return <Loader />;
      }
      if (image) {
        return <Image src={image} style={{ ...FAMILIES_TABLE_IMAGE_STYLE }} />;
      }
    },
  },
  {
    ...getCountriesColumn(),
    onCell: () => ellipsisStyle,
    ellipsis: true,
    render: (_: any, record: TrademarkFamilies) => (
      <Tooltip title={record[getCountriesKey()]}>
        {record[getCountriesKey()]}
      </Tooltip>
    ),
  },
  { key: 'firstDepositNumber', ellipsis: true },
  { key: 'firstDepositDate', ellipsis: true },
  { key: 'registrationNumber', checked: false, ellipsis: true },
  { key: 'lastRegistrationDate', checked: false, ellipsis: true },
  { key: 'clientRef', checked: false, ellipsis: true },
  { key: 'bdlRef', checked: false, ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
  { key: 'bdlOfficerName', ellipsis: true },
];

const TrademarkFamiliesTable: React.FC<TableTrademarkFamiliesProps> = (
  props: TableTrademarkFamiliesProps,
) => {
  const { t } = useTranslation();

  return (
    <Table
      id="trademarkFamiliesTable"
      {...props}
      family="trademark"
      showEffectiveCheckbox
      defaultColumns={defaultColumns}
      header={
        props.displayHeader ? (
          <h1 className="headerTitle">{t('pages.header.trademarkFamilies')}</h1>
        ) : null
      }
    />
  );
};

export default TrademarkFamiliesTable;

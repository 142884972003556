import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BdlUserModal from 'business/configuration/accessManagement/components/modals/bdlUser.modal';
import UserStatusFilter from 'business/admin/components/userStatusFilter';
import useGetBdlUsers from 'business/admin/hooks/useGetBdlUsers';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import BdlUsersTable from 'business/configuration/accessManagement/components/tables/bdlUsersTable';
import { BdlUserFormValues } from 'business/configuration/accessManagement/services/types';
import useHandleBdlUserModal from 'business/configuration/accessManagement/services/useHandleBdlUserModal';

const BdlUsersPage: React.VFC = () => {
  const { t } = useTranslation();
  const {
    data,
    tableProps,
    filters: {
      filtersValues: { userStatus },
      setFilterValue,
    },
  } = useGetBdlUsers();
  const {
    isModalVisible,
    selectedUser,
    openBdlUserCreationModal,
    openBdlUserEditionModal,
    submitBdlUser,
    closeModal,
  } = useHandleBdlUserModal();

  const onSubmitBdlUser = async (bdlUserToEdit: BdlUserFormValues) => {
    await submitBdlUser(bdlUserToEdit);
    tableProps.refetch();
  };

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('pages.admin.bdlUsers.title') }]}
      translationKey="bdlUsers"
    >
      <Flex column alignItems="flex-start">
        <h1 className="headerTitle">{t('pages.admin.bdlUsers.title')}</h1>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={openBdlUserCreationModal}
          type="link"
          data-test-id="bdl-users-creation-modal-button"
        >
          {t('pages.admin.bdlUsers.creationModal.title')}
        </Button>
      </Flex>

      <BdlUsersTable
        data-test-id="bdlUsersTable"
        dataSource={data}
        editUser={openBdlUserEditionModal}
        customAction={
          <UserStatusFilter
            status={userStatus}
            setStatusFilter={(value) => setFilterValue('userStatus', value)}
          />
        }
        {...tableProps}
      />
      <BdlUserModal
        editedUser={selectedUser}
        visible={isModalVisible}
        onClose={closeModal}
        onSubmit={onSubmitBdlUser}
      />
    </ConnectedPage>
  );
};

export default BdlUsersPage;

import appLogo from 'config/homeLogo.png';

interface HomeLogoProps {
  height?: string;
  width?: string;
  className?: any;
}

const HomeLogo = ({ height, width, className }: HomeLogoProps) => {
  return (
    <img
      className={className}
      style={{ height, width }}
      src={appLogo}
      alt="Home logo"
    />
  );
};

export default HomeLogo;

import Flex from 'ui/flex';
import { Spin } from 'antd';

interface Props {
  label?: string;
}

const Loader = ({ label }: Props) => {
  return (
    <Flex column justify="center">
      <Spin />
      <h1>{label}</h1>
    </Flex>
  );
};

export default Loader;

import Button from 'ui/button';
import Spacer from 'ui/spacer';
import Flex from 'ui/flex';
import OsrColorDescription from 'business/resources/osr/components/common/osrColorDescription';
import Table from 'ui/table';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';
import { DESIGN_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { handleToRenewDesignColor } from 'business/resources/osr/components/common/osrRowColors';
import { Checkbox, Radio } from 'antd';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import { RangePicker } from 'ui/date/datePicker';
import { useTranslation } from 'react-i18next';
import { IFiltersActions } from 'business/resources/osr/services/useOsrFilters';
import TagResults from 'ui/tag/tagResults';

interface IRenewalTracking {
  filters: OsrFilterState;
  actions: IFiltersActions;
}

const RenewalTracking = (props: IRenewalTracking) => {
  const { t } = useTranslation();

  const { data: renewedData, tableProps: renewedTableProps } =
    useGetDesignOsrData(
      { ...props.filters, eventStatus: props.filters.eventStatus },
      'RENEW',
    );

  const { generateExcel, loading } = useGenerateExcelFile(
    'design',
    props.filters,
  );

  return (
    <Spacer direction="vertical" size="small">
      <Flex alignItems="center" justify="space-between">
        <Flex column style={{ width: '33%' }}>
          <RangePicker
            allowClear
            inputReadOnly={true}
            defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
            onChange={(e) => {
              props.actions.onChangeRangeDate(
                e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
              );
            }}
          />
        </Flex>

        <Flex>
          <Checkbox.Group
            options={[
              { label: 'À renouveler', value: 'ENCOUR' },
              { label: 'Renouvelé', value: 'TRAITE' },
            ]}
            defaultValue={['ENCOUR', 'TRAITE']}
            onChange={props.actions.onChangeEventStatus}
          ></Checkbox.Group>
        </Flex>

        <Flex>
          <Radio.Group
            defaultValue={'all'}
            onChange={(e) => props.actions.onChangeOffice(e.target.value)}
          >
            <Radio value={'all'}>
              {t('pages.osr.filters.office', {
                context: 'all',
              })}
            </Radio>
            <Radio value={'Europe'}>
              {t('pages.osr.filters.office', {
                context: 'europe',
              })}
            </Radio>
            <Radio value={'France'}>
              {t('pages.osr.filters.office', {
                context: 'france',
              })}
            </Radio>
            <Radio value={'InterDetail'}>
              {t('pages.osr.filters.office', {
                context: 'international',
              })}
            </Radio>
            <Radio value={'Others'}>
              {t('pages.osr.filters.office', {
                context: 'others',
              })}
            </Radio>
          </Radio.Group>
        </Flex>
      </Flex>

      <Flex>
        <Button
          loading={loading}
          type="primary"
          onClick={() => generateExcel('RENEWS')}
        >
          Exporter les Renouvellements
        </Button>
      </Flex>

      <Spacer direction="vertical" size="small">
        <Flex justify="flex-end">
          <OsrColorDescription
            colors={[
              {
                color: '#92bbf9',
                label: 'Traité',
              },
              {
                color: '#90EE90',
                label: 'Renouv. Possible',
              },
              {
                color: '#FFA502',
                label: 'Délai de grace',
              },
              {
                color: '#f27979',
                label: 'Hors délai',
              },
            ]}
          />
        </Flex>
        <Table
          name="designRenewed"
          confirmationReceipt={{
            value: props.filters.ar ?? false,
            onChange: (e: boolean) =>
              props.actions.onChangeConfirmationReceipt(e),
          }}
          header={
            <TagResults
              total={renewedTableProps.pagination?.total ?? 0}
              dataTestId="nbBudget"
            />
          }
          defaultColumns={DESIGN_RENEW_COLUMNS(t)}
          dataSource={renewedData}
          handleTableRowColor={handleToRenewDesignColor}
          {...renewedTableProps}
        />
      </Spacer>
    </Spacer>
  );
};

export default RenewalTracking;

import { generatePathByAssetType } from 'business/common/services';
import { ResourceType } from 'business/common/services/types';
import { AllEvents } from 'business/resources/events/services/types';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { ColumnGenerationData } from 'ui/table/types';

const getAllEventColumns = (t: TFunction): ColumnGenerationData<AllEvents> => [
  {
    key: 'asset',
    render: (_, record: AllEvents) => {
      return t('table.common.column.asset', { context: record.asset });
    },
  },
  {
    key: 'type',
    translationKey: 'eventType',
    alwaysVisible: true,
    ellipsis: true,
  },
  {
    key: 'holder',
    checked: false,
  },
  {
    key: 'effectiveHolder',
    checked: false,
    ellipsis: true,
  },
  {
    key: 'title',
    render: (_, record: AllEvents) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType(
          record.asset as ResourceType,
          record.assetId,
        )}
      >
        {record.title}
      </Link>
    ),
  },
  {
    key: 'dueDate',
    alwaysVisible: true,
    ellipsis: true,
  },
  {
    key: 'depositNumber',
    checked: false,
    ellipsis: true,
  },
  {
    key: 'depositDate',
    checked: false,
  },

  {
    key: 'bdlRef',
    checked: false,
    ellipsis: true,
  },
  {
    key: 'misRef',
    checked: false,
    ellipsis: true,
  },
  {
    key: 'clientRef',
    checked: false,
  },
  {
    key: 'bdlOfficer',
  },
];

export default getAllEventColumns;

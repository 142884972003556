import { Event } from 'business/resources/events/services/types';
import {
  GetDesignEventsQuery,
  GetDesignEventsQueryVariables,
  Order_By,
  useGetDesignEventsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetDesignEvents = (designId: number) =>
  useQueryForTable<Event, GetDesignEventsQuery, GetDesignEventsQueryVariables>(
    {
      subStorageKey: storageKeys.designEvents,
      useQuery: ({ limit, offset, orderBy }) =>
        useGetDesignEventsQuery({
          variables: { limit, offset, designId, orderBy },
        }),
      getTotal: (res) => res?.event_designEvent_aggregate.aggregate?.count ?? 0,
      map: (res) => res?.event_designEvent ?? [],
    },
    {
      orderBy: {
        dueDate: Order_By.Asc,
      },
    },
  );

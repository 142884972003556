import {
  Archive_CompanyArchiveSubscription_Bool_Exp,
  GetCompanyArchiveSubscriptionsQuery,
  GetCompanyArchiveSubscriptionsQueryVariables,
  useGetCompanyArchiveSubscriptionsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { CompanyArchiveSubscription } from 'business/archive/services/types';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (
  searchText: string,
): Archive_CompanyArchiveSubscription_Bool_Exp => ({
  _or: [
    ...generateTextSearchConditions(searchText, [
      'companyRef',
      'holder',
      'subscriptionTitle',
    ]),
  ],
});

const useCompanyArchiveSubscriptions = () =>
  useQueryForTable<
    CompanyArchiveSubscription,
    GetCompanyArchiveSubscriptionsQuery,
    GetCompanyArchiveSubscriptionsQueryVariables
  >({
    subStorageKey: storageKeys.companyArchiveSubscription,
    commonFiltersKeys: ['search'],
    useQuery: ({ limit, offset, orderBy, searchText }) =>
      useGetCompanyArchiveSubscriptionsQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: searchText ? generateSearch(searchText) : {},
        },
      }),
    map: (res) => res?.archive_companyArchiveSubscription ?? [],
    getTotal: (res) =>
      res?.archive_companyArchiveSubscription_aggregate.aggregate?.count ?? 0,
  });

export default useCompanyArchiveSubscriptions;

import { Form, Input, InputProps } from 'antd';
import { FilterField } from '..';

type StringFieldFilterOptions = {
  fieldProps?: InputProps;
};

const StringFieldFilter: FilterField<StringFieldFilterOptions> = {
  component: ({ fieldProps, ...formItemProps }) => (
    <Form.Item {...formItemProps}>
      <Input {...fieldProps} placeholder={formItemProps.label} />
    </Form.Item>
  ),
};

export default StringFieldFilter;

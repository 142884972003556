import UserStatusFilter from 'business/admin/components/userStatusFilter';
import useCreateUserForm from 'business/configuration/accessManagement/components/forms/hooks/useCreateUserForm';
import { UserModal } from 'business/configuration/accessManagement/components/modals/user.modal';
import UserGroupsTable from 'business/configuration/accessManagement/components/tables/userGroupsTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfigurationLinks from 'ui/configurationLinks';
import Spacer from 'ui/spacer';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import getConfigurationLinks from 'business/configuration/accessManagement/services/configurationLinks';
import CompanyResourceStatistic from 'business/configuration/companies/components/companyResourceStatistic';
import CompanyDescription from 'business/configuration/companies/components/companyDescription';
import Flex from 'ui/flex';
import useGetUserGroups from 'business/groups/hooks/useGetUserGroups';
import { CompanyDetail } from './types';
import { getResourcesStatistic } from './constants';

interface CompanyDetailsProps {
  company: CompanyDetail;
}

const CompanyDetails = ({ company }: CompanyDetailsProps) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();

  const [userCreationModalVisible, setUserCreationModalVisible] =
    useState(false);

  const {
    data: userTableData,
    tableProps: userTableProps,
    filters: { setFilterValue, filtersValues },
  } = useGetUserGroups(company.id);

  const closeModal = () => {
    setUserCreationModalVisible(false);
    userTableProps.refetch();
  };

  const { onSubmit, form } = useCreateUserForm({
    closeModal,
    refetch: userTableProps.refetch,
  });

  return (
    <>
      <Spacer size="middle" direction="vertical">
        <Flex alignItems="center">
          <CompanyDescription
            address={company.fullAddress ?? ''}
            companyRef={company?.companyRef}
            name={company?.name}
          />
          <CompanyResourceStatistic
            resources={getResourcesStatistic(company, t)}
          />
        </Flex>
        <ConfigurationLinks
          onClickButtonText={t('configLink.addUser')}
          links={getConfigurationLinks(t, user, company.id)}
          onClick={() => setUserCreationModalVisible(true)}
        />
        <UserGroupsTable
          data-test-id="userGroupsTable"
          dataSource={userTableData}
          companyId={company.id}
          groups={
            company?.groups.map(({ name, id }) => ({
              label: name,
              value: id,
            })) ?? []
          }
          customAction={
            <UserStatusFilter
              status={filtersValues.userStatus}
              setStatusFilter={(value) => setFilterValue('userStatus', value)}
            />
          }
          {...userTableProps}
        />
      </Spacer>
      {/* User info modal display */}
      <UserModal
        onSubmit={onSubmit}
        form={form}
        visible={userCreationModalVisible}
        handleClose={closeModal}
        companyId={company.id}
        groupOptions={
          company?.groups.map(({ name, id }) => ({
            label: name,
            value: id,
          })) ?? []
        }
      />
    </>
  );
};

export default CompanyDetails;

import { useGetTrademarkQuery } from 'generated/graphql';
import { getTrademarkTimelineEvents } from './getTrademarkTimeline';
import { useTranslation } from 'react-i18next';

export const useGetTrademark = (id: number) => {
  const { t } = useTranslation();
  const { data, ...trademarkDataState } = useGetTrademarkQuery({
    variables: { id },
  });

  const trademark = data?.trademark_by_pk ?? null;

  return {
    trademark,
    trademarkDataState,
    timeline: getTrademarkTimelineEvents(t, trademark),
  };
};

import { Modal } from 'antd';

import notifyPromise from 'business/common/services/notifyPromise';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import useGetDefaultKeywords from 'business/configuration/keywords/services/useGetDefaultKeywords';
import useUpdateDefaultKeywords from 'business/configuration/keywords/services/useUpdateDefaultKeywords';
import KeywordsListEdition from 'business/configuration/keywords/components/listEdition';

interface KeywordsModalEditionProps {
  visible: boolean;
  onClose: () => void;
}

const KeywordsModalEdition = ({
  visible,
  onClose,
}: KeywordsModalEditionProps) => {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState<string[]>([]);
  const { updateDefaultKeywords } = useUpdateDefaultKeywords();
  const {
    keywords: defaultKeywords,
    loading,
    error,
    refetch,
  } = useGetDefaultKeywords();

  const updateKeywords = async () => {
    const notEmptyUniqueKeywords = keywords.filter(
      (keyword, index, array) =>
        keyword.trim() !== '' && array.indexOf(keyword) === index,
    );
    await updateDefaultKeywords(notEmptyUniqueKeywords);
  };

  return (
    <Modal
      open={visible}
      title={<h1 className="headerTitle">{t('archive.keywords.title')}</h1>}
      okText={t('common.save')}
      onCancel={onClose}
      destroyOnClose
      onOk={() => {
        notifyPromise(t, updateKeywords, {
          refetch,
          successNotification: t('archive.keywords.success-notification'),
          finally: onClose,
          errorNotification: t('archive.keywords.error-notification'),
        });
      }}
    >
      <ErrorOverlay error={error} loading={loading}>
        <KeywordsListEdition options={defaultKeywords} onChange={setKeywords} />
      </ErrorOverlay>
    </Modal>
  );
};

export default KeywordsModalEdition;

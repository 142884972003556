import { notification } from 'antd';
import { format, setYear } from 'date-fns';
import {
  useCanceledArchiveCompanySubscriptionMutation,
  useUpdateSubscriptionEndDateMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { getLocaleDate, getYearWithDateAndPeriod } from 'technical/date';
import { DATE_FORMAT } from 'technical/date/constant';
import errorReporting from 'technical/error-reporting';
import { CompanyArchiveSubscription } from 'business/configuration/companies/services/types';

export const useHandleSubCancellation = (
  companySubInfo: CompanyArchiveSubscription[number],
) => {
  const { t } = useTranslation();
  const [canceledArchiveCompanySubscription] =
    useCanceledArchiveCompanySubscriptionMutation();
  const [updateEndDate] = useUpdateSubscriptionEndDateMutation();

  /**
   * Calcul la fin d'une date d'abonnement
   * basé sur sa durée en année et sa date de création
   *
   */
  const getEndSubscriptionDate = () => {
    const createdAt = new Date(companySubInfo.createdAt);
    const { subscriptionDuration } = companySubInfo.archivePackage;
    return setYear(
      createdAt,
      getYearWithDateAndPeriod(createdAt, subscriptionDuration),
    );
  };

  const getMessage = () => {
    if (companySubInfo.isExpired) {
      return t('archive.config.message', {
        context: 'isExpired',
        date: format(getEndSubscriptionDate(), DATE_FORMAT.readableDate, {
          locale: getLocaleDate(),
        }),
      });
    }
    if (companySubInfo.subscriptionEndDate) {
      return t('archive.config.message', {
        context: 'expiredDate',
        date: format(getEndSubscriptionDate(), DATE_FORMAT.readableDate, {
          locale: getLocaleDate(),
        }),
      });
    }

    // TODO: Je ne sais pas si ce cas est possible, rajouté pour les warning eslint
    return null;
  };

  const deleteSubscription = async () => {
    try {
      await canceledArchiveCompanySubscription({
        variables: {
          archivePackageCompanyId: companySubInfo.id,
        },
      });
      notification.success({
        message: t('archive.config.success', {
          context: 'companySubscriptionDeletion',
        }),
      });
    } catch (err) {
      errorReporting.error(err);
      notification.error({
        message: t('errors.error', { context: 'deleteSubscription' }),
      });
    }
  };

  const cancelSubscription = async () => {
    try {
      await updateEndDate({
        variables: {
          id: companySubInfo.id,
          subscriptionEndDate: getEndSubscriptionDate().toISOString(),
        },
      });
      notification.success({
        message: t('archive.config.success', {
          context: 'companySubscriptionDeletion',
        }),
      });
    } catch (err) {
      errorReporting.error(err);
      notification.error({
        message: t('errors.error', { context: 'cancelSubscription' }),
      });
    }
  };

  return {
    deleteSubscription,
    cancelSubscription,
    subscriptionEndMessage: getMessage(),
  };
};

import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import TrademarkFamiliesTable from 'business/resources/trademark/components/trademarkFamiliesTable';
import { useGetTrademarkFamilies } from 'business/resources/trademark/services/useGetTrademarkFamilies';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import TrademarkFamiliesMosaic from 'business/resources/trademark/components/mosaic';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const TrademarkFamiliesPage: React.FC = () => {
  const { data, tableProps } = useGetTrademarkFamilies();
  const { t } = useTranslation();
  const { filtersValues, pagination, loading, setFilterValue } = tableProps;
  const { onExport, loading: loadingExport } = useDownloadTableToXlsx();

  return (
    <ConnectedPage translationKey="trademarks">
      <h1 className="headerTitle">{t('pages.header.trademarkFamilies')}</h1>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: 'Tableau',
            children: (
              <TrademarkFamiliesTable
                data-test-id="trademarkFamiliesTable"
                dataSource={data}
                loadingExport={loadingExport}
                onExport={(columns) => {
                  onExport(columns, 'trademarkFamily');
                }}
                {...tableProps}
              />
            ),
          },
          {
            key: '2',
            label: 'Mosaique',
            children: (
              <TrademarkFamiliesMosaic
                loading={loading}
                data={data}
                filtersValues={filtersValues}
                setFilterValue={setFilterValue}
                pagination={{
                  ...pagination,
                  onChange: (current, pageSize) => {
                    tableProps.onChange?.(
                      {
                        current,
                        pageSize,
                      },
                      {},
                      [],
                      { action: 'paginate', currentDataSource: data },
                    );
                  },
                }}
              />
            ),
          },
        ]}
      />
    </ConnectedPage>
  );
};

export default TrademarkFamiliesPage;

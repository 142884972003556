import { useGetDesignLinkQuery } from 'generated/graphql';
import { Design } from './types';

const internationalCountryCode = ['WO', 'MO'];
const isInternational = (countryCode: string) => {
  return internationalCountryCode.includes(countryCode);
};

export const useGetDesignLinks = (design: Design | null) => {
  const { data: designLinks } = useGetDesignLinkQuery({
    variables: {
      depositNumber: design?.depositNumber ?? '',
      familyId: design?.familyId ?? '',
      isInternational: isInternational(design?.country?.code ?? ''),
      id: design?.id ?? 0,
    },
    skip: design === null,
  });

  return {
    designLinks: [
      ...(designLinks?.internationalDesign ?? []),
      ...(designLinks?.designs ?? []),
    ],
  };
};

import Card from 'ui/card';
import SimpleTable, { Records } from 'ui/simpleTable';

interface ZonesTableProps<T> {
  dataSource: T[];
  columnsTitleContext?: string;
  subTitle: string;
}

const ZonesTable = <T extends Records>({
  dataSource,
  columnsTitleContext,
  subTitle,
}: ZonesTableProps<T>) => {
  return (
    <Card title={subTitle} className="fullHeight">
      <SimpleTable
        className="marginTop20"
        columnsTitleContext={columnsTitleContext}
        simpleSort
        columns={[
          { key: 'name' },
          { key: 'type' },
          { key: 'value', ellipsis: true },
          {
            key: 'ttl',
            render: (v: string) => (v ? `${v} sec.` : null),
          },
        ]}
        dataSource={dataSource}
      />
    </Card>
  );
};

export default ZonesTable;

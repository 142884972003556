import useGetCompanyKeywords from './useGetCompanyKeywords';
import useGetDefaultKeywords from './useGetDefaultKeywords';

const useGetCompanyOrDefaultKeywords = (companyId: number) => {
  const {
    keywords: companyKeywords,
    loading,
    error,
  } = useGetCompanyKeywords({ companyId });

  const { keywords: defaultKeywords } = useGetDefaultKeywords();

  const keywords = companyKeywords.length ? companyKeywords : defaultKeywords;

  return {
    keywords,
    loading,
    error,
  };
};

export default useGetCompanyOrDefaultKeywords;

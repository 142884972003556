import {
  GetPatentFamilySearchQuery,
  GetPatentFamilySearchQueryVariables,
  useGetPatentFamilyBreadcrumbQuery,
  useGetPatentFamilySearchQuery,
} from 'generated/graphql';

import {
  GetPatentFamilySearchParam,
  PatentFamily,
} from 'business/resources/patent/types';

import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

export const useGetPatentFamily = ({
  filterValues,
  overrideOrderBy,
  storageKey,
  search,
}: GetPatentFamilySearchParam) =>
  useQueryForTable<
    PatentFamily,
    GetPatentFamilySearchQuery,
    GetPatentFamilySearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKey ?? storageKeys.patentFamilies,
    commonFiltersKeys: ['search'],
    useQuery: ({ limit, offset, orderBy, searchText, isOwn }) => {
      return useGetPatentFamilySearchQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...filterValues,
            isOwn,
            search: search ?? searchText,
          },
        },
      });
    },
    getTotal: (res) => res?.patentSearch?.count ?? 0,
    map: (res) => res?.patentSearch?.resources ?? [],
  });

export const useGetPatentFamilyInfos = (familyId: string) => {
  const { data } = useGetPatentFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0];
};

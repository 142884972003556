import ResourceImageDisplay, { ImageQueryHook } from 'ui/imageDisplay';
import classNames from 'classnames';
import { BasicImageQueryResult } from 'ui/imageDisplay/resourceImage';
import styles from './index.module.scss';

interface TrademarkImageProps<ImageQueryResult extends BasicImageQueryResult> {
  title: string;
  height: number;
  query: ImageQueryHook<ImageQueryResult>;
}

const TrademarkImage = <ImageQueryResult extends BasicImageQueryResult>({
  title,
  query,
  height,
}: TrademarkImageProps<ImageQueryResult>) => {
  return (
    <ResourceImageDisplay
      query={query}
      imgHeight={height}
      overrideNoResult={
        <h1 className={classNames('headerTitle', styles.trademarkName)}>
          {title}
        </h1>
      }
    />
  );
};

export default TrademarkImage;

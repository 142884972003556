import { generatePathByAssetType } from 'business/common/services';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import { PatentEvent } from 'business/resources/events/services/types';
import { Link } from 'react-router-dom';
import { ColumnGenerationData } from 'ui/table/types';

const getPatentEventColumns = (): ColumnGenerationData<PatentEvent> => [
  {
    key: 'type',
    alwaysVisible: true,
    translationKey: 'eventType',
    ellipsis: true,
  },
  { key: 'holder', checked: false },
  { key: 'effectiveHolder', checked: false, ellipsis: true },
  {
    key: 'title',
    alwaysVisible: true,
    render: (_, { assetId, title }: PatentEvent) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('patent', assetId)}
      >
        {title}
      </Link>
    ),
  },
  { ...getCountryColumn(), alwaysVisible: true },
  { key: 'dueDate', alwaysVisible: true, ellipsis: true },
  { key: 'depositNumber', checked: false, ellipsis: true },
  { key: 'depositDate', checked: false, ellipsis: true },
  { key: 'issueNumber', checked: false, ellipsis: true },
  { key: 'issueDate', checked: false, ellipsis: true },
  { key: 'bdlRef', alwaysVisible: true, ellipsis: true },
  { key: 'clientRef', alwaysVisible: true, ellipsis: true },
  { key: 'clientRef2', alwaysVisible: true, ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
  { key: 'inventors', alwaysVisible: true },
];

export default getPatentEventColumns;

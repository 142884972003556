import { ArrowColorByType, ArrowType } from './types';

export const arrowSize = (size: number) => Math.hypot(size / 2, size / 2);

export const arrowColorByType: ArrowColorByType = {
  information: {
    borderColor: '#8CC5CB',
    backgroundColor: '#EBF4F5',
    titleColor: '#8CC5CB',
  },
  error: {
    borderColor: '#DE7676',
    backgroundColor: '#F5ECEB',
    titleColor: '#DE7676',
  },
};

export const getArrowStyle = (type: ArrowType, size: number) => {
  return {
    borderRight: `2px solid ${arrowColorByType[type].borderColor}`,
    borderBottom: `2px solid ${arrowColorByType[type].borderColor}`,
    height: arrowSize(size),
    width: arrowSize(size),
  };
};

export const getArrowTitleStyle = (type: ArrowType, size: number) => {
  return {
    backgroundColor: arrowColorByType[type].backgroundColor,
    borderTop: `2px solid ${arrowColorByType[type].borderColor}`,
    borderBottom: `2px solid ${arrowColorByType[type].borderColor}`,
    paddingLeft: arrowSize(size),
    paddingRight: arrowSize(size),
    color: arrowColorByType[type].titleColor,
  };
};

import { ApolloProvider } from '@apollo/client';
import { Button, Form, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import apolloClient from 'technical/graphql/client';
import Spacer from 'ui/spacer';
import TermCheckbox from 'business/user/components/termCheckbox';
import styles from './index.module.scss';
import { useUserConsent } from 'business/user/provider/useUserConsent';

interface TouModalContentProps {
  buttonText: string;
  touPath: string;
}

const TouModalContent = ({ buttonText, touPath }: TouModalContentProps) => {
  const { t } = useTranslation();
  const { validateTerm } = useUserConsent();

  const onAcceptingTou = async () => {
    try {
      await validateTerm('tou');
      Modal.destroyAll();
    } catch (err) {
      errorReporting.error(err);
      notification.error(t('errors.acceptingTerms', { context: 'tou' }));
    }
  };

  return (
    <Form onFinish={onAcceptingTou}>
      <Spacer direction="vertical" size="small" className={styles.touContent}>
        <TermCheckbox termPath={touPath} type="tou" />
        <Form.Item>
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            htmlType="submit"
            block
          >
            {buttonText}
          </Button>
        </Form.Item>
      </Spacer>
    </Form>
  );
};

const touValidationModal = (
  touPath: string,
  title: string,
  buttonText: string,
) => {
  return Modal.confirm({
    width: 500,
    title,
    cancelButtonProps: { className: 'hidden' },
    okButtonProps: { className: 'hidden' },
    content: (
      <ApolloProvider client={apolloClient}>
        <TouModalContent touPath={touPath} buttonText={buttonText} />
      </ApolloProvider>
    ),
  });
};

export default touValidationModal;

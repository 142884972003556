import { ColumnGenerationData } from 'ui/table/types';
import { Event } from 'business/resources/events/services/types';

export const defaultColumns: ColumnGenerationData<Event> = [
  { key: 'dueDate', alwaysVisible: true, width: 200 },
  {
    key: 'type',
    context: 'event',
    alwaysVisible: true,
  },
];

export const annuityType = 'TAXE ANNUELLE (ANN)';

export const filterOutAnnuitiesFromEvents = (
  dataSource: readonly Event[] | undefined,
) => {
  return dataSource?.filter((event) => event.type !== annuityType);
};

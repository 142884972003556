import Button from 'ui/button';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';
import { DESIGN_ANOMALY_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';

interface IDesignAnomaly {
  filters: OsrFilterState;
}

const DesignAnomaly = (props: IDesignAnomaly) => {
  const { t } = useTranslation();
  const { generateExcel } = useGenerateExcelFile('design', props.filters);
  const {
    data: dataWithoutClientRef,
    tableProps: dataWithoutClientRefTableProps,
  } = useGetDesignOsrData(
    { companyRef: props.filters.companyRef },
    'clientRefIsNull',
  );

  const {
    data: dataDuplicateClientRef,
    tableProps: dataDuplicateClientRefTableProps,
  } = useGetDesignOsrData(
    { companyRef: props.filters.companyRef },
    'duplicateClientRef',
  );

  const {
    data: dataDuplicateProcessAgentRef,
    tableProps: dataDuplicateProcessAgentRefTableProps,
  } = useGetDesignOsrData(
    { companyRef: props.filters.companyRef },
    'duplicateRenewAgentRef',
  );

  const {
    data: dataInvalidProcessAgentRef,
    tableProps: dataInvalidProcessAgentRefTableProps,
  } = useGetDesignOsrData(
    { companyRef: props.filters.companyRef },
    'invalidProcessAgentRef',
  );

  return (
    <OsrTabs
      actions={
        <Button type="primary" onClick={() => generateExcel('ANOMALIES')}>
          Exporter les anomalies
        </Button>
      }
      tabsItems={[
        {
          key: 'withoutClientRef',
          label: `${t('pages.osr.tableAnomaly', {
            context: 'withoutClientRef',
          })} (${dataWithoutClientRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={DESIGN_ANOMALY_COLUMNS}
              dataSource={dataWithoutClientRef}
              {...dataWithoutClientRefTableProps}
            />
          ),
        },
        {
          key: 'clientRef',
          label: `${t('pages.osr.tableAnomaly', {
            context: 'duplicateClientRef',
          })} (${dataDuplicateClientRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={DESIGN_ANOMALY_COLUMNS}
              searchBarEnabled={false}
              dataSource={dataDuplicateClientRef}
              {...dataDuplicateClientRefTableProps}
            />
          ),
        },
        {
          key: 'processAgentRef',
          label: `Doublons référence Acumass (${dataDuplicateProcessAgentRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={DESIGN_ANOMALY_COLUMNS}
              dataSource={dataDuplicateProcessAgentRef}
              searchBarEnabled={false}
              {...dataDuplicateProcessAgentRefTableProps}
            />
          ),
        },
        {
          key: 'processAgentRefNotAcumass',
          label: `Dossier étranger avec agent autre que ACUMASS (${dataInvalidProcessAgentRefTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              defaultColumns={DESIGN_ANOMALY_COLUMNS}
              dataSource={dataInvalidProcessAgentRef}
              searchBarEnabled={false}
              {...dataInvalidProcessAgentRefTableProps}
            />
          ),
        },
      ]}
    />
  );
};

export default DesignAnomaly;

import { GetPatentQuery } from 'generated/graphql';
import { TFunction } from 'i18next';
import { isBeforeToday } from 'technical/date';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';

export const getPatentTimeline = (
  t: TFunction,
  patent: GetPatentQuery['patent_by_pk'],
) => {
  if (!patent) {
    return [];
  }
  const events: TimelineEvent[] = [
    ...patent.priorities.map((n) => ({
      title: t('table.common.column.priority'),
      date: n.priorityDate,
      reference: n.priorityNumber,
    })),
    {
      title: t('table.common.column.firstDeposit'),
      date: patent.depositDate,
      reference: patent.depositNumber,
    },
    {
      title: t('table.common.column.publication'),
      date: patent.publicationDate,
      reference: patent.publicationNumber,
    },
    {
      title: t('table.common.column.issue'),
      date: patent.issueDate,
      reference: patent.issueNumber,
    },
  ];

  if (
    patent.abandonmentDate &&
    isBeforeToday(new Date(patent.abandonmentDate))
  ) {
    // If the patent is abandonned. No need to display next events but we display the abandoment date instead
    events.push({
      title: t('table.common.column.abandonment'),
      date: patent.abandonmentDate,
      type: 'error',
    });
  } else if (
    patent.expirationDate &&
    isBeforeToday(new Date(patent.expirationDate))
  ) {
    // If the patent is expired. No need to display next events but we display the expiration date instead
    events.push({
      title: t('table.common.column.expiration'),
      date: patent.expirationDate,
      type: 'error',
    });
  }

  return createTimelineEvents(events);
};

import { MailOutlined } from '@ant-design/icons';
import { Auth0ParseHashError } from 'auth0-js';
import { useAppContext } from 'business/AppBootstrapper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import { Result } from 'antd';
import authService from 'business/user/services/authService';
import DisconnectedPage from 'business/user/helpers/disconnectedPage';
import { useHistory } from 'react-router';

const LoginProviderCallbackScreen = () => {
  const [error, setError] = useState<Auth0ParseHashError | undefined>(
    undefined,
  );
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();

  const history = useHistory();

  useEffect(() => {
    authService
      .requestLoginCallback()
      .then(() => requestRebootstrap())
      .then(() => {
        const originPath = sessionStorage.getItem('originPath');
        if (originPath) {
          history.push(originPath);
        }
        sessionStorage.removeItem('originPath');
      })
      .catch((err) => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  if (error) {
    let errorKey = '';

    switch (error.errorDescription) {
      case 'invalid-email-domain':
      case 'email-not-verified':
        errorKey = error.errorDescription;
        break;
      default:
        break;
    }

    return (
      <DisconnectedPage>
        <Flex
          style={{ flex: 1, padding: '20px' }}
          justify="center"
          alignItems="center"
        >
          <Result
            icon={<MailOutlined />}
            title={t('notConnected.login.callback.error.title', {
              context: errorKey,
            })}
            subTitle={t('notConnected.login.callback.error.subTitle', {
              context: errorKey,
            })}
          />
        </Flex>
      </DisconnectedPage>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error
    // or redirecting to home page
    <DisconnectedPage>
      <Loader />
    </DisconnectedPage>
  );
};

export default LoginProviderCallbackScreen;

import { useEffect, useState } from 'react';
import {
  getJsonParsedStorageValues,
  getStorage,
  getStorageValues,
  parseStorageValues,
} from './parseStorageValue';
import { UseStorageProps } from './types';

export const useStorage = <T>({
  storageKey,
  filters,
  storageType,
  subKey,
}: UseStorageProps<T>) => {
  const defaultStorageValues = getStorageValues<T>({
    storageKey,
    storageType,
    filters,
    subKey,
  });

  const [storageValues, setNewStorageValues] =
    useState<T>(defaultStorageValues);
  const [previousValue, setPreviousValue] = useState<T>(defaultStorageValues);

  const storeValues = () => {
    const parsedStorageValues = parseStorageValues({ filters, storageValues });
    const rawStorageValues = getJsonParsedStorageValues(
      storageKey,
      storageType,
    );
    const newStorageValue = subKey
      ? { ...rawStorageValues, [subKey]: parsedStorageValues }
      : parsedStorageValues;

    getStorage(storageType).setItem(
      storageKey,
      JSON.stringify(newStorageValue),
    );
  };

  useEffect(() => {
    storeValues();
  }, [storageKey, storageValues]);

  const deleteStorage = () => {
    getStorage(storageType).removeItem(storageKey);
  };

  const setStorageValues = (newValue: T) => {
    setPreviousValue(storageValues);
    setNewStorageValues(newValue);
  };

  const resetToPreviousValue = () => {
    setStorageValues(previousValue);
  };

  const setStorageValue = <Key extends keyof T>(
    keyFilter: Key,
    value: T[Key],
  ) => {
    setStorageValues({ ...storageValues, [keyFilter]: value });
  };

  return {
    storageValues,
    setStorageValues,
    deleteStorage,
    resetToPreviousValue,
    setStorageValue,
  };
};

import { TabsFamily } from 'business/common/services/types';

export const resourceTabsOrder: TabsFamily[] = [
  'patent',
  'design',
  'trademark',
  'domain',
  'all',
];

export const defaultTabOpened: TabsFamily = 'patent';

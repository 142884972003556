import {
  GetDesignDocumentsDocument,
  GetDomainDocumentsDocument,
  GetPatentDocumentsDocument,
  GetTrademarkDocumentsDocument,
} from 'generated/graphql';

export const documentQueryByResource = {
  patent: GetPatentDocumentsDocument,
  trademark: GetTrademarkDocumentsDocument,
  domain: GetDomainDocumentsDocument,
  design: GetDesignDocumentsDocument,
};

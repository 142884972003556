import { Button, Form, Modal } from 'antd';
import { UserArchivesDocuments } from 'business/archive/services/types';
import notifyPromise from 'business/common/services/notifyPromise';
import { useUpdateArchiveDocumentMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import ArchiveInfoForm from 'business/archive/components/archiveInfoForm';
import { ArchiveInfoFormType } from 'business/archive/components/archiveInfoForm/types';

interface ArchiveEditionModalProps {
  isOpen: boolean;
  onClose: () => void;
  archive: UserArchivesDocuments;
  refetch?: () => void;
}

const ArchiveEditionModal = ({
  isOpen,
  onClose,
  archive,
  refetch,
}: ArchiveEditionModalProps) => {
  const { t } = useTranslation();
  const [updateArchiveMutation] = useUpdateArchiveDocumentMutation();
  const [form] = Form.useForm<ArchiveInfoFormType>();

  const onFinish = (values: ArchiveInfoFormType) => {
    notifyPromise(
      t,
      async () => {
        await updateArchiveMutation({
          variables: { id: archive.id, ...values },
        });
      },
      {
        refetch,
        finally: onClose,
        successNotification: t('common.successNotification'),
      },
    );
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      title={
        <h3>
          <b>{t('archive.config.editArchive')}</b>
        </h3>
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() =>
            form
              .validateFields()
              .then(onFinish)
              .catch((err) => logger.error(err))
          }
        >
          {t('common.confirm')}
        </Button>,
      ]}
    >
      {archive.companyId && (
        <Form<ArchiveInfoFormType>
          layout="vertical"
          initialValues={archive}
          form={form}
        >
          <ArchiveInfoForm companyId={archive.companyId} />
        </Form>
      )}
    </Modal>
  );
};

export default ArchiveEditionModal;

import { notification } from 'antd';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { UploadForm } from 'business/archive/components/archiveUploadForm/archiveUploadFormModal';
import { UserWithPrivateInfos } from 'business/user/types/user';
import notifyPromise from 'business/common/services/notifyPromise';
import useUploadFormActions from './useUploadFormActions';
import useGetCompaniesArchiveUpload from './useGetCompaniesArchiveUpload';
import { useUserConsent } from 'business/user/provider/useUserConsent';

/**
 * This modal opens only if we succeed to fetch companies data.
 * Display an error if there is none
 */

interface Props {
  refetchArchives: () => void;
  user: UserWithPrivateInfos;
}
const useHandleUploadFormModal = ({ refetchArchives, user }: Props) => {
  const { t } = useTranslation();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const { uploadArchives } = useUploadFormActions();
  const {
    companiesListOptions,
    generateCompaniesListOptions,
    setSelectedCompany,
    selectedCompany,
  } = useGetCompaniesArchiveUpload();
  const { getUserConsent, validateTos } = useUserConsent();

  const closeModal = () => {
    setUploadModalOpen(false);
  };

  const openModal = () => {
    setUploadModalOpen(true);
  };

  const handleModalOpen = async () => {
    if (companiesListOptions?.length) {
      // prevent refetch everytime modal opens
      openModal();
      return;
    }
    try {
      await generateCompaniesListOptions();
      openModal();
    } catch (err) {
      logger.error({
        user,
        err,
      });
      notification.error({
        message: t('errors.generic'),
      });
    }
  };

  const onUpload = async (
    { files, ...formData }: UploadForm,
    resetForm: () => void,
  ) =>
    notifyPromise(
      t,
      async () => {
        setIsUploading(true);
        await uploadArchives({ files, ...formData });
        closeModal();
        await validateTos();
        resetForm();
      },
      {
        refetch: refetchArchives,
        successNotification: t('pages.archive.notifications.uploadArchive'),
        finally: () => setIsUploading(false),
        notificationType: 'info',
        successDescription: t('pages.archive.notifications.uploadArchiveDesc'),
      },
    );

  return {
    closeModal,
    handleModalOpen,
    uploadModalOpen,
    companiesListOptions,
    isUploading,
    onUpload,
    getUserConsent,
    setSelectedCompany,
    selectedCompany,
  };
};

export default useHandleUploadFormModal;

import Spacer from 'ui/spacer';

interface ConfigurationSectionProps {
  title: string;
  children: React.ReactNode;
}

const ConfigurationSection = ({
  title,
  children,
}: ConfigurationSectionProps) => {
  return (
    <Spacer size="small" direction="vertical">
      <span className="headerTitle">{title}</span>
      {children}
    </Spacer>
  );
};

export default ConfigurationSection;

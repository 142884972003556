import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface LinkSettingsProps {
  pathname: string;
  icon: ReactNode;
  text: string;
}

const LinkSettings = ({ pathname, icon, text }: LinkSettingsProps) => {
  return (
    <Link
      className="configLink"
      to={{
        pathname,
      }}
    >
      <>
        {icon}
        {text}
      </>
    </Link>
  );
};

export default LinkSettings;

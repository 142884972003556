import { useGetPrivateDnsDataQuery } from 'generated/graphql';

interface GetPrivateDnsProps {
  domainId: number;
  skip: boolean;
}
export const useGetPrivateDns = ({ domainId, skip }: GetPrivateDnsProps) => {
  const { data, ...privateDomainDNSDataState } = useGetPrivateDnsDataQuery({
    variables: { id: domainId },
    fetchPolicy: 'no-cache',
    skip,
  });

  const servers =
    data?.domain_by_pk?.privateDns?.nameservers?.map((server, i) => ({
      serverName: `DNS ${i + 1}`,
      server,
    })) ?? [];

  const zones = data?.domain_by_pk?.privateDns?.zones ?? [];

  return {
    servers,
    privateDomainDNSDataState,
    zones,
  };
};

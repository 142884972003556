import { useAppContext } from 'business/AppBootstrapper';
import React from 'react';
import { Redirect } from 'react-router';
import CanAccessSafranRequestPage from './canAccessSafranRequestPage';
import {
  DEFAULT_CONNECTED_ROUTE,
  DEFAULT_NOT_CONNECTED_ROUTE,
} from './constant';

/**
 * Redirect the user to the default not-connected route when the user
 * is not-connected and intend to access connected route
 */
export const ConnectedContainer = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { isConnected } = useAppContext();
  return isConnected ? children : <Redirect to={DEFAULT_NOT_CONNECTED_ROUTE} />;
};

/**
 * Redirect the user to the default connected route when the user is
 * connected and intend to access not connected route
 */
export const NotConnectedContainer = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { isConnected } = useAppContext();
  return isConnected ? <Redirect to={DEFAULT_CONNECTED_ROUTE} /> : children;
};

export function ConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => {
    return (
      <ConnectedContainer>
        <Component {...props} />
      </ConnectedContainer>
    );
  };
}

export function ConnectedHocBdlAdminOrSafranUser<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => {
    return (
      <ConnectedContainer>
        <CanAccessSafranRequestPage>
          <Component {...props} />
        </CanAccessSafranRequestPage>
      </ConnectedContainer>
    );
  };
}

export function NotConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => {
    return (
      <NotConnectedContainer>
        <Component {...props} />
      </NotConnectedContainer>
    );
  };
}

import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { UserGroupsFormValues } from 'business/configuration/accessManagement/components/modals/types';
import { userGroupsFormListName } from 'business/configuration/accessManagement/components/modals/constant';
import { User } from 'business/configuration/accessManagement/services/types';
import MultiCompaniesFormList from 'business/configuration/accessManagement/components/forms/multiCompaniesFormList/multiCompaniesFormList';

const formatUserGroupsCompanyName = (name: string, companyRef: string) =>
  `${name} [${companyRef}]`;

interface UserGroupFormProps {
  form: FormInstance;
  user: User;
}
const UserGroupForm = ({ form, user }: UserGroupFormProps) => {
  const { t } = useTranslation();
  const defaultUserGroups = user.groups.map(
    ({
      group: {
        id: groupId,
        company: { id, name, companyRef },
      },
    }) => ({
      companyId: id,
      companyName: formatUserGroupsCompanyName(name, companyRef),
      groupId,
    }),
  );
  return (
    <Form<UserGroupsFormValues>
      layout="vertical"
      form={form}
      initialValues={{
        [userGroupsFormListName]: defaultUserGroups,
      }}
    >
      <h1>{t('user.groupModal.title')}</h1>
      <MultiCompaniesFormList
        form={form}
        formListName={userGroupsFormListName}
        defaultSelectedCompaniesId={defaultUserGroups.map(
          (userGroups) => userGroups.companyId,
        )}
      />
    </Form>
  );
};
export default UserGroupForm;

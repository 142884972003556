import { Tooltip } from 'antd';
import { PatentFamilies } from 'business/resources/patent/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import { ellipsisStyle } from 'ui/table/columns';
import sanitizeStringList from 'technical/format';
import {
  getCountriesColumn,
  getCountriesKey,
} from 'technical/countriesTranslation/constants';

type TablePatentFamiliesProps = Omit<
  TableProps<PatentFamilies>,
  'defaultColumns'
>;

const defaultColumns: ColumnGenerationData<PatentFamilies> = [
  { key: 'holder', ellipsis: true },
  {
    key: 'title',
    context: 'patent',
    ellipsis: true,
    alwaysVisible: true,
    render: (_: any, record: PatentFamilies) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={`/patent/family/${record.id}`}
      >
        {record.title}
      </Link>
    ),
  },
  {
    ...getCountriesColumn(),
    onCell: () => ellipsisStyle,
    ellipsis: true,
    render: (_: any, record: PatentFamilies) => (
      <Tooltip title={record[getCountriesKey()]}>
        {record[getCountriesKey()]}
      </Tooltip>
    ),
  },
  { key: 'firstDepositNumber' },
  { key: 'firstDepositDate' },
  { key: 'clientRef', checked: true, ellipsis: true },
  { key: 'bdlRef' },
  { key: 'clientOfficer', ellipsis: true },
  { key: 'bdlOfficerName', ellipsis: true },
  {
    key: 'inventors',
    render: (list) => sanitizeStringList(list),
    ellipsis: true,
  },
];

const PatentFamiliesTable: React.FC<TablePatentFamiliesProps> = (
  props: TablePatentFamiliesProps,
) => {
  const { t } = useTranslation();

  return (
    <Table
      id="patentFamiliesTable"
      {...props}
      family="patent"
      showEffectiveCheckbox
      defaultColumns={defaultColumns}
      header={
        <h1 className="headerTitle">{t('pages.header.patentFamilies')}</h1>
      }
    />
  );
};

export default PatentFamiliesTable;

import { Switch, SwitchProps } from 'react-router-dom';
import ReportingNoMatch from './reportingNoMatch';

type Props = SwitchProps;

// A react-router Switch which handle routes not found
const SwitchNotFoundHandler = ({ children, ...props }: Props) => {
  return (
    <Switch {...props}>
      {children}
      <ReportingNoMatch />
    </Switch>
  );
};

export default SwitchNotFoundHandler;

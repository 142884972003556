import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  translationKey?: string;
}
const useSetDocumentTitle = ({ translationKey }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `BDL-IP Access ${
      translationKey ? `- ${t(`metadata.${translationKey}`)}` : ''
    }`;
  }, [translationKey]);
};

export default useSetDocumentTitle;

import { UserRole } from 'business/roles/constant';

import useHandleUploadFormModal from 'business/archive/hooks/useHandleUploadFormModal';

import RolesChecker from 'technical/rolesChecker';
import Flex from 'ui/flex';
import { UserWithPrivateInfos } from 'business/user/types/user';
import ArchiveUploadFormModal from './archiveUploadFormModal';
import { isModalDisabled } from './constant';
import OpenModalButton from './openModalButton';

interface ArchiveUploadFormProps {
  refetchArchives: () => void;
  user: UserWithPrivateInfos;
}
const ArchiveUploadForm = ({
  refetchArchives,
  user,
}: ArchiveUploadFormProps) => {
  const {
    handleModalOpen,
    closeModal,
    uploadModalOpen,
    companiesListOptions,
    isUploading,
    onUpload,
    getUserConsent,
    selectedCompany,
    setSelectedCompany,
  } = useHandleUploadFormModal({ refetchArchives, user });

  return (
    <RolesChecker roles={[UserRole.user]}>
      <Flex justify="flex-start">
        <OpenModalButton
          disabled={isModalDisabled(user)}
          onClick={handleModalOpen}
        />
        <ArchiveUploadFormModal
          companiesOptions={companiesListOptions}
          onUpload={onUpload}
          isOpened={uploadModalOpen}
          isUploading={isUploading}
          onCancel={closeModal}
          tosPath={getUserConsent()?.tos?.path}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
        />
      </Flex>
    </RolesChecker>
  );
};

export default ArchiveUploadForm;

import { AdvancedFilters } from 'business/advancedSearch/services/types';
import { isFamilyField, tabsFamily } from 'business/common/services/types';
import { EventAdvancedFilters } from 'business/resources/events/services/types';
import { Filter } from 'technical/filters/types';

const isAdvancedFilters = (filters: any): filters is AdvancedFilters => {
  if (filters?.filters && typeof filters.filters === 'object') {
    return Object.keys(filters.filters).every((field) => isFamilyField(field));
  }
  return false;
};

export const storageRessourceFilter = (): Filter<AdvancedFilters> => {
  return tabsFamily.reduce<Filter<AdvancedFilters>>((acc, family) => {
    return {
      ...acc,
      [family]: {
        readParse: (param: string | null) => {
          if (isAdvancedFilters(param)) {
            return param;
          }
          return {};
        },
        writeParse: (value: AdvancedFilters) => value,
      },
    };
  }, {} as Filter<AdvancedFilters>);
};

export const storageEventFilter = (): Filter<EventAdvancedFilters> => {
  return tabsFamily.reduce<Filter<EventAdvancedFilters>>((acc, family) => {
    return {
      ...acc,
      [family]: {
        readParse: (param: string | null) => param,
        writeParse: (value: EventAdvancedFilters) => value,
      },
    };
  }, {} as Filter<EventAdvancedFilters>);
};

enum Env {
  local = 'local',
  ci = 'ci',
  prod = 'production',
  test = 'test',
}

if (!process.env.REACT_APP_AUTHENTICATION_MODE) {
  throw new Error('Set REACT_APP_AUTHENTICATION_MODE');
}

if (process.env.REACT_APP_AUTHENTICATION_MODE === 'auth0') {
  if (!process.env.REACT_APP_AUTH0_DOMAIN) {
    throw new Error('Set REACT_APP_AUTH0_DOMAIN');
  }

  if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
    throw new Error('Set REACT_APP_AUTH0_CLIENT_ID');
  }

  if (!process.env.REACT_APP_AUTH0_REDIRECT_URI) {
    throw new Error('Set REACT_APP_AUTH0_REDIRECT_URI');
  }
}

if (!process.env.REACT_APP_GRAPHQL_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_URI');
}

if (!process.env.REACT_APP_GRAPHQL_WS_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_WS_URI');
}

if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID) {
  throw new Error('Set REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID');
}

if (!process.env.REACT_APP_ENVIRONMENT) {
  throw new Error('Set REACT_APP_ENVIRONMENT');
}

if (
  process.env.REACT_APP_ENVIRONMENT &&
  !(process.env.REACT_APP_ENVIRONMENT in Env)
) {
  throw new Error(
    `REACT_APP_ENVIRONMENT is not recognized: ${process.env.REACT_APP_ENVIRONMENT}`,
  );
}

// eslint-disable-next-line unused-imports/no-unused-vars
const env = process.env.REACT_APP_ENVIRONMENT;

const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
  },
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI,
  graphqlWsUri: process.env.REACT_APP_GRAPHQL_WS_URI,
  contact: {
    email: 'matters@matters.tech',
    address: '10, rue du Faubourg Poissonnière, 75010 Paris',
    phone: '+33 9 72 64 96 09',
    credits: '© 2020 Matters - ',
  },
};

export default config;

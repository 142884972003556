import classnames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  fixedWith?: boolean;
  width?: number;
  style?: React.CSSProperties;
  title?: string;
}

const Card = ({
  children,
  className,
  dataTestId,
  fixedWith,
  width,
  style,
  title,
}: Props) => {
  return (
    <div
      style={{ ...style, width }}
      className={classnames(styles.card, className, {
        [styles.fixedWith]: fixedWith,
      })}
      data-test-id={dataTestId}
    >
      {title && <span className={styles.title}>{title}</span>}
      {children}
    </div>
  );
};

export default Card;

import { FloatButton, Layout } from 'antd';
import classNames from 'classnames';
import React from 'react';
import ScrollToTop from 'technical/router/scroll-to-top';
import NavBar from 'ui/navBar';
import styles from './index.module.scss';
import useSetDocumentTitle from './useSetDocumentTitle';

const { Content, Footer } = Layout;

export interface PageProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  scrollToTop?: boolean;
  isConnected: boolean;
  translationKey?: string;
}

const Page = ({
  children = null,
  footer = null,
  img,
  scrollToTop = true,
  isConnected,
  translationKey,
}: PageProps) => {
  useSetDocumentTitle({ translationKey });

  return (
    <Layout className={styles.layout}>
      <ScrollToTop enabled={scrollToTop} />
      <NavBar isConnected={isConnected} />
      {img ? <img src={img.src} alt={img.alt} /> : null}
      <Content
        className={classNames(styles.content, {
          [styles.connected]: isConnected,
        })}
      >
        {children}
        <FloatButton.BackTop />
      </Content>
      {footer ? <Footer className={styles.footer}>{footer}</Footer> : null}
    </Layout>
  );
};

export default Page;

import { UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

import { UserWithPrivateInfos } from 'business/user/types/user';
import Section from 'ui/navBar/components/logoSection';
import styles from 'ui/navBar/index.module.scss';
import useMenuDropDownItems from './hooks/useMenuDropDownItems';

interface MenuDropdownProps {
  user: UserWithPrivateInfos;
}

const MenuDropdown = ({ user }: MenuDropdownProps) => {
  const items = useMenuDropDownItems(user);
  return (
    <Dropdown className={styles.headerRight} menu={{ items }}>
      <div className="ant-dropdown-link">
        <Section
          onClick={() => {}}
          label={`${user.firstName} ${user.lastName}`}
          labelClassName={styles.userName}
          icon={
            <UserOutlined
              data-test-id="menu_icon"
              className={styles.userIcon}
            />
          }
        />
      </div>
    </Dropdown>
  );
};

export default MenuDropdown;

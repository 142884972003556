import Routes from 'business/router/routes';
import { ExtractRouteParams, useParams } from 'react-router';
import useGetResourceV2Url from 'business/resources/shared/services/useGetResourceV2Url';
import ConnectedPage from 'business/user/helpers/connectedPage';
import IsLoading from 'ui/isLoading';
import NotFoundPage from 'ui/notFoundPage';

/**
This page is only used to redirect the user to the corresponding resource 
in case he tries to access with a v1 Url
 */
const V1RedirectionPage = () => {
  const { id, resourceType } =
    useParams<ExtractRouteParams<Routes.ResourceAccessV1Url, string>>();

  const { loading, error } = useGetResourceV2Url(Number(id), resourceType);

  if (error) {
    return <NotFoundPage />;
  }
  return (
    <ConnectedPage>
      <IsLoading loading={loading} />
    </ConnectedPage>
  );
};

export default V1RedirectionPage;

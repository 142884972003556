import { Form, FormInstance, InputProps } from 'antd';
import MultiCompanies from 'business/configuration/accessManagement/components/forms/multiCompanies';
import {
  USER_INFO_MODAL_RULES,
  formListName,
} from 'business/configuration/accessManagement/components/forms/userForm/constants';
import UserFormFields from 'business/common/form/userFormFields';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { UserWithGroups } from 'business/groups/services/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import Select from 'ui/select';

type UserFormFieldsKeys = 'firstName' | 'lastName' | 'email';

interface UserFormProps<T> {
  form: FormInstance<T>;
  groupOptions: { label: string; value: string }[];
  user?: UserWithGroups;
  companyId: number;
  userFieldProps?: Partial<
    Record<
      UserFormFieldsKeys,
      {
        field?: InputProps;
        label?: string;
      }
    >
  >;
}

const UserForm = <T,>({
  form,
  groupOptions,
  user,
  userFieldProps,
  companyId: currentCompanyId,
}: UserFormProps<T>) => {
  const { t } = useTranslation();
  const { user: currentUser } = useLoggedInAppContext();
  const defaultUserGroups = user?.multiCompaniesGroups.map(
    ({
      group: {
        id: groupId,
        name: groupName,
        company: { name: companyName, id: companyId },
      },
    }) => {
      return {
        groupId,
        companyName,
        companyId,
        groupName,
      };
    },
  );

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        ...user,
        groupId: user?.companyGroup[0].group.id,
        [formListName]: defaultUserGroups,
      }}
    >
      <h1>
        {t('user.infoModal.title', {
          context: user ? 'edit' : null,
        })}
      </h1>
      <UserFormFields additionnalProps={userFieldProps} />
      <Spacer size="small" direction="vertical">
        <div>
          <Form.Item
            label={t('user.infoModal.group')}
            name="groupId"
            rules={USER_INFO_MODAL_RULES(t).groupId}
          >
            <Select
              data-test-id="group-input"
              disabled={user?.id === currentUser.id}
              options={groupOptions.map((group) => {
                return {
                  dataTestId: group.label,
                  code: group.value,
                  label: group.label,
                };
              })}
            />
          </Form.Item>
        </div>
        <MultiCompanies
          isDisabled={user?.id === currentUser.id}
          form={form}
          currentCompanyId={currentCompanyId}
          selectedCompaniesId={
            defaultUserGroups?.map((userGroup) => userGroup.companyId) ?? []
          }
        />
      </Spacer>
    </Form>
  );
};

export default UserForm;

import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Spacer from 'ui/spacer';

interface ArchiveConfigurationLinksProps {
  openKeywordModal: () => void;
}

const ArchiveConfigurationLinks = ({
  openKeywordModal,
}: ArchiveConfigurationLinksProps) => {
  const { t } = useTranslation();
  return (
    <Spacer direction="vertical" size="large">
      <div>
        <Link className="configLink" to={Routes.Invoices}>
          <SettingOutlined />
          {t('archive.configLinks.invoiceList')}
        </Link>
        <Link
          data-test-id="archivePriceConfig"
          className="configLink"
          to={Routes.ArchiveConfiguration}
        >
          <SettingOutlined />
          {t('archive.configLinks.archivePrice')}
        </Link>
        <Link className="configLink" to={Routes.ArchiveDeletionsRequest}>
          <SettingOutlined />
          {t('archive.configLinks.deletionRequest')}
        </Link>
        <Button
          size="small"
          type="link"
          onClick={() => openKeywordModal()}
          icon={<SettingOutlined />}
        >
          {t('archive.configLinks.keywordEdition')}
        </Button>
      </div>
    </Spacer>
  );
};

export default ArchiveConfigurationLinks;

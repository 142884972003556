import { FormikProps, FormikState } from 'formik';
import get from 'lodash.get';

export const formikStatus = (formik: FormikState<any>, fieldName: string) => {
  return get(formik.errors, fieldName) && get(formik.touched, fieldName)
    ? 'error'
    : undefined;
};

export const formikErrorMessage = <T>(
  formik: FormikProps<T>,
  fieldName: keyof T,
) =>
  formik.touched[fieldName] ? formik.errors[fieldName]?.toString() : undefined;

import React from 'react';
import IsLoading from 'ui/isLoading';
import { List as AntdList, ConfigProvider, Empty } from 'antd';
import ErrorResult from 'ui/errorOverlay/errorResult';

const List: React.FC<{
  data?: any[];
  error: any;
  refetch: any;
  loading: boolean;
}> = ({ data, error, loading, refetch }) => {
  return (
    <ConfigProvider
      renderEmpty={() =>
        error ? (
          <ErrorResult refetch={refetch} />
        ) : (
          <Empty description="no data" />
        )
      }
    >
      <IsLoading loading={loading}>
        <AntdList
          size="small"
          dataSource={data}
          renderItem={(item) => <AntdList.Item>{item}</AntdList.Item>}
        />
      </IsLoading>
    </ConfigProvider>
  );
};

export default List;

import { useGetPatentQuery } from 'generated/graphql';
import { getPatentTimeline } from './getPatentTimeline';
import { useTranslation } from 'react-i18next';

export const useGetPatent = (id: number) => {
  const { t } = useTranslation();
  const { data, ...patentDataState } = useGetPatentQuery({
    variables: { id },
  });
  const patent = data?.patent_by_pk;

  return {
    patent,
    timeline: getPatentTimeline(t, patent),
    patentDataState,
  };
};

import { Result } from 'antd';
import { useEffect } from 'react';
import { useAppContext } from 'business/AppBootstrapper';
import ConnectedPage from 'business/user/helpers/connectedPage';
import DisconnectedPage from 'business/user/helpers/disconnectedPage';
import history from 'technical/history';
import Button from 'ui/button';
import errorReporting from 'technical/error-reporting';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { isConnected } = useAppContext();
  const { t } = useTranslation();
  useEffect(() => {
    errorReporting.info(new Error('404'), { location: history.location });
  }, []);

  const Page = isConnected ? ConnectedPage : DisconnectedPage;

  return (
    <Page>
      <Result
        status="404"
        title="404"
        subTitle={t('errors.notFoundPage')}
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            {t('common.back')}
          </Button>
        }
      />
    </Page>
  );
};

export default NotFoundPage;

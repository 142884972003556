import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { BdlUser } from 'business/admin/services/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { BdlUserParams } from 'business/configuration/accessManagement/services/types';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { TableProps } from 'ui/table/types';
import TagResults from 'ui/tag/tagResults';
import useTableActions from 'business/configuration/accessManagement/components/tables/services/useGetUserTableActions';
import React from 'react';
import { ITableAction } from 'business/configuration/accessManagement/components/tables/services/types';
import defaultColumns from './columns';

type BdlUsersTableProps = Omit<TableProps<BdlUser>, 'defaultColumns'> & {
  editUser: (user: BdlUserParams) => void;
};

const BdlUsersTable: React.FC<BdlUsersTableProps> = (props) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { pagination } = props;
  const {
    confirmDeleteUser,
    sendBackRegistration,
    updateUserStatus,
    isUserActivePending,
  } = useTableActions({ refetch: props.refetch, user, currentUser: user });

  const actions: ITableAction<BdlUser>[] = [
    {
      getTooltipTitle: (_data: BdlUser) => {
        return t('common.edit');
      },
      getIcon: (_data: BdlUser) => {
        return <EditOutlined />;
      },
      dataTestId: 'editUserButton',
      onClick: (data: BdlUser) => {
        props.editUser(data);
      },
    },
    {
      getTooltipTitle: (data: BdlUser) => {
        return t('pages.admin.bdlUsers.table.actions.tooltips.set', {
          context: data?.isActive ? 'inactive' : null,
        });
      },
      getIcon: (data: BdlUser) => {
        return data?.isActive ? <CloseOutlined /> : <CheckOutlined />;
      },
      dataTestId: 'editStatusButton',
      isDisabled: (data: BdlUser) => data.id === user.id,
      onClick: (data: BdlUser) => {
        updateUserStatus(data.id, data.isActive);
      },
    },
    {
      getTooltipTitle: (_data: BdlUser) => {
        return t('common.delete');
      },
      getIcon: (_data: BdlUser) => {
        return <DeleteOutlined />;
      },
      dataTestId: 'deleteUserButton',
      onClick: (data: BdlUser) => {
        confirmDeleteUser(data.id, data.fullName);
      },
    },
    {
      getTooltipTitle: (_data: BdlUser) => {
        return t('pages.admin.bdlUsers.table.actions.tooltips.sendEmail');
      },
      getIcon: (_data: BdlUser) => {
        return <MailOutlined />;
      },
      dataTestId: 'sendBackEmailButton',
      hide: isUserActivePending,
      onClick: (data: BdlUser) => {
        sendBackRegistration(data.id, data.email);
      },
    },
  ];

  return (
    <Table
      id="bdlUsersTable"
      {...props}
      defaultColumns={defaultColumns(t, user, actions)}
      header={
        <TagResults dataTestId="bdlUsersCount" total={pagination?.total ?? 0} />
      }
    />
  );
};

export default BdlUsersTable;

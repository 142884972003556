import { Trademark } from 'business/resources/trademark/services/types';
import { useGetTrademarkLinkQuery } from 'generated/graphql';

const internationalCountryCode = ['WO', 'MO'];
const isInternational = (countryCode: string) => {
  return internationalCountryCode.includes(countryCode);
};

export const useGetTrademarkLink = (trademark: Trademark | null) => {
  const { data: trademarkLinks } = useGetTrademarkLinkQuery({
    variables: {
      depositNumber: trademark?.depositNumber ?? '',
      familyId: trademark?.familyId ?? '',
      isInternational: isInternational(trademark?.country?.code ?? ''),
      id: trademark?.id ?? 0,
    },
    skip: trademark === null,
  });

  return {
    trademarkLinks: [
      ...(trademarkLinks?.internationalTrademark ?? []),
      ...(trademarkLinks?.trademarks ?? []),
    ],
  };
};

import { Companies } from 'business/admin/services/types';
import {
  Company_Bool_Exp,
  GetCompaniesQuery,
  GetCompaniesQueryVariables,
  useGetCompaniesQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { storageKeys } from 'technical/storage/constants';

const buildSearchConditions = (searchText: string): Company_Bool_Exp => ({
  _or: [...generateTextSearchConditions(searchText, ['companyRef', 'name'])],
});

export const useGetCompanies = () =>
  useQueryForTable<Companies, GetCompaniesQuery, GetCompaniesQueryVariables>({
    subStorageKey: storageKeys.companies,
    commonFiltersKeys: ['search'],
    useQuery: ({ limit, offset, orderBy, searchText }) =>
      useGetCompaniesQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: searchText ? buildSearchConditions(searchText) : {},
        },
      }),
    getTotal: (res) => res?.companyMV_aggregate.aggregate?.count ?? 0,
    map: (res) => res?.companyMV ?? [],
  });

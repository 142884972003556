import { i18n } from 'translations';
import { NamePath } from 'antd/lib/form/interface';
import { RuleObject } from 'antd/lib/form';
import { isBefore } from 'date-fns';
import { message } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const requiredRule = {
  required: true,
  message: i18n.t('errors.required'),
};

interface CustomRule {
  type?:
    | 'string'
    | 'number'
    | 'boolean'
    | 'method'
    | 'regexp'
    | 'integer'
    | 'float'
    | 'object'
    | 'enum'
    | 'date'
    | 'url'
    | 'hex'
    | 'email';
  // Any here because i18n does not return a string
  [key: string]: any;
}

export const positiveNumberRule: CustomRule = {
  type: 'number',
  min: 0,
  message: i18n.t('errors.too_small_number', { number: 0 }),
};

export const limitedInputLength: CustomRule = {
  type: 'string',
  max: 50,
  message: i18n.t('errors.too_long_number', { number: 50 }),
};

export const customLimitedInputLength: CustomRule = (lenghtMax: number) => ({
  type: 'string',
  max: lenghtMax,
  message: i18n.t('errors.too_long_number', { number: lenghtMax }),
});

export const limitedTextAreaLength: CustomRule = {
  type: 'string',
  max: 500,
  message: i18n.t('errors.too_long_number', { number: 500 }),
};

export const urlValidation: CustomRule = {
  pattern:
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  message: i18n.t('errors.wrong_url_format'),
};

export const notBeforeStartDate =
  (name: NamePath) =>
  ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
    validator(_rule: RuleObject, value: Date) {
      const startDate: Date = getFieldValue(name);
      if (!value) {
        return Promise.resolve();
      }
      if (isBefore(startDate, value)) {
        return Promise.reject(i18n.t('errors.before_start'));
      }
      return Promise.resolve();
    },
  });

export const requiredErrorAlert = () => {
  message.error(i18n.t('errors.missing_required_fields'));
};

export const passwordConfirmation =
  (name: NamePath) =>
  ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
    validator(_rule: RuleObject, value: string) {
      if (!value || getFieldValue(name) === value) {
        return Promise.resolve();
      }
      return Promise.reject(i18n.t('errors.passwords_did_not_match'));
    },
  });

import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { hasUserArchiveAccess } from 'business/archive/services';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import ArchivePage from '.';

const ArchivePageContainer = () => {
  const { user } = useLoggedInAppContext();
  const { t } = useTranslation();
  const userArchiveAccess = hasUserArchiveAccess(user);

  return (
    <ResourceNotFound resourceExists={userArchiveAccess}>
      <ConnectedPage breadcrumbs={[{ label: t('pages.archive.title') }]}>
        <ArchivePage user={user} />
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default ArchivePageContainer;

import { useAppContext } from 'business/AppBootstrapper';
import { Router as ReactDomRouter, Redirect, Route } from 'react-router-dom';
import history from 'technical/history';
import SwitchNotFoundHandler from 'technical/router/switch';
import { commonRoutesProps, routesPropsListBuilder } from './services';
import { RouteProps } from './types';
import Routes from './routes';

const routesListBuilder = (routesProps: RouteProps[]) =>
  routesProps.map(({ path, component, exact = true, redirectTo }) => {
    if (redirectTo) {
      return <Redirect key={path} from={path} exact={exact} to={redirectTo} />;
    }
    return <Route key={path} path={path} exact={exact} component={component} />;
  });

const shouldSavePathForRedirection = (
  path: string,
  isUserConnected: boolean,
) => {
  return (
    path !== Routes.Home &&
    !path.match(Routes.SignIn) &&
    !path.match(Routes.LoginCallback) &&
    !isUserConnected
  );
};

// WARNING: don't bump history version until this is fixed: https://github.com/ReactTraining/history/issues/804
// In any case test all navigation use cases before merging
const Router = () => {
  const appContext = useAppContext();
  const routesProps = routesPropsListBuilder(appContext);
  const originPath = window.location.pathname;
  const pathWithQueryParams = window.location.pathname + window.location.search;

  if (shouldSavePathForRedirection(originPath, appContext.isConnected)) {
    sessionStorage.setItem('originPath', pathWithQueryParams);
  }
  const buildedRoutes = routesListBuilder([
    ...commonRoutesProps,
    ...routesProps,
  ]);
  return (
    <ReactDomRouter history={history}>
      <SwitchNotFoundHandler>{buildedRoutes}</SwitchNotFoundHandler>
    </ReactDomRouter>
  );
};

export default Router;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useUpdateUserGroupsForm } from 'business/configuration/accessManagement/components/forms/hooks/useUpdateUserGroupsForm';
import { FC } from 'react';
import logger from 'technical/logger';
import UserGroupForm from 'business/configuration/accessManagement/components/forms/userGroupForm/userGroupForm';
import { User } from 'business/configuration/accessManagement/services/types';

interface UserMultiCompaniesModalProps {
  visible: boolean;
  handleClose: () => void;
  user: User;
}

const UserGroupsModal: FC<UserMultiCompaniesModalProps> = ({
  visible,
  handleClose,
  user,
}) => {
  const { form, onSubmitForm } = useUpdateUserGroupsForm({
    user,
    handleClose,
  });

  return (
    <Modal
      width={800}
      closable
      open={visible}
      onCancel={handleClose}
      onOk={() =>
        form
          .validateFields()
          .then(onSubmitForm)
          .catch((err) => logger.error(err))
      }
      closeIcon={<CloseCircleOutlined />}
    >
      <UserGroupForm user={user} form={form} />
    </Modal>
  );
};

export default UserGroupsModal;

import {
  Locale,
  differenceInYears,
  format,
  getYear,
  isBefore,
  isDate,
} from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { i18n } from 'translations';
import { getDateFormatter } from 'ui/table/columns';
import { DATE_FORMAT } from './constant';

/**
 * Return the number of period that occured between two dates
 * Exemple: from: 2010, to: 2020, period: 2
 * then nbPeriod = 5
 */

export const getNbPeriods = (fromDate: Date, period: number) => {
  const today = new Date();
  return Math.ceil(differenceInYears(today, fromDate) / period) || 1;
};

export const getYearWithDateAndPeriod = (fromDate: Date, period: number) => {
  const nbPeriods = getNbPeriods(fromDate, period);
  return nbPeriods * period + getYear(fromDate);
};

export const getLocaleDate = () => {
  const locales: Record<string, Locale> = {
    fr,
    en: enGB,
  };
  return locales[i18n.language] || fr;
};

export const formatDate = (date: Date, dateFormat?: string) => {
  return format(date, dateFormat ?? DATE_FORMAT.dayMonthYearWithSpace, {
    locale: getLocaleDate(),
  });
};

export const formatDateAndNumberString = ({
  nbToString,
  date,
}: {
  nbToString?: string | null;
  date?: Date;
}) => {
  if (!date && !nbToString) {
    return null;
  }
  const dateFormatted = isDate(date) ? getDateFormatter().format(date) : '';
  const pipe = isDate(date) && nbToString ? ' | ' : '';

  return `${dateFormatted}${pipe}${nbToString ?? ''}`;
};

export const dateCompareFn =
  <Key extends string, T extends { [k in Key]: Date | any | null }>(
    field: Key,
  ) =>
  (a: T, b: T) => {
    if (!a[field]) {
      return 1;
    }
    if (!b[field]) {
      return -1;
    }
    return new Date(a[field]).getTime() - new Date(b[field]).getTime();
  };

export const isBeforeToday = (date: Date) =>
  !!(date && isBefore(date, new Date()));

import { useLoggedInAppContext } from 'business/AppBootstrapper';
import notifyPromise from 'business/common/services/notifyPromise';
import { useUpdateUserMfaMutation } from 'generated/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import passwordValidationModal from 'ui/passwordValidationModal';

const useUpdateMFA = () => {
  const { t } = useTranslation();

  const { user } = useLoggedInAppContext();
  const [updateUserMFAMutation] = useUpdateUserMfaMutation();

  const [mfaEnabled, setMfaEnabled] = useState<boolean>(user.enabledMFA);
  const [loading, setLoading] = useState<boolean>(false);

  const updateUserMFA = (enabledMFA: boolean) =>
    notifyPromise(
      t,
      () =>
        updateUserMFAMutation({
          variables: { userId: user.id, enabledMFA },
        }).then(() => setMfaEnabled(enabledMFA)),
      {},
    ).then(() => {
      setLoading(false);
    });

  const editMFA = (enabledMFA: boolean) => {
    setLoading(true);
    passwordValidationModal({
      onOk: () => updateUserMFA(enabledMFA),
      modalTitle: t('user.mfa.modal.title', {
        context: enabledMFA ? 'enable' : 'disable',
      }),

      instructionText: t('user.mfa.modal.instructionText'),
      confirmButtonText: t('common.confirm'),
      props: {
        onCancel: () => setLoading(false),
      },
    });
  };

  return { mfaEnabled, editMFA, loading };
};

export default useUpdateMFA;

enum Routes {
  All = '*',

  LoginCallback = '/login/callback',

  Home = '/',
  Search = '/search',
  Profile = '/my-profile',

  SignIn = '/signin',
  ForgotPassword = '/forgot-password',
  WelcomSetPassword = '/welcome/:token',
  ResetPassword = '/reset-password/:token',

  Patent = '/patent',
  PatentFamily = '/patent/family/:id',
  PatentPage = '/patent/:id',

  Design = '/design',
  DesignFamily = '/design/family/:id',
  DesignPage = '/design/:id',

  Trademark = '/trademark',
  TrademarkPage = '/trademark/:id',
  TrademarkFamily = '/trademark/family/:id',

  Domain = '/domain',
  DomainFamily = '/domain/family/:id',
  DomainPage = '/domain/:id',

  Events = '/event',

  Archive = '/archive',

  Invoices = '/invoices',

  Logs = '/admin/logs',
  BdlUsers = '/admin/bdl-users',
  Users = '/admin/users',

  ArchiveSubscriptions = '/admin/archives/subscriptions',
  ArchiveConfiguration = '/admin/archives/configuration',
  ArchiveDeletionsRequest = '/admin/archives/subscriptions/deletionsRequest',
  ArchiveInvoices = '/admin/archives/invoices',

  DomainInvoices = '/admin/invoices/domain',

  Companies = '/admin/companies',
  CompanyDetails = '/admin/company/:id',
  CompanyGroups = '/admin/company/:id/group',
  GroupCreate = '/admin/company/:id/group/create',
  GroupUpdate = '/admin/company/:id/group/:groupId/update',

  TrademarkOsr = '/admin/osr/trademark',
  DesignOsr = '/admin/osr/design',

  CompanyArchiveConfiguration = '/admin/company/:id/archive/configuration',
  CompanyDomainInvoicingConfiguration = '/admin/company/:id/invoicing/configuration',

  SafranExportQuery = '/admin/exportQuery',

  OpsDocumentsPage = '/admin/patents-ops',

  /** V1 resources urls */
  ResourceAccessV1Url = '/backend/:resourceType/view/:id',
}

export default Routes;

import { FormInstance, Modal } from 'antd';
import Button from 'ui/button';
import UserForm from 'business/configuration/accessManagement/components/forms/userForm/userForm';
import { UserFormValues } from 'business/configuration/accessManagement/services/types';
import { useTranslation } from 'react-i18next';
import { UserWithGroups } from 'business/groups/services/types';
import { formListName } from 'business/configuration/accessManagement/components/forms/userForm/constants';
import logger from 'technical/logger';

interface UserModalProps {
  visible: boolean;
  onSubmit: (values: UserFormValues) => void;
  handleClose: () => void;
  groupOptions: { label: string; value: string }[];
  form: FormInstance;
  user?: UserWithGroups;
  companyId: number;
}

export const UserModal = ({
  visible,
  onSubmit,
  handleClose,
  groupOptions,
  user,
  form,
  companyId,
}: UserModalProps) => {
  const { t } = useTranslation();

  const handleSubmit = (val: UserFormValues) => {
    const groupsIds: string[] = [
      ...(form.getFieldValue(formListName) ?? []).map(
        (m: { groupId: string }) => m.groupId,
      ),
      form.getFieldValue('groupId'),
    ];

    const submitInfo = {
      userId: user?.id,
      email: val.email,
      firstName: val.firstName,
      lastName: val.lastName,
      groupsIds,
    };
    return onSubmit(submitInfo);
  };

  const handleValidate = async () =>
    form
      .validateFields()
      .then(handleSubmit)
      .catch((error) => {
        logger.error(error);
      });

  return (
    <Modal
      open={visible}
      closable
      onOk={handleValidate}
      onCancel={handleClose}
      forceRender
      footer={
        <Button
          type="primary"
          onClick={handleValidate}
          data-test-id="user-creation-submit-company"
        >
          {t('user.infoModal.okButton', { context: user ? 'edit' : undefined })}
        </Button>
      }
    >
      <UserForm<UserFormValues>
        user={user}
        form={form}
        groupOptions={groupOptions}
        companyId={companyId}
      />
    </Modal>
  );
};

export default UserModal;

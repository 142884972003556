import { ApolloError } from '@apollo/client';
import { notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UserWithGroups } from 'business/groups/services/types';
import {
  useEditUserCompanyDataMutation,
  useUpdateUserGroupMutation,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFormValues } from 'business/configuration/accessManagement/services/types';
import errorReporting from 'technical/error-reporting';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface UpdateUserFormHookParam {
  refetch?: () => void;
}

const useUpdateUserForm = ({ refetch }: UpdateUserFormHookParam) => {
  const { t } = useTranslation();
  const [editUserData] = useEditUserCompanyDataMutation();
  const [editUserGroup] = useUpdateUserGroupMutation();
  const [form] = useForm<UserFormValues>();
  const [user, setUser] = useState<UserWithGroups>();
  const { user: currentUser } = useLoggedInAppContext();

  useEffect(() => form.resetFields(), [user]);

  const handleClose = () => {
    form.resetFields();
    setUser(undefined);
    refetch?.();
  };

  const updateUserGroups = async ({
    userId,
    groupsIds,
  }: {
    userId: string;
    groupsIds: string[];
  }) => {
    const userGroupObject = groupsIds.map((groupId) => ({ userId, groupId }));
    if (!userGroupObject.length) {
      // No groups to update no need to send the query
      return true;
    }
    return editUserGroup({
      variables: {
        userId,
        objects: userGroupObject,
      },
    });
  };

  const onEdit = async (values: UserFormValues) => {
    try {
      if (!values.userId) {
        throw new Error('UserId not defined');
      }
      await editUserData({
        variables: {
          userId: values.userId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      });

      const isCurrentUser = currentUser.id === values.userId;

      if (!isCurrentUser) {
        await updateUserGroups({
          userId: values.userId,
          groupsIds: values.groupsIds,
        });
      }

      notification.success({
        message: t('user.infoModal.notifications.success', { context: 'edit' }),
      });
      return handleClose();
    } catch (err) {
      errorReporting.error(err);
      return notification.error({
        message: t('user.infoModal.notifications.error', {
          context: err instanceof ApolloError ? err.message : null,
        }),
      });
    }
  };
  return {
    onEdit,
    form,
    user: user
      ? {
          ...user,
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
        }
      : undefined,
    setUser,
  };
};

export default useUpdateUserForm;

import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

import { useTranslation } from 'react-i18next';
import Select from 'ui/select';
import Spacer from 'ui/spacer';

interface CompanyGroupSelectProps {
  isDisabled?: boolean;
  groups: {
    label: string;
    code: string;
  }[];
  onRemove: () => void;
  name: number;
}
const CompanyGroupSelect = ({
  isDisabled = false,
  groups,
  onRemove,
  name,
}: CompanyGroupSelectProps) => {
  const { t } = useTranslation();

  return (
    <Spacer align="baseline" className="flexDisplay">
      <Form.Item className="flex1" name={[name, 'companyName']}>
        <Input readOnly disabled />
      </Form.Item>

      <Form.Item
        required
        className="flex1"
        data-testid="selectGroupItem"
        name={[name, 'groupId']}
        rules={[
          {
            required: true,
            message: t('errors.required'),
          },
        ]}
      >
        <Select
          placeholder={t('user.groupModal.selectGroup')}
          disabled={isDisabled}
          options={groups}
          data-test-id="multiCompanyGroup"
        />
      </Form.Item>

      <DeleteOutlined
        className="iconFontSize"
        disabled={isDisabled}
        data-testid="deleteItem"
        onClick={onRemove}
      />
    </Spacer>
  );
};

export default CompanyGroupSelect;

import Routes from 'business/router/routes';
import { Link, generatePath } from 'react-router-dom';
import styles from './index.module.scss';
import Spacer from 'ui/spacer';
import classNames from 'classnames';
import Flex from 'ui/flex';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isDefined } from 'technical/validation';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface HeaderTitlesProps {
  title: string;
  subtitle: string;
  dataTestId?: string;
  children?: React.ReactNode;
  family?: { id?: string | null; route: Routes };
}

const HeaderTitles = ({
  title,
  subtitle,
  dataTestId,
  children,
  family,
}: HeaderTitlesProps) => {
  const { t } = useTranslation();
  const titleNode =
    isDefined(family) && isDefined(family.id) ? (
      <Tooltip
        title={t('common.back', { context: 'family' })}
        placement="right"
      >
        <Link
          className={classNames(styles.familyLink, 'headerTitle')}
          to={generatePath(family.route, { id: family.id })}
        >
          <Spacer direction="horizontal" align="center" size="xxsmall">
            <ArrowLeftOutlined />
            <span data-test-id="title" className="headerTitle">
              {title}
            </span>
          </Spacer>
        </Link>
      </Tooltip>
    ) : (
      <h1 data-test-id="title" className="headerTitle">
        {title}
      </h1>
    );

  return (
    <Flex
      column
      className="headerContainer"
      data-test-id={dataTestId}
      alignItems="flex-start"
    >
      {titleNode}
      <h2 data-test-id="subTitle" className="subTitle">
        {subtitle}
      </h2>
      {children}
    </Flex>
  );
};

export default HeaderTitles;

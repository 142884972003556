import { Form, Radio } from 'antd';
import { CheckList } from 'business/groups/services/types';

interface RadioOptionsProps {
  card: CheckList;
}

const RadioOptions = ({ card }: RadioOptionsProps) => {
  return (
    <Form.Item name={card.radioFormName}>
      <Radio.Group>
        {card.radioOptions?.map((list) => (
          <Radio key={list.value} value={list.value}>
            {list.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioOptions;

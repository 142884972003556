import { Form, Input, Modal, notification } from 'antd';
import { useGetResourceCommentQuery } from 'generated/graphql';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import { useCommentActions } from 'business/resources/shared/services/comments/useCommentActions';
import { useState } from 'react';
import Comment from './comment';
import { useAppContext } from 'business/AppBootstrapper';
import Button from 'ui/button';
import Flex from 'ui/flex';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';

const ResourceCommentList = ({
  id,
  resourceType,
}: {
  id: number;
  resourceType: string;
}) => {
  const { t } = useTranslation();
  const [createForm] = useForm<{ comment: string }>();
  const [updateForm] = useForm();
  const { user } = useAppContext();
  const [commentDeleteId, setCommentDeleteId] = useState<string | null>(null);
  const [commentUpdateData, setCommentUpdateData] = useState<{
    id: string;
    comment: string;
  } | null>(null);
  const { onDelete, onUpdate, userCreateCommentEnabled, onCreate } =
    useCommentActions({ resourceId: id, resourceType });
  const { data, loading, refetch } = useGetResourceCommentQuery({
    fetchPolicy: 'no-cache',
    variables: {
      resourceId: id,
      resourceType,
    },
  });

  const comments = data?.getResourceComments ?? [];

  const onCreateComment = ({ comment }: { comment: string }) => {
    onCreate({ resourceId: id, resourceType, comment: comment })
      .then(() => {
        refetch();
        notification.success({
          message: t('notes.add', { context: 'success' }),
          duration: 5,
        });
      })
      .catch((err) => {
        logger.error(err);
        notification.error({
          message: t('notes.add', { context: 'error' }),
        });
      });
  };

  const onUpdateComment = ({ comment }: { comment: string }) => {
    if (!commentUpdateData?.id) {
      return null;
    }
    onUpdate({
      resourceId: id,
      id: commentUpdateData?.id,
      resourceType,
      comment: comment,
    })
      .then(() => {
        refetch();
        notification.success({
          message: t('notes.edit', { context: 'success' }),
          duration: 5,
        });
        setCommentUpdateData(null);
      })
      .catch((err) => {
        logger.error(err);
        notification.error({
          message: t('notes.edit', { context: 'error' }),
        });
      });
  };

  return (
    <Spacer
      direction="vertical"
      size="small"
      style={{ maxHeight: '420px', overflowY: 'auto' }}
    >
      <IsLoading loading={loading}>
        <Spacer direction="vertical" size="small">
          {comments.map((c) => (
            <Comment
              key={c.id}
              comment={c}
              userId={user?.id}
              onEdit={(currentUpdatedData) => {
                setCommentUpdateData(currentUpdatedData);
                updateForm.setFieldsValue({
                  comment: currentUpdatedData?.comment,
                });
              }}
              onDelete={setCommentDeleteId}
            />
          ))}
        </Spacer>
      </IsLoading>
      {userCreateCommentEnabled ? (
        <Form
          form={createForm}
          onFinish={onCreateComment}
          style={{ position: 'sticky', bottom: 0 }}
        >
          <Flex justify="space-between">
            <Form.Item name="comment" style={{ width: '100%' }}>
              <Input size="large" placeholder="Ajouter un commentaire" />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              {t('notes.add', { context: 'button' })}
            </Button>
          </Flex>
        </Form>
      ) : null}

      <Modal
        onCancel={() => setCommentDeleteId(null)}
        onOk={() => {
          if (commentDeleteId) {
            onDelete(commentDeleteId)
              .then(() => {
                refetch();
                notification.success({
                  message: t('notes.delete', { context: 'success' }),
                  duration: 5,
                });
                setCommentDeleteId(null);
              })
              .catch((err) => {
                logger.error(err);
                notification.error({
                  message: t('notes.delete', { context: 'error' }),
                });
              });
          }
        }}
        title={t('notes.delete', { context: 'title' })}
        open={!!commentDeleteId}
      >
        <span>{t('notes.delete', { context: 'confirmation' })} </span>
      </Modal>

      <Modal
        open={!!commentUpdateData?.id}
        onCancel={() => {
          setCommentUpdateData(null);
        }}
        onOk={() => updateForm.submit()}
        title={t('notes.edit', { context: 'title' })}
      >
        <Form
          form={updateForm}
          initialValues={{ comment: commentUpdateData?.comment }}
          onFinish={(updateFormData) =>
            onUpdateComment({
              ...updateFormData,
            })
          }
        >
          <Form.Item name="comment">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Spacer>
  );
};

export default ResourceCommentList;

import { OpsDocument } from 'business/admin/services/types';
import {
  GetAllOpsDocumentsQuery,
  GetAllOpsDocumentsQueryVariables,
  useGetAllOpsDocumentsQuery,
  useGetOpsDocumentsStatsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';

const useGetPatentDocuments = () => {
  const opsDocumentsStats = useGetOpsDocumentsStatsQuery();

  const queryForTable = useQueryForTable<
    OpsDocument,
    GetAllOpsDocumentsQuery,
    GetAllOpsDocumentsQueryVariables
  >({
    subStorageKey: storageKeys.patentOpsDocuments,
    useQuery: ({ limit, offset, orderBy, searchText }) =>
      useGetAllOpsDocumentsQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: { search: searchText },
        },
      }),
    map: (data) => data?.patentDocumentSearch?.resources ?? [],
    getTotal: (data) => data?.patentDocumentSearch?.count ?? 0,
  });

  return {
    queryForTable,
    opsDocumentsStats: {
      docCount:
        opsDocumentsStats.data?.file_patentDocument_aggregate.aggregate
          ?.count ?? 0,
      totalSize:
        opsDocumentsStats.data?.file_patentDocument_aggregate.aggregate?.sum
          ?.fileSize ?? 0,
      ...opsDocumentsStats,
    },
  };
};

export default useGetPatentDocuments;

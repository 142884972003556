import { TableRowSelection } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import {
  ArchiveConfigurationListQuery,
  useInsertArchivePackageCompanyMutation,
} from 'generated/graphql';

import { useState } from 'react';
import { notification } from 'antd';
import logger from 'technical/logger';
import errorReporting from 'technical/error-reporting';
import Routes from 'business/router/routes';
import history from 'technical/history';
import { generatePath } from 'react-router';
import confirmationModal from 'ui/modalConfirm';

type ArchiveSubscription =
  ArchiveConfigurationListQuery['archive_archivePackage'][number];

interface CompanyArchiveSubscription {
  companyId: number;
}

export const useSelectArchiveSubscription = ({
  companyId,
}: CompanyArchiveSubscription) => {
  const { t } = useTranslation();
  const [insertSubscription] = useInsertArchivePackageCompanyMutation();
  const [subscription, setSubscription] = useState<ArchiveSubscription>();

  const rowSelection: TableRowSelection<ArchiveSubscription> = {
    type: 'radio',
    onSelect: (sub: ArchiveSubscription) => {
      setSubscription(sub);
    },
  };

  const submitSubscription = async () => {
    try {
      if (!subscription?.id) {
        throw new Error('Missing subscription id');
      }

      await insertSubscription({
        variables: {
          data: {
            companyId,
            archivePackageId: subscription?.id,
          },
        },
      });

      notification.success({
        message: t('archive.config.success', {
          context: 'companySubscription',
        }),
      });
      history.push(generatePath(Routes.CompanyDetails, { id: companyId }));
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        message: 'Error trying to insert new subscription',
        companyId,
        subscriptionData: subscription,
        err,
      });
      notification.error({
        message: t('errors.generic'),
      });
    }
  };

  const openModalSubmitSubscription = () => {
    confirmationModal(
      t,
      submitSubscription,
      t('archive.config.confirmationModalTitle', {
        context: 'subscribe',
        title: subscription?.title,
      }),
    );
  };

  return {
    subscription,
    rowSelection,
    openModalSubmitSubscription,
  };
};

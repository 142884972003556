import { Parser } from 'technical/filters/types';
import * as yup from 'yup';

export const storageNumberParser = (defaultValue: number): Parser<number> => ({
  defaultValue,
  readParse: (param) =>
    yup
      .number()
      .required()
      .validateSync(param ?? defaultValue),
  writeParse: (value) => value.toString(),
});

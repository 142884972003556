import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import DomainFamiliesTable from 'business/resources/domain/components/domainFamiliesTable';
import { useGetDomainFamilies } from 'business/resources/domain/services/useGetDomainFamilies';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const DomainFamiliesPage: React.FC = () => {
  const { data, tableProps } = useGetDomainFamilies();
  const { onExport } = useDownloadTableToXlsx();

  return (
    <ConnectedPage translationKey="domains">
      <DomainFamiliesTable
        data-test-id="domainFamiliesTable"
        dataSource={data}
        onExport={(columns) => {
          onExport(columns, 'domainFamily');
        }}
        {...tableProps}
      />
    </ConnectedPage>
  );
};

export default DomainFamiliesPage;

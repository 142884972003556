import { FormInstance, Switch } from 'antd';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import MultiCompaniesFormList from 'business/configuration/accessManagement/components/forms/multiCompaniesFormList/multiCompaniesFormList';
import { formListName } from 'business/configuration/accessManagement/components/forms/userForm/constants';

interface MultiCompaniesProps<T> {
  form: FormInstance<T>;
  isDisabled?: boolean;
  selectedCompaniesId: number[];
  currentCompanyId?: number;
}

const MultiCompanies = <T,>({
  form,
  selectedCompaniesId,
  isDisabled = false,
  currentCompanyId,
}: MultiCompaniesProps<T>) => {
  const { t } = useTranslation();
  const [displayForm, setDisplayForm] = useState(!!selectedCompaniesId.length);

  return (
    <Spacer direction="vertical" size="small">
      <Spacer size="small">
        <Switch
          data-test-id="multiCompaniesSwitch"
          checked={displayForm}
          onClick={setDisplayForm}
          disabled={isDisabled}
        />
        <h3>{t('user.infoModal.title', { context: 'multi' })}</h3>
      </Spacer>
      {displayForm && (
        <MultiCompaniesFormList
          form={form}
          formListName={formListName}
          defaultSelectedCompaniesId={selectedCompaniesId}
          currentCompanyId={currentCompanyId}
        />
      )}
    </Spacer>
  );
};

export default MultiCompanies;

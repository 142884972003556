import {
  GetTrademarkFamiliesSearchQuery,
  GetTrademarkFamiliesSearchQueryVariables,
  useGetTrademarkFamiliesSearchQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';
import { TrademarkFamilies } from './types';
import { useGetFamiliesImages } from './useGetFamiliesImages';
import { isDefined } from 'technical/validation';

export const useGetTrademarkFamiliesData = () =>
  useQueryForTable<
    TrademarkFamilies,
    GetTrademarkFamiliesSearchQuery,
    GetTrademarkFamiliesSearchQueryVariables
  >({
    subStorageKey: storageKeys.trademarkFamilies,
    commonFiltersKeys: ['search', 'isEffective'],
    useQuery: ({ searchText, isEffective, ...filters }) =>
      useGetTrademarkFamiliesSearchQuery({
        variables: {
          where: {
            isEffective: !!isEffective,
            search: searchText,
          },
          ...filters,
        },
        fetchPolicy: 'network-only',
      }),
    getTotal: (res) => res?.trademarkFamilySearch?.count ?? 0,
    map: (res) => res?.trademarkFamilySearch?.resources ?? [],
  });

export const useGetTrademarkFamilies = () => {
  const { data, error, loading, tableProps } = useGetTrademarkFamiliesData();
  const { imageList, loading: imageLoading } = useGetFamiliesImages(
    data.map((d) => d.id).filter(isDefined),
  );

  const trademarkWithImages = data.map((d) => {
    const image = imageList.filter((i) => i.id === d.id);
    return {
      ...d,
      image: imageLoading ? 'loading' : image[0]?.image,
    };
  });

  return {
    data: trademarkWithImages,
    error,
    loading,
    tableProps,
  };
};

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import classnames from 'classnames';
import styles from './index.module.scss';

interface AdvancedFiltersDrawerActionsProps<T> {
  onApplyFilters: (filters: T, closeFilters?: boolean) => void;
  onClickNewFilter: () => void;
  resetFilters: () => void;
}

const AdvancedFiltersDrawerActions = <T,>({
  onClickNewFilter,
  onApplyFilters,
  resetFilters,
}: AdvancedFiltersDrawerActionsProps<T>) => {
  const { t } = useTranslation();

  return (
    <Spacer direction="vertical" size="xsmall" className={styles.buttons}>
      <Button
        htmlType="submit"
        size="small"
        type="primary"
        className="flex1"
        data-test-id="advancedSearch-applyFilters-button"
      >
        {t('pages.advancedSearch.filtersDrawer.applyFilters')}
      </Button>
      <Button
        type="primary"
        size="small"
        className={classnames('flex1', styles.filterButton)}
        onClick={onClickNewFilter}
        data-test-id="advancedSearch-createCustomFilter-button"
      >
        {t('pages.advancedSearch.customFilters.create')}
      </Button>
      <Button
        type="link"
        size="small"
        onClick={() => {
          onApplyFilters({} as T);
          resetFilters();
        }}
        data-test-id="advancedSearch-resetFilters-button"
      >
        {t('pages.advancedSearch.filtersDrawer.resetFilters')}
      </Button>
    </Spacer>
  );
};

export default AdvancedFiltersDrawerActions;

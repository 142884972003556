import { TFunction } from 'i18next';

export const GROUP_NAME_RULES = (t: TFunction) => [
  {
    required: true,
    message: t('errors.required'),
  },
  {
    min: 3,
    required: true,
    message: t('errors.too_short'),
  },
  {
    max: 255,
    required: true,
    message: t('errors.too_long'),
  },
];

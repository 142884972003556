import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import EventsSearchTabs from 'business/resources/events/components/eventsSearchTabs';

import { useFetchAllEvents } from 'business/resources/events/services/useFetchAllEvents';
import { removeUndefinedField } from 'technical/validation/index';
import Spacer from 'ui/spacer';
import EventTablesActions from 'business/resources/events/components/eventTablesActions';
import AdvancedFiltersDrawer from 'business/advancedSearch/components/advancedFiltersDrawer';
import useEventAdvancedFilters from 'business/resources/events/services/useEventAdvancedFilters';
import { EventAdvancedFilters } from 'business/resources/events/services/types';
import { notification } from 'antd';
import logger from 'technical/logger';
import errorReporting from 'technical/error-reporting';
import { useFilters } from 'technical/filters/useFilters';
import { filtersStorageKey, storageKeys } from 'technical/storage/constants';
import { useSessionStorage } from 'technical/storage/useSessionStorage';
import { storageEventFilter } from 'technical/filters/parser/storageParser/advancedFiltersParser';

const DEFAULT_TAB = 'all';

const EventsPage: React.FC = () => {
  const { t } = useTranslation();

  const {
    storageValues: advancedFilters,
    setStorageValues: setAdvancedFilters,
    resetToPreviousValue,
  } = useSessionStorage<EventAdvancedFilters>({
    storageKey: filtersStorageKey,
    subKey: storageKeys.eventAdvancedFilters,
    filters: storageEventFilter(),
  });

  const {
    filtersValues: { search, family: filterFamily, filtersOpen },
    setFilterValue,
  } = useFilters({
    subStorageKey: storageKeys.eventPage,
    urlFilterKeys: ['family', 'filtersOpen', 'search'],
  });

  const allEvents = useFetchAllEvents(
    advancedFilters,
    setAdvancedFilters,
    (err) => {
      logger.error(err);
      errorReporting.error(err);
      resetToPreviousValue();
      notification.error({
        message: t('errors.filterError'),
      });
    },
    search,
  );

  const setFilterOpen = (bool: boolean) => setFilterValue('filtersOpen', bool);

  const family = filterFamily ?? DEFAULT_TAB;
  const { components } = useEventAdvancedFilters(family);

  return (
    <ConnectedPage translationKey="events">
      <Spacer direction="vertical">
        <Spacer align="center" size="small">
          <h1 className="headerTitle flex1">
            {t('pages.event.searchPage.title')}
          </h1>
          <EventTablesActions
            onSearch={(value) => setFilterValue('search', value)}
            searchDefaultValue={search}
            onClickFiltersButton={() => setFilterOpen(true)}
          />
        </Spacer>

        <EventsSearchTabs
          family={family}
          onClickTabFamily={(f) => setFilterValue('family', f)}
          onClickAdvancedFilter={() => setFilterOpen(true)}
          data={allEvents}
          filters={advancedFilters}
        />

        <AdvancedFiltersDrawer
          family={family}
          open={filtersOpen}
          onClose={() => setFilterOpen(false)}
          filtersValues={advancedFilters[family]?.filters}
          onApplyFilters={(newFilters) => {
            setAdvancedFilters({
              ...advancedFilters,
              [family]: {
                ...advancedFilters[family],
                filters: removeUndefinedField(newFilters),
              },
            });
            setFilterOpen(false);
          }}
          filterComponents={components}
        />
      </Spacer>
    </ConnectedPage>
  );
};

export default EventsPage;

import { FormListOperation } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import {
  useGetCompaniesWithGroupsQuery,
  useSearchCompaniesLazyQuery,
} from 'generated/graphql';
import { useMemo, useState } from 'react';

const minCharactersToFetch = 3;

interface MultiCompaniesListParam {
  form: FormInstance;
  formName: string;
  defaultSelectedCompaniesId: number[];
  currentCompanyId?: number;
}

const useMultiCompaniesList = ({
  form,
  formName,
  defaultSelectedCompaniesId,
  currentCompanyId,
}: MultiCompaniesListParam) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [selectedCompaniesId, setSelectedCompaniesId] = useState<number[]>(
    defaultSelectedCompaniesId,
  );
  const [fetchCompanies, { data }] = useSearchCompaniesLazyQuery();

  const { data: selectedCompaniesGroups, loading } =
    useGetCompaniesWithGroupsQuery({
      variables: {
        companyIds: [...selectedCompaniesId],
      },
    });

  const onSearch = (value: string) => {
    if (value.length >= minCharactersToFetch) {
      fetchCompanies({
        variables: {
          input: `%${value}%`,
          excludedIds: currentCompanyId
            ? [...selectedCompaniesId, currentCompanyId]
            : selectedCompaniesId,
        },
      });
    }
  };

  const onRemove = (remove: FormListOperation['remove'], name: number) => {
    const companyId = form.getFieldValue([formName, name, 'companyId']);
    setSelectedCompaniesId(
      selectedCompaniesId.filter((id) => id !== companyId),
    );
    remove(name);
  };

  const onSelect =
    (add: FormListOperation['add']) =>
    (
      value: string | number,
      options: { label: string; value: string | number },
    ) => {
      add({
        companyId: Number(value),
        companyName: options.label,
      });
      setSelectedCompaniesId([...selectedCompaniesId, Number(value)]);
      setAutoCompleteValue('');
    };

  const getGroupSelectOptions = ({ name }: { name: number }) => {
    const companyGroups = selectedCompaniesGroups?.company.find(
      ({ id }) => form.getFieldValue([formName, name, 'companyId']) === id,
    );

    return (
      companyGroups?.groups.map(({ id, name: groupName }) => ({
        label: groupName,
        dataTestId: `multi-${groupName}`,
        code: id,
      })) ?? []
    );
  };

  const companiesOptions = useMemo(() => {
    return data?.company
      .filter((c) => !selectedCompaniesId.includes(c.id))
      .map(({ id, name }) => ({
        value: String(id),
        label: name,
      }));
  }, [data?.company, selectedCompaniesId]);

  return {
    onSelect,
    onSearch,
    onRemove,
    loading,
    getGroupSelectOptions,
    setAutoCompleteValue,
    autoCompleteValue,
    companiesOptions,
  };
};

export default useMultiCompaniesList;

import { Button, Card, Form, Input } from 'antd';
import UserFormFields from 'business/common/form/userFormFields';
import notifyPromise from 'business/common/services/notifyPromise';
import {
  useGetArchiveInvoiceConfigurationQuery,
  useUpdateArchiveInvoiceConfigurationMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import Spacer from 'ui/spacer';

interface ArchiveInvoiceConfigurationProps {
  companyId: number;
}

const ArchiveInvoiceConfiguration = ({
  companyId,
}: ArchiveInvoiceConfigurationProps) => {
  const { data, error, loading } = useGetArchiveInvoiceConfigurationQuery({
    variables: {
      id: companyId,
    },
  });

  const { t } = useTranslation();

  const [insertArchiveInvoiceConfiguration] =
    useUpdateArchiveInvoiceConfigurationMutation();

  const invoiceConfiguration = data?.company_by_pk?.invoiceConfiguration ?? {};

  return (
    <ErrorOverlay error={error} loading={loading}>
      <Card className="elevation1">
        <Form
          layout="vertical"
          initialValues={invoiceConfiguration}
          onFinish={(values) =>
            notifyPromise(t, () =>
              insertArchiveInvoiceConfiguration({
                variables: {
                  object: { ...values, companyId },
                },
              }),
            )
          }
        >
          <Spacer direction="vertical" size="xsmall">
            <UserFormFields nameOnSameLine requiredField={false} />

            <Form.Item
              label="Cc"
              rules={[
                { type: 'email', message: t('errors.wrong_email_address') },
              ]}
              name="cc"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t(
                'pages.companyDetails.invoiceConfiguration.clientReference',
              )}
              name="clientReference"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="SIRENnumber"
              label={t('pages.companyDetails.invoiceConfiguration.SIRENnumber')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t(
                'pages.companyDetails.invoiceConfiguration.holderVATnumber',
              )}
              name="holderVATnumber"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ marginTop: 15 }}
                htmlType="submit"
              >
                {t('pages.companyDetails.invoiceConfiguration.save')}
              </Button>
            </Form.Item>
          </Spacer>
        </Form>
      </Card>
    </ErrorOverlay>
  );
};

export default ArchiveInvoiceConfiguration;

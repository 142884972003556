import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, List, ListProps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EditableListProps
  extends Omit<ListProps<string>, 'dataSource' | 'renderItem' | 'footer'> {
  defaultValue?: string[];
  onChange: (value: string[]) => void;
  /**
   * Debounce between changes and call to onChange function
   */
  addOptionLabel?: string;
}

const EditableList = ({
  defaultValue = [],
  onChange,
  addOptionLabel,
  ...listProps
}: EditableListProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string[]>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <List
      {...listProps}
      dataSource={value}
      footer={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setValue([...value, '']);
          }}
        >
          {addOptionLabel ?? t('common.addOption')}
        </Button>
      }
      renderItem={(val, index) => (
        <List.Item>
          <Input.Group compact>
            <Input
              value={val}
              style={{ width: 'calc(100% - 40px)' }}
              onChange={(event) => {
                const newValue = [...value];
                newValue[index] = event.target.value;
                setValue(newValue);
                onChange(newValue);
              }}
            />
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => {
                const newValue = [...value];
                newValue.splice(index, 1);
                setValue(newValue);
                onChange(newValue);
              }}
            />
          </Input.Group>
        </List.Item>
      )}
    />
  );
};

export default EditableList;

import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

export interface SimpleTableProps<T> extends Omit<TableProps<T>, 'columns'> {
  columnsTitleContext?: string;
  columns: (ColumnType<T> & { key: string })[];
  simpleSort?: boolean;
}

type SupportedValues = string | number | null | undefined | Date;

export type Records = { [key: string]: SupportedValues };

const genericSort = (key: string) => (a: Records, b: Records) =>
  (a[key] ?? 0).toString().localeCompare((b[key] ?? 0).toString());

const SimpleTable = <T extends Records>({
  columnsTitleContext,
  columns,
  simpleSort,
  pagination = false,
  dataSource,
  ...props
}: SimpleTableProps<T>) => {
  const { t } = useTranslation();

  return (
    <Table<T>
      {...props}
      dataSource={dataSource?.map((data, i) => ({ ...data, key: i }))}
      columns={columns.map((column) => ({
        ...column,
        dataIndex: column.key,
        ...(simpleSort ? { sorter: genericSort(column.key) } : {}),
        ...(columnsTitleContext
          ? {
              title: t(`${columnsTitleContext}.${column.key}`),
            }
          : {}),
      }))}
      showSorterTooltip={false}
      pagination={pagination}
    />
  );
};

export default SimpleTable;

import { Avatar } from 'antd';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { ResourceType } from 'business/common/services/types';
import { mapStyles } from 'ui/map/styles';
import styles from 'ui/map/index.module.scss';
import { RESOURCE_STATUSES } from 'ui/map/constants';

const statusDisplay = (t: TFunction, resourceType: ResourceType) => {
  return RESOURCE_STATUSES[resourceType].map((key) => {
    const style = mapStyles[key];
    return (
      <Flex key={key} alignItems="center">
        <Avatar
          shape="square"
          size="default"
          className="noShrink"
          style={{ backgroundColor: style.default.fill }}
        />
        <span className={styles.status}>
          {t(`map.legend.${key}`, { context: resourceType })}
        </span>
      </Flex>
    );
  });
};

interface MapStatusProps {
  resourceType: ResourceType;
}

const MapStatus: React.VFC<MapStatusProps> = ({ resourceType }) => {
  const { t } = useTranslation();
  return (
    <Flex column className="fullHeight">
      <h3>{t('map.status')}</h3>
      <Flex column justify="space-between" className="fullHeight">
        <Spacer direction="vertical" size="small">
          {statusDisplay(t, resourceType)}
        </Spacer>
      </Flex>
    </Flex>
  );
};

export default MapStatus;

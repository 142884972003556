import { Form } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import { NullableDateRange } from 'technical/filters/types';

import { isDefined } from 'technical/validation';
import { RangePicker } from 'ui/date/datePicker';
import { FilterField } from '..';

type DateRangePickerProps = Omit<
  RangePickerProps<Date>,
  'value' | 'onChange'
> & {
  value?: NullableDateRange;
  onChange?: (value?: NullableDateRange) => void;
};

const castToNullableDateRange = (
  value?: null | { from: Date | string | null; to: Date | string | null },
): [Date | null, Date | null] | undefined =>
  isDefined(value)
    ? [
        value.from ? new Date(value.from) : null,
        value.to ? new Date(value.to) : null,
      ]
    : undefined;

const DateRangePicker = ({
  value,
  onChange,
  ...datePickerProps
}: DateRangePickerProps) => (
  <RangePicker
    value={castToNullableDateRange(value)}
    onChange={(val) => {
      onChange?.(val ? { from: val[0], to: val[1] } : undefined);
    }}
    {...datePickerProps}
    picker="date"
  />
);

type DateRangeFieldFilterOptions = {
  datePickerProps?: Omit<RangePickerProps<Date>, 'value' | 'onChange'>;
};

const DateRangeFieldFilter: FilterField<DateRangeFieldFilterOptions> = {
  component: ({ datePickerProps, ...formItemProps }) => (
    <Form.Item {...formItemProps}>
      <DateRangePicker
        {...datePickerProps}
        picker="date"
        style={{ width: '100%' }}
      />
    </Form.Item>
  ),
};

export default DateRangeFieldFilter;

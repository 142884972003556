import { useMemo } from 'react';
import { Route } from 'react-router';
import NotFoundPage from 'ui/notFoundPage';

const ReportingNoMatch = () => {
  const notFoundComponent = useMemo(() => {
    return () => <NotFoundPage />;
  }, []);

  return <Route component={notFoundComponent} />;
};

export default ReportingNoMatch;

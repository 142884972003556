import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Card from 'ui/card';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import UserProfilePasswordForm from 'business/configuration/accessManagement/components/forms/userProfilePasswordForm/userProfilePasswordForm';
import styles from './index.module.scss';
import UpdateLanguage from './components/updateLanguage';
import UserInformation from './components/userInformation';
import MfaSetting from './components/mfaSetting';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const {
    user: {
      firstName,
      lastName,
      privateInfos: { email },
    },
  } = useLoggedInAppContext();

  return (
    <ConnectedPage>
      <Spacer direction="vertical" size="small">
        <Flex justify="flex-start">
          <h1 className="headerTitle">{t('common.profile')}</h1>
        </Flex>
        <Flex justify="space-around" className={styles.cardContainer}>
          <Card fixedWith>
            <Spacer size="middle" direction="vertical">
              <Flex column>
                <h2>{t('pages.profile.form.accountManagement.userInfo')}</h2>
                <UserInformation
                  firstName={firstName ?? ''}
                  lastName={lastName ?? ''}
                  email={email}
                />
              </Flex>
              <Spacer direction="vertical" size="small">
                <h2>{t('pages.profile.form.accountManagement.title')}</h2>
                <MfaSetting />
                <UpdateLanguage />
              </Spacer>
            </Spacer>
          </Card>
          <Card fixedWith>
            <h2> {t('pages.profile.form.passwordUpdate.title')}</h2>
            <UserProfilePasswordForm />
          </Card>
        </Flex>
      </Spacer>
    </ConnectedPage>
  );
};

export default Profile;

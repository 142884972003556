import Spacer from 'ui/spacer';
import Arrow from '.';
import ArrowLegends from './arrowLegends';
import { arrowColorByType } from './constants';
import { ArrowInformations } from './types';
import styles from './index.module.scss';

interface ArrowsProps {
  height: number;
  arrows: ArrowInformations[];
}

const Arrows = ({ arrows, height }: ArrowsProps) => {
  return (
    <Spacer size="xsmall" align="start">
      {arrows.map(({ title, legends, type = 'information' }) => (
        <Spacer
          direction="vertical"
          size="small"
          className={styles.arrowLegendContainer}
          key={`${title} ${legends.concat()}`}
        >
          <Arrow title={title} height={height} type={type} />
          <ArrowLegends
            legends={legends}
            color={arrowColorByType[type].titleColor}
          />
        </Spacer>
      ))}
    </Spacer>
  );
};

export default Arrows;

import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import { useGetCompanyByPkQuery } from 'generated/graphql';

const useGetCompany = (companyId: number) => {
  const { data, loading } = useGetCompanyByPkQuery({
    variables: {
      id: Number(companyId),
    },
    fetchPolicy: 'network-only',
  });

  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();

  return {
    company: data?.company_by_pk,
    getCompanyBaseBreadcrumbs,
    loading,
  };
};

export default useGetCompany;

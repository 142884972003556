import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ResourceType } from 'business/common/services/types';
import classNames from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import { setCountry, translateCountry } from 'technical/countriesTranslation';
import Button from 'ui/button';
import Flex from 'ui/flex';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import MapStatus from './components/mapStatus';
import NotShownCountries from './components/notShowCountries';
import { NOT_SHOWN_COUNTRIES } from './constants';
import geoData from './countries-geoData.json';
import styles from './index.module.scss';
import { handleMapStyle, processMapData } from './mapUtils';
import { MAP_HEIGHT, MAP_TRANSLATE_EXTENT, MAP_WIDTH } from './styles';
import { MapData, NotShowCountry } from './types';

interface MapProps {
  data: MapData;
  isDataLoading: boolean;
  resourceType: ResourceType;
  overrideNotShowCountries?: NotShowCountry[];
}

type Position = {
  coordinates: [number, number];
  zoom: number;
};

const Map = ({
  data: rawData,
  isDataLoading,
  resourceType,
  overrideNotShowCountries,
}: MapProps) => {
  const { t } = useTranslation();
  const [tooltipContent, setTooltipContent] = useState('');
  const [position, setPosition] = useState<Position>({
    coordinates: [0, 45], // Center France
    zoom: 1,
  });

  const data = useMemo(() => processMapData(rawData), [rawData]);

  const notShownCountries = useMemo(
    () =>
      overrideNotShowCountries ??
      Object.keys(data)
        .filter((countryCode) => NOT_SHOWN_COUNTRIES.includes(countryCode))
        .map((countryCode) => ({
          code: countryCode,
          tooltip: translateCountry(countryCode),
          status: data[countryCode],
        })),
    [data, overrideNotShowCountries],
  );

  useEffect(() => {
    setCountry();
  }, []);

  function handleZoomIn() {
    if (position.zoom >= 4) {
      return;
    }
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) {
      return;
    }
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(positionMove: Position) {
    setPosition(positionMove);
  }

  return (
    <Spacer direction="vertical" size="xxsmall">
      <h2>{t('map.title')}</h2>
      <Flex className={classNames(styles.parent, 'elevation1')}>
        <Flex column className={styles.mapStatusContainer}>
          <NotShownCountries
            data={notShownCountries}
            resourceType={resourceType}
          />
          <MapStatus resourceType={resourceType} />
        </Flex>
        <Flex
          column
          alignContent="flex-end"
          className={styles.mapLegendContainer}
        >
          <Flex
            column
            alignContent="flex-end"
            className={classNames(styles.mapContainer, 'innerShadow1')}
          >
            <IsLoading loading={isDataLoading}>
              <Flex className={styles.buttons}>
                <Button type="primary" onClick={() => handleZoomOut()}>
                  <MinusOutlined />
                </Button>
                <Button type="primary" onClick={() => handleZoomIn()}>
                  <PlusOutlined />
                </Button>
              </Flex>
              <ComposableMap
                projection="geoMercator"
                projectionConfig={{ scale: 120, center: [0, 30] }}
                height={MAP_HEIGHT}
                width={MAP_WIDTH}
              >
                <ZoomableGroup
                  zoom={position.zoom}
                  center={position.coordinates}
                  onMoveEnd={(pos) => handleMoveEnd(pos)}
                  translateExtent={MAP_TRANSLATE_EXTENT}
                >
                  <Geographies data-tip="" geography={geoData}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            style={handleMapStyle(geo, data)}
                            onMouseEnter={() => {
                              setTooltipContent(
                                translateCountry(geo.properties['Alpha-2']),
                              );
                            }}
                            onMouseLeave={() => {
                              setTooltipContent('');
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
              <ReactTooltip>
                <div className={styles.tooltip}>{tooltipContent}</div>
              </ReactTooltip>
            </IsLoading>
          </Flex>
          <div className={styles.description}>
            {t('map.description', { context: resourceType })
              .split('\n')
              .map((paragraph) => (
                <p key={paragraph}>{paragraph}</p>
              ))}
          </div>
        </Flex>
      </Flex>
    </Spacer>
  );
};

export default memo(Map);

import { FolderFilled, FolderOpenFilled } from '@ant-design/icons';
import { TableProps } from 'antd';
import styles from './index.module.scss';
import classNames from 'classnames';
import { DocumentRecord } from 'business/resources/shared/services/documents/types';

const ExpandButton: NonNullable<
  TableProps<DocumentRecord>['expandable']
>['expandIcon'] = ({ record, onExpand, expanded }) => {
  if (!record.children?.length) {
    return null;
  }
  return (
    <a
      onClick={(e) => {
        onExpand(record, e);
      }}
      className={classNames(styles.icon)}
    >
      {expanded ? <FolderOpenFilled /> : <FolderFilled />}
    </a>
  );
};

export default ExpandButton;

import { Form, Input, InputProps } from 'antd';
import { USER_INFO_MODAL_RULES } from 'business/configuration/accessManagement/components/forms/userForm/constants';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import style from './index.module.scss';

type UserFormFieldsKeys = 'firstName' | 'lastName' | 'email';

interface UserFormFieldsProps {
  requiredField?: boolean;
  nameOnSameLine?: boolean;
  additionnalProps?: Partial<
    Record<
      UserFormFieldsKeys,
      {
        field?: InputProps;
        label?: string;
      }
    >
  >;
}

const nameFieldsName = ['firstName', 'lastName'] as const;

const UserFormFields = ({
  nameOnSameLine,
  additionnalProps,
  requiredField = true,
}: UserFormFieldsProps) => {
  const { t } = useTranslation();

  const nameFields = nameFieldsName.map((field) => (
    <Form.Item
      key={field}
      rules={USER_INFO_MODAL_RULES(t, requiredField)[field]}
      name={field}
      label={additionnalProps?.[field]?.label ?? t(`user.infoModal.${field}`)}
      className={nameOnSameLine ? style.userField : undefined}
    >
      <Input
        data-test-id={`${field}-input`}
        {...additionnalProps?.[field]?.field}
      />
    </Form.Item>
  ));

  return (
    <>
      {nameOnSameLine ? (
        <Spacer size="xsmall">{nameFields}</Spacer>
      ) : (
        nameFields
      )}
      <Form.Item
        rules={USER_INFO_MODAL_RULES(t, requiredField).email}
        name="email"
        label={additionnalProps?.email?.label ?? t('user.infoModal.email')}
      >
        <Input data-test-id="email-input" {...additionnalProps?.email?.field} />
      </Form.Item>
    </>
  );
};

export default UserFormFields;

import { Tooltip } from 'antd';
import classNames from 'classnames';
import Flex from 'ui/flex';
import {
  arrowColorByType,
  getArrowStyle,
  getArrowTitleStyle,
} from './constants';
import styles from './index.module.scss';
import { ArrowType } from './types';

interface ArrowProps {
  title: string;
  height: number;
  type?: ArrowType;
}

const Arrow = ({ title, height, type = 'information' }: ArrowProps) => {
  const arrowStyle = getArrowStyle(type, height);
  const arrowTitleStyle = getArrowTitleStyle(type, height);

  return (
    <Flex style={{ height }} className="fullWidth">
      <div className={styles.arrowContainer}>
        <div
          className={classNames(styles.arrow, styles.left)}
          style={arrowStyle}
        />
      </div>
      <div className={styles.arrowTitleContainer} style={arrowTitleStyle}>
        <Tooltip title={title}>
          <span className={styles.arrowTitle}>{title}</span>
        </Tooltip>
      </div>
      <div className={styles.arrowContainer}>
        <div
          className={classNames(styles.arrow, styles.right)}
          style={{
            ...arrowStyle,
            backgroundColor: arrowColorByType[type].backgroundColor,
          }}
        />
      </div>
    </Flex>
  );
};

export default Arrow;

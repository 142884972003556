import { Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isPasswordValid } from './constants';
import PasswordRules from './passwordRules';

const PasswordConfirmationForm = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  return (
    <>
      <PasswordRules password={password} />
      <Form.Item
        name="password"
        label={t('notConnected.login.resetPassword')}
        rules={[
          {
            required: true,
            message: t('errors.required'),
          },
          () => ({
            validator(_, value) {
              if (!isPasswordValid(value)) {
                return Promise.reject(
                  new Error(t('errors.signUp_password-not-safe')),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password onChange={(event) => setPassword(event.target.value)} />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label={t('pages.profile.form.passwordUpdate.confirmPassword')}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('errors.required'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('errors.passwords_did_not_match')),
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};

export default PasswordConfirmationForm;

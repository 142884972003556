import {
  Carousel as AntdCarousel,
  CarouselProps as AntdCarouselProps,
  Empty,
  Image,
  Pagination,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import IsLoading from 'ui/isLoading';
import styles from './index.module.scss';

interface CarouselProps extends AntdCarouselProps {
  urls: string[];
  className?: string;
  loading?: boolean;
  height?: number;
}

const Carousel: React.VFC<CarouselProps> = ({
  urls,
  loading,
  className,
  height = 500,
  ...props
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const ref = useRef<CarouselRef>(null);

  const totalPageCount = urls.length - (props.slidesToShow ?? 0);
  return (
    <div className={classNames(styles.wrapper, className)} style={{ height }}>
      <IsLoading loading={loading}>
        {urls.length ? (
          <Flex column justify="center">
            <Image.PreviewGroup>
              <AntdCarousel
                ref={ref}
                speed={100}
                className={styles.carousel}
                beforeChange={(_from, to) => setPage(to)}
                infinite={false}
                dots={false}
                {...props}
              >
                {urls.map((url) => (
                  <Flex justify="center" alignItems="center" key={url}>
                    <Image src={url} alt="test" className={styles.img} />
                  </Flex>
                ))}
              </AntdCarousel>
            </Image.PreviewGroup>
            <Flex justify="center" className="padding10">
              <Pagination
                current={page + 1}
                total={totalPageCount}
                pageSize={1}
                hideOnSinglePage
                onChange={(p) => ref.current?.goTo(p - 1)}
                size="small"
                simple
              />
            </Flex>
          </Flex>
        ) : (
          <Empty
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            className={className}
            description={t('common.emptyImage')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </IsLoading>
    </div>
  );
};

export default Carousel;

import { isBdlAdmin } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import ArchiveDeletionTable from 'business/archive/components/archiveDeletionTable';
import { useGetArchiveDeletionRequest } from 'business/archive/hooks/useGetArchiveDeletionRequest';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';

const ArchiveDeletion = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();

  const isUserBdlAdmin = isBdlAdmin(user);
  const { data, tableProps, rawData, refetch } = useGetArchiveDeletionRequest();
  return (
    <ResourceNotFound resourceExists={isUserBdlAdmin}>
      <ConnectedPage
        breadcrumbs={[{ label: t('pages.archive.title') }]}
        translationKey="archiveDeletionsRequest"
      >
        <Flex>
          <h1 className="headerTitle">{t('pages.archive.title')}</h1>
        </Flex>
        <Spacer direction="vertical" size="xsmall">
          <h3>
            {t('archive.information.nbTotalDeletions', {
              nbTotalDeletions:
                rawData?.archive_deletionRequest_aggregate.aggregate?.count ??
                0,
            })}
          </h3>
        </Spacer>
        <Flex>
          <ArchiveDeletionTable
            dataSource={data}
            {...tableProps}
            refetch={refetch}
          />
        </Flex>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default ArchiveDeletion;

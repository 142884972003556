import { Switch, Tooltip } from 'antd';
import Spacer from 'ui/spacer';
import { useTranslation } from 'react-i18next';
import useUpdateMFA from 'business/user/pages/profile/hooks/useUpdateMFA';
import Typography from 'ui/typography';
import { QuestionCircleOutlined } from '@ant-design/icons';

const MfaSetting = ({}) => {
  const { t } = useTranslation();
  const { mfaEnabled, editMFA, loading } = useUpdateMFA();

  return (
    <Spacer direction="horizontal" size="xsmall" align="center">
      <Typography.Text strong>{t('user.mfa.title')}</Typography.Text>
      <Tooltip title={t('user.mfa.tooltip')} placement="top">
        <QuestionCircleOutlined />
      </Tooltip>
      <span className="flex1" />
      <Switch loading={loading} checked={mfaEnabled} onChange={editMFA} />
    </Spacer>
  );
};

export default MfaSetting;

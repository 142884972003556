import { useEffect, useState } from 'react';

interface UseDebounceProps<T> {
  defaultValue: T;
  delay?: number;
}
export function useDebounce<T>({
  defaultValue,
  delay = 300,
}: UseDebounceProps<T>) {
  const [value, setValue] = useState<T>(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return {
    value,
    setValue,
    debouncedValue,
  };
}

import { Resource } from 'business/resources/shared/types';
import { useGetDocument } from 'business/resources/shared/services/documents/useGetDocument';
import ErrorOverlay from 'ui/errorOverlay';
import Table from 'ui/table';
import { ColumnGenerationData } from 'ui/table/types';
import { DocumentRecord } from 'business/resources/shared/services/documents/types';
import { AllTableFilter } from 'technical/filters/types';
import DownloadDocumentAction from './downloadDocumentAction';
import ViewDocumentAction from './viewDocumentAction';
import Flex from 'ui/flex';
import ExpandButton from './expandButton';
import DocumentLabel from './documentLabel';

const pageSize = 20;
interface ResourceDocumentsProps {
  resource: Resource;
  resourceId?: number;
}

const documentColumns = (
  resource: Resource,
): ColumnGenerationData<DocumentRecord> => [
  {
    key: 'label',
    translationKey: 'title',
    render: (_, record: DocumentRecord) => <DocumentLabel {...record} />,
  },
  {
    key: 'id',
    translationKey: 'action',
    width: '100px',
    sorter: false,
    render: (_, record: DocumentRecord) => {
      if (record.children?.length && !record.extension) {
        return null;
      }
      return (
        <Flex gap={'8px'}>
          <DownloadDocumentAction resource={resource} documentId={record.id} />
          {record.extension === 'pdf' ? (
            <ViewDocumentAction
              resource={resource}
              documentId={record.id}
            ></ViewDocumentAction>
          ) : null}
        </Flex>
      );
    },
  },
];

const ResourceDocuments = ({
  resource,
  resourceId,
}: ResourceDocumentsProps) => {
  const { data, error, loading } = useGetDocument({
    resource,
    variables: {
      id: resourceId,
    },
  });

  return (
    <ErrorOverlay error={error}>
      <Table<DocumentRecord>
        setFilterValue={() => {}}
        filtersValues={{} as AllTableFilter<DocumentRecord>}
        loading={loading}
        editButtonEnabled={false}
        resetButtonEnabled={false}
        showHeader={false}
        searchBarEnabled={false}
        scroll={{ y: 320 }} // Define the scroll area. Prevent the table to be too big
        defaultColumns={documentColumns(resource)}
        expandable={{
          expandIcon: ExpandButton,
          indentSize: 50,
          expandRowByClick: true,
        }}
        dataSource={data}
        pagination={{ pageSize }}
      />
    </ErrorOverlay>
  );
};

export default ResourceDocuments;

import Select from 'ui/select';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useGetCompanyOrDefaultKeywords from 'business/configuration/keywords/services/useGetCompanyOrDefaultKeywords';

interface CompanyKeywordsSelectProps {
  companyId: number;
}

const CompanyKeywordsSelect = ({ companyId }: CompanyKeywordsSelectProps) => {
  const { t } = useTranslation();
  const { loading, keywords } = useGetCompanyOrDefaultKeywords(companyId);

  return (
    <Form.Item
      name="keyword"
      label={t('pages.archive.form.keywordLabel')}
      rules={[{ required: true, message: t('errors.required') }]}
    >
      <Select
        loading={loading}
        options={keywords.map((keyword: string) => ({
          code: keyword,
          label: keyword,
        }))}
      />
    </Form.Item>
  );
};

export default CompanyKeywordsSelect;

import { useGetCompanyKeywordsQuery } from 'generated/graphql';

interface GetCompanyKeywordsProps {
  companyId: number;
}

const useGetCompanyKeywords = ({ companyId }: GetCompanyKeywordsProps) => {
  const { data, loading, error } = useGetCompanyKeywordsQuery({
    variables: { id: companyId },
  });

  return {
    keywords: data?.archive_documentType[0]?.options ?? [],
    loading,
    error,
  };
};

export default useGetCompanyKeywords;

import NotFoundPage from 'ui/notFoundPage';

interface ResourceNotFoundProps {
  resourceExists: boolean;
  children: React.ReactNode;
}

const ResourceNotFound = ({
  resourceExists,
  children,
}: ResourceNotFoundProps) => {
  return resourceExists ? <>{children}</> : <NotFoundPage />;
};

export default ResourceNotFound;

// Promise allSettled predicate for fullfield promises
// Used to correctly type promise result value
export const isFullfiled = <ValueType>(
  promiseSettlesResult: PromiseSettledResult<ValueType>,
): promiseSettlesResult is PromiseFulfilledResult<ValueType> => {
  if (promiseSettlesResult.status === 'fulfilled') {
    return true;
  }
  return false;
};

// Promise allSettled predicate for rejected promises
// Used to correctly type promise reason
export const isRejected = (
  promiseSettlesResult: PromiseSettledResult<unknown>,
): promiseSettlesResult is PromiseRejectedResult => {
  if (promiseSettlesResult.status === 'rejected') {
    return true;
  }
  return false;
};

// Access an object property
// Mostly use in obj.map to easily return an array of one property
export const getProperty =
  <T, K extends keyof T>(key: K) =>
  (obj: T) => {
    return obj[key]; // Inferred type is T[K]
  };

export const booleanFilter = <T>(value: T | null | undefined): value is T =>
  Boolean(value);

export function isInArray<T>(array: readonly T[], val: any): val is T {
  return !!array.find((e) => e === val);
}

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

import { EyeOutlined } from '@ant-design/icons';
import useDownloadDocument from 'business/resources/shared/services/documents/useDownloadDocuments';
import { Resource } from 'business/resources/shared/types';

const ViewDocumentAction = ({
  documentId,
  resource,
}: {
  documentId: number;
  resource: Resource;
}) => {
  const { getAndDownloadDocument } = useDownloadDocument();
  return (
    <EyeOutlined
      onClick={() =>
        getAndDownloadDocument({ resource, id: documentId, openInNewTab: true })
      }
    />
  );
};

export default ViewDocumentAction;

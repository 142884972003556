import { ColumnType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { Order_By } from 'generated/graphql';
import { Parser } from 'technical/filters/types';

export type OrderBy<T> = {
  [key in keyof T]?: Order_By.Asc | Order_By.Desc | null;
};

function areOrderByEqual<T>(a: OrderBy<T>, b: OrderBy<T>) {
  const [aKeys, bKeys] = [Object.keys(a), Object.keys(b)];
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.reduce<boolean>((acc, k) => {
    const key = k as keyof OrderBy<T>;
    return a[key] === b[key] && acc;
  }, true);
}

const noOrderString = 'no-sort';

export const orderByParser = <T>(
  defaultValue: OrderBy<T> = {},
): Parser<OrderBy<T>> => ({
  defaultValue,
  readParse: (param) => {
    if (param === null || param === undefined) {
      return defaultValue;
    }
    if (param === noOrderString) {
      return {};
    }

    return param.split(',').reduce<OrderBy<T>>((acc, c) => {
      const [key, order] = c.split(':');
      return { ...acc, [key]: order === 'asc' ? 'asc' : 'desc' };
    }, {});
  },
  writeParse: (value) => {
    if (areOrderByEqual(value, defaultValue)) {
      return undefined;
    }
    const res = Object.keys(value).reduce<string[]>((acc, key) => {
      const v = value[key as keyof T];
      return v != null ? [...acc, `${key}:${v}`] : acc;
    }, []);

    return res.length ? res.join(',') : noOrderString;
  },
});

export const sorterResultsToOrderBy = <T>(
  s: SorterResult<T> | SorterResult<T>[],
): OrderBy<T> => {
  const k = Array.isArray(s) ? s : [s];
  return k.reduce<OrderBy<T>>((acc, { order, columnKey }) => {
    if (order == null) {
      return acc;
    }
    return {
      ...acc,
      [columnKey as keyof T]: order === 'ascend' ? 'asc' : 'desc',
    };
  }, {});
};

export const orderByToDefaultSortOrder = <T>(
  order: OrderBy<T>[keyof OrderBy<T>],
): ColumnType<T>['defaultSortOrder'] => {
  switch (order) {
    case Order_By.Asc:
      return 'ascend';
    case Order_By.Desc:
      return 'descend';
    default:
      return null;
  }
};

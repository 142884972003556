import { UploadFile } from 'antd';

import { useUploadArchiveToArkhineoMutation } from 'generated/graphql';
import { uploadObjectAsync } from 'technical/fileManagement';
import { UploadForm } from 'business/archive/components/archiveUploadForm/archiveUploadFormModal';
import { useGetGeneratedSignedUrl } from 'business/archive/hooks/useGetGeneratedSignedUrl';
import errorReporting from 'technical/error-reporting';

const stepFileExtensions = ['step', 'stp'];

interface VerifiedUploadFile extends UploadFile {
  originFileObj: NonNullable<UploadFile['originFileObj']>;
  type: NonNullable<UploadFile['type']>;
  size: NonNullable<UploadFile['size']>;
}

const useUploadFormActions = () => {
  const { getGeneratedSignedUrl } = useGetGeneratedSignedUrl();
  const [uploadFileToArkhineo] = useUploadArchiveToArkhineoMutation();

  const isStepFile = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase() ?? '';
    return stepFileExtensions.includes(extension);
  };

  function isValidFile(file: UploadFile): file is VerifiedUploadFile {
    return (
      file.originFileObj !== undefined &&
      (file.type ? true : isStepFile(file.name)) &&
      file.size !== undefined
    );
  }

  const uploadFile = async (
    file: UploadFile,
    {
      clientReference1,
      clientReference2,
      holderId,
      description,
      keyword,
    }: Omit<UploadForm, 'files'>,
  ) => {
    if (!isValidFile(file)) {
      const err = new Error('No file obj reference in archive upload');
      errorReporting.warning(err, { fileUid: file.uid });
      throw err;
    }
    const { bucketSignedUrl, filePath } = await getGeneratedSignedUrl(
      file.name,
    );

    await uploadObjectAsync(file.originFileObj, bucketSignedUrl);
    await uploadFileToArkhineo({
      variables: {
        args: {
          filePath,
          clientReference1,
          clientReference2,
          description,
          keyword,
          companyId: holderId,
          fileName: file.name,
          fileType: file.type ? file.type : '.step',
        },
      },
    });
  };

  const uploadArchives = async ({ files, ...formData }: UploadForm) => {
    await Promise.all(files.map(async (file) => uploadFile(file, formData)));
  };

  return {
    uploadArchives,
  };
};

export default useUploadFormActions;

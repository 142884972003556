import { MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { isBdlUser } from 'business/admin/services/permission';
import Routes from 'business/router/routes';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import colors from 'ui/colors';
import MenuLink from 'ui/menu/menuLink';
import styles from 'ui/navBar/index.module.scss';

interface MenuSectionsProps {
  user: UserWithPrivateInfos;
}

const MenuSections = ({ user }: MenuSectionsProps) => {
  const { t } = useTranslation();

  const activeSectionPath = history.location.pathname;

  const sections = [
    {
      label: t('pages.patent.title'),
      path: Routes.Patent,
      key: 'patent',
      disabled: !user.patentAccess,
    },
    {
      label: t('pages.design.title'),
      path: Routes.Design,
      disabled: !user.designAccess,
    },
    {
      label: t('pages.trademark.title'),
      path: Routes.Trademark,
      disabled: !user.trademarkAccess,
    },
    {
      label: t('pages.domain.title'),
      path: Routes.Domain,
      disabled: !user.domainAccess,
    },
    {
      label: t('pages.event.title'),
      path: Routes.Events,
    },
    {
      key: 'archive',
      hide: isBdlUser(user),
      label: t('pages.archive.title', { context: 'menu' }),
      disabled: !user.privateInfos.archivePageEnabled,
      tooltipText: user.privateInfos.archivePageEnabled
        ? undefined
        : t('pages.archive.notAllowedViewingPage'),
      path: Routes.Archive,
    },
    {
      key: 'news',
      label: t('common.news'),
      externalLink: true,
      path: 'https://www.bdl-ip.com/actualites-documentations/actualites/',
    },
  ];

  const items = sections
    .filter((s) => !s.hide)
    .map((el) => {
      return {
        label: (
          <MenuLink
            tooltipText={el.tooltipText}
            disabled={el.disabled}
            onClick={
              el.externalLink
                ? () => {
                    return window.open(el.path, '_blank');
                  }
                : undefined
            }
            data-testid={`menuLink-${el.key}`}
            path={el.path}
            style={
              activeSectionPath === el.path
                ? { color: colors.primaryColor }
                : undefined
            }
          >
            <span className={styles.menuSection}>{el.label}</span>
          </MenuLink>
        ),
        key: el.path,
      };
    });
  return (
    <nav
      className={styles.navLinks}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Menu
        style={{ display: 'flex', alignItems: 'center' }}
        className={styles.menu}
        mode="horizontal"
        items={items}
        selectedKeys={[activeSectionPath]}
        overflowedIndicator={<MenuOutlined className={styles.menuItem} />}
      ></Menu>
    </nav>
  );
};

export default MenuSections;

import { Select as AntSelect, SelectProps as AntdSelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';
import { isIncluded } from 'technical/format/string';
import { alphabeticalSortByKey } from 'technical/sort';

type Options<T> = Array<{ label: string; code: T; dataTestId?: string }>;

export interface SelectProps<T> extends AntdSelectProps<T> {
  options: Options<T>;
  searchByLabel?: boolean;
  alphabeticallySortOptions?: boolean;
}

const labelSearchFilterOption = (input: string, option?: DefaultOptionType) =>
  isIncluded(option?.children?.toString(), input);

const Select = <T extends string | number>({
  options: rawOptions,
  alphabeticallySortOptions = false,
  searchByLabel = false,
  ...props
}: SelectProps<T>) => {
  const options = useMemo(
    () =>
      alphabeticallySortOptions
        ? alphabeticalSortByKey(rawOptions, 'label')
        : rawOptions,
    [rawOptions],
  );

  return (
    <AntSelect<T>
      filterOption={searchByLabel && labelSearchFilterOption}
      {...props}
    >
      {options.map((val) => {
        return (
          <AntSelect.Option
            data-test-id={`selectItems-${val.dataTestId ?? val.code}`}
            key={val.code}
            value={val.code}
          >
            {val.label}
          </AntSelect.Option>
        );
      })}
    </AntSelect>
  );
};

export default Select;

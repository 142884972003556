import { notification } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import GroupForm from 'business/groups/components/GroupForm';
import { formatFormValues } from 'business/groups/services/methods';
import { GroupSubmitFormValues } from 'business/groups/services/types';
import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useUpdateGroupAccessMutation } from 'generated/graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExtractRouteParams,
  generatePath,
  useHistory,
  useParams,
} from 'react-router';
import logger from 'technical/logger';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import { resourceExists } from 'business/common/services';
import { useGetCompanyGroupAccess } from 'business/groups/hooks/useGetCompanyGroupAccess';
import { isNameAlreadyTaken } from 'business/groups/services/error';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';

const GroupUpdate = () => {
  const { t } = useTranslation();
  const { canSeeCompany } = useLoggedInAppContext();
  const [updateGroupAccess] = useUpdateGroupAccessMutation();
  const history = useHistory();

  const { id, groupId } =
    useParams<ExtractRouteParams<Routes.GroupUpdate, string>>();

  const companyId = Number(id);

  const { group, error, loading, groupAccess, visiblePermissions } =
    useGetCompanyGroupAccess(t, companyId, groupId);

  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();

  useEffect(() => {
    if (error) {
      notification.error({
        message: t('errors.generic'),
      });
    }
  }, [error]);

  const onFinish = async (values: GroupSubmitFormValues) => {
    const access = formatFormValues(values, visiblePermissions);

    try {
      await updateGroupAccess({
        variables: {
          groupId,
          companyId,
          name: values.name,
          data: { ...access, isAdmin: values.isAdmin ?? false },
        },
      });
      notification.success({
        message: t('pages.groupUpdate.notifications.success'),
      });
      history.push(generatePath(Routes.CompanyGroups, { id: companyId }));
    } catch (err) {
      logger.error(err);
      if (isNameAlreadyTaken(err)) {
        notification.error({
          message: t('errors.nameAlreadyTaken'),
        });
      } else {
        notification.error({
          message: t('errors.generic'),
        });
      }
    }
  };

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(group, { loading })}
    >
      <ConnectedPage
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(group?.company),
          { label: t('pages.groupUpdate.title') },
        ]}
        translationKey="groupUpdate"
      >
        <IsLoading loading={loading}>
          <Spacer direction="vertical" size="small">
            <h1 className="headerTitle">
              {t('pages.groupUpdate.title', { groupName: group?.name })}
            </h1>
          </Spacer>
          <GroupForm
            onFinish={onFinish}
            formName="groupUpdate"
            companyName={group?.company.name}
            visiblePermissions={visiblePermissions}
            initialValues={{
              ...groupAccess,
              name: group?.name,
              isAdmin: group?.isAdmin,
            }}
            cancelLink={generatePath(Routes.CompanyGroups, { id: companyId })}
          />
        </IsLoading>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default GroupUpdate;

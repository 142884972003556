import { useGetDomainExpirationQuery } from 'generated/graphql';

export const useGetDomainExpiration = (companyId: number) => {
  const { data, loading, error } = useGetDomainExpirationQuery({
    variables: {
      companyId,
    },
  });

  const domainExpirationResult = data?.domain_expirationContact;

  const formattedDomainExpiration = domainExpirationResult?.length
    ? {
        email: domainExpirationResult[0].email,
        cc: domainExpirationResult[0].cc,
      }
    : undefined;

  return {
    domainExpiration: formattedDomainExpiration,
    loading,
    error,
  };
};

import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Table from 'ui/table';
import { DESIGN_ACUMASS_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';

interface IDesignAcumass {
  filters: OsrFilterState;
}

const DesignAcumass = (props: IDesignAcumass) => {
  const { generateExcel } = useGenerateExcelFile('design', props.filters);

  const { data: newEntriesData, tableProps: newEntriesTableProps } =
    useGetDesignOsrData(
      {
        companyRef: props.filters.companyRef,
        countryCode: props.filters.countryCode,
      },
      'newEntries',
    );
  const { data: newRegistrationsData, tableProps: newRegistrationsTableProps } =
    useGetDesignOsrData(
      {
        companyRef: props.filters.companyRef,
        countryCode: props.filters.countryCode,
      },
      'newRegistration',
    );

  return (
    <OsrTabs
      actions={
        <Button type="primary" onClick={() => generateExcel('ACUMASS')}>
          Exporter les mise à jour Acumass
        </Button>
      }
      tabsItems={[
        {
          key: 'newEntries',
          label: `Nouvelles entrées (${newEntriesTableProps.pagination?.total})`,
          children: (
            <Table
              defaultColumns={DESIGN_ACUMASS_COLUMNS}
              dataSource={newEntriesData}
              name="newEntries"
              {...newEntriesTableProps}
            />
          ),
        },
        {
          key: 'newRegistration',
          label: `Nouveaux enregistrements (${newRegistrationsTableProps.pagination?.total})`,
          children: (
            <Table
              {...newRegistrationsTableProps}
              dataSource={newRegistrationsData}
              defaultColumns={DESIGN_ACUMASS_COLUMNS}
              name="newRegistration"
            />
          ),
        },
      ]}
    />
  );
};

export default DesignAcumass;

import { MailOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { createMailProps } from 'business/common/services/mailto';
import { ResourceType } from 'business/common/services/types';
import { Resource } from 'business/common/services/types';
import { useTranslation } from 'react-i18next';
import MailButton from 'ui/mailButton';

type ContactBdlByMailButtonProps = {
  resource: Resource;
  resourceType: ResourceType;
};

const ContactBdlByMailButton: React.FC<ContactBdlByMailButtonProps> = ({
  resource,
  resourceType,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t('pages.common.contactBdlByMailButton')}
      placement="topRight"
    >
      <MailButton
        {...createMailProps(resource, resourceType, t)}
        icon={<MailOutlined />}
      ></MailButton>
    </Tooltip>
  );
};

export default ContactBdlByMailButton;

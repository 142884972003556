import useGetFiltersData from 'business/common/hooks/useGetFiltersData';
import getFilters from 'business/common/services/filters';
import { TabsFamily } from 'business/common/services/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fieldsPerEventFamilyTabs } from 'business/resources/events/services/constants';

const useEventAdvancedFilters = (family: TabsFamily) => {
  const { t } = useTranslation();

  const filtersData = useGetFiltersData(t, family, true);

  return useMemo(
    () => getFilters(t, family, filtersData, fieldsPerEventFamilyTabs),
    [family, filtersData],
  );
};

export default useEventAdvancedFilters;

import { MutableRefObject, useEffect, useState } from 'react';

/**
 * Used to know if an Element is visible on screen
 * @param ref the ref of the element
 */
const useOnScreen = <T extends Element>(
  ref: MutableRefObject<T | null>,
  rootMargin = '0px',
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const { current } = ref;
    if (!current) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { rootMargin },
    );

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;

import notifyPromise from 'business/common/services/notifyPromise';
import {
  useDeleteCompanyClientRefsMutation,
  useGetCompanyClientRefsQuery,
  useUpsertCompanyClientRefsMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export interface CompanyClientRefValues {
  clientRefs: string[];
  isTextVersion: boolean;
}
export const useCompanyClientRef = (companyId: number) => {
  const { data, loading, error } = useGetCompanyClientRefsQuery({
    variables: {
      companyId,
    },
  });

  const { t } = useTranslation();
  const { clientRefs, isTextVersion }: CompanyClientRefValues = data
    ?.archive_companyClientRefs.length
    ? data.archive_companyClientRefs[0]
    : { clientRefs: [], isTextVersion: true };

  const [upsertCompanyClientRefs] = useUpsertCompanyClientRefsMutation();

  const [deleteCompanyClientRefs] = useDeleteCompanyClientRefsMutation();

  const notifyUpdateClientRef = (func: () => Promise<any>) => {
    return notifyPromise(t, func, {
      successNotification: t('archive.clientRefs.successNotif'),
    });
  };

  const updateClientRefs = (values: CompanyClientRefValues) => {
    if (values.clientRefs.length) {
      return notifyUpdateClientRef(() =>
        upsertCompanyClientRefs({
          variables: {
            object: {
              companyId,
              ...values,
            },
          },
        }),
      );
    }
    return notifyUpdateClientRef(() =>
      deleteCompanyClientRefs({
        variables: {
          companyId,
        },
      }),
    );
  };

  const initialValues = {
    clientRefs: clientRefs.map((clientRef) => {
      return {
        clientRef,
      };
    }),
    isTextVersion,
  };

  return {
    initialValues,
    updateClientRefs,
    loading,
    error,
  };
};

import { TFunction } from 'i18next';

const storageDurations = [10, 30];
export const getSectionOptions = (t: TFunction) =>
  storageDurations.map((storageDuration) => {
    return {
      label: t('archive.storageDuration', { storageDuration }),
      code: storageDuration,
    };
  });

import { TFunction } from 'react-i18next';
import { CompanyDetail } from './types';

export const getResourcesStatistic = (company: CompanyDetail, t: TFunction) => {
  return [
    {
      nbTotal: company.patentCount ?? 0,
      nbEffective: company.effectivePatentCount ?? 0,
      title: t('metadata.patents'),
    },
    {
      nbTotal: company.designCount ?? 0,
      nbEffective: company.effectiveDesignCount ?? 0,
      title: t('metadata.designs'),
    },
    {
      nbTotal: company.trademarkCount ?? 0,
      nbEffective: company.effectiveTrademarkCount ?? 0,
      title: t('metadata.trademarks'),
    },
    {
      nbTotal: company.domainCount ?? 0,
      nbEffective: company.effectiveDomainCount ?? 0,
      title: t('metadata.domains'),
    },
  ];
};

import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

interface SubmitButtonFormProps {
  label?: string;
  className?: string;
  dataTestId?: string;
  style?: React.CSSProperties;
}

const SubmitButtonForm = ({
  label,
  className,
  dataTestId,
  style,
}: SubmitButtonFormProps) => {
  const { t } = useTranslation();
  const buttonLabel = label ?? t('common.confirm');
  return (
    <Form.Item className={className}>
      <Button
        style={style}
        data-test-id={dataTestId ?? 'submit-button'}
        type="primary"
        htmlType="submit"
        block
      >
        {buttonLabel}
      </Button>
    </Form.Item>
  );
};

export default SubmitButtonForm;

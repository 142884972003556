import { UserArchivesDocuments } from 'business/archive/services/types';
import { formatDate } from 'technical/date';
import { formatBytes } from 'technical/fileManagement';
import { getFormatFile } from 'technical/fileManagement/constants';
import IsValidatedIcon from 'ui/isValidatedIcon';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import ArchiveDocumentTableActionColumn from './actionsColumn';
import { TFunction, useTranslation } from 'react-i18next';

type ArchiveDocumentTableProps = Omit<
  TableProps<UserArchivesDocuments>,
  'defaultColumns'
> & { setEditingArchive: (archive: UserArchivesDocuments) => void };

const defaultColumns = (
  refetch: (() => void) | undefined,
  setEditingArchive: (archive: UserArchivesDocuments) => void,
  t: TFunction,
): ColumnGenerationData<NonNullable<UserArchivesDocuments>> => {
  return [
    {
      key: 'id',
      ellipsis: true,
      width: 70,
    },
    {
      key: 'fileName',
      width: 160,
    },
    {
      key: 'fileSize',
      width: 100,
      render: (_, { fileSize }) => <span>{formatBytes(fileSize ?? 0)}</span>,
    },
    {
      key: 'fileType',
      width: 100,
      render: (_, { fileType }) => (
        <span>{getFormatFile(fileType)?.toUpperCase()}</span>
      ),
    },
    {
      key: 'companyRef',
      checked: false,
      ellipsis: true,
    },
    {
      key: 'clientReference1',
      ellipsis: true,
    },
    {
      key: 'clientReference2',
      ellipsis: true,
    },
    {
      key: 'company',
      checked: false,
      ellipsis: true,
    },
    {
      key: 'authorName',
      ellipsis: true,
    },
    {
      key: 'createdAt',
      ellipsis: true,
      translationKey: 'archiveDate',
      render: (_, { createdAt }) => (
        <span>{formatDate(new Date(createdAt))}</span>
      ),
    },
    {
      key: 'storageEndDate',
      ellipsis: true,
      translationKey: 'archiveEndDate',
      render: (_, { storageEndDate, createdAt }) => (
        <span>{formatDate(new Date(storageEndDate ?? createdAt))}</span>
      ),
    },

    {
      key: 'description',
    },
    {
      key: 'keyword',
      ellipsis: true,
    },
    {
      key: 'arkhineoArchiveId',
      translationKey: 'iua',
      checked: false,
      ellipsis: true,
      render: (_, { arkhineoArchiveId }) => {
        if (arkhineoArchiveId) {
          return <span>{arkhineoArchiveId}</span>;
        }
        return (
          <span>
            {t('table.common.column.archive', { context: 'loading' })}
          </span>
        );
      },
    },
    {
      key: 'delivered',
      ellipsis: true,
      checked: false,
      render: (_, record) => <IsValidatedIcon isValid={record.delivered} />,
    },
    {
      key: 'id',
      translationKey: 'actions',
      alwaysVisible: true,
      sorter: false,
      width: 200,
      render: (_, record) => (
        <ArchiveDocumentTableActionColumn
          setEditingArchive={setEditingArchive}
          refetch={refetch}
          archive={record}
        />
      ),
    },
  ];
};

const ArchiveDocumentTable: React.FC<ArchiveDocumentTableProps> = (props) => {
  const { refetch, setEditingArchive } = props;
  const { t } = useTranslation();
  return (
    <Table
      id="archiveDocumentTable"
      {...props}
      defaultColumns={defaultColumns(refetch, setEditingArchive, t)}
    />
  );
};

export default ArchiveDocumentTable;

import {
  GetUserCompaniesWithUploadAccessQuery,
  useGetUserCompaniesWithUploadAccessLazyQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { CompanyArchiveUpload } from 'business/archive/components/archiveUploadForm/types';

const useGetCompaniesArchiveUpload = () => {
  const [companiesListOptions, setCompaniesListOptions] = useState<
    CompanyArchiveUpload[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<
    CompanyArchiveUpload | undefined
  >(undefined);

  const [getCompaniesList] = useGetUserCompaniesWithUploadAccessLazyQuery();

  const formatCompaniesOptions = (
    data: GetUserCompaniesWithUploadAccessQuery,
  ) => {
    return data.getUserCompaniesWithUploadAccess.map(
      ({ id, name, companyRef, hasActiveArchiveSubscription }) => ({
        label: `${companyRef} - ${name}`,
        code: id,
        hasActiveArchiveSubscription: !!hasActiveArchiveSubscription,
      }),
    );
  };

  const generateCompaniesListOptions = async () => {
    const { data } = await getCompaniesList();
    const companiesList = data ? formatCompaniesOptions(data) : [];
    if (!companiesList?.length) {
      throw new Error('Missing company for user');
    }
    setCompaniesListOptions(companiesList);
  };

  return {
    generateCompaniesListOptions,
    companiesListOptions,
    selectedCompany,
    setSelectedCompany,
  };
};

export default useGetCompaniesArchiveUpload;

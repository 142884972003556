import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import CompaniesTable from 'business/configuration/companies/components/companiesTable';
import { useGetCompanies } from 'business/configuration/companies/services/useGetCompanies';
import ConnectedPage from 'business/user/helpers/connectedPage';

const CompaniesPage = () => {
  const { data, tableProps } = useGetCompanies();
  const { root } = useCompanyBreacrumbs();
  return (
    <ConnectedPage breadcrumbs={root} translationKey="companies">
      <CompaniesTable
        data-test-id="companiesTable"
        dataSource={data}
        {...tableProps}
      />
    </ConnectedPage>
  );
};

export default CompaniesPage;

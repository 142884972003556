import { notification } from 'antd';
import { useSendArchiveDownloadLinkByEmailMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';

export const useGetArchiveDocument = () => {
  const { t } = useTranslation();

  const [sendArchiveDownloadLinkByEmail] =
    useSendArchiveDownloadLinkByEmailMutation();

  const sendArchiveDownloadLink = async (archiveId: number) => {
    try {
      await sendArchiveDownloadLinkByEmail({
        variables: {
          archiveId,
        },
      });

      notification.info({
        message: t('pages.archive.notifications.sendDownloadUrlByEmail'),
        description: t(
          'pages.archive.notifications.sendDownloadUrlByEmailDesc',
        ),
        duration: 120,
      });
    } catch (err) {
      errorReporting.error(err);
      notification.error({
        message: t('errors.error', { context: 'gettingArchive' }),
      });
    }
  };

  return {
    sendArchiveDownloadLink,
  };
};

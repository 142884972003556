import { Spin } from 'antd';
import { CSSProperties } from 'react';
import ErrorResult, { ErrorResultProps } from './errorResult';
import styles from './index.module.scss';

interface ErrorOverlayProps extends ErrorResultProps {
  children: React.ReactNode;
  loading?: boolean;
  styleError?: CSSProperties | undefined;
}

const ErrorOverlay: React.FC<ErrorOverlayProps> = ({
  children,
  error,
  loading,
  styleError,
  refetch,
}) => {
  if (loading) {
    return <Spin spinning>{children}</Spin>;
  }

  if (error) {
    return (
      <div className={styles.overlay}>
        <ErrorResult
          className={styles.errorMessage}
          style={styleError}
          // Instead of real errors, generic errors are displayed
          // title={error.name}
          // subTitle={error.message}
          refetch={refetch}
        />
        {children}
      </div>
    );
  }

  return <>{children}</>;
};

export default ErrorOverlay;

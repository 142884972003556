const DateNumber = ({
  date,
  number,
  name,
}: {
  date: string | null;
  number: string;
  name: string;
}) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{name}: </span>
      <span>
        {number} | {date}
      </span>
    </>
  );
};

export default DateNumber;

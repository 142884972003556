import {
  GetLogsQuery,
  GetLogsQueryVariables,
  Logs_Bool_Exp,
  useGetLogsQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import {
  generateLocalizedSearch,
  generateTextSearchConditions,
} from 'technical/hooks/useQueryForTable/filterCondition';
import { Log } from 'business/admin/services/types';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): Logs_Bool_Exp => ({
  _or: [
    ...generateLocalizedSearch(
      (tradKeys) => tradKeys.log.action.type,
      searchText,
      (stringComparison) => ({
        action: stringComparison,
      }),
    ),
    ...generateTextSearchConditions(searchText, ['details']),
    {
      identity: {
        user: {
          _or: [
            ...generateTextSearchConditions(searchText, ['firstName', 'email']),
          ],
        },
      },
    },
  ],
});

const useGetLogs = () => {
  const { tableProps, ...rest } = useQueryForTable<
    Log,
    GetLogsQuery,
    GetLogsQueryVariables
  >({
    subStorageKey: storageKeys.logs,
    commonFiltersKeys: ['dateRange', 'search'],
    useQuery: ({ limit, offset, searchText, orderBy, dateRange }) =>
      useGetLogsQuery({
        variables: {
          limit,
          offset,
          where: {
            ...(dateRange
              ? { created_at: { _gt: dateRange.from, _lt: dateRange.to } }
              : {}),
            ...(searchText ? generateSearch(searchText) : {}),
          },
          order_by: {
            identity: {
              user: {
                email: orderBy.email,
                firstName: orderBy.firstName,
              },
            },
            action: orderBy.action,
            created_at: orderBy.date,
            details: orderBy.details,
          },
        },
      }),
    getTotal: (res) => res?.logs_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.logs.map((log) => {
        return {
          ...log,
          firstName: log.identity?.user?.firstName ?? '',
          email: log.identity?.user?.email ?? '',
        };
      }) ?? [],
  });

  return {
    ...rest,
    tableProps,
  };
};

export default useGetLogs;

const commonFields = [
  'clientRef',
  'clientRef2',
  'id',
  'title',
  'holder',
  'effectiveHolder',
  'bdlOfficer',
  'clientOfficer',
  'status',
] as const; // satisfies Fields[];

export const fieldsPerTabs = {
  all: [
    ...commonFields,
    'countryCode',
    'depositDate',
    'depositPublicationIssueNumber',
  ],
  patent: [
    'clientRef',
    'clientRef2',
    'id',
    'title',
    'holder',
    'effectiveHolder',
    'inventors',
    'bdlOfficer',
    'clientOfficer',
    'status',
    'countryCode',
    'protectionCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'publicationDate',
    'issueDate',
    'ownershipType',
  ],
  design: [
    'clientRef',
    'clientRef2',
    'id',
    'title',
    'holder',
    'effectiveHolder',
    'bdlOfficer',
    'clientOfficer',
    'adjournedPublication',
    'status',
    'countryCode',
    'protectionCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'registrationDate',
    'ownershipType',
  ],
  trademark: [
    ...commonFields,
    'countryCode',
    'protectionCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'publicationDate',
    'firstRegistrationDate',
    'lastRegistrationDate',
    'nextRegistrationDate',
    'expirationDate',
    'ownershipType',
  ],
  domain: [
    'holder',
    'extension',
    'title',
    'status',
    'reservationDate',
    'expirationDate',
    'clientRef',
    'clientRef2',
    'bdlOfficer',
    'clientOfficer',
  ],
} as const; // satisfies Record<TabsFamily, Fields[]>;

import { isDefined } from 'technical/validation';
import { storages } from './constants';
import { StorageType, UseStorageProps, WriteStorageProps } from './types';

export const getStorage = (storageType: StorageType) => storages[storageType];

export const getJsonParsedStorageValues = <T>(
  storageKey: string,
  storageType: StorageType,
) => {
  try {
    const storageValues = getStorage(storageType).getItem(storageKey);
    if (!storageValues) {
      return {} as T;
    }

    const jsonParsedStorageValues = JSON.parse(storageValues);
    if (!Object.keys(jsonParsedStorageValues).length) {
      return {} as T;
    }

    return jsonParsedStorageValues;
  } catch (err) {
    return {} as T;
  }
};

export const getStorageValues = <T>({
  storageKey,
  filters,
  storageType,
  subKey,
}: UseStorageProps<T>) => {
  const jsonParsedStorageValues = getJsonParsedStorageValues(
    storageKey,
    storageType,
  );

  if (
    subKey &&
    (!jsonParsedStorageValues[subKey] ||
      !Object.keys(jsonParsedStorageValues[subKey]).length)
  ) {
    return {} as T;
  }

  return Object.keys(filters).reduce<T>((res: T, filterKey) => {
    return {
      ...res,
      [filterKey]: filters[filterKey as keyof T].readParse(
        subKey
          ? jsonParsedStorageValues[subKey][filterKey]
          : jsonParsedStorageValues[filterKey],
      ),
    };
  }, {} as T);
};

export const parseStorageValues = <T>({
  filters,
  storageValues,
}: WriteStorageProps<T>) => {
  const parsedValues = {} as { [key: string]: string };
  Object.keys(filters).forEach((key) => {
    if (isDefined(storageValues[key as keyof T])) {
      const value = filters[key as keyof T].writeParse(
        storageValues[key as keyof T],
      );

      if (value !== undefined) {
        parsedValues[key] = value;
      }
    }
  });

  return parsedValues;
};

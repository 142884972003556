import { compareAsc } from 'date-fns';

export interface TimelineEvent {
  title: string;
  date: string;
  reference?: string | null;
  type?: 'information' | 'error';
}

export const createTimelineEvents = (events: TimelineEvent[]) => {
  const timelineEvents = events.reduce((acc: TimelineEvent[], event) => {
    if (event.date) {
      acc.push(event);
    }
    return acc;
  }, []);

  return timelineEvents.sort((a, b) =>
    compareAsc(new Date(a.date), new Date(b.date)),
  );
};

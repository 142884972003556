import useGetDomainInvoices from 'business/invoice/hooks/useGetDomainInvoices';
import { Domain_Invoice_Bool_Exp } from 'generated/graphql';

export const useGetDomainInvoicesById = (domainId: number) => {
  const condition: Domain_Invoice_Bool_Exp = {
    invoiceLines: { domainEvent: { domainId: { _eq: domainId } } },
  };
  const domainInvoices = useGetDomainInvoices({ where: condition });

  return {
    domainInvoices,
  };
};

import { notification } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { resourceExists } from 'business/common/services';
import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';
import GroupForm from 'business/groups/components/GroupForm';
import { useGetCompanyGroupAccess } from 'business/groups/hooks/useGetCompanyGroupAccess';
import { isNameAlreadyTaken } from 'business/groups/services/error';
import { formatFormValues } from 'business/groups/services/methods';
import { GroupSubmitFormValues } from 'business/groups/services/types';
import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import {
  GetCompanyWithGroupsByPkDocument,
  useInsertGroupAccessMutation,
} from 'generated/graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExtractRouteParams,
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import logger from 'technical/logger';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';

type LocationState = { cancelLink: string };

const GroupCreate = () => {
  const { t } = useTranslation();
  const { canSeeCompany } = useLoggedInAppContext();
  const [insertGroup] = useInsertGroupAccessMutation();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  const { id } = useParams<ExtractRouteParams<Routes.GroupCreate, string>>();
  const companyId = Number(id);

  const { group, error, loading, groupAccess, visiblePermissions } =
    useGetCompanyGroupAccess(t, companyId);

  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();

  // If cancelLink is defined, then it means we are creating the first group of the company so we are redirecting to companies list
  // Else it means we are creating an another one so we are redirecting to company groups
  const cancelLink =
    state?.cancelLink ?? generatePath(Routes.CompanyGroups, { id: companyId });

  useEffect(() => {
    if (error) {
      notification.error({
        message: t('errors.generic'),
      });
    }
  }, [error]);

  const onFinish = async (values: GroupSubmitFormValues) => {
    const access = formatFormValues(values, visiblePermissions);

    try {
      await insertGroup({
        variables: {
          companyId,
          name: values.name,
          data: { ...access, isAdmin: values.isAdmin ?? false },
        },
        refetchQueries: [
          {
            query: GetCompanyWithGroupsByPkDocument,
            variables: { id: companyId },
          },
        ],
      });
      notification.success({
        message: t('pages.groupCreate.success'),
      });
      history.push(generatePath(Routes.CompanyGroups, { id: companyId }));
    } catch (err) {
      logger.error(err);
      if (isNameAlreadyTaken(err)) {
        notification.error({
          message: t('errors.nameAlreadyTaken'),
        });
      } else {
        notification.error({
          message: t('errors.generic'),
        });
      }
    }
  };

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(group, { loading })}
    >
      <ConnectedPage
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(group?.company),
          { label: t('pages.groupCreate.title') },
        ]}
        translationKey="groupCreate"
      >
        <IsLoading loading={loading}>
          <Spacer direction="vertical" size="small">
            <h1 className="headerTitle">
              {t('pages.groupCreate.title', {
                companyName: group?.company.name,
              })}
            </h1>
            <GroupForm
              onFinish={onFinish}
              companyName={group?.company.name}
              initialValues={groupAccess}
              visiblePermissions={visiblePermissions}
              formName="groupCreate"
              cancelLink={cancelLink}
            />
          </Spacer>
        </IsLoading>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default GroupCreate;

import { DomainFamily } from 'business/resources/domain/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

type DomainFamiliesTableProps = Omit<
  TableProps<DomainFamily>,
  'defaultColumns'
>;

const defaultColumns: ColumnGenerationData<DomainFamily> = [
  { key: 'holder' },
  {
    key: 'domain',
    alwaysVisible: true,
    render: (_: any, record: DomainFamily) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={`/domain/family/${record.id}`}
      >
        {record.domain?.toUpperCase()}
      </Link>
    ),
  },
  { key: 'extensions' },
  { key: 'bdlOfficerName' },
];

const DomainFamiliesTable: React.VFC<DomainFamiliesTableProps> = ({
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Table
      id="domainFamiliesTable"
      {...props}
      showEffectiveCheckbox
      family="domain"
      defaultColumns={defaultColumns}
      header={
        <h1 className="headerTitle">{t('pages.header.domainFamilies')}</h1>
      }
    />
  );
};

export default DomainFamiliesTable;

import { Checkbox } from 'antd';
import ArchiveDocumentTable from 'business/archive/components/archiveDocumentTable';
import ArchiveEditionModal from 'business/archive/components/archiveEditionModal';
import ArchiveUploadForm from 'business/archive/components/archiveUploadForm';
import { useGetUserArchivesDocument } from 'business/archive/hooks/useGetUserArchivesDocument';
import { UserArchivesDocuments } from 'business/archive/services/types';
import DateRangeTableFilter from 'business/common/components/dateRangeTableFilter';
import { UserWithPrivateInfos } from 'business/user/types/user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'technical/fileManagement';
import Flex from 'ui/flex';
import AllKeywordsSelect from 'business/configuration/keywords/components/selectAll';
import { useGetNbUserArchivesDocumentQuery } from 'generated/graphql';
import TagResults from 'ui/tag/tagResults';

interface ArchivePageProps {
  user: UserWithPrivateInfos;
}
const ArchivePage = ({ user }: ArchivePageProps) => {
  const { t } = useTranslation();
  const [editingArchive, setEditingArchive] = useState<
    UserArchivesDocuments | undefined
  >(undefined);

  const {
    data,
    tableProps,
    refetch,
    filters: { filtersValues, setFilterValue, setFiltersValues },
  } = useGetUserArchivesDocument();

  const { data: nbArchiveData } = useGetNbUserArchivesDocumentQuery();

  const totalNbFiles =
    Number(nbArchiveData?.getNbUserArchives.totalNbFiles) ?? 0;
  const totalFileSizeGo = formatBytes(
    Number(nbArchiveData?.getNbUserArchives.totalFileSize) ?? 0,
  );

  return (
    <>
      {editingArchive && (
        <ArchiveEditionModal
          archive={editingArchive}
          isOpen={!!editingArchive}
          refetch={refetch}
          onClose={() => setEditingArchive(undefined)}
        />
      )}

      <Flex>
        <h1 className="headerTitle">{t('pages.archive.title')}</h1>
      </Flex>
      <ArchiveUploadForm refetchArchives={refetch} user={user} />
      <Flex>
        <h3>
          {t('archive.information.nbTotalArchiveAndSize', {
            totalNbFiles,
            totalFileSize: totalFileSizeGo,
          })}
        </h3>
      </Flex>
      <Flex>
        <ArchiveDocumentTable
          dataSource={data}
          header={
            <TagResults
              total={tableProps.pagination?.total ?? 0}
              dataTestId="nbArchive"
            />
          }
          {...tableProps}
          refetch={refetch}
          setEditingArchive={setEditingArchive}
          customAction={
            <>
              <Checkbox
                checked={filtersValues.myArchives}
                onChange={({ target: { checked: myArchives } }) =>
                  setFilterValue('myArchives', myArchives)
                }
              >
                {t('table.common.filters.myArchives')}
              </Checkbox>
              <DateRangeTableFilter
                filtersValues={filtersValues}
                setFiltersValues={setFiltersValues}
                fromDateFilterProps={{ style: { marginLeft: 'auto' } }}
              />
              <AllKeywordsSelect
                value={filtersValues.type}
                onSelect={(type: string | undefined) =>
                  setFilterValue('type', type)
                }
                onClear={() =>
                  setFiltersValues({ ...filtersValues, type: undefined })
                }
              />
            </>
          }
        />
      </Flex>
    </>
  );
};
export default ArchivePage;

// Scroll to top when changing location through react-router
// See https://v5.reactrouter.com/web/guides/scroll-restoration
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  enabled: boolean;
}

const ScrollToTop = ({ enabled }: ScrollToTopProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (enabled) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;

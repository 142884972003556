/* eslint-disable import/prefer-default-export */
import { FIND_ALL_COMAS } from 'technical/regex';
import { Panel } from 'ui/description/types';
import StatusBadge from 'business/common/components/statusBadge';
import { StatusType } from 'business/common/services';
import { getCountryLabel, isEuropean } from 'technical/countriesTranslation';
import { formatDate, formatDateAndNumberString } from 'technical/date';
import { isAnnuityNotHandledByBdl } from 'business/resources/patent/services';
import { Patent } from 'business/resources/patent/types';
import OptOut from 'business/resources/patent/components/optOut';
import { bdlOfficerAndSecretaryColumns } from 'business/resources/domain/components/panels';
import { TFunction } from 'i18next';

export const getPatentDetail = (
  t: TFunction,
  patentData: Patent | null,
): Panel<Patent> => {
  return {
    protections: [
      {
        key: 'status',
        render: ({ status, ...patent }) => (
          <StatusBadge
            status={
              isAnnuityNotHandledByBdl(patent)
                ? StatusType.NotHandledByBdl
                : status
            }
          />
        ),
      },
      {
        key: 'country',
        render: (data: Patent) => {
          return <span>{getCountryLabel(data.country)}</span>;
        },
      },
      {
        key: 'id',
        translationKey: 'optOut',
        hideRow: (data) => !isEuropean(data?.country),
        render: ({ publicationNumber }: Patent) =>
          publicationNumber ? (
            <OptOut publicationNumber={publicationNumber} />
          ) : null,
      },
      {
        key: 'frenchPolynesiaExtension',
        hideRow: (data) => !(data?.country?.code === 'FR'), // Display only for french countries
        render: (record: Patent) => {
          return (
            <div>
              <span>
                {t('common.response', {
                  context:
                    record.frenchPolynesiaExtension &&
                    record.frenchPolynesiaExtension === 'Yes'
                      ? 'yes'
                      : 'no',
                })}
              </span>
            </div>
          );
        },
      },
      {
        key: 'protection',
        render: (data) => {
          return data.protection?.protectionLabel;
        },
      },
      {
        key: 'priorities',
        translationKey: 'priority',
        hideRow: (data) => !data?.priorities?.length,
        render: (data) =>
          formatDateAndNumberString({
            nbToString: `${data.priorities[0].country?.labelFr} | ${data.priorities[0]?.priorityNumber}`,
            date: new Date(data.priorities[0]?.priorityDate),
          }),
      },
      {
        key: 'depositNumber',
        translationKey: 'deposit',
        render: (data) =>
          formatDateAndNumberString({
            nbToString: data.depositNumber,
            date: new Date(data.depositDate),
          }),
      },
      {
        key: 'publicationNumber',
        translationKey: 'publication',
        hideRow: (d) => !(d?.publicationDate && d.publicationNumber),
        render: (data) =>
          formatDateAndNumberString({
            nbToString: data.publicationNumber,
            date: new Date(data.publicationDate),
          }),
      },
      {
        key: 'issueNumber',
        translationKey: 'issue',
        hideRow: (d) => !(d?.issueDate && d.issueNumber),
        render: (data) =>
          formatDateAndNumberString({
            nbToString: data.issueNumber,
            date: new Date(data.issueDate),
          }),
      },
      {
        key: 'abandonmentDate',
        render: (data: Patent) => formatDate(new Date(data?.abandonmentDate)),
        hideRow: (data) => !data?.abandonmentDate,
      },
      {
        key: 'expirationDate',
        hideRow: (data) => {
          return !(data && data.expirationDate);
        },
      },
    ],
    references: [
      {
        key: 'holder',
      },
      {
        key: 'effectiveHolder',
      },
      {
        key: 'inventors',
        render: (data) => {
          return data.inventors?.replace(FIND_ALL_COMAS, ' - ');
        },
      },

      {
        key: 'clientRef',
        hideRow: (data) => !data?.clientRef,
      },
      {
        key: 'clientRef2',
        render: (data) => {
          return data.clientRef2;
        },
        hideRow: (data) => !data?.clientRef2,
      },
      {
        key: 'bdlRef',
        render: (data) => {
          return data?.misRef
            ? `${data?.bdlRef} (${data?.misRef})`
            : data?.bdlRef;
        },
      },
      {
        key: 'clientOfficer',
      },
      { ...bdlOfficerAndSecretaryColumns(t, 'patent', patentData) },
    ],
  };
};

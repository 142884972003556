import { GetTrademarkQuery } from 'generated/graphql';
import { TFunction } from 'i18next';
import { isBeforeToday } from 'technical/date';
import { TimelineEvent, createTimelineEvents } from 'technical/timeline';

export const getTrademarkTimelineEvents = (
  t: TFunction,
  trademark?: GetTrademarkQuery['trademark_by_pk'],
): TimelineEvent[] => {
  if (!trademark) {
    return [];
  }

  const events: TimelineEvent[] = [
    {
      title: t('table.common.column.firstDeposit'),
      date: trademark.depositDate,
      reference: trademark.depositNumber,
    },
    {
      title: t('table.common.column.firstRegistration'),
      date: trademark.firstRegistrationDate,
      reference: trademark.registrationNumber,
    },
    {
      title: t('table.common.column.registration'),
      date: trademark.lastRegistrationDate,
      reference: trademark.registrationNumber,
    },
    ...trademark.priorities.map((p) => ({
      title: t('table.common.column.priority'),
      date: p.priorityDate,
      reference: p.priorityNumber,
    })),
  ];

  if (
    trademark.abandonmentDate &&
    isBeforeToday(new Date(trademark.abandonmentDate))
  ) {
    events.push({
      title: t('table.common.column.abandonmentDate'),
      date: trademark.abandonmentDate,
    });
  } else {
    events.push(
      ...trademark.nextRegistrations.map((n) => ({
        title: t('table.common.column.nextAnnuity', { context: 'trademark' }),
        date: n.dueDate,
      })),
    );
  }
  return createTimelineEvents(events);
};

import { Tabs, TabsProps } from 'antd';
import { Resource } from 'business/resources/shared/types';
import Description from 'ui/description';
import { Panel } from 'ui/description/types';
import { useTranslation } from 'react-i18next';
import ResourceDocuments from 'business/resources/shared/components/documents/resourceDocuments';

interface ResourcePanelProps<T> {
  description: {
    panels: Panel<T>;
    data: T | null;
  };
  resource: Resource;
  resourceId?: number;
  additionalTabs?: TabsProps['items'];
}

const ResourcePanelTabs = <T,>({
  description: { panels, data },
  resource,
  resourceId,
  additionalTabs,
}: ResourcePanelProps<T>) => {
  const { t } = useTranslation();

  const tabs: TabsProps['items'] = [
    {
      key: 'description',
      label: t('common.informations'),
      children: <Description<T> details={panels} data={data} />,
    },
    {
      key: 'documents',
      label: t('pages.document.title'),
      children: (
        <ResourceDocuments resource={resource} resourceId={resourceId} />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey="informations"
      items={additionalTabs ? [...tabs, ...additionalTabs] : tabs}
      type="card"
    />
  );
};

export default ResourcePanelTabs;

import {
  GetActiveArchiveSubscriptionByCompanyIdQuery,
  GetCompanyDomainInvoiceConfigurationQuery,
} from 'generated/graphql';

export type CompanyArchiveSubscription =
  GetActiveArchiveSubscriptionByCompanyIdQuery['archive_archivePackageCompany'];

export const moneyFields = [
  'registerFee',
  'renewFee',
  'transferFee',
  'corporateCost',
] as const;

export type MoneyFields = (typeof moneyFields)[number];

export type CompanyDomainFee = Pick<
  GetCompanyDomainInvoiceConfigurationQuery['domain_domainFee'][number],
  MoneyFields
>;

export type InvoiceContact = Omit<
  GetCompanyDomainInvoiceConfigurationQuery['domain_invoiceContact'][number],
  '__typename'
>;

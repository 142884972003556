import {
  GetDomainEventSearchQuery,
  GetDomainEventSearchQueryVariables,
  useGetDomainEventSearchQuery,
} from 'generated/graphql';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { DomainEvent } from 'business/resources/events/services/types';
import { storageKeys } from 'technical/storage/constants';

interface UseGetDomainEventParams {
  filterValues: GetDomainEventSearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<DomainEvent>;
  search?: string;
}

export const useGetDomainEvent = ({
  filterValues,
  overrideOrderBy,
  search,
}: UseGetDomainEventParams) =>
  useQueryForTable<
    DomainEvent,
    GetDomainEventSearchQuery,
    GetDomainEventSearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKeys.domainEvents,
    useQuery: ({
      limit,
      offset,
      orderBy,
      displayAFU,
      displayAnnuities,
      isOwn,
    }) =>
      useGetDomainEventSearchQuery({
        variables: {
          where: {
            ...filterValues,
            isOwn,
            search,
            displayAFU,
            displayAnnuities,
          },
          limit,
          offset,
          orderBy,
        },
      }),
    getTotal: (res) => res?.domainEventSearch?.count ?? 0,
    map: (res) => res?.domainEventSearch?.resources ?? [],
  });

import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { ExtractRouteParams, useParams } from 'react-router';
import ErrorOverlay from 'ui/errorOverlay';
import { useCompanyBreacrumbs } from 'business/common/services/breadcrumbs';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { resourceExists } from 'business/common/services';
import { useGetCompanyWithGroups } from 'business/configuration/companies/services/useGetCompanyWithGroups';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';
import CompanyWithNoGroupsRedirection from 'business/configuration/companies/components/companyWithNoGroupsRedirect';
import CompanyDetails from 'business/configuration/companies/components/companyDetails';

export const CompanyDetailPageContainer = () => {
  const { id } = useParams<ExtractRouteParams<Routes.CompanyDetails, string>>();
  const companyId = Number(id);
  const { company, companyDataState } = useGetCompanyWithGroups(companyId);
  const { getCompanyBaseBreadcrumbs } = useCompanyBreacrumbs();
  const { canSeeCompany } = useLoggedInAppContext();

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(company, companyDataState)}
    >
      <ConnectedPage
        breadcrumbs={getCompanyBaseBreadcrumbs(company)}
        translationKey="companyDetails"
      >
        <CompanyWithNoGroupsRedirection company={company}>
          <ErrorOverlay {...companyDataState}>
            {company && (
              <>
                <h1 className="headerTitle">{company.name}</h1>
                <CompanyDetails company={company} />
              </>
            )}
          </ErrorOverlay>
        </CompanyWithNoGroupsRedirection>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default CompanyDetailPageContainer;

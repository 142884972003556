import {
  Archive_Invoice_Bool_Exp,
  GetArchiveInvoicesQuery,
  GetArchiveInvoicesQueryVariables,
  useGetArchiveInvoicesQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): Archive_Invoice_Bool_Exp => ({
  _or: [
    ...generateTextSearchConditions(searchText, [
      'invoiceNumber',
      'holder',
      'archivePackageName',
    ]),
  ],
});

export const useGetArchiveInvoices = () => {
  const buildRangeFilter = (
    from: Date | null,
    to: Date | null,
  ): GetArchiveInvoicesQueryVariables['where'] => {
    const range: GetArchiveInvoicesQueryVariables['where'] = {};
    if (from) {
      range.date = { _gte: from.toISOString() };
    }
    if (to) {
      range.date = { _lte: to.toISOString() };
    }
    return range;
  };

  const query = useQueryForTable<
    GetArchiveInvoicesQuery['archive_invoice'][number],
    GetArchiveInvoicesQuery,
    GetArchiveInvoicesQueryVariables
  >({
    subStorageKey: storageKeys.archiveInvoices,
    commonFiltersKeys: ['from', 'to', 'search'],
    useQuery: ({ limit, offset, searchText, from, to, orderBy }) =>
      useGetArchiveInvoicesQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...buildRangeFilter(from, to),
            ...(searchText ? generateSearch(searchText) : {}),
          },
        },
      }),
    getTotal: (res) => res?.archive_invoice_aggregate.aggregate?.count ?? 0,
    map: (res) => res?.archive_invoice ?? [],
  });
  return { ...query };
};

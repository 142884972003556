import { formatDate } from 'technical/date';
import { DATE_FORMAT } from 'technical/date/constant';
import { TimelineEvent } from 'technical/timeline';
import { ArrowInformations } from 'ui/timeline/arrow/types';

export const arrowHeight = 40;

const formatLegends = (date: Date | null, reference?: string | null) => {
  return [
    date ? formatDate(date, DATE_FORMAT.dayMonthYearWithSpace) : '',
    reference ? `n° ${reference}` : '',
  ];
};

export const formatTimelineArrows = (
  timelineElements: TimelineEvent[],
): ArrowInformations[] => {
  return timelineElements.map(({ date, reference, title, type }) => {
    return {
      title,
      type,
      legends: formatLegends(new Date(date), reference),
    };
  });
};

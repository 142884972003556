import WhoIsButton from 'business/resources/domain/components/whoisButton';
import WWWLinks from 'business/resources/domain/components/wwwLinks';
import { DomainPageProps } from 'business/resources/domain/services/useGetDomain';
import { TFunction } from 'i18next';
import { Panel } from 'ui/description/types';
import { bdlOfficerAndSecretaryColumns } from 'business/resources/domain/components/panels';

export type Domain = NonNullable<DomainPageProps['domain']>;

const getDomainDetails = (
  t: TFunction,
  isRegistrarData: boolean,
  domain: Domain | null,
): Panel<Domain> => {
  const suffix = isRegistrarData
    ? ` (${t('pages.domain.details.registrarData')})`
    : undefined;

  return {
    protections: [
      {
        key: 'status',
      },
      {
        key: 'reservationDate',
        suffix: () => suffix,
      },
      {
        key: 'expirationDate',
        suffix: () => suffix,
      },
      {
        key: 'id',
        translationKey: 'whois',
        render: ({ title }) => <WhoIsButton domain={title} />,
      },
      {
        key: 'title',
        translationKey: 'link',
        render: ({ title }) => <WWWLinks domain={title} />,
      },
    ],
    references: [
      { key: 'holder' },
      {
        key: 'bdlRef',
        render: (data) => {
          return data?.misRef
            ? `${data?.bdlRef} (${data?.misRef})`
            : data?.bdlRef;
        },
      },
      bdlOfficerAndSecretaryColumns(t, 'domain', domain),
    ],
  };
};

export default getDomainDetails;

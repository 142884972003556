import { User } from 'business/configuration/accessManagement/services/types';
import { isUserStatus } from 'business/user/services/status';
import { USER_STATUSES, UserStatus } from 'business/user/types/status';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  User_Bool_Exp,
  useGetUsersQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';
import { storageKeys } from 'technical/storage/constants';

const generateSearch = (searchText: string): User_Bool_Exp => ({
  _or: [
    ...generateTextSearchConditions(searchText, [
      'email',
      'firstName',
      'lastName',
    ]),
    {
      groups: {
        group: {
          _or: [
            ...generateTextSearchConditions(searchText, ['name']),
            {
              company: {
                _or: [
                  ...generateTextSearchConditions(searchText, [
                    'name',
                    'companyRef',
                  ]),
                ],
              },
            },
          ],
        },
      },
    },
  ],
});

const generateWhere = (
  searchText: string | null,
  statusFilter: UserStatus | null,
) => {
  return {
    ...(statusFilter ? { status: { _eq: statusFilter } } : {}),
    ...(searchText ? generateSearch(searchText) : {}),
  };
};
const useGetUsers = () => {
  const { tableProps, ...rest } = useQueryForTable<
    User,
    GetUsersQuery,
    GetUsersQueryVariables
  >({
    subStorageKey: storageKeys.users,
    commonFiltersKeys: ['userStatus', 'search'],
    useQuery: ({ limit, offset, searchText, orderBy, userStatus }) => {
      return useGetUsersQuery({
        variables: {
          limit,
          orderBy,
          offset,
          where: generateWhere(searchText, userStatus),
        },
        fetchPolicy: 'cache-and-network',
      });
    },
    getTotal: (res) => res?.user_aggregate.aggregate?.count ?? 0,
    map: (res) =>
      res?.user?.map(({ status, ...user }) => ({
        ...user,
        status: isUserStatus(status) ? status : USER_STATUSES.unknown,
        companyRef: user.groups.length
          ? user.groups[0].group.company.companyRef
          : '',
      })) ?? [],
  });

  return {
    ...rest,
    tableProps,
  };
};

export default useGetUsers;

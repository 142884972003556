import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

interface CompanyClientRefListProps {
  disabled: boolean;
}

const CompanyClientRefList = ({ disabled }: CompanyClientRefListProps) => {
  const { t } = useTranslation();
  return (
    <Form.List name="clientRefs">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Spacer key={key} align="center">
              <Form.Item
                {...restField}
                name={[name, 'clientRef']}
                style={{ flex: 1 }}
              >
                <Input
                  disabled={disabled}
                  placeholder={t('table.common.clientReference1')}
                />
              </Form.Item>
              <MinusCircleOutlined
                onClick={() => remove(name)}
                disabled={disabled}
              />
            </Spacer>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              disabled={disabled}
              icon={<PlusOutlined />}
            >
              {t('archive.clientRefs.addClientRef')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default CompanyClientRefList;

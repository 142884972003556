import Routes from 'business/router/routes';
import fetchUser from 'business/user/services/user';
import { UserWithConsent } from 'business/user/types/user';
import {
  AskForPasswordResetDocument,
  CreatePasswordDocument,
  LogoutUserDocument,
  RefreshTokenDocument,
  ResetPasswordDocument,
  SignInDocument,
  SignInMutation,
  SignInMutationVariables,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import client from 'technical/graphql/client';
import history from 'technical/history';
import { i18n } from 'translations';

let authResult: UserWithConsent | null = null;
let accessToken: string | null = null;

export const goToLogin = () => {
  history.push(Routes.SignIn);
};

export function isAuthenticated() {
  return !!authResult;
}

export function getAccessToken() {
  return accessToken;
}

export async function persistAuth(token: string) {
  accessToken = token;
  const user = await fetchUser(client);
  if (user) {
    authResult = user;
  }
}

async function unpersistAuth(): Promise<void> {
  authResult = null;
  accessToken = null;
  errorReporting.removeUser();
  await client.mutate({
    mutation: LogoutUserDocument,
  });
}

const storeTokenAndLogin = async (idToken: string) => {
  await persistAuth(idToken);
  history.push(Routes.LoginCallback);
};

export async function renewToken() {
  // Do not launch the next request with an access token, it may lead
  // to an infinite loop if the current access token is outdated
  accessToken = null;

  const { data, errors } = await client.mutate({
    mutation: RefreshTokenDocument,
    fetchPolicy: 'no-cache',
  });

  if (errors) {
    throw new Error('Token renewal have failed');
  }

  if (data.queryRefreshToken.success && data.queryRefreshToken.idToken) {
    await persistAuth(data.queryRefreshToken.idToken);
  } else {
    throw new Error(data.queryRefreshToken.message);
  }
}

export const initAuthentication = async () => {
  try {
    await renewToken();
  } catch (error) {
    if (
      error instanceof Error &&
      error.message !== 'login-required' &&
      error.message !== 'email-not-verified'
    ) {
      errorReporting.error(error);
    }
  }
};

export const signIn = async (
  email: string,
  password: string,
  mfaToken: string | null,
) => {
  const { data } = await client.mutate<SignInMutation, SignInMutationVariables>(
    {
      mutation: SignInDocument,
      variables: {
        email,
        password,
        mfaToken,
      },
    },
  );

  if (data?.signIn?.mfaRequired) {
    return 'mfa-required';
  }

  if (data?.signIn?.success && data.signIn.idToken) {
    await storeTokenAndLogin(data.signIn.idToken);
  } else {
    throw new Error(
      data?.signIn?.message ?? 'An error occured during authentication',
    );
  }
};

export const requestLoginCallback = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (authResult) {
      resolve();
      return;
    }

    reject(new Error('An error occured during authentication'));
  });

export const getAuthResult = () => authResult;

export const logout = async () => {
  await unpersistAuth();
  // Instead of using History, we use window.location so
  // The app is re-bootstrap and informations concerning our user
  // are up to date: ie he's not here anymore
  window.location.href = Routes.Home;
};

export const forgotPassword = async (email: string) => {
  const { data, errors } = await client.mutate({
    mutation: AskForPasswordResetDocument,
    variables: {
      email,
    },
  });
  if (errors || !data?.askForPasswordReset?.success) {
    throw new Error(i18n.t('errors.generic'));
  }
};

// Token can be null because if the user is logged in he does not require
// a token to reset his password
export const resetPassword = async (
  resetPasswordToken: string | null,
  password: string,
  passwordConfirmation: string,
) => {
  const { errors } = await client.mutate({
    mutation: ResetPasswordDocument,
    variables: {
      resetPasswordToken,
      password,
      passwordConfirmation,
    },
  });
  if (errors) {
    throw new Error(i18n.t('errors.generic'));
  }
};

export const createPassword = async (
  resetPasswordToken: string | null,
  password: string,
) => {
  const { errors, data } = await client.mutate({
    mutation: CreatePasswordDocument,
    variables: {
      resetPasswordToken,
      password,
    },
  });
  if (errors) {
    throw new Error(i18n.t('errors.generic'));
  }
  await storeTokenAndLogin(data.createPassword.token);
};

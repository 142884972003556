import { Link } from 'react-router-dom';
import { PatentFamily } from 'business/resources/patent/types';
import { ColumnGenerationData } from 'ui/table/types';
import { TableOutlined } from '@ant-design/icons';
import StatusBadge from 'business/common/components/statusBadge';
import {
  StatusType,
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import { isAnnuityNotHandledByBdl } from 'business/resources/patent/services/index';
import {
  getCountryColumn,
  getCountryKey,
} from 'technical/countriesTranslation/constants';

export const patentsFamilyColumns: ColumnGenerationData<PatentFamily> = [
  { key: 'holder' },
  { key: 'effectiveHolder' },
  {
    key: 'title',
    alwaysVisible: true,
  },
  {
    ...getCountryColumn(),
    alwaysVisible: true,
    render: (_, patent: PatentFamily) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('patent', patent.id)}
      >
        {patent[getCountryKey()]}
      </Link>
    ),
  },
  { key: 'depositNumber' },
  { key: 'depositDate' },
  { key: 'publicationNumber', checked: false },
  { key: 'publicationDate', checked: false },
  { key: 'issueNumber' },
  { key: 'issueDate' },
  { key: 'expirationDate' },
  { key: 'bdlRef' },
  { key: 'misRef' },
  { key: 'clientRef' },
  { key: 'clientRef2', checked: false },
  { key: 'bdlOfficer' },
  { key: 'inventors' },

  {
    key: 'status',
    width: 100,
    render: (status: string, patent) => (
      <StatusBadge
        status={
          isAnnuityNotHandledByBdl(patent) ? StatusType.NotHandledByBdl : status
        }
      />
    ),
  },
];

export const patentsColumnsAdvancedSearchTable: ColumnGenerationData<PatentFamily> =
  [
    { key: 'holder' },
    { key: 'effectiveHolder', ellipsis: true },
    {
      key: 'title',
      alwaysVisible: true,
      render: (_, { id, title }: PatentFamily) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={generatePathByAssetType('patent', id)}
        >
          {title}
        </Link>
      ),
    },
    {
      key: getCountryKey(),
      translationKey: 'country',
    },
    { key: 'depositNumber' },
    { key: 'depositDate' },
    { key: 'publicationNumber', ellipsis: true },
    { key: 'publicationDate', ellipsis: true },
    { key: 'issueNumber', checked: false, ellipsis: true },
    { key: 'issueDate', checked: false, ellipsis: true },
    { key: 'bdlRef' },
    { key: 'misRef' },
    { key: 'clientRef', checked: false, ellipsis: true },
    { key: 'clientRef2', checked: false, ellipsis: true },
    { key: 'bdlOfficer', checked: false, ellipsis: true },
    { key: 'clientOfficer', checked: false, ellipsis: true },
    { key: 'inventors' },
    { key: 'status' },
    {
      key: 'familyId',
      width: 50,
      translationKey: 'family',
      render: (_, { familyId }: PatentFamily) => (
        <Link to={generateFamilyPathByAssetType('patent', familyId)}>
          <TableOutlined />
        </Link>
      ),
    },
  ];

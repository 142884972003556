import * as Yup from 'yup';
import yupPassword from 'yup-password';
import { TFunction } from 'i18next';

yupPassword(Yup);

export const email = (t: TFunction) =>
  Yup.string()
    .email(t('errors.wrong_email_address'))
    .required(t('errors.required'));

import { useGetCompanyWithGroupsByPkQuery } from 'generated/graphql';

export const useGetCompanyWithGroups = (companyId: number) => {
  const { data: companyData, ...companyDataState } =
    useGetCompanyWithGroupsByPkQuery({
      variables: { id: companyId },
    });

  return {
    company: companyData?.company_by_pk,
    companyDataState,
  };
};

export default useGetCompanyWithGroups;

import { Image, Tooltip } from 'antd';

import { DesignFamily } from 'business/resources/design/services/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import { ellipsisStyle } from 'ui/table/columns';
import { FAMILIES_TABLE_IMAGE_STYLE } from 'ui/imageDisplay/constants';
import {
  getCountriesColumn,
  getCountriesKey,
} from 'technical/countriesTranslation/constants';
import Loader from 'ui/loader';

type DesignFamiliesTableProps = Omit<
  TableProps<DesignFamily>,
  'defaultColumns'
>;

const defaultColumns: ColumnGenerationData<DesignFamily> = [
  { key: 'holder', ellipsis: true },
  {
    key: 'title',
    context: 'design',
    alwaysVisible: true,
    render: (_: any, record: DesignFamily) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={`/design/family/${record.id}`}
      >
        {record.title}
      </Link>
    ),
  },
  {
    key: 'image',
    translationKey: 'image',
    sorter: false,
    width: 200,
    render: (_: any, { image }) => {
      if (image === 'loading') {
        return <Loader />;
      }
      if (image) {
        return (
          <Image
            src={image}
            style={{ ...FAMILIES_TABLE_IMAGE_STYLE, objectFit: 'contain' }}
          />
        );
      }
    },
  },
  {
    ...getCountriesColumn(),
    onCell: () => ellipsisStyle,
    ellipsis: true,
    render: (_, record: DesignFamily) => (
      <Tooltip title={record[getCountriesKey()]}>
        {record[getCountriesKey()]}
      </Tooltip>
    ),
  },
  {
    key: 'firstDepositNumber',
    ellipsis: true,
  },
  {
    key: 'firstDepositDate',
    ellipsis: true,
  },
  { key: 'bdlRef', ellipsis: true },
  { key: 'bdlOfficerName', ellipsis: true },
];

const DesignFamiliesTable: React.FC<DesignFamiliesTableProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Table
      id="designFamiliesTable"
      {...props}
      name="designFamily"
      showEffectiveCheckbox
      family="design"
      defaultColumns={defaultColumns}
      header={
        <h1 className="headerTitle"> {t('pages.header.designFamilies')}</h1>
      }
    />
  );
};

export default DesignFamiliesTable;

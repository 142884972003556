import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import { getPasswordRules } from './constants';
import styles from './index.module.scss';

interface PasswordRulesProps {
  password: string;
}

const PasswordRules = ({ password }: PasswordRulesProps) => {
  const { t } = useTranslation();
  return (
    <Flex column>
      {getPasswordRules(t).map((rule) => {
        const textColor =
          !!password.length && password.match(rule.pattern)
            ? styles.valid
            : styles.invalid;
        return (
          <span key={rule.label} className={textColor}>
            {rule.label}
          </span>
        );
      })}
    </Flex>
  );
};

export default PasswordRules;

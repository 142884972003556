import { useGetPatentEvents } from 'business/resources/patent/services';
import EventTable from 'business/resources/shared/components/event/eventTable';

const EventTab = ({ id }: { id: number }) => {
  const { data: events, tableProps: eventTableProps } = useGetPatentEvents(id);

  return (
    <EventTable
      dataSource={events}
      {...eventTableProps}
      searchBarEnabled={false}
      editButtonEnabled={false}
    />
  );
};

export default EventTab;

import { Language } from 'business/user/types/user';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Flex from 'ui/flex';
import Select from 'ui/select';
import useLanguageSelection from 'business/user/pages/profile/hooks/useLanguageSelection';
import styles from './index.module.scss';

const UpdateLanguage = () => {
  const { t } = useTranslation();
  const {
    defaultValue,
    language,
    updateLanguage,
    setLanguage,
    languageOptions,
  } = useLanguageSelection();

  return (
    <Flex column>
      <span className={styles.label}>
        {t('pages.profile.form.accountManagement.labelLanguage')}
      </span>
      <Select<Language>
        data-test-id="language-select"
        defaultValue={defaultValue?.code}
        options={languageOptions}
        onChange={setLanguage}
      />
      <Button
        data-test-id="submitUpdateLanguage"
        disabled={!language || language === defaultValue?.code}
        onClick={updateLanguage}
        className={styles.button}
        type="primary"
      >
        {t('pages.profile.form.accountManagement.submit')}
      </Button>
    </Flex>
  );
};

export default UpdateLanguage;

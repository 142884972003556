import { useGetClaims } from 'business/resources/patent/services/useGetClaims';
import { useTranslation } from 'react-i18next';
import List from 'ui/list';
import Typography from 'ui/typography';

interface IClaims {
  patentId: number;
}

const Claims = ({ patentId }: IClaims) => {
  const { claims, type, loading, error, refetch } = useGetClaims(patentId);
  const { t } = useTranslation();
  const title = type
    ? t('pages.patent.sections.claims.claims', { context: type })
    : t('pages.patent.sections.claims.claims');
  return (
    <>
      <Typography.Title>{title}</Typography.Title>
      <List loading={loading} error={error} data={claims} refetch={refetch} />
    </>
  );
};

export default Claims;

import { tabsFamily } from 'business/common/services/types';
import { dateParser, dateRangeParser } from './parser/commonParser/dateParser';
import { stringEnumParser } from './parser/commonParser/enumParser';
import { orderByParser } from './parser/commonParser/orderByParser';
import { storageBooleanParser } from './parser/storageParser/booleanParser';
import { storageNumberParser } from './parser/storageParser/numberParser';
import { storageSearchParser } from './parser/storageParser/stringParser';
import { storageUserStatusParser } from './parser/storageParser/userStatusParser';
import { storageVisibleColumnsParser } from './parser/storageParser/visibleColumnsParser';
import { urlBooleanParser } from './parser/urlParser/booleanParser';
import { urlNumberParser } from './parser/urlParser/numberParser';
import { urlSearchParser } from './parser/urlParser/stringParser';
import { urlUserStatusParser } from './parser/urlParser/userStatusParser';
import {
  CommonTableFilter,
  DefaultValues,
  Filter,
  StorageTableFilter,
  UrlTableFilter,
} from './types';

const DEFAULT_PAGE_SIZE = 30;

export const getUrlFiltersParser = <T>(defaultValues?: DefaultValues<T>) => {
  return {
    page: urlNumberParser(1),
    pageSize: urlNumberParser(DEFAULT_PAGE_SIZE),
    isOwn: storageBooleanParser(false),
    search: urlSearchParser(''),
    isEffective: urlBooleanParser(true),
    orderBy: orderByParser(defaultValues?.orderBy),
    family: stringEnumParser(tabsFamily),
    filtersOpen: urlBooleanParser(false),
    displayAFU: storageBooleanParser(false),
    displayAnnuities: storageBooleanParser(true),
    userStatus: urlUserStatusParser(),
    dateRange: dateRangeParser(),
    from: dateParser(),
    to: dateParser(),
    type: urlSearchParser(),
    myArchives: urlBooleanParser(false),
  };
};

export const getStorageFiltersParser = <T>(
  defaultValues?: DefaultValues<T>,
) => {
  return {
    page: storageNumberParser(1),
    pageSize: storageNumberParser(DEFAULT_PAGE_SIZE),
    search: storageSearchParser(''),
    isOwn: storageBooleanParser(false),
    displayAnnuities: storageBooleanParser(false),
    displayAFU: storageBooleanParser(false),
    isEffective: storageBooleanParser(true),
    orderBy: orderByParser(defaultValues?.orderBy),
    family: stringEnumParser(tabsFamily),
    filtersOpen: storageBooleanParser(false),
    userStatus: storageUserStatusParser(),
    dateRange: dateRangeParser(),
    from: dateParser(),
    to: dateParser(),
    type: storageSearchParser(),
    myArchives: storageBooleanParser(false),
    visibleColumns: storageVisibleColumnsParser(),
  };
};

export const getUrlFiltersFromKey = <T>(
  keys: (keyof UrlTableFilter<T>)[],
  defaultValues?: DefaultValues<T>,
) => {
  return keys.reduce<Filter<UrlTableFilter<T>>>(
    (acc, key) => {
      return {
        ...acc,
        [key]: getUrlFiltersParser(defaultValues)[key],
      };
    },
    {} as Filter<UrlTableFilter<T>>,
  );
};

export const getStorageFiltersFromKey = <T>(
  keys: (keyof StorageTableFilter<T>)[],
  defaultValues?: DefaultValues<T>,
) => {
  return keys.reduce<Filter<StorageTableFilter<T>>>(
    (acc, key) => {
      return {
        ...acc,
        [key]: getStorageFiltersParser(defaultValues)[key],
      };
    },
    {} as Filter<StorageTableFilter<T>>,
  );
};

export const getDefaultFilters = <T>(): (keyof CommonTableFilter<T>)[] => [
  'orderBy',
  'page',
  'pageSize',
];

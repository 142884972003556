import ArchiveConfigurationLinks from 'business/archive/components/archiveConfigurationLinks';
import ArchiveSubscriptionsInformation from 'business/archive/components/archiveSubscriptionsInformation';
import ArchiveSubscriptionTable from 'business/archive/components/archiveSubscriptionTable';
import { useGetArchiveSubscriptionInformation } from 'business/archive/services';
import KeywordsModalEdition from 'business/configuration/keywords/components/modalEdition';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import style from './index.module.scss';

const ArchiveSubscriptions = () => {
  const { t } = useTranslation();
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);

  const archiveSubscriptionInformation = useGetArchiveSubscriptionInformation();

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('archive.title') }]}
      translationKey="archiveSubscriptions"
    >
      <Spacer direction="vertical" size="middle">
        <h1 className="headerTitle">{t('archive.title')}</h1>
        <Flex
          column
          justify="center"
          className={style.archiveSubscriptionsInformation}
        >
          <ArchiveSubscriptionsInformation
            {...archiveSubscriptionInformation}
          />
        </Flex>
        <ArchiveConfigurationLinks
          openKeywordModal={() => setIsKeywordsModalOpen(true)}
        />
        <ArchiveSubscriptionTable />
        <KeywordsModalEdition
          visible={isKeywordsModalOpen}
          onClose={() => setIsKeywordsModalOpen(false)}
        />
      </Spacer>
    </ConnectedPage>
  );
};

export default ArchiveSubscriptions;

import { useTranslation } from 'react-i18next';

const dateFormats: Record<string, string> = {
  'fr-FR': 'DD/MM/YYYY',
  en: 'MM/DD/YYYY',
};

export const useDateformat = () => {
  const { i18n } = useTranslation();
  return dateFormats[i18n.language] ?? dateFormats.en;
};

import { Resource } from 'business/resources/shared/types';
import { convertToBinaryAndDownloadFile } from 'technical/fileManagement';
import { useTranslation } from 'react-i18next';
import { useGetResourceDocumentLazyQuery } from 'generated/graphql';
import notifyPromise from 'business/common/services/notifyPromise';

interface DownloadDocumentParam {
  resource: Resource;
  id: number;
  openInNewTab?: boolean;
}

const useDownloadDocument = () => {
  const { t } = useTranslation();
  const [getResourceDocument] = useGetResourceDocumentLazyQuery();

  const downloadDocument = (
    file?: string | null,
    type?: string | null,
    openInNewTab?: boolean,
  ) => {
    if (!file || !type) {
      throw new Error('document not found');
    }
    return convertToBinaryAndDownloadFile(
      file,
      `application/pdf`,
      `document.${type}`,
      openInNewTab,
    );
  };

  const getAndDownloadDocument = async ({
    resource,
    id,
    openInNewTab = false,
  }: DownloadDocumentParam) => {
    notifyPromise(
      t,
      async () => {
        const { data } = await getResourceDocument({
          variables: {
            type: resource,
            id,
          },
        });
        return downloadDocument(
          data?.getResourceDocument?.file,
          data?.getResourceDocument?.type,
          openInNewTab,
        );
      },
      {
        errorNotification: t('errors.error', { context: 'downloadDocument' }),
        successNotification: null,
      },
    );
  };
  return {
    getAndDownloadDocument,
  };
};

export default useDownloadDocument;

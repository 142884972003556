import { Button, Input, Modal } from 'antd';
import useMfa from 'business/user/services/useMfa';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import Typography from 'ui/typography';

interface MfaModalProps {
  open: boolean;
  onOk: (mfaToken: string) => void;
  onCancel: () => void;
  email: string;
}

const MfaModal: React.VFC<MfaModalProps> = ({
  onCancel,
  onOk,
  open,
  email,
}) => {
  const { t } = useTranslation();
  const { mfaToken, setMfaToken, canResend, resendMfaToken } = useMfa({
    open,
    email,
  });

  return (
    <Modal
      title={t('pages.signIn.mfa.title')}
      open={open}
      onOk={() => onOk(mfaToken)}
      onCancel={onCancel}
    >
      <Spacer direction="vertical" size="small">
        <Typography.Text>
          {t('pages.signIn.mfa.description', { email })}
        </Typography.Text>
        <Input
          size="large"
          value={mfaToken}
          onChange={(event) => setMfaToken(event.target.value)}
          placeholder={t('pages.signIn.mfa.placeholder')}
        />
        {canResend && (
          <Spacer direction="horizontal" size="small">
            <Button size="small" type="link" onClick={resendMfaToken}>
              {t('pages.signIn.mfa.sendCode')}
            </Button>
          </Spacer>
        )}
      </Spacer>
    </Modal>
  );
};

export default MfaModal;

import Arrows from 'ui/timeline/arrow/arrows';
import { arrowSize } from 'ui/timeline/arrow/constants';
import Card from 'ui/card';
import Spacer from 'ui/spacer';
import { arrowHeight, formatTimelineArrows } from './format';
import { TimelineEvent } from 'technical/timeline';

interface TimelineProps {
  events: TimelineEvent[];
}

const Timeline = ({ events }: TimelineProps) => {
  return events.length ? (
    <Spacer direction="vertical" size="small">
      <Card
        style={{
          paddingRight: arrowSize(arrowHeight),
          paddingLeft: arrowSize(arrowHeight) / 2,
          overflowX: 'auto',
        }}
      >
        <Arrows height={arrowHeight} arrows={formatTimelineArrows(events)} />
      </Card>
    </Spacer>
  ) : null;
};

export default Timeline;

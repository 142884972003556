import { DomainInvoice } from 'business/admin/services/types';
import InvoiceTableActions from 'business/common/components/invoiceTable/actions';
import notifyPromise from 'business/common/services/notifyPromise';
import { TFunction, useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import confirmationModal from 'ui/modalConfirm';
import useSendBackInvoice from 'business/invoice/hooks/useSendBackInvoice';
import useOpenInvoiceInNewTab from 'business/invoice/hooks/useOpenInvoiceInNewTab';

type DomainInvoiceTableProps = Omit<
  TableProps<DomainInvoice>,
  'defaultColumns'
> & {
  canSendBackInvoiceByMail?: boolean;
};

const defaultColumns = (
  t: TFunction,
  openInvoice: (domainInvoiceId: number) => Promise<void>,
  sendBackInvoice?: (domainInvoiceId: number) => Promise<void>,
): ColumnGenerationData<DomainInvoice> => [
  { key: 'invoiceNumber', alwaysVisible: true },
  { key: 'date', context: 'domainInvoice', dataType: 'date' },
  {
    key: 'id',
    render: (_: any, { holder }: DomainInvoice) =>
      t('pages.admin.invoice.table.invoiceTitle', { holder }),
    ellipsis: true,
  },
  { key: 'holder' },
  { key: 'clientRef', ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'section' },
  { key: 'total', dataType: 'priceInCents' },
  { key: 'VATComputedTotal', dataType: 'priceInCents' },
  { key: 'totalWithVAT', dataType: 'priceInCents' },
  { key: 'domains', ellipsis: true },
  { key: 'sendDate' },
  {
    key: 'id',
    fixed: 'right',
    translationKey: 'action',
    alwaysVisible: true,
    width: 85,
    sorter: false,
    render: (_: any, { id, recipientEmail }: DomainInvoice) => (
      <InvoiceTableActions
        onClickView={() => openInvoice(id)}
        onClickMail={sendBackInvoice ? () => sendBackInvoice(id) : undefined}
        emails={recipientEmail ?? undefined}
      />
    ),
  },
];

const DomainInvoiceTable: React.FC<DomainInvoiceTableProps> = ({
  canSendBackInvoiceByMail = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [sendBackDomainInvoice] = useSendBackInvoice('domain');
  const [openDomainInvoiceInNewTab] = useOpenInvoiceInNewTab('domain');

  const openInvoiceInNewTab = async (id: number) =>
    notifyPromise(t, async () => openDomainInvoiceInNewTab(id), {
      successNotification: null,
    });

  const sendBackEmail = async (id: number) => {
    confirmationModal(
      t,
      () => {
        notifyPromise(t, async () => sendBackDomainInvoice(id), {
          successNotification: t('pages.admin.invoice.sendBackInvoice.success'),
        });
      },
      t('pages.admin.invoice.sendBackInvoice.modal.title'),
    );
  };

  return (
    <Table
      id="domainInvoiceTable"
      {...props}
      defaultColumns={defaultColumns(
        t,
        openInvoiceInNewTab,
        canSendBackInvoiceByMail ? sendBackEmail : undefined,
      )}
    />
  );
};

export default DomainInvoiceTable;

import Card from 'ui/card';

interface ConfigurationCardProps {
  title: string;
  children: React.ReactNode;
}

const ConfigurationCard = ({ children, title }: ConfigurationCardProps) => {
  return (
    <>
      <h1 className="tableTitle">{title}</h1>
      <Card>{children}</Card>
    </>
  );
};

export default ConfigurationCard;

import { Button, Form, Input, Modal } from 'antd';
import { ValidatePasswordDocument } from 'generated/graphql';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import apolloClient from 'technical/graphql/client';
import logger from 'technical/logger';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';

interface PasswordValidationModalProps {
  onOk: () => void | Promise<void>;
  warningMessage?: string;
  confirmButtonText: string;
  modalContent: ReactNode;
  instructionText: string;
}
const PasswordValidationModal: React.VFC<PasswordValidationModalProps> = ({
  onOk,
  warningMessage,
  confirmButtonText,
  modalContent,
  instructionText,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const onSubmitPassword = async (values: { password: string }) => {
    const { password } = values;
    try {
      const validatePasswordResponse = await apolloClient.mutate({
        mutation: ValidatePasswordDocument,
        variables: { password },
      });
      if (validatePasswordResponse.data.validatePassword.success) {
        Modal.destroyAll();
        onOk();
      } else {
        form.setFields([
          {
            name: 'password',
            errors: [t('errors.password-confirm-not-matching')],
          },
        ]);
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Spacer direction="vertical" size="small" style={{ marginTop: 20 }}>
      {modalContent}
      <Form onFinish={onSubmitPassword} form={form}>
        <span>
          <b>{instructionText}</b>
        </span>
        <Form.Item
          name="password"
          style={{ marginTop: 10 }}
          rules={[{ required: true, message: t('errors.required') }]}
        >
          <Input.Password
            placeholder={t('pages.signUp.form.passwordConfirmation')}
          />
        </Form.Item>

        <Form.Item>
          <Flex
            justify="space-between"
            style={{ marginTop: 15 }}
            alignItems="center"
          >
            <span style={{ color: 'red' }}>{warningMessage}</span>
            <Button type="primary" danger htmlType="submit">
              {confirmButtonText}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Spacer>
  );
};
export default PasswordValidationModal;

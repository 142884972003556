import {
  GetPatentEventSearchQuery,
  GetPatentEventSearchQueryVariables,
  useGetPatentEventSearchQuery,
} from 'generated/graphql';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { PatentEvent } from 'business/resources/events/services/types';
import { storageKeys } from 'technical/storage/constants';

interface UseGetPatentEventParams {
  filterValues: GetPatentEventSearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<PatentEvent>;
  search?: string;
}

export const useGetPatentEvent = ({
  filterValues,
  overrideOrderBy,
  search,
}: UseGetPatentEventParams) =>
  useQueryForTable<
    PatentEvent,
    GetPatentEventSearchQuery,
    GetPatentEventSearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKeys.patentEvents,
    useQuery: ({
      limit,
      offset,
      orderBy,
      displayAFU,
      displayAnnuities,
      isOwn,
    }) =>
      useGetPatentEventSearchQuery({
        variables: {
          where: {
            ...filterValues,
            search,
            status: ['effective'],
            displayAFU,
            displayAnnuities,
            isOwn,
          },
          limit,
          offset,
          orderBy,
        },
      }),
    getTotal: (res) => res?.patentEventSearch?.count ?? 0,
    map: (res) => res?.patentEventSearch?.resources ?? [],
  });

import { Form, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import errorReporting from 'technical/error-reporting';
import { useEffect } from 'react';
import useDefaultDomainFee, {
  DomainFeeData,
} from 'business/admin/hooks/useGetDefaultDomainFee';
import DomainFeeFormFields from 'business/common/form/domainFeeFormFields';
import logger from 'technical/logger';

const { useForm } = Form;

interface DomainFeeModalProps {
  visible: boolean;
  onClose: () => void;
}

const DomainFeeModal = ({ visible, onClose }: DomainFeeModalProps) => {
  const { t } = useTranslation();

  const { defaultDomainFeeData, updateDefaultDomainFee, ...rest } =
    useDefaultDomainFee();

  const [form] = useForm<DomainFeeData>();

  const onFinish = async (value: DomainFeeData) => {
    try {
      const id = defaultDomainFeeData?.id;
      if (id === undefined) {
        throw new Error(
          '[DOMAIN FEE MODAL] Unexpected: default domain id is undefined',
        );
      }
      await updateDefaultDomainFee(id, value);
      notification.success({
        message: t(
          'pages.admin.invoice.domainConfig.modal.notifications.success',
        ),
      });
      onClose();
    } catch (err) {
      errorReporting.error(err);
      notification.error({
        message: t('errors.generic'),
      });
    }
  };

  const handleValidate = async () => {
    form
      .validateFields()
      .then(onFinish)
      .catch((error) => {
        logger.error(error);
      });
  };

  useEffect(form.resetFields, [defaultDomainFeeData]);

  return (
    <Modal
      forceRender
      open={visible}
      onCancel={onClose}
      afterClose={() => form.resetFields()}
      okText={t('common.save')}
      onOk={() => handleValidate()}
    >
      <h2 className="tableLabel">
        {t('pages.admin.invoice.domainConfig.modal.title')}
      </h2>
      <h3>{t('pages.admin.invoice.domainConfig.modal.subTitle')}</h3>
      <Form layout="vertical" form={form} initialValues={defaultDomainFeeData}>
        <ErrorOverlay {...rest}>
          <DomainFeeFormFields registerFee renewFee corporateCost transferFee />
        </ErrorOverlay>
      </Form>
    </Modal>
  );
};

export default DomainFeeModal;

import { useTranslation } from 'react-i18next';
import Card from 'ui/card';
import SimpleTable, { Records } from 'ui/simpleTable';
import { getColumnsDataType } from 'ui/table/columns';

interface CertificateTableProps<T> {
  certificate: T[];
}

const CertificateTable = <T extends Records>({
  certificate,
}: CertificateTableProps<T>) => {
  const { t } = useTranslation();

  return (
    <Card title={t('pages.domain.dnsPublicData.sslCertificate.title')}>
      <SimpleTable
        columnsTitleContext="pages.domain.dnsPublicData.sslCertificate"
        className="marginTop10"
        columns={[
          { key: 'provider' },
          { key: 'domain' },
          { key: 'validFrom', render: getColumnsDataType().date },
          { key: 'validTo', render: getColumnsDataType().date },
        ]}
        dataSource={certificate}
      />
    </Card>
  );
};

export default CertificateTable;

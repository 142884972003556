import notifyPromise from 'business/common/services/notifyPromise';
import { useSendExportQueryRequestMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export interface ExportQueryForm {
  monthlyOp?: boolean;
  tableData?: boolean;
  yearReportDate?: Date;
}
export const useSendExportQueryRequest = () => {
  const [sendExport] = useSendExportQueryRequestMutation();
  const { t } = useTranslation();

  const sendExportQueryRequest = async (data: ExportQueryForm) => {
    notifyPromise(
      t,
      async () => {
        await sendExport({
          variables: {
            args: {
              monthlyOp: data.monthlyOp,
              tableData: data.tableData,
              yearReportDate: data.yearReportDate
                ? new Date(data.yearReportDate).getFullYear().toString()
                : undefined,
            },
          },
        });
      },
      {
        errorNotification: t('pages.exportQuery.error'),
        successNotification: t('pages.exportQuery.success'),
      },
    );
  };
  return {
    sendExportQueryRequest,
  };
};

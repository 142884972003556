import { ResourceType } from 'business/common/services/types';
import { MapStatus } from './types';

// Source : https://www.mewburn.com/law-practice-library/pct-member-states

// List of country codes of countries in the EU at the date 1rst of June 2014
// Note : Brexit did not happen yet
const EP_COUNTRIES = (
  'AL,AT,BE,BG,CH,CY,CZ,DE,DK,EE,ES,FI,FR,GB,GR,HR,HU,IE,IS,' +
  'IT,LI,LT,LU,LV,MC,MK,MT,NL,NO,PL,PT,RO,RS,SE,SI,SK,SM,TR'
).split(',');

// List of country codes of countries in the EU
// EM is the countryCode for the European Union for designs and trademarks
const EM_COUNTRIES = (
  'BE,EL,LT,PT,BG,ES,LU,RO,CZ,FR,HU,SI,DK,HR,MT,SK,DE,IT,NL,' +
  'FI,EE,CY,AT,SE,IE,LV,PL'
).split(',');

// List of country codes of countries in the PCT (international) at the date 1rst of June 2014
const PCT_COUNTRIES = (
  'AE,AG,AL,AM,AO,AT,AU,AZ,BA,BB,BE,BF,BG,BH,BJ,BN,BR,BW,BY,BZ,' +
  'CA,CF,CG,CH,CI,CL,CM,CN,CO,CR,CU,CY,CZ,DE,DK,DM,DO,DZ,EC,EE,' +
  'EG,ES,FI,FR,GA,GB,GD,GE,GH,GM,GN,GQ,GR,GT,GW,HN,HR,HU,ID,IE,' +
  'IL,IN,IR,IS,JP,KE,KG,KM,KN,KP,KR,KZ,LA,LC,LI,LK,LR,LS,LT,' +
  'LU,LV,LY,MA,MC,MD,ME,MG,MK,ML,MN,MR,MT,MW,MX,MY,MZ,NA,NE,' +
  'NG,NI,NL,NO,NZ,OM,PA,PE,PG,PH,PL,PT,QA,RO,RS,RU,RW,SA,SC,SD,' +
  'SE,SG,SI,SK,SL,SM,SN,ST,SV,SY,SZ,TD,TG,TH,TJ,TM,TN,TR,TT,TZ,' +
  'UA,UG,US,UZ,VC,VN,ZA,ZM,ZW'
).split(',');

// List of country codes of countries in the EA (Eurasian Patent)
// at the date 1rst of June 2014
const EA_COUNTRIES = 'AM,AZ,BY,KG,KZ,RU,TJ,TM'.split(',');

// List of country codes of countries in the OAPI
// (African Intellectual Property Organisation) at the date 1rst of June 2014
const OAPI_COUNTRIES =
  'BF,BJ,CF,CG,CI,CM,GA,GN,GQ,GW,KM,ML,MR,NE,SN,TD,TG'.split(',');

// List of country codes of countries in the ARIPO
// (African Regional Industrial Property Organisation) at the date 1rst of June 2014
const ARIPO_COUNTRIES = 'GH,GM,KE,LR,LS,MW,MZ,SD,SL,ST,SZ,TZ,UG,ZM,ZW'.split(
  ',',
);

// List of country codes of French territories at the date 1rst of June 2014
// Wihtout French Polynesia, which has its countryCode and protections
const FR_COUNTRIES = 'FR,BL,GP,MF,MQ,NC,PM,RE,TF,WF'.split(',');

export const EXPANDED_COUNTRIES: Record<string, string[]> = {
  EP: EP_COUNTRIES,
  EM: EM_COUNTRIES,
  QT: PCT_COUNTRIES,
  EA: EA_COUNTRIES,
  OA: OAPI_COUNTRIES,
  AP: ARIPO_COUNTRIES,
  FR: FR_COUNTRIES,
};

const PATENT_STATUSES = [
  MapStatus.Valid,
  MapStatus.Published,
  MapStatus.Deposited,
  MapStatus.PlannedDeposit,
  MapStatus.NotEffective,
  MapStatus.Unprotected,
  MapStatus.MandatedOut,
];

const OTHER_ASSETS_STATUSES = [
  MapStatus.Valid,
  MapStatus.NotEffective,
  MapStatus.Unprotected,
];

export const RESOURCE_STATUSES: Record<ResourceType, MapStatus[]> = {
  patent: PATENT_STATUSES,
  design: OTHER_ASSETS_STATUSES,
  trademark: OTHER_ASSETS_STATUSES,
  domain: OTHER_ASSETS_STATUSES,
};

// List of country codes of countries not shown on the map
export const NOT_SHOWN_COUNTRIES = (
  'AS,AD,AI,AG,AW,BH,BB,BV,IO,CV,KY,CX,CC,KM,CK,DM,FO,PF,GI,GD,GP,' +
  'GU,GG,HM,VA,HK,IM,JE,KI,LI,MO,MV,MH,MQ,MU,YT,FM,MC,MS,NR,AN,NU,' +
  'NF,MP,PW,PN,RE,SH,KN,LC,PM,VC,VC,VC,WS,SM,ST,SC,SG,GS,SJ,TK,TO,' +
  'TC,TV,UM,VG,VI,WF'
).split(',');

import { useQuery } from '@apollo/client';

import { GetDocumentParam } from 'business/resources/shared/types';
import { documentQueryByResource } from './constant';

import { Document, DocumentFolder, DocumentRecord } from './types';

function groupByLabel(folders: DocumentFolder[]): DocumentRecord[] {
  const grouped: { [key: string]: DocumentRecord } = {};

  folders.forEach((folder) => {
    const key = folder.label;

    if (!grouped[key]) {
      grouped[key] = {
        id: folder.id,
        key: folder.id,
        label: key,
        children: [],
      };
    }

    const processDocuments = (
      documents: Document[],
      parent: DocumentRecord,
    ) => {
      documents.forEach((doc) => {
        const docRecord: DocumentRecord = {
          id: doc.id,
          key: doc.id,
          label: doc.title,
          extension: doc.extension,
          children: [],
        };

        parent.children?.push(docRecord);

        if (doc.childDocuments && doc.childDocuments.length > 0) {
          processDocuments(doc.childDocuments, docRecord);
        }
      });
    };

    processDocuments(folder.documents, grouped[key]);
  });

  return Object.values(grouped);
}
export const useGetDocument = ({ resource, variables }: GetDocumentParam) => {
  const document = documentQueryByResource[resource];
  const { data, ...rest } = useQuery<{
    folders: DocumentFolder[];
  }>(document, { variables });

  return { data: groupByLabel(data?.folders ?? []), ...rest };
};

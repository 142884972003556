import NotFoundPage from 'ui/notFoundPage';

interface CompanyNotFoundProps {
  canSeeCompany: boolean;
  resourceExists: boolean;
  children: React.ReactNode;
}

const CompanyNotFound = ({
  canSeeCompany,
  resourceExists,
  children,
}: CompanyNotFoundProps) => {
  return canSeeCompany && resourceExists ? <>{children}</> : <NotFoundPage />;
};

export default CompanyNotFound;

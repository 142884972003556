import OpsDocumentsStats from 'business/admin/components/opsDocumentStats';
import OpsDocumentsTable from 'business/admin/components/opsDocumentsTable';
import useGetPatentDocuments from 'business/admin/hooks/useGetPatentDocuments';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

const OpsDocumentsPage = () => {
  const { t } = useTranslation();
  const {
    queryForTable: { data, tableProps },
    opsDocumentsStats,
  } = useGetPatentDocuments();

  return (
    <ConnectedPage
      breadcrumbs={[{ label: t('pages.admin.patentOps.title') }]}
      translationKey="patentOps"
    >
      <Spacer direction="vertical" size="middle">
        <h1 className="headerTitle">{t('pages.admin.patentOps.title')}</h1>
        <OpsDocumentsStats {...opsDocumentsStats} />

        <OpsDocumentsTable {...tableProps} dataSource={data} />
      </Spacer>
    </ConnectedPage>
  );
};

export default OpsDocumentsPage;

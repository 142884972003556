import { useGetDesignImagesQuery, useGetDesignQuery } from 'generated/graphql';
import { getDesignTimeline } from './getDesignTimeline';
import { useTranslation } from 'react-i18next';

export const useGetDesign = (id: number) => {
  const { t } = useTranslation();
  const { data, ...designDataState } = useGetDesignQuery({
    variables: { id },
  });
  const { data: imageData } = useGetDesignImagesQuery({
    variables: { designId: id },
  });

  const design = data?.design_by_pk;

  return {
    design: design
      ? {
          ...design,
          images: imageData?.image ?? [],
        }
      : null,
    timeline: getDesignTimeline(t, design),
    designDataState,
  };
};

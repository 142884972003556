import { message } from 'antd';
import notifyPromise from 'business/common/services/notifyPromise';
import { useGetDocumentSignedUrlLazyQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const useOpenOfficialDocument = () => {
  const { t } = useTranslation();
  const [getDocumentLink] = useGetDocumentSignedUrlLazyQuery();

  const openOfficalDocument = async (id: string) => {
    return notifyPromise(
      t,
      async () => {
        const { data } = await getDocumentLink({
          variables: { documentId: id },
        });
        const url = data?.getSignedDownloadUrlForPatentDocument;

        if (!url) {
          message.warning(t('patent.officialDocuments.notifications.noUrl'));
          return;
        }
        window.open(url);
      },
      { successNotification: null, reportError: 'error' },
    );
  };

  return {
    openOfficalDocument,
  };
};

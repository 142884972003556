import { TabsFamily } from 'business/common/services/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { fieldsPerTabs } from 'business/advancedSearch/services/constants';
import getFilters from 'business/common/services/filters';
import useGetFiltersData from 'business/common/hooks/useGetFiltersData';

import { filtersStorageKey, storageKeys } from 'technical/storage/constants';
import { AdvancedFilters } from 'business/advancedSearch/services/types';
import { useSessionStorage } from 'technical/storage/useSessionStorage';
import { storageRessourceFilter } from 'technical/filters/parser/storageParser/advancedFiltersParser';

const useResourceAdvancedFilters = (family: TabsFamily) => {
  const { t } = useTranslation();

  const {
    storageValues: advancedFilters,
    setStorageValues,
    resetToPreviousValue,
  } = useSessionStorage<AdvancedFilters>({
    storageKey: filtersStorageKey,
    subKey: storageKeys.advancedFilters,
    filters: storageRessourceFilter(),
  });

  const filtersData = useGetFiltersData(t, family);

  const setAdvancedFilters = (filters: AdvancedFilters) => {
    setStorageValues(filters);
  };

  const { components } = useMemo(
    () => getFilters(t, family, filtersData, fieldsPerTabs),
    [family, filtersData],
  );
  return {
    advancedFilters,
    setAdvancedFilters,
    resetToPreviousValue,
    components,
  };
};

export default useResourceAdvancedFilters;

import { Form, Input } from 'antd';
import Routes from 'business/router/routes';
import authService from 'business/user/services/authService';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Card from 'ui/card';
import PasswordInput from 'ui/form/passwordInput';
import Spacer from 'ui/spacer';
import MfaModal from 'business/user/components/mfaModal';

interface Values {
  login: string;
  password: string;
  mfaToken: string | null;
}
const LoginCard = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<Values>();

  const [mfaTokenModalVisible, setMfaTokenModalVisible] = useState(false);

  const onSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const openMfaModal = await authService.signIn(
        values.login,
        values.password,
        values.mfaToken,
      );

      if (openMfaModal === 'mfa-required') {
        setMfaTokenModalVisible(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      form.setFields([
        {
          name: 'password',
          errors: [
            t('errors.signIn', {
              context: getAuthErrorContextFromAuthProviderError(error),
            }),
          ],
        },
      ]);
      setLoading(false);
    }
  };

  const onSubmitMfaToken = async (mfaToken: string) => {
    setMfaTokenModalVisible(false);
    const values = await form.validateFields();
    onSubmit({ ...values, mfaToken });
  };

  return (
    <>
      <Card fixedWith>
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Spacer direction="vertical" align="center">
            <AppLogo />
            <Spacer direction="vertical" size="small">
              <div data-test-id="auth-inputs">
                <Form.Item label={t('notConnected.login.email')} name="login">
                  <Input
                    autoComplete="username"
                    data-test-id="login-input"
                    id="login"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label={t('notConnected.login.password')}
                >
                  <PasswordInput
                    autoComplete="current-password"
                    id="password"
                    data-test-id="password-input"
                    passwordHint={
                      <Link color="tundora" to={Routes.ForgotPassword}>
                        {t('pages.forgotPassword.title', {
                          context: 'question',
                        })}
                      </Link>
                    }
                  />
                </Form.Item>
              </div>
            </Spacer>

            <Button
              id="submit-button"
              data-test-id="submit-button"
              type="primary"
              htmlType="submit"
              block
              loading={loading}
            >
              {t('notConnected.login.submit')}
            </Button>
          </Spacer>
        </Form>
      </Card>
      <MfaModal
        open={mfaTokenModalVisible}
        onOk={onSubmitMfaToken}
        onCancel={() => {
          setMfaTokenModalVisible(false);
          setLoading(false);
        }}
        email={form.getFieldValue('login')}
      />
    </>
  );
};

export default LoginCard;

export const imageStyle: React.CSSProperties = {
  width: '200px',
  height: '80px',
  objectFit: 'contain',
};

export const overrideNoResultStyle: React.CSSProperties = {
  border: '1px solid #00A9B4',
  padding: '5px',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
};

import { CloudDownloadOutlined } from '@ant-design/icons';
import useDownloadDocument from 'business/resources/shared/services/documents/useDownloadDocuments';
import { Resource } from 'business/resources/shared/types';

const DownloadDocumentAction = ({
  documentId,
  resource,
}: {
  documentId: number;
  resource: Resource;
}) => {
  const { getAndDownloadDocument } = useDownloadDocument();
  return (
    <CloudDownloadOutlined
      onClick={() => getAndDownloadDocument({ resource, id: documentId })}
    />
  );
};

export default DownloadDocumentAction;

import { Statistic } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'technical/fileManagement';
import Card from 'ui/card';
import ErrorOverlay from 'ui/errorOverlay';
import Flex from 'ui/flex';

interface OpsDocumentsStatsProps {
  docCount: number;
  /**
   * Size in bytes
   */
  totalSize: number;
  loading?: boolean;
  error?: Error;
}

const OpsDocumentsStats: React.FC<OpsDocumentsStatsProps> = ({
  docCount,
  totalSize,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <ErrorOverlay error={error}>
        <Flex>
          <Statistic
            title={t('pages.admin.patentOps.stats.count')}
            value={docCount}
            loading={loading}
            className="flex1"
          />
          <Statistic
            title={t('pages.admin.patentOps.stats.size')}
            value={formatBytes(totalSize)}
            loading={loading}
            className="flex1"
          />
        </Flex>
      </ErrorOverlay>
    </Card>
  );
};

export default OpsDocumentsStats;

import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import { useGetActiveArchivePackageQuery } from 'generated/graphql';

import ArchiveConfigurationTable from 'business/archive/components/archiveConfigurationTable';
import IsLoading from 'ui/isLoading';
import { useSelectArchiveSubscription } from 'business/configuration/companies/services/useSelectArchiveSubscription';
import { AllTableFilter } from 'technical/filters/types';
import { Archive } from 'business/archive/services/types';

interface CompanyArchiveSubscription {
  companyId: number;
}

const CompanyArchiveSubSelection = ({
  companyId,
}: CompanyArchiveSubscription) => {
  const { t } = useTranslation();
  const { data, loading } = useGetActiveArchivePackageQuery();
  const { rowSelection, openModalSubmitSubscription, subscription } =
    useSelectArchiveSubscription({ companyId });

  return (
    <div data-test-id="companyArchiveSubscription">
      <IsLoading loading={loading}>
        <ArchiveConfigurationTable
          filtersValues={{} as AllTableFilter<Archive>}
          setFilterValue={() => {}}
          editButtonEnabled={false}
          dataSource={data?.archive_archivePackage ?? []}
          rowSelection={rowSelection}
          resetButtonEnabled={false}
          searchBarEnabled={false}
        />
        <Button
          disabled={!subscription}
          style={{ marginTop: '20px' }}
          onClick={openModalSubmitSubscription}
          type="primary"
        >
          {t('archive.config.button', {
            context: 'subscriptionTable',
          })}
        </Button>
      </IsLoading>
    </div>
  );
};

export default CompanyArchiveSubSelection;

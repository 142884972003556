import { useTranslation } from 'react-i18next';
import { Records } from 'ui/simpleTable';
import ZonesTable from '.';

interface PrivateZonesTableProps<T> {
  zones: T[];
}

const PrivateZonesTable = <T extends Records>({
  zones,
}: PrivateZonesTableProps<T>) => {
  const { t } = useTranslation();

  return (
    <ZonesTable
      dataSource={zones}
      columnsTitleContext="pages.privateDomain.dnsData.records"
      subTitle={t('pages.privateDomain.dnsData.records.title')}
    />
  );
};

export default PrivateZonesTable;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload';

import TermCheckbox from 'business/user/components/termCheckbox';
import { useTranslation } from 'react-i18next';

import logger from 'technical/logger';
import ArchiveInfoForm from 'business/archive/components/archiveInfoForm';
import { ArchiveInfoFormType } from 'business/archive/components/archiveInfoForm/types';
import UploadDragger from './uploadDragger';
import { CompanyArchiveUpload } from './types';
import SelectCompanies from './selectCompanies';

export type UploadForm = ArchiveInfoFormType & {
  files: UploadFile[];
  holderId: number;
};

interface ArchiveUploadFormModalProps {
  onCancel: () => void;
  isOpened: boolean;
  isUploading: boolean;
  onUpload: (archiveInfo: UploadForm, resetForm: () => void) => Promise<void>;
  companiesOptions: CompanyArchiveUpload[];
  tosPath?: string | null;
  selectedCompany?: CompanyArchiveUpload;
  setSelectedCompany: (company: CompanyArchiveUpload | undefined) => void;
}

const ArchiveUploadFormModal = ({
  isOpened,
  onCancel,
  onUpload,
  companiesOptions,
  isUploading,
  tosPath,
  setSelectedCompany,
  selectedCompany,
}: ArchiveUploadFormModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<UploadForm>();

  const resetForm = () => {
    form.resetFields();
    setSelectedCompany(undefined);
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((archiveInfo) => onUpload(archiveInfo, resetForm))
      .catch((err) => logger.error(err));
  };

  return (
    <Modal
      title={t('pages.archive.addArchive')}
      open={isOpened}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={resetForm}>
          {t('common.erase')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isUploading}
          disabled={!selectedCompany?.hasActiveArchiveSubscription}
          onClick={onConfirm}
        >
          {t('common.confirm')}
        </Button>,
      ]}
      closeIcon={<CloseCircleOutlined />}
    >
      <Form<UploadForm> layout="vertical" form={form}>
        <UploadDragger />

        <SelectCompanies
          companiesOptions={companiesOptions}
          onSelect={setSelectedCompany}
        />

        {selectedCompany && !selectedCompany.hasActiveArchiveSubscription && (
          <span style={{ color: 'red' }}>
            {t('pages.archive.companyNotAllowedViewingPage')}
          </span>
        )}

        <ArchiveInfoForm companyId={selectedCompany?.code} />

        {tosPath && <TermCheckbox termPath={tosPath} type="tos" />}
      </Form>
    </Modal>
  );
};

export default ArchiveUploadFormModal;

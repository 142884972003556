import useGetAllKeywords from 'business/configuration/keywords/services/useGetAllKeywords';
import { useTranslation } from 'react-i18next';
import Select from 'ui/select';

interface AllKeywordsSelectProps {
  value?: string;
  onClear: () => void;
  onSelect: (keyword: string) => void;
}
const AllKeywordsSelect = ({
  value,
  onClear,
  onSelect,
}: AllKeywordsSelectProps) => {
  const { t } = useTranslation();

  const { allKeywords } = useGetAllKeywords();

  return (
    <Select<string>
      options={allKeywords.map((keyword) => ({
        code: keyword,
        label: keyword,
      }))}
      placeholder={t('table.common.filters.allTypes')}
      allowClear
      onClear={onClear}
      value={value}
      onSelect={onSelect}
      style={{ width: 150 }}
    />
  );
};
export default AllKeywordsSelect;

import {
  GetDefaultDomainFeeQuery,
  useGetDefaultDomainFeeQuery,
  useUpdateDomainFeeByIdMutation,
} from 'generated/graphql';
import { map } from 'technical/format';

const moneyFields = [
  'registerFee',
  'renewFee',
  'transferFee',
  'corporateCost',
] as const;

type MoneyFields = typeof moneyFields;

export type DomainFeeData = Pick<
  GetDefaultDomainFeeQuery['domain_domainFee'][number],
  MoneyFields[number]
>;

const useDefaultDomainFee = () => {
  const { data, ...rest } = useGetDefaultDomainFeeQuery();
  const [updateDefaultDomainFee] = useUpdateDomainFeeByIdMutation();

  const defaultDomainFee = data?.domain_domainFee?.[0];

  return {
    defaultDomainFeeData: defaultDomainFee
      ? {
          id: defaultDomainFee.id,
          ...map<MoneyFields, number>(
            defaultDomainFee,
            moneyFields,
            (val) => val / 100,
          ),
        }
      : undefined,
    updateDefaultDomainFee: (id: number, value: DomainFeeData) =>
      updateDefaultDomainFee({
        variables: {
          id,
          value: map<MoneyFields, number>(
            value,
            moneyFields,
            (val) => val * 100,
          ),
        },
      }),
    ...rest,
  };
};

export default useDefaultDomainFee;

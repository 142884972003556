import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import PatentFamiliesTable from 'business/resources/patent/components/patentFamiliesTable';
import { useGetPatentFamilies } from 'business/resources/patent/services';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

/**
 * The goal of this component is :
 * - Display the header
 * - Fetch the data
 * - Give the data to the table component
 */
const PatentFamiliesPage: React.FC = () => {
  const { data, tableProps } = useGetPatentFamilies();
  const { onExport, loading } = useDownloadTableToXlsx();

  return (
    <ConnectedPage translationKey="patents">
      <PatentFamiliesTable
        data-test-id="patentFamiliesTable"
        dataSource={data}
        loadingExport={loading}
        onExport={(columns) => {
          onExport(columns, 'patentFamily');
        }}
        name="patentFamily"
        {...tableProps}
      />
    </ConnectedPage>
  );
};

export default PatentFamiliesPage;

import {
  GetTrademarkFamilySearchQuery,
  GetTrademarkFamilySearchQueryVariables,
  useGetTrademarkFamilyBreadcrumbQuery,
  useGetTrademarkFamilyMapQuery,
  useGetTrademarkFamilySearchQuery,
} from 'generated/graphql';
import { TrademarkFamily } from './types';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { StorageKey, storageKeys } from 'technical/storage/constants';
import { resourcesToMapData } from 'business/common/services';
import { useGetTrademarkImages } from './useGetTrademarkImages';
import { isDefined } from 'technical/validation';

export const useGetTrademarkFamilyData = ({
  filterValues,
  overrideOrderBy,
  storageKey,
  search,
}: {
  filterValues: GetTrademarkFamilySearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<TrademarkFamily>;
  storageKey?: StorageKey;
  search?: string;
}) =>
  useQueryForTable<
    TrademarkFamily,
    GetTrademarkFamilySearchQuery,
    GetTrademarkFamilySearchQueryVariables
  >({
    subStorageKey: storageKey ?? storageKeys.trademarkFamilies,
    overrideOrderBy,
    commonFiltersKeys: ['search'],
    useQuery: ({ limit, offset, searchText, orderBy, isOwn }) =>
      useGetTrademarkFamilySearchQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...filterValues,
            search: search ?? searchText,
            isOwn,
          },
        },
      }),
    getTotal: (res) => res?.trademarkSearch?.count ?? 0,
    map: (res) => res?.trademarkSearch?.resources ?? [],
  });

export const useGetTrademarkFamily = (params: {
  filterValues: GetTrademarkFamilySearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<TrademarkFamily>;
  storageKey?: StorageKey;
  search?: string;
}) => {
  const trademarkData = useGetTrademarkFamilyData(params);
  const { imageList, loading } = useGetTrademarkImages(
    trademarkData.data.map((d) => d.id).filter(isDefined),
  );

  const trademarkWithImages = trademarkData.data.map((d) => {
    const image = imageList.filter((i) => i.id === d.id);
    return {
      ...d,
      image: loading ? 'loading' : image[0]?.image,
    };
  });

  return {
    ...trademarkData,
    data: trademarkWithImages,
  };
};

export const useGetTrademarkFamilyMap = (familyId: string) => {
  const { data, loading } = useGetTrademarkFamilyMapQuery({
    variables: { id: familyId },
  });

  return { dataMap: resourcesToMapData(data?.trademark), loading };
};

export const useGetTrademarkFamilyInfos = (familyId: string) => {
  const { data } = useGetTrademarkFamilyBreadcrumbQuery({
    variables: { familyId: familyId ?? '' },
    skip: !familyId,
  });
  return data?.family?.[0];
};

import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';
import {
  getAlissiaResourceUrl,
  getBdlIprResourceUrl,
} from 'business/resources/shared/services/constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isDefined } from 'technical/validation';

interface ExternalLinksProps {
  resource: { id: number; alissiaId?: number | null };
}

const ExternalLinks: React.VFC<ExternalLinksProps> = ({
  resource: { id, alissiaId },
}) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();

  if (!(isBdlAdmin(user) || isBdlUser(user))) {
    return null;
  }

  return (
    <>
      <Link
        to={{ pathname: getBdlIprResourceUrl(id) }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button icon={<LinkOutlined />} type="primary">
          {t('table.common.column.iprLink')}
        </Button>
      </Link>
      {isDefined(alissiaId) && (
        <Link
          to={{ pathname: getAlissiaResourceUrl(alissiaId) }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon={<LinkOutlined />} type="primary">
            {t('table.common.column.alissiaLink')}
          </Button>
        </Link>
      )}
    </>
  );
};

export default ExternalLinks;

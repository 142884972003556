import { TFunction } from 'i18next';
import { formatDate } from 'technical/date';
import { DATE_FORMAT } from 'technical/date/constant';
import { MailProps } from 'technical/mailto';
import { Resource, ResourceType } from './types';
import { getCountryLabel } from 'technical/countriesTranslation';

export const createMailProps = (
  resource: Resource,
  resourceType: ResourceType,
  t: TFunction,
): MailProps => {
  const { depositDate, priorities, id } = resource;
  const resourceTitle = resource.title || null;
  const depositDateStr = depositDate
    ? formatDate(new Date(depositDate), DATE_FORMAT.dayMonthYearWithSpace)
    : null;
  const to = resource?.bdlOfficer?.email ?? '';
  const cc = resource?.bdlSecretary?.email ?? '';

  const mailObjectData = {
    subject: `[BDL Access] ${t(`table.common.mailTitle.${resourceType}`)}`,
    resourceTitle: resource.title || null,
    country: getCountryLabel(resource.country),
    bdlRefStr: resource.bdlRef
      ? `${t(`table.common.column.bdlRef`)} : ${resource.bdlRef}`
      : null,
    clientRefStr: resource.clientRef
      ? `${t(`table.common.column.clientRef`)} : ${resource.clientRef}`
      : null,
  };

  const mailData = {
    titleStr: resourceTitle
      ? `${t(`table.common.mailTitle.${resourceType}`)}: ${resource.title}`
      : null,
    bdlRefStr: resource.bdlRef
      ? `${t(`table.common.column.bdlRef`)} : ${resource.bdlRef}`
      : null,
    clientRefStr: resource.clientRef
      ? `${t(`table.common.column.clientRef`)} : ${resource.clientRef}`
      : null,
    holderStr: resource.holder
      ? `${t(`table.common.column.holder`)} : ${resource.holder}`
      : null,
    depositStr: depositDateStr
      ? `${t('table.common.column.deposit')}: ${depositDateStr} ${
          resource.depositNumber ? `| N° ${resource.depositNumber}` : ''
        }`
      : null,
    priorityStr: priorities?.[0]
      ? `${t('table.common.column.priority')}: ${
          priorities[0].priorityDate
            ? formatDate(new Date(priorities[0].priorityDate))
            : ''
        } | ${priorities[0].priorityNumber}`
      : '',
    countryStr: mailObjectData.country
      ? `${t(`table.common.column.countries`, {
          context: resourceType === 'domain' ? 'ndd' : '',
        })}: ${mailObjectData.country}`
      : null,
    link: `${t('table.common.column.link_page')}: https://${
      window.location.hostname
    }/${resourceType}/${id}`,
  };

  return {
    to,
    cc,
    subject: Object.values(mailObjectData).filter(Boolean).join(' | '),
    body: Object.values(mailData).filter(Boolean).join('\n'),
  };
};

import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

interface InvoiceTableActionsProps {
  onClickView?: () => void;
  onClickMail?: () => void;
  emails?: string;
}

const InvoiceTableActions: React.VFC<InvoiceTableActionsProps> = ({
  onClickMail,
  onClickView,
  emails,
}) => {
  const { t } = useTranslation();

  return (
    <Spacer size="xxsmall">
      {onClickView ? (
        <Tooltip
          placement="topRight"
          title={t('pages.admin.invoice.table.viewActionTooltip')}
        >
          <Button
            type="primary"
            size="middle"
            icon={<EyeOutlined />}
            onClick={onClickView}
          />
        </Tooltip>
      ) : null}
      {onClickMail ? (
        <Tooltip
          placement="topRight"
          title={t('pages.admin.invoice.table.sendMailActionTooltip', {
            emails,
          })}
        >
          <Button
            type="primary"
            size="middle"
            icon={<MailOutlined />}
            onClick={onClickMail}
          />
        </Tooltip>
      ) : null}
    </Spacer>
  );
};

export default InvoiceTableActions;

import { QueryHookOptions } from '@apollo/client';
import {
  GetUserArchivesDocumentQuery,
  GetUserArchivesDocumentQueryVariables,
  Order_By,
  useGetUserArchivesDocumentQuery,
} from 'generated/graphql';

import useQueryForTable from 'technical/hooks/useQueryForTable';
import { UserArchivesDocuments } from 'business/archive/services/types';
import { storageKeys } from 'technical/storage/constants';

export const useGetUserArchivesDocument = (
  baseOptions?: QueryHookOptions<
    GetUserArchivesDocumentQuery,
    GetUserArchivesDocumentQueryVariables
  >,
) =>
  useQueryForTable<
    UserArchivesDocuments,
    GetUserArchivesDocumentQuery,
    GetUserArchivesDocumentQueryVariables
  >({
    subStorageKey: storageKeys.archiveDocuments,
    commonFiltersKeys: ['type', 'from', 'to', 'myArchives'],
    useQuery: ({
      limit,
      offset,
      orderBy,
      type,
      from,
      to,
      myArchives,
      searchText,
    }) =>
      useGetUserArchivesDocumentQuery({
        variables: {
          limit,
          offset,
          type: type ?? null,
          from: from?.toISOString(),
          to: to?.toISOString(),
          myArchives,
          orderBy: Object.keys(orderBy).length
            ? orderBy
            : { createdAt: Order_By.Desc },
          search: searchText,
        },
        fetchPolicy: 'cache-and-network',
        ...baseOptions,
      }),
    map: (res) => res?.getUserArchives.archives ?? [],
    getTotal: (res) => res?.getUserArchives.total ?? 0,
  });

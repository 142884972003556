import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { CompanyGroup } from 'business/groups/services/types';
import Routes from 'business/router/routes';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

type GroupListTableProps = Omit<TableProps<CompanyGroup>, 'defaultColumns'>;

const defaultColumns = (
  t: TFunction,
  redirectToEditPage: (group: CompanyGroup) => void,
): ColumnGenerationData<CompanyGroup> => [
  {
    key: 'name',
    context: 'group',
  },
  {
    key: 'nbUsers',
  },
  {
    key: 'isAdmin',
    render: (_val: string, group: CompanyGroup) => (
      <span>
        {t('common.response', {
          context: group.isAdmin ? 'yes' : 'no',
        })}
      </span>
    ),
  },
  {
    key: 'id',
    title: t('table.common.column.actions'),
    fixed: 'right',
    sorter: false,
    alwaysVisible: true,
    render: (_val: string, group: CompanyGroup) => {
      return (
        <Spacer size="xsmall">
          {group.canEdit && (
            <Tooltip
              title={t('pages.admin.bdlUsers.table.actions.tooltips.editUser')}
            >
              <Button
                type="text"
                size="middle"
                onClick={() => {
                  redirectToEditPage(group);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}
        </Spacer>
      );
    },
  },
];
const GroupListTable: React.FC<GroupListTableProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToEditPage = (group: CompanyGroup) => {
    history.push(
      generatePath(Routes.GroupUpdate, {
        id: group.companyId,
        groupId: group.id,
      }),
    );
  };
  return (
    <Table
      id="groupListTable"
      {...props}
      defaultColumns={defaultColumns(t, redirectToEditPage)}
      header={<h1 className="headerTitle">{t('table.common.details')}</h1>}
    />
  );
};

export default GroupListTable;

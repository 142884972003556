import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Map from 'ui/map';
import Spacer from 'ui/spacer';
import { resourceExists } from 'business/common/services';
import { patentsFamilyColumns } from 'business/resources/patent/services/constant/columns';
import Table from 'ui/table';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import HeaderTitles from 'ui/headerTitles';
import {
  useGetPatentFamily,
  useGetPatentFamilyInfos,
  useGetPatentFamilyMap,
  usePatentBreadcrumbs,
} from 'business/resources/patent/services';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';

const PatentFamilyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: familyId } = useParams<{ id: string }>();
  const { onExport, loading: onExportLoading } = useDownloadTableToXlsx();
  const { data, tableProps } = useGetPatentFamily({
    filterValues: {
      familyId,
    },
  });
  const { dataMap, loading } = useGetPatentFamilyMap(familyId);

  const { getFamilyBreadcrumbs } = usePatentBreadcrumbs();
  const patentFamily = useGetPatentFamilyInfos(familyId);

  return (
    <ResourceNotFound resourceExists={resourceExists(patentFamily, tableProps)}>
      <ConnectedPage
        breadcrumbs={getFamilyBreadcrumbs(patentFamily)}
        translationKey="patentFamily"
      >
        <HeaderTitles
          title={patentFamily?.title ?? ''}
          subtitle={t('pages.header.patentFamily')}
        />
        <Spacer direction="vertical" size="large">
          <Table
            data-test-id="patentFamiliesTable"
            dataSource={data}
            loadingExport={onExportLoading}
            onExport={(columns) => {
              onExport(columns, 'patent', false, { familyId });
            }}
            defaultColumns={patentsFamilyColumns}
            {...tableProps}
          />
          <Map resourceType="patent" isDataLoading={loading} data={dataMap} />
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default PatentFamilyPage;

export const MIME_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/json',
  'application/zip',
  '.step',
  'image/tiff',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const FILE_FORMATS = [
  'jpg',
  'jpeg',
  'png',
  'pdf',
  'json',
  'zip',
  'step',
  'tiff',
  'xlsx',
] as const;

export type FileFormatType = (typeof FILE_FORMATS)[number];
export type MimeType = (typeof MIME_TYPES)[number];

export const MIME_TYPES_BY_FILE_FORMAT: { [key: string]: MimeType } = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
  json: 'application/json',
  zip: 'application/zip',
  step: '.step',
  tiff: 'image/tiff',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const getFormatFile = (mimetype: string) => {
  return Object.keys(MIME_TYPES_BY_FILE_FORMAT).find(
    (key) => MIME_TYPES_BY_FILE_FORMAT[key] === mimetype,
  );
};

export const isPdf = (mimetype: string) => {
  return getFormatFile(mimetype) === 'pdf';
};

import { StopOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';

import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

import { CompanyArchiveUpload } from './types';

const { Option } = Select;

interface SelectCompaniesProps {
  companiesOptions: CompanyArchiveUpload[];
  onSelect: (company: CompanyArchiveUpload | undefined) => void;
}

const SelectCompanies = ({
  companiesOptions,
  onSelect,
}: SelectCompaniesProps) => {
  const { t } = useTranslation();

  const handleChange = (value: number) => {
    onSelect(companiesOptions.find((company) => company.code === value));
  };

  return (
    <Form.Item
      name="holderId"
      label={t('pages.archive.form.holderLabel')}
      rules={[{ required: true, message: t('errors.required') }]}
    >
      <Select
        onChange={(value) => handleChange(value)}
        optionLabelProp="children"
      >
        {companiesOptions.map(
          ({ label, code, hasActiveArchiveSubscription }) => {
            return (
              <Option key={code} value={code}>
                <Spacer justify="space-between" align="center">
                  {label}
                  {!hasActiveArchiveSubscription && (
                    <StopOutlined style={{ color: 'red' }} />
                  )}
                </Spacer>
              </Option>
            );
          },
        )}
      </Select>
    </Form.Item>
  );
};

export default SelectCompanies;

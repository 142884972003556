import SimpleLink from 'ui/link/SimpleLink';
import Spacer from 'ui/spacer';

interface WwwLinksProps {
  domain: string;
}

const WWWLinks = ({ domain }: WwwLinksProps) => {
  const link1 = `https://${domain}`;
  const link2 = `https://www.${domain}`;

  return (
    <Spacer size="xxsmall" direction="vertical">
      <SimpleLink
        link={link1}
        label={link1}
        displayIcon={false}
        className="whiteSpaceNoBreak"
      />
      <SimpleLink
        link={link2}
        label={link2}
        displayIcon={false}
        className="whiteSpaceNoBreak"
      />
    </Spacer>
  );
};
export default WWWLinks;

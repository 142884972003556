import notifyPromise from 'business/common/services/notifyPromise';
import { useUpdateBdlUserMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { BdlUserParams } from 'business/configuration/accessManagement/services/types';

export const useUpdateBdlUser = () => {
  const [updateBdlUserMutation] = useUpdateBdlUserMutation();
  const { t } = useTranslation();

  const updateBdlUser = async (user: BdlUserParams) =>
    notifyPromise(
      t,
      async () => {
        await updateBdlUserMutation({
          variables: {
            user,
          },
        });
      },
      {
        successNotification: t('user.infoModal.notifications.success', {
          context: 'edit',
        }),
        errorNotification: t('user.infoModal.notifications.error'),
      },
    );

  return {
    updateBdlUser,
  };
};

import {
  CountriesColumn,
  CountryColumn,
} from 'technical/countriesTranslation/types';
import { i18n } from 'translations';

export const getCountryKey = () =>
  i18n.language === 'en' ? 'countryEn' : 'countryFr';
export const getCountriesKey = () =>
  i18n.language === 'en' ? 'countriesEn' : 'countriesFr';

export const getCountryColumn = (): CountryColumn => ({
  key: getCountryKey(),
  translationKey: 'country',
});

export const getCountriesColumn = (): CountriesColumn => ({
  key: getCountriesKey(),
  translationKey: 'countries',
});

export const invalidCountries = ['PCT', 'EUROPE'];
export const countriesKeys = ['countriesEn', 'countriesFr'];

import { useGetPatentEpoDocumentsQuery } from 'generated/graphql';

export const useGetPatentEpoDocument = ({
  id,
  publicationDate,
}: {
  id: number;
  publicationDate: string | null | undefined;
}) => {
  const { data, ...patentDocumentDataState } = useGetPatentEpoDocumentsQuery({
    skip: publicationDate ? false : true, // publicationDate is mandatory to have data
    variables: { id },
  });

  return {
    patentDocuments: data?.patent_by_pk?.documents ?? [],
    loading: patentDocumentDataState?.loading,
  };
};

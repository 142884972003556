import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import { TableProps } from 'ui/table/types';
import { User } from 'business/configuration/accessManagement/services/types';
import TagResults from 'ui/tag/tagResults';
import defaultColumns from './columns';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
} from '@ant-design/icons';
import useTableActions from 'business/configuration/accessManagement/components/tables/services/useGetUserTableActions';
import { ITableAction } from 'business/configuration/accessManagement/components/tables/services/types';

type UsersTableProps = Omit<TableProps<User>, 'defaultColumns'> & {
  editUser: (user: User) => void;
};

const UsersTable: React.FC<UsersTableProps> = (tableProps) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { pagination } = tableProps;
  const {
    confirmDeleteUser,
    sendBackRegistration,
    updateUserStatus,
    isMyself,
    isUserActivePending,
  } = useTableActions({ refetch: tableProps.refetch, user, currentUser: user });

  const actions: ITableAction<User>[] = [
    {
      getTooltipTitle: (_userData: User) => t('common.edit'),
      getIcon: (_userData: User) => <EditOutlined />,
      dataTestId: 'editUserButton',
      onClick: (userData: User) => {
        tableProps.editUser(userData);
      },
    },
    {
      getTooltipTitle: (userData: User) =>
        t('pages.admin.bdlUsers.table.actions.tooltips.set', {
          context: userData?.isActive ? 'inactive' : null,
        }),
      getIcon: (userData: User) =>
        userData?.isActive ? <CloseOutlined /> : <CheckOutlined />,
      dataTestId: 'editStatusButton',
      isDisabled: () => isMyself,
      onClick: (userData: User) => {
        updateUserStatus(userData.id, userData.isActive);
      },
    },
    {
      getTooltipTitle: (_userData: User) => t('common.delete'),
      getIcon: (_userData: User) => <DeleteOutlined />,
      dataTestId: 'deleteUserButton',
      onClick: (userData: User) => {
        confirmDeleteUser(userData.id, userData.fullName);
      },
    },
    {
      getTooltipTitle: (_userData: User) =>
        t('pages.admin.bdlUsers.table.actions.tooltips.sendEmail'),
      getIcon: (_userData: User) => <MailOutlined />,
      dataTestId: 'sendBackEmailButton',
      hide: isUserActivePending,
      onClick: (userData: User) => {
        sendBackRegistration(userData.id, userData.email);
      },
    },
  ];

  return (
    <Table
      id="usersTable"
      {...tableProps}
      defaultColumns={defaultColumns(t, user, actions)}
      header={
        <TagResults dataTestId="usersCount" total={pagination?.total ?? 0} />
      }
    />
  );
};

export default UsersTable;

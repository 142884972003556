import { useEffect, useState } from 'react';
import { Filter } from 'technical/filters/types';
import history from 'technical/history';

export const useUrl = <T>(urlFilters: Filter<T>) => {
  const getUrlFiltersValues = () => {
    const uri = new URLSearchParams(decodeURI(history.location.search));
    return Object.keys(urlFilters).reduce((res, key) => {
      return {
        ...res,
        [key]: urlFilters[key as keyof T].readParse(uri.get(key)),
      };
    }, {} as T);
  };

  const [urlValues, setUrlFiltersValues] = useState<T>(getUrlFiltersValues());

  const writeUrlFiltersValues = () => {
    const uri = new URLSearchParams(decodeURI(history.location.search));
    Object.keys(urlFilters).forEach((key) => {
      const value = urlFilters[key as keyof T].writeParse(
        urlValues[key as keyof T],
      );
      if (value !== undefined) {
        uri.set(key, value);
      } else {
        uri.delete(key);
      }
    });

    const newSearch = encodeURI(uri.toString());

    // history.location.search add a "?" as first character
    if (history.location.search.substring(1) !== newSearch) {
      history.replace({ search: newSearch });
    }
  };

  useEffect(() => {
    writeUrlFiltersValues();
  }, [urlValues]);

  const setUrlFilterValue = <Key extends keyof T>(
    filter: Key,
    value: T[Key],
  ) => {
    setUrlFiltersValues({ ...urlValues, [filter]: value });
  };

  return {
    urlValues,
    setUrlFilterValue,
    setUrlFiltersValues,
  };
};

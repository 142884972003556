import { Tooltip } from 'antd';
import { ResourceType } from 'business/common/services/types';
import { useTranslation } from 'react-i18next';
import { NotShowCountry } from 'ui/map/types';
import Spacer from 'ui/spacer';
import { mapStyles } from 'ui/map/styles';
import styles from './index.module.scss';

interface NotShownCountriesProps {
  resourceType: ResourceType;
  data: NotShowCountry[];
}

const NotShownCountries = ({ data, resourceType }: NotShownCountriesProps) => {
  const { t } = useTranslation();

  if (!data.length) {
    return null;
  }

  return (
    <Spacer direction="vertical" size="small">
      <h3>{t('map.notShownCountries', { context: resourceType })}</h3>
      <div className={styles.notShownCountryWrapper}>
        {data.map(({ code, status, tooltip }) => (
          <Tooltip key={code} title={tooltip}>
            <div
              className={styles.notShownCountry}
              style={{ backgroundColor: mapStyles[status].default.fill }}
            >
              {code}
            </div>
          </Tooltip>
        ))}
      </div>
    </Spacer>
  );
};

export default NotShownCountries;

import { useGetOptOutQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const useGetOptOut = (patentNumber: string) => {
  const { t } = useTranslation();
  const { data, loading, error } = useGetOptOutQuery({
    variables: {
      patentNumber,
    },
  });

  return {
    loading,
    optOut: error
      ? t('errors.upcServerError')
      : t('common.response', {
          context: data?.getOptOut ? 'yes' : 'no',
        }),
  };
};

import {
  GetTrademarkEventSearchQuery,
  GetTrademarkEventSearchQueryVariables,
  useGetTrademarkEventSearchQuery,
} from 'generated/graphql';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { TrademarkEvent } from 'business/resources/events/services/types';
import { storageKeys } from 'technical/storage/constants';

interface UseGetTrademarkEventParams {
  filterValues: GetTrademarkEventSearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<TrademarkEvent>;
  search?: string;
}

export const useGetTrademarkEvent = ({
  filterValues,
  overrideOrderBy,
  search,
}: UseGetTrademarkEventParams) =>
  useQueryForTable<
    TrademarkEvent,
    GetTrademarkEventSearchQuery,
    GetTrademarkEventSearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKeys.trademarkEvents,
    useQuery: ({
      limit,
      offset,
      orderBy,
      displayAFU,
      displayAnnuities,
      isOwn,
    }) =>
      useGetTrademarkEventSearchQuery({
        variables: {
          where: {
            ...filterValues,
            search,
            displayAFU,
            displayAnnuities,
            isOwn,
          },
          limit,
          offset,
          orderBy,
        },
      }),
    getTotal: (res) => res?.trademarkEventSearch?.count ?? 0,
    map: (res) => res?.trademarkEventSearch?.resources ?? [],
  });

import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  TableProps as AntdTableProps,
  Button,
  Checkbox,
  Popover,
  Row,
} from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

interface ColumnsEditionPopOverProps<RecordType>
  extends Omit<AntdTableProps<RecordType>, 'columns' | 'pagination'> {
  setColumnsVisibility: (visibledColumns: CheckboxValueType[]) => void;
  resetTable?: () => void;
  resetButtonEnabled?: boolean;
  columnsEditionItems: {
    label: string;
    value: boolean;
    key: string;
  }[];
}

const ColumnsEditionPopOver = <RecordType extends object>({
  setColumnsVisibility,
  columnsEditionItems,
  resetTable,
  resetButtonEnabled = true,
}: ColumnsEditionPopOverProps<RecordType>) => {
  const { t } = useTranslation();

  const visibleColumns = columnsEditionItems
    .filter((col) => col.value)
    .map((col) => col.key);

  const constructCheckboxes = (
    <Spacer direction="vertical" size="xsmall">
      {resetButtonEnabled && (
        <Button
          type="link"
          onClick={resetTable}
          icon={<ReloadOutlined />}
          className="marginBottom5"
        >
          <span>{t('table.common.resetTable')}</span>
        </Button>
      )}

      <Spacer direction="vertical" size="xsmall">
        {columnsEditionItems.map(({ key, label, value }) => {
          return (
            <Row key={label}>
              <Checkbox
                checked={value}
                data-test-id={`columnsVisibilityButton-${key}`}
                onChange={(e) => {
                  if (e.target.checked) {
                    setColumnsVisibility([...visibleColumns, key]);
                  } else {
                    setColumnsVisibility(
                      visibleColumns.filter((col) => col !== key),
                    );
                  }
                }}
              >
                {label}
              </Checkbox>
            </Row>
          );
        })}
      </Spacer>
    </Spacer>
  );

  return (
    <Popover content={constructCheckboxes} trigger="click" placement="bottom">
      <Button
        icon={<EditOutlined />}
        data-test-id="columnsVisibilityButton"
        type="primary"
      >
        {t('table.common.editColumns')}
      </Button>
    </Popover>
  );
};

export default ColumnsEditionPopOver;

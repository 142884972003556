import Spacer from 'ui/spacer';
import styles from './index.module.scss';

interface ArrowsProps {
  legends: string[];
  color: string;
}

const ArrowLegends = ({ legends, color }: ArrowsProps) => {
  return (
    <Spacer direction="vertical" align="center" size="xsmall">
      {legends.map((legend) => (
        <span key={legend} className={styles.legend} style={{ color }}>
          {legend}
        </span>
      ))}
    </Spacer>
  );
};

export default ArrowLegends;

import {
  Table as AntTable,
  ConfigProvider,
  Empty,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import classnames from 'classnames';
import ErrorResult from 'ui/errorOverlay/errorResult';
import classes from 'ui/table/index.module.scss';
import { ColumnGenerationData, TableProps } from 'ui/table/types';

interface TableContentProps<RecordType>
  extends Pick<TableProps<RecordType>, 'error' | 'refetch'> {
  columns: ColumnGenerationData<RecordType>;
  pagination?: TablePaginationConfig;
  handleTableRowColor?: (data: any) => any;
  error?: {
    name: string;
    message?: string;
  };
  refetch?: () => void;
}

const TableContent = <RecordType extends object>({
  error,
  refetch,
  columns: rawColumns,
  pagination,
  ...props
}: TableContentProps<RecordType>) => {
  const columns = rawColumns.map(({ title, ...rest }) => ({
    title: (
      <Tooltip title={title} placement="topLeft">
        {title}
      </Tooltip>
    ),
    ...rest,
  }));

  return (
    <div className={classnames(classes.tableContainer, 'elevation1')}>
      <ConfigProvider
        renderEmpty={() =>
          error ? (
            <ErrorResult refetch={refetch} />
          ) : (
            <Empty description="no data" />
          )
        }
      >
        <AntTable<RecordType>
          columns={columns}
          rowKey="id"
          pagination={{ ...pagination, position: ['bottomLeft'] }}
          {...props}
          rowClassName={(_record, index) => {
            if (props.handleTableRowColor) {
              return props.handleTableRowColor(_record);
            }
            return classnames({ [classes.tableRowLight]: index % 2 === 0 });
          }}
          className={classes.table}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableContent;

import {
  GetDesignFamilySearchQuery,
  GetDesignFamilySearchQueryVariables,
  useGetDesignFamilySearchQuery,
} from 'generated/graphql';
import { DesignFamily, DesignOfFamily } from './types';
import useQueryForTable, {
  OverrideOrderBy,
} from 'technical/hooks/useQueryForTable';
import { StorageKey, storageKeys } from 'technical/storage/constants';
import { useGetDesignImages } from './useGetImages';
import { isDefined } from 'technical/validation';

export const useGetDesignFamilyData = ({
  filterValues,
  overrideOrderBy,
  storageKey,
  search,
}: {
  filterValues: GetDesignFamilySearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<DesignFamily>;
  storageKey?: StorageKey;
  search?: string;
}) =>
  useQueryForTable<
    DesignOfFamily,
    GetDesignFamilySearchQuery,
    GetDesignFamilySearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKey ?? storageKeys.designFamilies,
    commonFiltersKeys: ['search'],
    useQuery: ({ limit, offset, orderBy, searchText, isOwn }) =>
      useGetDesignFamilySearchQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...filterValues,
            search: search ?? searchText,
            isOwn,
          },
        },
      }),
    getTotal: (res) => res?.designSearch?.count ?? 0,
    map: (res) => res?.designSearch?.resources ?? [],
  });

export const useGetDesignFamily = (param: {
  filterValues: GetDesignFamilySearchQueryVariables['where'];
  overrideOrderBy?: OverrideOrderBy<DesignFamily>;
  storageKey?: StorageKey;
  search?: string;
}) => {
  const designList = useGetDesignFamilyData(param);
  const { imageList, loading: imageLoading } = useGetDesignImages(
    designList.data.map((d) => d.familyId).filter(isDefined),
  );

  const designWithImages = designList.data.map((d) => {
    const image = imageList.filter((i) => i.id === d.familyId);
    return {
      ...d,
      image: imageLoading ? 'loading' : image[0]?.image,
    };
  });
  return {
    ...designList,
    data: designWithImages,
  };
};

import { TFunction } from 'react-i18next';
import EventTab from 'business/resources/patent/components/tabs/eventTab';
import { GetPatentEpoDocumentsQuery } from 'generated/graphql';
import OfficialDocuments from 'business/resources/patent/components/officialDocuments';
import Claims from 'business/resources/patent/components/claims';
import ResourceComment from 'business/resources/shared/components/comments/resourceCommentList';

const getPatentTabs = ({
  id,
  t,
  patentDocuments,
  patentDocumentLoading,
  publicationDate,
}: {
  id: number;
  t: TFunction;
  patentDocuments: NonNullable<
    GetPatentEpoDocumentsQuery['patent_by_pk']
  >['documents'];
  patentDocumentLoading: boolean;
  publicationDate?: string | null;
}) => {
  const tabs = [
    {
      key: 'events',
      label: t('table.title.event'),
      children: <EventTab id={id} />,
    },
  ];

  // No need to display thoses tabs publicationDate is mandatory
  if (publicationDate) {
    tabs.push(
      {
        key: 'official',
        label: t('pages.patent.sections.officialDocuments.header'),
        children: (
          <OfficialDocuments
            documents={patentDocuments}
            loading={patentDocumentLoading}
          />
        ),
      },
      {
        key: 'claims',
        label: t('table.title.claims'),
        children: <Claims patentId={id} />,
      },
    );
  }

  tabs.push({
    key: 'comments',
    label: t('notes.title'),
    children: <ResourceComment id={id} resourceType="patent" />,
  });

  return tabs;
};

export default getPatentTabs;

import { resourceExists } from 'business/common/services';

import { useGetDomainQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { getDomainTimelineEvents } from './getDomainTimelineEvents';
import { isGandiDomain } from './isGandiDomain';
import { useDomainBreadcrumbs } from './useDomainBreadcrumbs';

const useGetDomain = (id: number) => {
  const { t } = useTranslation();
  const { data, ...domainDataState } = useGetDomainQuery({
    variables: { id },
  });
  const { getResourceBreadcrumbs } = useDomainBreadcrumbs();

  const domain = data?.domain_by_pk
    ? {
        ...data.domain_by_pk,
        depositNumber: null,
      }
    : null;

  return {
    id,
    domain,
    isGandiDomain: isGandiDomain(domain?.registrar),
    breadcrumbs: getResourceBreadcrumbs(domain),
    notFound: !resourceExists(domain, domainDataState),
    domainDataState,
    timeline: getDomainTimelineEvents(t, domain),
  };
};

export type DomainPageProps = ReturnType<typeof useGetDomain>;

export default useGetDomain;

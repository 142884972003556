import { OperationVariables, QueryResult } from '@apollo/client';
import { TableProps as AntdTableProps } from 'antd';
import { TableProps } from 'ui/table/types';

import { UserStatus } from 'business/user/types/status';
import { getDefaultFilters } from 'technical/filters/filters';
import {
  OrderBy,
  sorterResultsToOrderBy,
} from 'technical/filters/parser/commonParser/orderByParser';
import {
  CommonTableFilter,
  DateRange,
  DefaultValues,
  StorageTableFilter,
  UrlTableFilter,
} from 'technical/filters/types';
import { useFilters } from 'technical/filters/useFilters';
import { storageKeys } from 'technical/storage/constants';

const DEFAULT_PAGE_SIZE = 30;

type Filters<T> = {
  limit: number;
  offset: number;
  orderBy: OrderBy<T>;
  searchText: string | null;
  isOwn: boolean;
  displayAnnuities: boolean;
  displayAFU: boolean;
  isEffective: boolean;
  userStatus: UserStatus | null;
  dateRange: DateRange | null;
  from: Date | null;
  to: Date | null;
  type: string | undefined;
  myArchives: boolean;
};

export type GeneratedTableProps<RecordType> = Pick<
  TableProps<RecordType>,
  | 'pagination'
  | 'defaultSort'
  | 'onChange'
  | 'filtersValues'
  | 'setFilterValue'
  | 'error'
> &
  Required<Pick<TableProps<RecordType>, 'refetch'>> & {
    loading: boolean;
    filtersActive: boolean;
  };

export type OverrideOrderBy<RecordType> = Readonly<
  [OrderBy<RecordType>, (orderBy: OrderBy<RecordType>) => void]
>;

interface QueryForTableProps<
  RecordType,
  Variables extends OperationVariables,
  Result,
> {
  /**
   * The call to the query given the following filters:
   * - limit
   * - offset
   * - orderBy
   * - searchText
   */
  useQuery: (filters: Filters<RecordType>) => QueryResult<Result, Variables>;

  /**
   * A map function that returns an array of RecordType objects
   */
  map: (result: Result | undefined) => RecordType[];

  /**
   * The total number of row, calculated from the result of the query
   * This total is used to paginate the table
   */
  getTotal?: (result: Result | undefined) => number;

  /**
   * To override the orderBy in the URL
   */
  overrideOrderBy?: OverrideOrderBy<RecordType>;

  storageFiltersKeys?: (keyof StorageTableFilter<RecordType>)[];

  urlFiltersKeys?: (keyof UrlTableFilter<RecordType>)[];

  commonFiltersKeys?: (keyof CommonTableFilter<RecordType>)[];

  subStorageKey: string;
}
/**
 * Use to paginate, sort, search (and filter) automatically queries,
 * The result can also be mapped to a more understandable format
 * The wrapper function only exists to set the RecordType type
 */
const useQueryForTable = <
  RecordType,
  Result,
  Variables extends OperationVariables,
>(
  {
    useQuery,
    map,
    getTotal,
    overrideOrderBy,
    subStorageKey,
    storageFiltersKeys,
    urlFiltersKeys,
    commonFiltersKeys,
  }: QueryForTableProps<RecordType, Variables, Result>,
  defaultValues?: DefaultValues<RecordType>,
) => {
  const filters = useFilters({
    subStorageKey,
    storageFilterKeys: [
      ...getDefaultFilters<RecordType>(),
      ...(commonFiltersKeys ?? []),
      ...(storageFiltersKeys ?? []),
      storageKeys.visibleColumns,
    ],
    urlFilterKeys: [
      ...getDefaultFilters<RecordType>(),
      ...(commonFiltersKeys ?? []),
      ...(urlFiltersKeys ?? []),
    ],
    defaultValues,
  });

  const {
    filtersValues: {
      pageSize,
      page,
      orderBy,
      isEffective,
      search,
      userStatus,
      dateRange,
      from,
      to,
      isOwn,
      displayAFU,
      displayAnnuities,
      myArchives,
      type,
    },
    setFilterValue,
    setFiltersValues,
  } = filters;

  const { data, previousData, loading, error, refetch, ...rest } = useQuery({
    limit: pageSize,
    offset: (page - 1) * pageSize,
    orderBy: overrideOrderBy?.[0] ?? orderBy,
    searchText: search ?? null,
    isEffective,
    userStatus,
    isOwn,
    displayAFU,
    displayAnnuities,
    dateRange,
    from,
    to,
    myArchives,
    type,
  });

  // set filters depending on antd table changes
  const handleOnChange: AntdTableProps<RecordType>['onChange'] = (
    pagination,
    _filters,
    sorterResults,
  ) => {
    const newOrderBy = sorterResultsToOrderBy(sorterResults);
    if (overrideOrderBy) {
      overrideOrderBy[1](newOrderBy);
    }
    setFiltersValues({
      ...filters.filtersValues,
      page: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? DEFAULT_PAGE_SIZE,
      orderBy: newOrderBy,
    });
  };

  // Define usefull custom table props to return
  const tableProps: GeneratedTableProps<RecordType> = {
    pagination: getTotal
      ? {
          current: page,
          pageSize,
          total: getTotal(data ?? previousData),
        }
      : undefined,
    defaultSort: orderBy,
    setFilterValue,
    filtersValues: filters.filtersValues,
    filtersActive: filters.filtersActive,
    onChange: handleOnChange,
    loading,
    error,
    refetch,
  };

  return {
    data: map(data ?? previousData),
    rawData: data ?? previousData,
    tableProps,
    loading,
    error,
    refetch,
    filters,
    ...rest,
  };
};

export default useQueryForTable;

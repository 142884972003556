import { AutoComplete, Form, FormInstance } from 'antd';

import { useTranslation } from 'react-i18next';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import useMultiCompaniesList from 'business/configuration/accessManagement/components/forms/hooks/useMultiCompaniesList';
import CompanyGroupSelect from './companyGroupSelect';

interface MultiCompaniesProps {
  form: FormInstance;
  isDisabled?: boolean;
  formListName: string;
  defaultSelectedCompaniesId: number[];
  currentCompanyId?: number;
}

const MultiCompaniesFormList = ({
  form,
  formListName,
  isDisabled = false,
  defaultSelectedCompaniesId,
  currentCompanyId,
}: MultiCompaniesProps) => {
  const { t } = useTranslation();

  const {
    autoCompleteValue,
    loading,
    onSearch,
    onSelect,
    getGroupSelectOptions,
    setAutoCompleteValue,
    onRemove,
    companiesOptions,
  } = useMultiCompaniesList({
    form,
    formName: formListName,
    defaultSelectedCompaniesId,
    currentCompanyId,
  });

  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => (
        <Spacer size="small" direction="vertical">
          <Form.Item data-testid="multiCompanies">
            <AutoComplete
              data-test-id="multiCompanyAutoComplete"
              className="fullWidth"
              onSearch={onSearch}
              disabled={isDisabled}
              placeholder={t('user.groupModal.autoComplete')}
              onChange={setAutoCompleteValue}
              onSelect={onSelect(add)}
              value={autoCompleteValue}
              options={companiesOptions}
            />
          </Form.Item>

          <IsLoading loading={loading}>
            <Form.Item noStyle>
              {fields.map(({ key, name }) => {
                return (
                  <div key={key} data-testid={`item-${key}`}>
                    <CompanyGroupSelect
                      name={name}
                      groups={getGroupSelectOptions({
                        name,
                      })}
                      onRemove={() => onRemove(remove, name)}
                    />
                  </div>
                );
              })}
            </Form.Item>
          </IsLoading>
        </Spacer>
      )}
    </Form.List>
  );
};

export default MultiCompaniesFormList;

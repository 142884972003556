import {
  isResourceNotEffective,
  resourcesToMapData,
} from 'business/common/services';
import { DomainMap } from 'business/resources/domain/types';
import { useGetDomainFamilyMapQuery } from 'generated/graphql';
import { MapStatus } from 'ui/map/types';

const isGenericExtensionDomain = ({ countryCode }: DomainMap) =>
  countryCode === 'WW';

export const useGetDomainFamilyMap = (familyId: string) => {
  const { data, loading } = useGetDomainFamilyMapQuery({
    variables: { id: familyId },
  });
  const domains = data?.domain ?? [];

  const dataMap = resourcesToMapData(domains);

  const genericExtensions = domains
    .filter(isGenericExtensionDomain)
    .map((domain) => ({
      code: `${domain.extension}`,
      status: isResourceNotEffective(domain)
        ? MapStatus.NotEffective
        : MapStatus.Valid,
      tooltip: null,
    }));

  return {
    loading,
    dataMap,
    genericExtensions,
  };
};

import useGetCompanyArchiveSubscription from 'business/configuration/companies/services/useGetCompanyArchiveSubscription';
import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import CompanySubscriptionInfo from 'business/configuration/companies/components/companySubscriptionInfo';
import ArchiveConfigurationContainer from 'business/configuration/companies/components/archiveConfigurationContainer';
import CompanyArchiveSubSelection from 'business/configuration/companies/components/companyArchiveSubSelection';
import { isBdlAdmin } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface ArchiveSubscriptionProps {
  companyId: number;
}

const ArchiveSubscription = ({ companyId }: ArchiveSubscriptionProps) => {
  const { user } = useLoggedInAppContext();
  const { t } = useTranslation();
  const { refetch, companyArchiveSubscription, error, loading } =
    useGetCompanyArchiveSubscription(companyId);

  const hasSubscription = !!companyArchiveSubscription?.length;

  return (
    <ErrorOverlay error={error} loading={loading}>
      {hasSubscription && (
        <ArchiveConfigurationContainer
          title={t('archive.config.title', {
            context: 'subscriptionInfoTable',
          })}
        >
          <CompanySubscriptionInfo
            companySubInfo={companyArchiveSubscription}
            refetch={refetch}
          />
        </ArchiveConfigurationContainer>
      )}

      {!hasSubscription && isBdlAdmin(user) && (
        <ArchiveConfigurationContainer title={t('archive.config.title')}>
          <CompanyArchiveSubSelection companyId={companyId} />
        </ArchiveConfigurationContainer>
      )}
    </ErrorOverlay>
  );
};

export default ArchiveSubscription;

import {
  ArchiveConfigurationListQuery,
  ArchiveConfigurationListQueryVariables,
  Archive_ArchivePackage_Bool_Exp,
  useArchiveConfigurationListQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { Archive } from 'business/archive/services/types';
import { storageKeys } from 'technical/storage/constants';
import { generateTextSearchConditions } from 'technical/hooks/useQueryForTable/filterCondition';

const generateSearch = (
  searchText: string,
): Archive_ArchivePackage_Bool_Exp => ({
  _or: [...generateTextSearchConditions(searchText, ['title'])],
});

const generateWhere = (searchText: string | null) => {
  return {
    ...(searchText ? generateSearch(searchText) : {}),
  };
};

export const useGetArchiveConfigurationList = () =>
  useQueryForTable<
    Archive,
    ArchiveConfigurationListQuery,
    ArchiveConfigurationListQueryVariables
  >({
    subStorageKey: storageKeys.archiveConfigurations,
    useQuery: ({ limit, offset, searchText, orderBy }) =>
      useArchiveConfigurationListQuery({
        variables: {
          limit,
          offset,
          order_by: orderBy,
          where: generateWhere(searchText),
        },
      }),
    map: (res) => res?.archive_archivePackage ?? [],
  });

import { useTranslation } from 'react-i18next';
import ErrorOverlay from 'ui/errorOverlay';
import Typography from 'ui/typography';

interface ArchiveSubscriptionsInformationProps {
  total: number;
  volume: string;
  nbDeletions: number;
  loading: boolean;
  error?: Error;
}

const ArchiveSubscriptionsInformation = ({
  total,
  volume,
  nbDeletions,
  error,
  loading,
}: ArchiveSubscriptionsInformationProps) => {
  const { t } = useTranslation();

  return (
    <ErrorOverlay error={error} loading={loading}>
      <Typography.Title level={5}>
        {t('archive.information.nbTotalArchive')}: {total}
      </Typography.Title>
      <Typography.Title level={5}>
        {t('archive.information.volumeSize')}: {volume}
      </Typography.Title>
      <Typography.Title level={5}>
        {t('archive.information.nbDeletionRequests')}: {nbDeletions}
      </Typography.Title>
    </ErrorOverlay>
  );
};

export default ArchiveSubscriptionsInformation;

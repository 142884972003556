import passwordValidationModal from 'ui/passwordValidationModal';
import notifyPromise from 'business/common/services/notifyPromise';
import { useSoftDeleteUserMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface DeleteUserHookParam {
  refetch?: () => void;
}

const useDeleteUser = ({ refetch }: DeleteUserHookParam) => {
  const [deleteUserMutation] = useSoftDeleteUserMutation();
  const { t } = useTranslation();

  const deleteUser = async (userId: string) =>
    notifyPromise(t, () => deleteUserMutation({ variables: { userId } }), {
      refetch,
    });

  const confirmDeleteUser = (userId: string, fullName?: string | null) => {
    passwordValidationModal({
      onOk: () => deleteUser(userId),
      modalTitle: t('passwordValidationModal.modalTitle', {
        context: 'deleteUser',
        fullName,
      }),
      warningMessage: t('passwordValidationModal.warningMessage', {
        context: 'delete',
      }),

      instructionText: t('passwordValidationModal.instructionText', {
        context: 'delete',
      }),

      confirmButtonText: t('passwordValidationModal.confirmButtonText', {
        context: 'delete',
      }),
    });
  };

  return {
    confirmDeleteUser,
  };
};

export default useDeleteUser;

import errorReporting from 'technical/error-reporting';

const NO_ERROR_MESSAGE = 'Error is not an instance of error';
const NO_ERROR = new Error('not_an_error');

/**
 * @name getAuthErrorContextFromAuthProviderError
 * @description Extract error message from param if it's an instance of
 * Error or report the inconsistancy and returns `unknown`
 *
 * @param {unknown} error Error catched for the authProvider context
 * @returns the error message if available or `unknown`
 */
export function getAuthErrorContextFromAuthProviderError(error: unknown) {
  if (error instanceof Error) {
    if (error.message) {
      return error.message;
    }
    errorReporting.warning(error, {
      message: 'Error message not defined',
    });
  } else {
    let stringifiedError = null;
    try {
      stringifiedError = JSON.stringify(error);
    } finally {
      errorReporting.error(NO_ERROR, {
        message: NO_ERROR_MESSAGE,
        context: stringifiedError ?? 'Unable to stringify initial error',
      });
    }
  }

  return 'unknown';
}

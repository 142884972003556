import { FormInstance } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import notifyPromise from 'business/common/services/notifyPromise';
import { TabsFamily } from 'business/common/services/types';
import {
  GetUserCustomFiltersQuery,
  useCreateCustomFilterMutation,
  useDeleteCustomFilterMutation,
  useGetUserCustomFiltersQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { isDefined } from 'technical/validation';

type UserCustomFilter = GetUserCustomFiltersQuery['userCustomFilters'][number];

interface UseCustomFiltersProps {
  form: FormInstance<any>;
  family: TabsFamily;
  onApplyFilters: (filters: any, closeFilters?: boolean) => void;
}

const useCustomFilters = ({
  family,
  form,
  onApplyFilters,
}: UseCustomFiltersProps) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();

  const { data: userCustomFilters, refetch } = useGetUserCustomFiltersQuery({
    variables: { filterKey: family },
  });
  const [createFilterMutation] = useCreateCustomFilterMutation();
  const [deleteFilterMutation] = useDeleteCustomFilterMutation();

  const [isFilterCreationModalVisible, setIsFilterCreationModalVisible] =
    useState(false);
  const [selectedCustomFilter, setSelectedCustomFilter] =
    useState<UserCustomFilter | null>(null);

  const customFilters = userCustomFilters?.userCustomFilters ?? [];

  const createNewFilter = async (name: string) =>
    notifyPromise(
      t,
      async () => {
        const filters = await form.validateFields();
        const { data } = await createFilterMutation({
          variables: {
            object: {
              name,
              filters,
              filterKey: family,
              userId: user.id,
            },
          },
        });
        const newCustomFilter = data?.insert_userCustomFilters_one;
        if (isDefined(newCustomFilter)) {
          setSelectedCustomFilter(newCustomFilter);
        }
      },
      {
        refetch,
        reportError: 'error',
        successNotification: t(
          'pages.advancedSearch.customFilters.modal.creationSuccess',
        ),
      },
    );

  const deleteCurrentCustomFilter = selectedCustomFilter
    ? async () =>
        notifyPromise(
          t,
          async () => {
            await deleteFilterMutation({
              variables: {
                id: selectedCustomFilter?.id,
              },
            });
            setSelectedCustomFilter(null);
            onApplyFilters({}, false);
          },
          {
            refetch,
            successNotification: t(
              'pages.advancedSearch.customFilters.modal.deletionSuccess',
            ),
            reportError: 'error',
          },
        )
    : undefined;

  const onClickNewFilter = () => {
    setIsFilterCreationModalVisible(true);

    form
      .validateFields()
      .then((filters) => {
        // set a dummy filter to display the right values in the modal and drawer
        setSelectedCustomFilter({
          filters,
          filterKey: family,
          name: 'test',
          id: -1,
        });
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  useEffect(() => {
    if (selectedCustomFilter && selectedCustomFilter.id !== -1) {
      onApplyFilters(selectedCustomFilter.filters);
    }
  }, [selectedCustomFilter]);

  return {
    customFilters: userCustomFilters?.userCustomFilters ?? [],
    selectedCustomFilter,
    createNewFilter,
    advancedFiltersDrawerActionsProps: {
      onApplyFilters,
      onClickNewFilter,
      resetFilters: () => {
        setSelectedCustomFilter(null);
        form.resetFields();
      },
    },
    deleteCurrentCustomFilter,
    createCustomFilterModalProps: {
      isOpen: isFilterCreationModalVisible,
      onClose: () => setIsFilterCreationModalVisible(false),
      onApply: (filterName: string) => createNewFilter(filterName),
      filters: form.getFieldsValue(),
    },
    filterSelectProps: {
      options: customFilters.map(({ name, id }) => ({
        code: id,
        label: name,
        dataTestId: `${name}`,
      })),
      onChange: (value: number) => {
        const selectedFilter = customFilters.find(
          ({ id }) => id === value,
        ) as UserCustomFilter;
        setSelectedCustomFilter(selectedFilter);
      },
      value: selectedCustomFilter?.id,
      onClear: () => {
        setSelectedCustomFilter(null);
        onApplyFilters({}, false);
        form.resetFields();
      },
    },
  };
};

export default useCustomFilters;

import { QueryHookOptions, QueryResult } from '@apollo/client';
import { CSSProperties, useEffect, useRef } from 'react';
import errorReporting from 'technical/error-reporting';
import useOnScreen from 'technical/hooks/useOnScreen';
import Flex from 'ui/flex';
import styles from './index.module.scss';
import ResourceImage, { BasicImageQueryResult } from './resourceImage';

export type ImageQueryHook<ImageQueryResult = BasicImageQueryResult> = (
  baseOptions: Pick<QueryHookOptions, 'skip'>,
) => Pick<QueryResult<ImageQueryResult>, 'data' | 'error' | 'loading'>;

export interface ResourceImageStyleProps
  extends Pick<CSSProperties, 'height' | 'width'> {
  imgHeight?: number | string;
  imgWidth?: number;
}

interface ResourceImageDisplayProps<
  ImageQueryResult extends BasicImageQueryResult,
> extends ResourceImageStyleProps {
  query: ImageQueryHook<ImageQueryResult>;
  lazyload?: boolean;
  overrideNoResult?: JSX.Element;
  style?: React.CSSProperties;
}

const ResourceImageDisplay = <ImageQueryResult extends BasicImageQueryResult>({
  query,
  lazyload = true,
  imgHeight,
  imgWidth,
  overrideNoResult,
  style,
}: ResourceImageDisplayProps<ImageQueryResult>) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const { error, ...queryResult } = query({
    skip: !isVisible && lazyload,
  });

  useEffect(() => {
    if (error) {
      errorReporting.error(error);
    }
  }, [error]);

  return (
    <div ref={ref} className="fullHeight">
      <Flex
        className={styles.imageDisplayContainer}
        alignItems="center"
        style={style}
        justify="center"
      >
        <ResourceImage
          {...queryResult}
          height={imgHeight}
          width={imgWidth}
          style={style}
          overrideNoResult={overrideNoResult}
        />
      </Flex>
    </div>
  );
};

export default ResourceImageDisplay;

import { UserWithPrivateInfos } from 'business/user/types/user';
import { MIME_TYPES_BY_FILE_FORMAT } from 'technical/fileManagement/constants';

export const allowedFilesTypes = [
  MIME_TYPES_BY_FILE_FORMAT.pdf,
  MIME_TYPES_BY_FILE_FORMAT.zip,
  MIME_TYPES_BY_FILE_FORMAT.step,
];

export const isModalDisabled = (user: UserWithPrivateInfos) =>
  !user.privateInfos.archiveEnabledCompanies.length;

import { Checkbox, Form } from 'antd';
import classNames from 'classnames';
import { splitArrayToChunks } from 'technical/array';
import Flex from 'ui/flex';
import Spacer from 'ui/spacer';
import { FilterField } from '..';
import styles from './index.module.scss';

type Option = {
  code: string;
  label: string;
};

interface CheckboxesProps {
  options: Option[];
  value?: string[];
  onChange?: (value?: string[]) => void;
  columns?: 1 | 2 | 3 | 4;
}

const Checkboxes: React.VFC<CheckboxesProps> = ({
  options,
  onChange,
  value: rawValue,
  columns = 2,
}) => {
  const value = rawValue ?? [];
  const rows = splitArrayToChunks(options, columns);
  return (
    <Spacer size="xsmall" direction="vertical">
      {rows.map((row) => (
        <Spacer size="xxsmall" key={row[0].code}>
          {row.map(({ code, label }) => (
            <Flex className="flex1" key={code}>
              <Checkbox
                checked={value.includes(code)}
                onChange={(e) => {
                  const newVal = e.target.checked
                    ? [...value, code]
                    : value.filter((val) => val !== code);
                  onChange?.(newVal.length ? newVal : undefined);
                }}
              >
                {label}
              </Checkbox>
            </Flex>
          ))}
        </Spacer>
      ))}
    </Spacer>
  );
};

type CheckboxesFieldFilterOptions = {
  checkboxesProps: CheckboxesProps;
};

const CheckboxesFieldFilter: FilterField<CheckboxesFieldFilterOptions> = {
  component: ({ checkboxesProps, label, ...formItemProps }) => (
    <Form.Item
      {...formItemProps}
      label={label}
      className={classNames({ [styles.noLabelStyle]: !label })}
    >
      <Checkboxes {...checkboxesProps} />
    </Form.Item>
  ),
};

export default CheckboxesFieldFilter;

import React from 'react';
import { useAppContext } from 'business/AppBootstrapper';
import Page from 'ui/layout/page';
import Breadcrumb, { BreadcrumbSegment } from 'ui/breadcrumb';
import Spacer from 'ui/spacer';
import Routes from 'business/router/routes';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  breadcrumbs?: BreadcrumbSegment[];
  translationKey?: string;
}

const ConnectedPage = ({ breadcrumbs, children, ...props }: Props) => {
  const { isConnected } = useAppContext();

  return (
    <Page {...props} isConnected={isConnected}>
      {breadcrumbs ? (
        <Spacer direction="vertical" size="small">
          <Breadcrumb segments={breadcrumbs} homeButtonRoute={Routes.Home} />
          {children}
        </Spacer>
      ) : (
        children
      )}
    </Page>
  );
};

export default ConnectedPage;

import { Modal } from 'antd';
import notifyPromise from 'business/common/services/notifyPromise';
import { useGetWhoIsLazyQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import styles from './index.module.scss';

interface WhoIsButtonProps {
  domain: string;
}

const WhoIsButton: React.VFC<WhoIsButtonProps> = ({ domain }) => {
  const { t } = useTranslation();
  const [whoIs, { loading }] = useGetWhoIsLazyQuery();

  const displayWhoIs = () =>
    notifyPromise(
      t,
      async () => {
        const { data: whoIdData } = await whoIs({
          variables: { domain: domain.toLowerCase() },
        });

        if (whoIdData?.whois) {
          Modal.info({
            content: <p className={styles.whoIsText}>{whoIdData.whois}</p>,
            width: 800,
            closable: true,
            maskClosable: true,
            icon: null,
          });
        }
      },
      { successNotification: null, reportError: 'error' },
    );

  return (
    <Button
      size="small"
      type="link"
      onClick={displayWhoIs}
      loading={loading}
      className={styles.whoIsButton}
    >
      <span>{t('table.common.column.whois')}</span>
    </Button>
  );
};

export default WhoIsButton;

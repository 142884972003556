import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';

interface UserInformationProps {
  firstName: string;
  lastName: string;
  email: string;
}

const UserInformation: React.VFC<UserInformationProps> = ({
  email,
  firstName,
  lastName,
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Descriptions layout="horizontal" bordered size="small" className="flex1">
        <Descriptions.Item
          label={t('pages.profile.form.accountManagement.name')}
          span={3}
        >
          {firstName} {lastName}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('pages.profile.form.accountManagement.email')}
          span={3}
        >
          {email}
        </Descriptions.Item>
      </Descriptions>
    </Flex>
  );
};
export default UserInformation;

import { Modal, ModalFuncProps } from 'antd';
import { ReactNode } from 'react';
import PasswordValidationModal from './components';

interface PasswordValidationModalProps {
  onOk: () => void | Promise<void>;
  modalTitle: string;
  modalContent?: ReactNode;
  instructionText: string;
  confirmButtonText: string;
  warningMessage?: string;
  props?: ModalFuncProps;
}
const passwordValidationModal = ({
  onOk,
  modalTitle,
  modalContent,
  instructionText,
  confirmButtonText,
  warningMessage,
  props,
}: PasswordValidationModalProps) => {
  return Modal.confirm({
    closable: true,
    width: 600,
    title: (
      <span>
        <b>{modalTitle}</b>
      </span>
    ),
    cancelButtonProps: { className: 'hidden' },
    okButtonProps: { className: 'hidden' },
    content: (
      <PasswordValidationModal
        modalContent={modalContent}
        warningMessage={warningMessage}
        instructionText={instructionText}
        confirmButtonText={confirmButtonText}
        onOk={onOk}
      />
    ),
    ...props,
  });
};

export default passwordValidationModal;

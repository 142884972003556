import {
  ResourceCommentCreateInput,
  ResourceCommentUpdateInput,
  useCreateResourceCommentMutation,
  useDeleteResourceCommentByIdMutation,
  useGetUserCommentAccessQuery,
  useUpdateResourceCommentMutation,
} from 'generated/graphql';
import logger from 'technical/logger';

export const useCommentActions = (params: {
  resourceId: number;
  resourceType: string;
}) => {
  const { data: userAccess, loading } = useGetUserCommentAccessQuery({
    variables: {
      ...params,
    },
  });
  const [createComment] = useCreateResourceCommentMutation();
  const [updateComment] = useUpdateResourceCommentMutation();
  const [deleteComment] = useDeleteResourceCommentByIdMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteComment({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            id,
          },
        },
      });
    } catch (err) {
      logger.error({
        message: 'Error deleting comment',
        commentId: id,
        err,
      });
    }
  };

  const onUpdate = async (data: ResourceCommentUpdateInput) => {
    try {
      await updateComment({
        fetchPolicy: 'no-cache',
        variables: {
          data,
        },
      });
    } catch (err) {
      logger.error({
        message: 'Error updating comment ',
        err,
        commentId: data.id,
      });
    }
  };

  const onCreate = async (data: ResourceCommentCreateInput) => {
    try {
      await createComment({
        fetchPolicy: 'no-cache',
        variables: {
          data,
        },
      });
    } catch (err) {
      logger.error({ message: 'Error ' });
    }
  };

  return {
    onDelete,
    onUpdate,
    onCreate,
    userCreateCommentEnabled: loading
      ? false
      : userAccess?.getUserCommentAccess,
  };
};

import { ArchiveDeletionRequest } from 'business/archive/services/types';
import { TFunction, useTranslation } from 'react-i18next';
import { formatDate } from 'technical/date';
import Table from 'ui/table';
import { ColumnGenerationData, TableProps } from 'ui/table/types';
import ArchiveDeletionTableActionColumn from './actionsColumn';

type ArchiveDeletionTableProps = Omit<
  TableProps<ArchiveDeletionRequest>,
  'defaultColumns'
>;

const defaultColumns = (
  t: TFunction,
  refetch?: () => void,
): ColumnGenerationData<NonNullable<ArchiveDeletionRequest>> => {
  return [
    {
      key: 'reference',
    },
    {
      key: 'fileName',
    },
    {
      key: 'holder',
    },
    {
      key: 'iua',
    },
    {
      key: 'askDeletionDate',
      render: (_, record) => (
        <span>{formatDate(new Date(record.askDeletionDate))}</span>
      ),
    },
    {
      key: 'userName',
    },
    {
      key: 'archiveDate',
      render: (_, record) => (
        <span>{formatDate(new Date(record.archiveDate))}</span>
      ),
    },
    {
      key: 'reference',
      title: t('table.common.column.action'),
      alwaysVisible: true,
      render: (_, record) => (
        <ArchiveDeletionTableActionColumn
          refetch={refetch}
          archiveName={record.fileName}
          archiveId={record.reference}
        />
      ),
    },
  ];
};

const ArchiveDeletionTable: React.FC<ArchiveDeletionTableProps> = (props) => {
  const { t } = useTranslation();
  const { refetch } = props;
  return (
    <Table
      id="archiveDeletionTable"
      {...props}
      defaultColumns={defaultColumns(t, refetch)}
    />
  );
};

export default ArchiveDeletionTable;

import {
  GetAllResourcesSearchQuery,
  GetAllResourcesSearchQueryVariables,
  useGetAllResourcesSearchQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import {
  AllResults,
  GetAllResourcesSearchParam,
} from 'business/advancedSearch/services/types';
import { storageKeys } from 'technical/storage/constants';

export const useGetAllResults = ({
  filterValues,
  overrideOrderBy,
  search,
}: GetAllResourcesSearchParam) =>
  useQueryForTable<
    AllResults,
    GetAllResourcesSearchQuery,
    GetAllResourcesSearchQueryVariables
  >({
    overrideOrderBy,
    subStorageKey: storageKeys.allSearch,
    useQuery: ({ limit, offset, orderBy, isOwn }) => {
      return useGetAllResourcesSearchQuery({
        variables: {
          limit,
          offset,
          orderBy,
          where: {
            ...filterValues,
            isOwn,
            search,
          },
        },
      });
    },
    getTotal: (res) => res?.allSearchResults?.count ?? 0,
    map: (res) => res?.allSearchResults?.resources ?? [],
  });

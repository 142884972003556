import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import Table from 'ui/table';
import { TRADEMARK_RENEW_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import { useGetTrademarkOsrData } from 'business/resources/osr/services/useGetTrademarkOsrData';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Flex from 'ui/flex';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import Spacer from 'ui/spacer';

interface ITrademarkAffidavits {
  filters: OsrFilterState;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
}

const TrademarkAffidavits = (props: ITrademarkAffidavits) => {
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);
  const { data: affidavitData, tableProps: affidavitTableProps } =
    useGetTrademarkOsrData('AFFIDAVIT', {
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    });

  return (
    <OsrTabs
      tabsItems={[
        {
          key: 'affidavitsTable',
          label: `Liste des affidavits (${affidavitTableProps.pagination?.total})`,
          children: (
            <Table
              editButtonEnabled={false}
              searchBarEnabled={false}
              defaultColumns={TRADEMARK_RENEW_COLUMNS}
              name="affidavitTable"
              {...affidavitTableProps}
              dataSource={affidavitData}
            />
          ),
        },
      ]}
      actions={
        <Spacer size="small" direction="vertical">
          <Flex column style={{ width: '33%' }}>
            <RangePicker
              allowClear
              inputReadOnly={true}
              defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
              onChange={(e) => {
                props.onChangeRangeDate(
                  e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                  e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                );
              }}
            />
          </Flex>
          <Flex>
            <Button type="primary" onClick={() => generateExcel('AFFIDAVIT')}>
              Exporter la liste des affidavits
            </Button>
          </Flex>
        </Spacer>
      }
    />
  );
};

export default TrademarkAffidavits;

import { isDefined } from 'technical/validation';

export const isIncluded = (value: string | undefined, search: string) =>
  isDefined(value) && value.toLowerCase().includes(search.toLowerCase());

// Function to compose string functions
export const pipe =
  <T>(...fns: ((str: string, asset: T) => string)[]) =>
  (str: string, asset: T) =>
    fns.reduce((v, f) => f(v, asset), str);
// String formatting helpers

// Remove everything that is not a letter, a number or a dash
export const sanitize = (str: string) => str.replace(/[^A-Za-z0-9-]/g, '');

// Remove whitespaces
export const removeSpaces = (str: string) => str.replace(/\s/g, '');

// Remove dashes
export const removeDashes = (str: string) => str.replace(/-/g, '');

// Remove commas
export const removeCommas = (str: string) => str.replace(/,/g, '');

// Remove slashes
export const removeSlashes = (str: string) => str.replace('/', '');

// Remove dots and everything after
export const takeBeforeDot = (str: string) =>
  str.includes('.') ? str.substring(0, str.indexOf('.')) : str;

// pad the string with 0s
export const pad = (length: number) => (str: string) =>
  str.padStart(length, '0');

// Remove slashes and dot and everything after
export const removeSlashAndDot = pipe(removeSlashes, takeBeforeDot);

// Remove the first slash and everything before
export const takeAfterSlash = (str: string) =>
  str.includes('/') ? str.split('/')[1] : str;

// Remove the first dash and everything after
export const removeDashAndAfter = (str: string) => str.replace(/(-.*)/, '');

export const onlyNumbers = (str: string) => str.replace(/\D/g, '');

export const removeAccent = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

import { TFunction } from 'react-i18next';

export const requestOptions = ({ t }: { t: TFunction }) => [
  {
    label: t('pages.exportQuery.options.monthlyOp'),
    code: 'monthlyOp',
  },
  {
    label: t('pages.exportQuery.options.dataTable'),
    code: 'tableData',
  },
  {
    label: t('pages.exportQuery.options.yearReport'),
    code: 'yearReport',
    datePicker: true,
  },
];

import { useLoggedInAppContext } from 'business/AppBootstrapper';
import CompanyArchiveKeywords from 'business/configuration/keywords/components/companyKeywordsCard';
import Routes from 'business/router/routes';
import ConnectedPage from 'business/user/helpers/connectedPage';

import { useTranslation } from 'react-i18next';
import { ExtractRouteParams, useParams } from 'react-router';

import Spacer from 'ui/spacer';
import { resourceExists } from 'business/common/services';
import ArchiveInvoiceConfiguration from 'business/configuration/companies/components/archiveInvoiceConfiguration';
import { isBdlAdmin } from 'business/admin/services/permission';
import Flex from 'ui/flex';
import ArchiveConfigurationContainer from 'business/configuration/companies/components/archiveConfigurationContainer';
import ArchiveSubscription from 'business/configuration/companies/components/archiveSubscription';
import useGetCompany from 'business/configuration/companies/services/useGetCompany';
import CompanyNotFound from 'business/configuration/companies/components/companyNotFound';
import CompanyClientRefConfiguration from 'business/configuration/archive/components/companyClientRefConfiguration';

const CompanyArchiveConfigurationPage = () => {
  const { id } =
    useParams<ExtractRouteParams<Routes.CompanyArchiveConfiguration, string>>();
  const companyId = Number(id);

  const { t } = useTranslation();
  const { canSeeCompany, user } = useLoggedInAppContext();
  const { company, loading, getCompanyBaseBreadcrumbs } =
    useGetCompany(companyId);

  return (
    <CompanyNotFound
      canSeeCompany={canSeeCompany(companyId)}
      resourceExists={resourceExists(company, { loading })}
    >
      <ConnectedPage
        breadcrumbs={[
          ...getCompanyBaseBreadcrumbs(company),
          { label: t('archive.title') },
        ]}
        translationKey="companyArchiveConfiguration"
      >
        <Spacer direction="vertical" size="middle">
          <h1 className="headerTitle">
            {t('pages.archive.title', {
              context: 'companySubscription',
              companyName: `${company?.name} - ${company?.companyRef}`,
            })}
          </h1>
          <Spacer size="large" direction="vertical">
            <ArchiveSubscription companyId={companyId} />
            <ArchiveConfigurationContainer
              title={t('archive.clientRefs.title')}
            >
              <CompanyClientRefConfiguration companyId={companyId} />
            </ArchiveConfigurationContainer>

            <Flex justify="space-between">
              <Spacer size="middle">
                <ArchiveConfigurationContainer
                  title={t('archive.keywords.title')}
                >
                  <CompanyArchiveKeywords companyId={companyId} />
                </ArchiveConfigurationContainer>

                {isBdlAdmin(user) && (
                  <ArchiveConfigurationContainer
                    title={t('archive.config.title', {
                      context: 'invoiceConfiguration',
                    })}
                  >
                    <ArchiveInvoiceConfiguration companyId={companyId} />
                  </ArchiveConfigurationContainer>
                )}
              </Spacer>
            </Flex>
          </Spacer>
        </Spacer>
      </ConnectedPage>
    </CompanyNotFound>
  );
};

export default CompanyArchiveConfigurationPage;

import { useCreateArchiveUploadUrlMutation } from 'generated/graphql';

export const useGetGeneratedSignedUrl = () => {
  const [generateSignedUrl] = useCreateArchiveUploadUrlMutation();

  const getGeneratedSignedUrl = async (fileName: string) => {
    const { data } = await generateSignedUrl({
      variables: { fileName },
    });

    if (!data?.createArchiveUploadUrl?.bucketSignedUrl) {
      throw new Error('Error signUrl not provided');
    }
    return {
      ...data.createArchiveUploadUrl,
    };
  };

  return {
    getGeneratedSignedUrl,
  };
};

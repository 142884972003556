import { Col, Row } from 'antd';

interface DnsDataRowProps {
  showRightComponent: boolean;
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
}

const DnsDataRow = ({
  leftComponent,
  rightComponent,
  showRightComponent,
}: DnsDataRowProps) => {
  return (
    <Row gutter={[16, 16]} align="stretch">
      <Col span={showRightComponent ? 12 : 24}>{leftComponent}</Col>
      {showRightComponent && <Col span={12}>{rightComponent}</Col>}
    </Row>
  );
};

export default DnsDataRow;

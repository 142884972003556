/**
 * Binds the DatePicker to date-fns in order to use simple Date object
 * and reduce bundle size.
 *
 * More infos: https://ant.design/docs/react/replace-moment
 */
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import React from 'react';
import { useDateformat } from 'technical/date/useDateFormat';

const AntdDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export type DatePickerProps = React.ComponentProps<typeof AntdDatePicker>;

const DatePicker = React.forwardRef<any, DatePickerProps>((props, ref) => {
  const dateFormat = useDateformat();
  return <AntdDatePicker format={dateFormat} {...props} ref={ref} />;
});

export type RangePickerProps = React.ComponentProps<
  typeof AntdDatePicker.RangePicker
>;

export const RangePicker = React.forwardRef<any, RangePickerProps>(
  (props, ref) => {
    const dateFormat = useDateformat();
    return (
      <AntdDatePicker.RangePicker format={dateFormat} {...props} ref={ref} />
    );
  },
);

export default DatePicker;

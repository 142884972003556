import { Button, Checkbox, Form, Input, notification } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import useCompanyInvoiceConfiguration, {
  InvoiceConfiguration,
} from 'business/configuration/companies/services/useInvoiceConfiguration';
import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import ErrorOverlay from 'ui/errorOverlay';
import Flex from 'ui/flex';
import DomainFeeFormFields from 'business/common/form/domainFeeFormFields';
import UserFormFields from 'business/common/form/userFormFields';
import Spacer from 'ui/spacer';
import ConfigurationCard from 'ui/configurationCard';

const { useWatch } = Form;

const EMAIL_RULES = (t: TFunction): Rule[] => [
  { type: 'email', message: t('errors.wrong_email_address') },
];

interface CompanyDomainInvoicingConfigurationProps {
  companyId: number;
}

const CompanyDomainInvoicingConfiguration = ({
  companyId,
}: CompanyDomainInvoicingConfigurationProps) => {
  const { t } = useTranslation();
  const { data, onSubmit, loading, error, refetch } =
    useCompanyInvoiceConfiguration(companyId);

  const [form] = useForm<InvoiceConfiguration>();

  const automaticInvoicing = useWatch('automaticInvoicing', form);
  const overrideDefaultFee = useWatch('overrideDefaultFee', form);

  async function handleSubmit(formData: InvoiceConfiguration) {
    try {
      await onSubmit(formData);
      notification.success({
        message: t(
          'pages.admin.invoice.domainConfig.modal.notifications.success',
        ),
      });
      refetch();
    } catch (err) {
      logger.error(err);
      errorReporting.error(err);
      notification.error({
        message: t('errors.generic'),
      });
    }
  }

  useEffect(form.resetFields, [data]);

  return (
    <ConfigurationCard
      title={t('pages.admin.invoice.domainConfig.invoiceConfiguration')}
    >
      <Spacer direction="vertical" size="small" align="start">
        <Form<InvoiceConfiguration>
          form={form}
          initialValues={data}
          layout="vertical"
        >
          <Flex column>
            <ErrorOverlay loading={loading} error={error}>
              <Form.Item name="automaticInvoicing" valuePropName="checked">
                <Checkbox>
                  {t(`pages.admin.invoice.domainConfig.automaticInvoicing`)}
                </Checkbox>
              </Form.Item>
              {automaticInvoicing ? (
                <>
                  <UserFormFields
                    nameOnSameLine
                    additionnalProps={{
                      firstName: {
                        label: t('pages.admin.invoice.domainConfig.firstName'),
                      },
                      lastName: {
                        label: t('pages.admin.invoice.domainConfig.lastName'),
                      },
                      email: {
                        label: t('pages.admin.invoice.domainConfig.email'),
                      },
                    }}
                  />
                  <Form.Item rules={EMAIL_RULES(t)} name="cc" label="Cc">
                    <Input />
                  </Form.Item>
                  <Form.Item rules={EMAIL_RULES(t)} name="cci" label="Cci">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t(
                      'pages.companyDetails.invoiceConfiguration.clientReference',
                    )}
                    name="clientReference"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="SIRENnumber"
                    label={t('pages.admin.invoice.domainConfig.SIRENnumber')}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="holderVATnumber"
                    rules={[{ required: true }]}
                    label={t(
                      'pages.admin.invoice.domainConfig.holderVATnumber',
                    )}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="overrideDefaultFee" valuePropName="checked">
                    <Checkbox>
                      {t(`pages.admin.invoice.domainConfig.overrideDefaultFee`)}
                    </Checkbox>
                  </Form.Item>
                  {overrideDefaultFee ? (
                    <DomainFeeFormFields registerFee renewFee transferFee />
                  ) : null}
                </>
              ) : null}
            </ErrorOverlay>
          </Flex>
        </Form>
        <Button
          type="primary"
          onClick={() =>
            form
              .validateFields()
              .then(handleSubmit)
              .catch((err) => logger.error(err))
          }
        >
          {t('common.save')}
        </Button>
      </Spacer>
    </ConfigurationCard>
  );
};

export default CompanyDomainInvoicingConfiguration;

import { Parser } from 'technical/filters/types';

export const readVisibleColumns = (value: string | null) => {
  if (!value) {
    return null;
  }
  const visibleColumns = JSON.parse(value);

  if (Array.isArray(visibleColumns)) {
    return visibleColumns.filter((col) => typeof col === 'string');
  }
  return null;
};

export const storageVisibleColumnsParser = (): Parser<string[] | null> => ({
  readParse: (param) => readVisibleColumns(param),
  writeParse: (value) => JSON.stringify(value),
});

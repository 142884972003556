const EVENT_FILTERS_ADDITIONNAL_FIELDS = [
  'dueDate',
  'completionDate',
  'type',
] as const; // satisfies Fields[];

const commonFields = [
  'title',
  'holder',
  'clientOfficer',
  'bdlOfficer',
] as const; // satisfies Fields[];

export const fieldsPerEventFamilyTabs = {
  all: [
    ...EVENT_FILTERS_ADDITIONNAL_FIELDS,
    'clientRef',
    'id',
    'title',
    'holder',
    'effectiveHolder',
    'clientOfficer',
    'bdlOfficer',
    'countryCode',
    'depositPublicationIssueNumber',
    'completionDate',
  ],
  patent: [
    ...EVENT_FILTERS_ADDITIONNAL_FIELDS,
    'countryCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'issueDate',
    'clientRef',
    'clientRef2',
    ...commonFields,
  ],
  design: [
    ...EVENT_FILTERS_ADDITIONNAL_FIELDS,
    'countryCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'adjournedPublication',
    'clientRef',
    ...commonFields,
  ],
  trademark: [
    ...EVENT_FILTERS_ADDITIONNAL_FIELDS,
    'countryCode',
    'depositPublicationIssueNumber',
    'depositDate',
    'registrationDate',
    'clientRef',
    ...commonFields,
  ],
  domain: [
    ...EVENT_FILTERS_ADDITIONNAL_FIELDS,
    'extension',
    'clientRef',
    'clientRef2',
    ...commonFields,
  ],
} as const; // satisfies Record<TabsFamily, Fields[]>;

export const DOMAIN_EVENT_TYPES = [
  'domain_renew',
  'domain_transfer',
  'domain_create',
];

export const ANNUITY_EVENT_TYPE = 'TAXE ANNUELLE (ANN)';

import React, { useMemo } from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { ExtractRouteParams, useParams } from 'react-router';

import Spacer from 'ui/spacer';
import Map from 'ui/map';
import Routes from 'business/router/routes';
import { useTranslation } from 'react-i18next';
import { resourceExists } from 'business/common/services';
import Table from 'ui/table';
import { domainFamilyColumns } from 'business/resources/domain/components/columns';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import HeaderTitles from 'ui/headerTitles';
import {
  removeExtension,
  useDomainBreadcrumbs,
} from 'business/resources/domain/services/useDomainBreadcrumbs';
import { useGetDomainFamily } from 'business/resources/domain/services/useGetDomainFamily';
import { useGetDomainFamilyInfos } from 'business/resources/domain/services/useGetDomainFamilyInfos';
import { useGetDomainFamilyMap } from 'business/resources/domain/services/useGetDomainFamilyMap';

const DomainFamilyPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: familyId } =
    useParams<ExtractRouteParams<Routes.DomainFamily, string>>();

  const { data, tableProps } = useGetDomainFamily({
    filterValues: { familyId },
  });
  const { dataMap, loading, genericExtensions } =
    useGetDomainFamilyMap(familyId);

  const { getFamilyBreadcrumbs } = useDomainBreadcrumbs();
  const domainFamily = useGetDomainFamilyInfos(familyId);

  const domainFamilyTitle = useMemo(
    () => removeExtension(domainFamily?.title ?? '').toUpperCase(),
    [domainFamily],
  );

  return (
    <ResourceNotFound resourceExists={resourceExists(domainFamily, tableProps)}>
      <ConnectedPage
        breadcrumbs={getFamilyBreadcrumbs(domainFamily)}
        translationKey="domainFamily"
      >
        <HeaderTitles
          title={domainFamilyTitle}
          subtitle={t('pages.header.domainFamily')}
        />
        <Spacer direction="vertical" size="large">
          <Table
            defaultColumns={domainFamilyColumns(t)}
            data-test-id="patentFamiliesTable"
            dataSource={data}
            {...tableProps}
          />
          <Map
            resourceType="domain"
            isDataLoading={loading}
            data={dataMap}
            overrideNotShowCountries={genericExtensions}
          />
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default DomainFamilyPage;

import Spacer from 'ui/spacer';

const ArchiveConfigurationContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Spacer size="small" direction="vertical">
      <span className="tableTitle">{title}</span>
      {children}
    </Spacer>
  );
};
export default ArchiveConfigurationContainer;

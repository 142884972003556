import {
  Archive_DeletionRequest_Bool_Exp,
  GetArchiveDeletionRequestQuery,
  GetArchiveDeletionRequestQueryVariables,
  useGetArchiveDeletionRequestQuery,
} from 'generated/graphql';
import useQueryForTable from 'technical/hooks/useQueryForTable';
import { storageKeys } from 'technical/storage/constants';
import { generateTextSearchCondition } from 'technical/hooks/useQueryForTable/filterCondition';
import { ArchiveDeletionRequest } from 'business/archive/services/types';

const generateSearch = (
  searchText: string,
): Archive_DeletionRequest_Bool_Exp => ({
  _or: [
    {
      user: generateTextSearchCondition(searchText, 'fullName'),
    },
    {
      archive: {
        _or: [
          generateTextSearchCondition(searchText, 'fileName'),
          { company: generateTextSearchCondition(searchText, 'name') },
        ],
      },
    },
  ],
});

const generateWhere = (
  searchText: string | null,
): Archive_DeletionRequest_Bool_Exp => {
  return {
    deletedByUserId: { _is_null: true },
    deletedAt: { _is_null: true },
    ...(searchText ? generateSearch(searchText) : {}),
  };
};

export const useGetArchiveDeletionRequest = () => {
  return useQueryForTable<
    ArchiveDeletionRequest,
    GetArchiveDeletionRequestQuery,
    GetArchiveDeletionRequestQueryVariables
  >({
    subStorageKey: storageKeys.archiveDeletionRequests,
    useQuery: ({ limit, offset, searchText }) =>
      useGetArchiveDeletionRequestQuery({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          where: generateWhere(searchText),
        },
      }),
    map: (res) =>
      res?.archive_deletionRequest.map(
        ({ archiveDocumentMetadataId, archive, createdAt, user }) => {
          return {
            reference: archiveDocumentMetadataId,
            fileName: archive.fileName,
            holder: archive.company?.name,
            iua: archive.arkhineoArchiveId,
            askDeletionDate: createdAt,
            userName: user.fullName,
            archiveDate: archive.createdAt,
          };
        },
      ) ?? [],
    getTotal: (res) =>
      res?.archive_deletionRequest_aggregate.aggregate?.count ?? 0,
  });
};

import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetDomainExpiration } from 'business/configuration/domain/expirationEmail/services/useGetDomainExpiration';
import { useUpdateDomainExpiration } from 'business/configuration/domain/expirationEmail/services/useUpdateDomainExpiration';
import ConfigurationCard from 'ui/configurationCard';
import ErrorOverlay from 'ui/errorOverlay';
import { DomainExpirationMailFormItems } from './types';

interface DomainExpirationMailFormProps {
  companyId: number;
}
const DomainExpirationMailForm = ({
  companyId,
}: DomainExpirationMailFormProps) => {
  const { t } = useTranslation();
  const { domainExpiration, loading, error } =
    useGetDomainExpiration(companyId);
  const { updateDomainExpiration } = useUpdateDomainExpiration();

  return (
    <ConfigurationCard title={t('pages.domain.expiration.emailRecipient')}>
      <span>{t('pages.domain.expiration.emailDescription')}</span>
      <ErrorOverlay loading={loading} error={error}>
        <Form<DomainExpirationMailFormItems>
          layout="vertical"
          onFinish={(values) =>
            updateDomainExpiration({ ...values, companyId })
          }
          name="domainExpirationForm"
          initialValues={domainExpiration}
        >
          <Form.Item
            name="email"
            label={t('pages.domain.expiration.emailRecipient')}
            rules={[{ type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cc"
            label="Cc"
            rules={[
              { type: 'email' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const emailValue = getFieldValue('email');
                  if (value && !emailValue) {
                    return Promise.reject(
                      t('pages.domain.expiration.onlyCcError'),
                    );
                  }
                  if (value && value === emailValue) {
                    return Promise.reject(
                      t('pages.domain.expiration.similarEmailsError'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="save">
            <Button type="primary" htmlType="submit" className="marginTop20">
              {t('common.save')}
            </Button>
          </Form.Item>
        </Form>
      </ErrorOverlay>
    </ConfigurationCard>
  );
};
export default DomainExpirationMailForm;

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface IsValidatedIconProps {
  isValid: boolean;
  iconSize?: number;
}

const IsValidatedIcon = ({ isValid, iconSize = 15 }: IsValidatedIconProps) => {
  return isValid ? (
    <CheckOutlined style={{ color: 'green', fontSize: iconSize }} />
  ) : (
    <CloseOutlined style={{ color: 'red', fontSize: iconSize }} />
  );
};

export default IsValidatedIcon;

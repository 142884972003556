import useDeleteUser from 'business/configuration/accessManagement/services/useDeleteUser';
import useDeleteUserGroup from 'business/configuration/accessManagement/services/useDeleteUserGroup';
import { useSendbackRegistration } from 'business/configuration/accessManagement/services/useSendBackRegistration';
import { useUpdateUserStatus } from 'business/configuration/accessManagement/services/useUpdateActive';
import { UserWithPrivateInfos } from 'business/user/types/user';

export interface HandleTableActionsProps {
  refetch?: () => void;
  currentUser: UserWithPrivateInfos;
  user: any;
}

const useTableActions = ({
  refetch,
  currentUser,
  user,
}: HandleTableActionsProps) => {
  const { confirmDeleteUser } = useDeleteUser({ refetch });
  const { confirmDeleteUserGroup } = useDeleteUserGroup({ refetch });
  const { sendBackRegistration } = useSendbackRegistration();
  const { updateUserStatus } = useUpdateUserStatus({ refetch });
  const isMyself = user.id === currentUser.id;
  const isUserActivePending = !user.emailVerified && user.isActive;

  return {
    confirmDeleteUser,
    sendBackRegistration,
    updateUserStatus,
    isMyself,
    confirmDeleteUserGroup,
    isUserActivePending,
  };
};

export default useTableActions;

import { isBefore, isValid } from 'date-fns';
import { DateRange, Parser } from 'technical/filters/types';

const parseDate = (val: string): Date | null =>
  !Number.isNaN(Number(val)) && isValid(new Date(Number(val)))
    ? new Date(Number(val))
    : null;

export const dateParser = (): Parser<Date | null> => ({
  defaultValue: null,
  readParse: (param) => (param ? parseDate(param) : null),
  writeParse: (value) => (!value ? undefined : value.getTime().toString()),
});

export const dateRangeParser = (): Parser<DateRange | null> => {
  return {
    defaultValue: null,
    readParse: (param) => {
      if (param === null || param === undefined) {
        return null;
      }
      const [from, to] = param.split('-');
      const [fromDate, toDate] = [parseDate(from), parseDate(to)];
      if (!toDate || !fromDate || isBefore(toDate, fromDate)) {
        return null;
      }
      return {
        from: fromDate,
        to: toDate,
      };
    },
    writeParse: (value) => {
      return !value
        ? undefined
        : `${value.from.getTime()}-${value.to.getTime()}`;
    },
  };
};

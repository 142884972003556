import { useInsertCompanyKeywordsMutation } from 'generated/graphql';
import { useState } from 'react';
import useGetCompanyOrDefaultKeywords from './useGetCompanyOrDefaultKeywords';

export const useHandleCompanyKeywordsCard = (companyId: number) => {
  const {
    keywords: companyKeywords,
    loading,
    error,
  } = useGetCompanyOrDefaultKeywords(companyId);
  const [updateKeywordsMutation] = useInsertCompanyKeywordsMutation();
  const [keywords, setKeywords] = useState<string[]>();

  const updateKeywords = () => {
    return updateKeywordsMutation({
      variables: {
        object: {
          companyId,
          options: keywords,
        },
      },
    });
  };

  return {
    companyKeywords,
    setKeywords,
    updateKeywords,
    loading,
    error,
  };
};

import Routes from 'business/router/routes';
import classnames from 'classnames';
import React from 'react';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Card from 'ui/card';
import SuccessIllustration from 'ui/illustrations/successIllustration';
import Spacer from 'ui/spacer';
import styles from './index.module.scss';

interface Props {
  title: string;
  hint: string;
  className?: string;
  dataTestId?: string;
  handleSubmit?: () => void;
  btnText?: string;
  children?: React.ReactElement;
}

const ActionCard = ({
  title,
  hint,
  className,
  dataTestId,
  btnText,
  children,
  handleSubmit = () => history.push(Routes.Home),
}: Props) => {
  return (
    <Card
      className={classnames(styles.card, className)}
      dataTestId={dataTestId}
    >
      <Spacer direction="vertical" align="center">
        <AppLogo />
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.hint}>{hint}</p>
        <div className={styles.successIllustration}>
          {children || <SuccessIllustration />}
        </div>
        <Button
          id="submit-button"
          data-test-id="submit-button"
          type="primary"
          onClick={handleSubmit}
          block
        >
          {btnText}
        </Button>
      </Spacer>
    </Card>
  );
};

export default ActionCard;

import { TFunction } from 'i18next';

const MIN_PASSWORD_LENGTH = 12;
const AT_LEAST_ONE_UPPERCASE_REGEX = /.*[A-Z]/;
const AT_LEAST_ONE_LOWERCASE_REGEX = /.*[a-z]/;
const AT_LEAST_ONE_NUMBER_REGEX = /.*\d/;
const MINIMUM_LENGTH_REGEX = new RegExp(`.{${MIN_PASSWORD_LENGTH},}$`);

const AT_LEAST_ONE_SYMBOL_REGEX = /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/;

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    AT_LEAST_ONE_UPPERCASE_REGEX.source,
    AT_LEAST_ONE_LOWERCASE_REGEX.source,
    AT_LEAST_ONE_NUMBER_REGEX.source,
    AT_LEAST_ONE_SYMBOL_REGEX.source,
    MINIMUM_LENGTH_REGEX.source,
  ].join(')(?=')}).*$`,
);

export const getPasswordRules = (t: TFunction) => {
  return [
    {
      label: t('errors.signUp', {
        context: 'password-doesnt-contain-lowercase',
      }),
      pattern: AT_LEAST_ONE_LOWERCASE_REGEX,
    },
    {
      label: t('errors.signUp', {
        context: 'password-doesnt-contain-uppercase',
      }),
      pattern: AT_LEAST_ONE_UPPERCASE_REGEX,
    },
    {
      label: t('errors.signUp', { context: 'password-doesnt-contain-symbol' }),
      pattern: AT_LEAST_ONE_SYMBOL_REGEX,
    },
    {
      label: t('errors.signUp', { context: 'password-doesnt-contain-number' }),
      pattern: AT_LEAST_ONE_NUMBER_REGEX,
    },
    {
      label: t('errors.signUp', {
        context: 'password-too-short',
        MIN_PASSWORD_LENGTH,
      }),
      pattern: MINIMUM_LENGTH_REGEX,
    },
  ];
};

export const isPasswordValid = (password: string) => {
  return !!password.length && password.match(PASSWORD_VALID_REGEX);
};
